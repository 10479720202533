export interface UnsupportedAccountAlertL {
  header: string,
  subHeader: string
}

export abstract class UnsupportedAccountAlertL {
  public static RU: UnsupportedAccountAlertL = {
    header: 'Аккаунт пока не поддерживается',
    subHeader: 'Для работы в мобильной версии нужно обновить Ваш аккаунт NovaPress до последней версии. Для обновления напишите, пожалуйста нам на '
  }


  public static EN: UnsupportedAccountAlertL = {
    header: 'Account not supported yet',
    subHeader: 'To work in the mobile version, you need to update your NovaPress account to the latest version. For updates, please write to us at '
  }
}