export interface UpdateCheckerL {
  installNow: string,
  newVersionAvailable: string,
  installing: string
}

export abstract class UpdateCheckerL {
  public static RU: UpdateCheckerL = {
    installNow: 'Установить',
    newVersionAvailable: 'Доступна новая версия NovaPress',
    installing: 'Идет установка...'
  }


  public static EN: UpdateCheckerL = {
    installNow: 'Install now',
    newVersionAvailable: 'New version of NovaPress available',
    installing: 'Installing...'
  }
}