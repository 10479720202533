import { Page, RouteList, Router } from '@happysanta/router';

export const ViewIds = {
	Primary: 'view_primary',
	Secondary: 'view_secondary'
}

export interface PanelRoute {
	Route: string,
	PanelId: string,
	ViewId: string
}

export const Pages = {
	SortSubUsers: {
		Route: '/subUsers/sort',
		PanelId: 'panel_sort_sub_users',
		ViewId: ViewIds.Secondary
	},
	
	StartPage: {
		Route: '/',
		PanelId: 'panel_switchto',
		ViewId: ViewIds.Primary
	},

	SwitchTo: {
		Route: '/user/:userId([0-9]+)/switchTo',
		PanelId: 'panel_startpage',
		ViewId: ViewIds.Primary
	},

	Posts: {
		Route: '/user/:userId([0-9]+)/posts',
		PanelId: 'panel_posts',
		ViewId: ViewIds.Primary
	},

	PostDrafts: {
		Route: '/user/:userId([0-9]+)/drafts',
		PanelId: 'panel_drafts',
		ViewId: ViewIds.Primary
	},

	PostTrash: {
		Route: '/user/:userId([0-9]+)/trash',
		PanelId: 'panel_trash',
		ViewId: ViewIds.Primary
	},


	Analytics: {
		Route: '/user/:userId([0-9]+)/analytics',
		PanelId: 'panel_analytics',
		ViewId: ViewIds.Primary
	},

	Settings: {
		Route: '/user/:userId([0-9]+)/settings',
		PanelId: 'panel_settings',
		ViewId: ViewIds.Primary
	},

	DemoAbout: {
		Route: '/about',
		PanelId: 'panel_about',
		ViewId: ViewIds.Primary
	},

	DemoProduct: {
		Route: '/product/:id([0-9]+)',
		PanelId: 'panel_product',
		ViewId: ViewIds.Primary
	},

	DemoInfo: {
		Route: '/info',
		PanelId: 'panel_info',
		ViewId: ViewIds.Secondary
	},

	Logout: {
		Route: '/logout',
		PanelId: 'panel_logout',
		ViewId: ViewIds.Secondary
	}
}

let routeList: RouteList = {
};

for (let key in Pages) {
	let panelRoute: PanelRoute = Pages[key];

	routeList = { [panelRoute.Route]: new Page(panelRoute.PanelId, panelRoute.ViewId), ...routeList };
}

export const router = new Router(routeList);