import React, { useEffect, useState } from 'react';////////////////////////////

import './LaunсherWorkspaceClassicMenu.css';

import { Principal, StateManager, useLocale, useStore } from '../../shared/_index';
import { observer } from 'mobx-react-lite';
import { LaunсherWorkspaceClassicMenuL } from './LaunсherWorkspaceClassicMenu.L';
import { useLocation, useRouter } from '@happysanta/router';

const LaunсherWorkspaceClassicMenu = () => {
	const { subUserStore, routeStore } = useStore();

	let locale: LaunсherWorkspaceClassicMenuL = useLocale(LaunсherWorkspaceClassicMenuL);

	let location = useLocation();
	let router = useRouter();

	const [currentSubUser, setCurrentSubUser] = useState<Principal.SubUser>(null as any);

	const showSubUserMenu = (event: any) => {
		router.pushPopup(routeStore.Popouts.CurrentSubUserMenu, {
			state: StateManager.setState({
				subUser: currentSubUser,
				event: event
			})
		});
	}

	useEffect(() => {
		setCurrentSubUser(subUserStore.subUser);
		console.log(JSON.stringify(subUserStore.subUser));

	}, [subUserStore.subUser]);

	return <>
		{!!currentSubUser && <ul className='launсherWorkspaceClassicMenu'>
			<li>
				<a>
					<div className='launсherWorkspaceClassicMenu_icon launсherWorkspaceClassicMenu_icon_profile'>
						<svg viewBox="0 0 512 512">
							<path d="m392 370c70 24 105 45 105 62c0 0 0 54 0 54c0 0-241 0-241 0c0 0-241 0-241 0c0 0 0-54 0-54c0-17 35-38 105-62c32-12 54-24 65-36c12-12 18-28 18-48c0-8-4-16-12-25c-7-10-12-22-16-38c-1-4-2-7-5-9c-2-2-4-3-7-4c-2-1-4-4-7-9c-2-5-4-12-4-22c0-5 0-10 2-13c2-4 3-6 5-6c0 0 2-2 2-2c-3-17-5-32-6-45c-2-19 5-38 21-58c15-19 42-29 80-29c38 0 65 10 81 29c16 20 22 39 20 58c0 0-6 45-6 45c6 2 9 10 9 21c0 10-2 17-4 22c-3 5-5 8-7 9c-3 1-5 2-7 4c-3 2-4 5-5 9c-3 17-8 29-16 38c-8 9-12 17-12 25c0 20 6 36 18 48c12 12 34 24 65 36" />
						</svg>
					</div>

					<span>
						{/* Личный кабинет */}
						{locale.profile}
					</span>
				</a>
			</li>
			<li>
				<a>
					<div className='launсherWorkspaceClassicMenu_icon launсherWorkspaceClassicMenu_icon_projects'>
						<svg viewBox="0 0 512 512">
							<path d="m112 0l-96 0c-9 0-16 7-16 16l0 96c0 9 7 16 16 16l96 0c9 0 16-7 16-16l0-96c0-9-7-16-16-16z m0 192l-96 0c-9 0-16 7-16 16l0 96c0 9 7 16 16 16l96 0c9 0 16-7 16-16l0-96c0-9-7-16-16-16z m0 192l-96 0c-9 0-16 7-16 16l0 96c0 9 7 16 16 16l96 0c9 0 16-7 16-16l0-96c0-9-7-16-16-16z m192-384l-96 0c-9 0-16 7-16 16l0 96c0 9 7 16 16 16l96 0c9 0 16-7 16-16l0-96c0-9-7-16-16-16z m0 192l-96 0c-9 0-16 7-16 16l0 96c0 9 7 16 16 16l96 0c9 0 16-7 16-16l0-96c0-9-7-16-16-16z m0 192l-96 0c-9 0-16 7-16 16l0 96c0 9 7 16 16 16l96 0c9 0 16-7 16-16l0-96c0-9-7-16-16-16z m192-384l-96 0c-9 0-16 7-16 16l0 96c0 9 7 16 16 16l96 0c9 0 16-7 16-16l0-96c0-9-7-16-16-16z m0 192l-96 0c-9 0-16 7-16 16l0 96c0 9 7 16 16 16l96 0c9 0 16-7 16-16l0-96c0-9-7-16-16-16z m0 192l-96 0c-9 0-16 7-16 16l0 96c0 9 7 16 16 16l96 0c9 0 16-7 16-16l0-96c0-9-7-16-16-16z" />
						</svg>
					</div>

					<span>
						{/* Мои проекты */}
						{locale.projects}
					</span>
				</a>
			</li>
			<li>
				<a>
					<div className='launсherWorkspaceClassicMenu_icon launсherWorkspaceClassicMenu_icon_team'>
						<svg viewBox="0 0 512 512">
							<path d="m338 382l-26 0c-29 0-52-23-52-52l0-17c12-13 20-30 25-47 0-3 3-4 5-6 10-10 12-27 4-39-1-1-2-3-2-5 0-16 0-31 0-46-1-18-6-38-19-51-10-11-25-18-39-21-19-3-38-3-57 2-16 4-31 13-40 27-8 12-12 27-13 41 0 16 0 31 0 47 1 3-2 5-3 8-7 12-4 30 7 39 3 2 4 6 5 9 5 15 13 29 23 42l0 17c0 29-23 52-52 52l-26 0c0 0-47 13-78 78l0 26c0 14 12 26 26 26l364 0c14 0 26-12 26-26l0-26c-31-65-78-78-78-78z m108-140l-22 0c-24 0-44-20-44-44l0-14c10-12 17-25 21-40 1-2 3-3 4-5 9-9 11-23 4-33-1-1-2-3-2-5 0-13 0-26 0-38 0-16-5-32-16-44-9-9-21-15-33-17-16-3-33-3-48 1-14 4-26 12-34 23-7 11-10 23-11 35 0 5 0 10 0 14 12 6 23 13 31 22 17 18 27 43 28 73 0 11 0 22 0 33l0 5c13 24 8 55-10 74 0 0 0 0 0 0-6 15-13 29-22 42l0 6c0 11 9 20 20 20l30 0 5 1c0 0 1 1 2 1l141 0c12 0 22-10 22-22l0-22c-26-55-66-66-66-66z" />
						</svg>
					</div>

					<span>
						{/* Мои сотрудники */}
						{locale.teamMembers}
					</span>
				</a>
			</li>
			<li>
				<a>
					<div className='launсherWorkspaceClassicMenu_icon launсherWorkspaceClassicMenu_icon_socials'>
						<svg viewBox="0 0 512 512">
							<path d="m305 20c52 0 96 19 132 55c36 36 55 80 55 131c0 48-17 90-50 126c-32 36-73 56-120 59c-12 30-31 54-58 72c-26 19-56 29-88 29c-43 0-80-16-110-47c-30-30-46-67-46-109c0-33 10-62 28-89c19-26 42-45 72-57c4-47 24-88 59-120c36-33 78-50 126-50m-129 419c40 0 70-17 89-51c-35-7-66-24-91-50c-26-25-43-56-50-91c-34 19-51 49-51 89c0 29 10 53 30 73c20 20 44 30 73 30m2-207c6 26 18 48 36 66c18 19 40 30 66 36c-1-27-11-51-31-71c-20-20-44-30-71-31m154 102c30-6 55-21 74-45c19-24 29-52 29-83c0-36-13-67-38-92c-26-26-56-38-92-38c-31 0-58 10-82 29c-25 20-39 45-45 75c43 1 79 16 108 46c30 29 45 66 46 108m-258-204c-15 0-27-5-38-15c-10-11-16-23-16-37c0-15 6-28 16-38c11-10 23-15 38-15c15 0 27 5 38 15c10 10 15 23 15 38c0 14-5 26-15 37c-11 10-23 15-38 15m0-82c-8 0-15 3-21 9c-5 6-8 13-8 21c0 7 3 14 8 20c6 6 13 8 21 8c8 0 15-2 21-8c6-6 8-13 8-20c0-8-2-15-8-21c-6-6-13-9-21-9" />
						</svg>
					</div>

					<span>
						{/* Социальные сети */}
						{locale.socials}
					</span>
				</a>
			</li>
		</ul >
		}
	</>
}

export default observer(LaunсherWorkspaceClassicMenu);