export interface UserAnalyticsL {

}

export abstract class UserAnalyticsL {
    public static RU: UserAnalyticsL = {
    }


    public static EN: UserAnalyticsL = {
    }
}