import { Base64 } from "./Base64";
import { Cookies } from "./Cookies";

export class Credentials {
  public static get(): { PrincipalId: number, PrincipalHash: string } {
    let hasV3Cookies = Cookies.get('principalId_v3') && Cookies.get('principalHash_v3');

    if (hasV3Cookies) {
      return {
        PrincipalId: +Cookies.get('principalId_v3'),
        PrincipalHash: Cookies.get('principalHash_v3')
      };
    }
    else {
      return null as any;
    }
  }

  public static getAccessToken(): string {
    let credentials = this.get();

    if (credentials) {
      return Base64.encode(JSON.stringify(credentials));
    }
    else {
      return null as any;
    }
  }
}