import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"
import { useLocation, useRouter } from '@happysanta/router';

import './UserAnalytics.css';

import { Cookies, Principal, useLocale, useStore } from '../../../shared/_index';
import { Panel, PanelHeader, PanelHeaderBack, PanelHeaderButton, Spinner, View } from '@vkontakte/vkui';
import { Icon24Done } from '@vkontakte/icons';
import { UserAnalyticsL } from './UserAnalytics.L';
import UnsupportedAccountAlert from '../../../shared/components/UnsupportedAccountAlert/UnsupportedAccountAlert';
import UnsupportedAnalyticsPricingPlanAlert from '../../../shared/components/UnsupportedAnalyticsPricingPlanAlert/UnsupportedAnalyticsPricingPlanAlert';

const UserAnalytics = (props: { id: string }) => {
	let locale: UserAnalyticsL = useLocale(UserAnalyticsL);

	let router = useRouter();
	let location = useLocation();

	const [isLoading, setIsLoading] = useState(true);

	const [isEmbedLoaded, setIsEmbedLoaded] = useState(false);

	const [isMultiUser, setIsMultiUser] = useState(false);

	const [embedUrl, setEmbedUrl] = useState('');

	const [pricingPlanHasAnalytics, setPricingPlanHasAnalytics] = useState(false);

	const { principalStore, subUserStore, routeStore, languageStore } = useStore();

	const receiveMessage = (event) => {
		if (event.origin !== "https://localhost:4767" && event.origin !== "https://analytics-embed.novapress.com") {
			return;
		}

		if (event.data === 'do-logout') {
			principalStore.logout();

			router.pushPage(routeStore.Pages.StartPage.Route);
		}
		else if (event.data === 'embed-loaded') {
			setIsEmbedLoaded(true);
		}
		else if (event.data === 'change-language-RU' || event.data === 'change-language-EN') {
			languageStore.setLanguage(event.data === 'change-language-RU' ? 'RU' : 'EN', languageStore.use24TimeFormat);
		}
		else if (event.data === 'set-time-format-24' || event.data === 'set-time-format-12') {
			languageStore.setLanguage(languageStore.language, event.data === 'set-time-format-24');
		}

		console.log(event);
	}

	const refreshEmbedUrl = () => {
		let principal = principalStore.principal;

		let principalId = +Cookies.get('principalId_v3');
		let principalHash = Cookies.get('principalHash_v3');

		let baseNovaPressUrl = window.location.hostname == 'localhost'
			? 'https://localhost:4767'
			: 'https://analytics-embed.novapress.com';

		let currentdate = new Date();

		let cookieOptions = {
			domain: window.location.hostname === 'm.novapress.com' || window.location.hostname === 'mobile.novapress.com'
				? '.novapress.com'
				: window.location.hostname,
			path: '/',
			expires: new Date(currentdate.setMonth(currentdate.getMonth() + 1))
		};

		Cookies.set('principalSubUser_v3', `${subUserStore.subUser.UserId}_${subUserStore.subUser.TeamMemberId}`, cookieOptions);

		let embedUrl = `${baseNovaPressUrl}/user/${subUserStore.subUser.UserId}/analytics`;

		embedUrl += `?principalId=${principalId}&principalHash=${principalHash}`;

		setEmbedUrl(embedUrl);
	}

	useEffect(() => {
		principalStore.selectedUserTab = 'analytics';
		principalStore.selectedUserTabChanged.next();

		if (!principalStore.principal || !subUserStore.subUser) {
			return;
		}

		setPricingPlanHasAnalytics(subUserStore.subUser.PricingPlan !== Principal.PricingPlan.Professional2010
			&& subUserStore.subUser.PricingPlan !== Principal.PricingPlan.Professional2015
			&& subUserStore.subUser.PricingPlan !== Principal.PricingPlan.Professional2020);

		setIsMultiUser(principalStore.principal.IsMultiUser);
		refreshEmbedUrl();
		setIsLoading(false);

	}, [principalStore.principal, subUserStore.subUser])

	useEffect(() => {
		window.addEventListener("message", receiveMessage, false);

		return () => {
			window.removeEventListener("message", receiveMessage, false);
		}
	}, [props.id]);

	return <Panel
		id={props.id}>

		{(!isEmbedLoaded && isMultiUser && pricingPlanHasAnalytics) &&
			<Spinner
				size='medium'
				className='userAnalytics__spinner'
			/>
		}

		{(!isLoading && isMultiUser && pricingPlanHasAnalytics) &&
			<iframe
				className={'userAnalytics__iframe ' + (isEmbedLoaded ? "userAnalytics__iframe_loaded" : "")}
				src={embedUrl}
			>
			</iframe>
		}

		{(!isLoading && isMultiUser && !pricingPlanHasAnalytics) && <>
			<UnsupportedAnalyticsPricingPlanAlert />
		</>}

		{(!isLoading && !isMultiUser) && <>
			<UnsupportedAccountAlert />
		</>}
	</Panel>
}

export default React.memo(UserAnalytics);