export interface LaunсherWorkspaceClassicMenuL {
  profile: string,
  projects: string,
  teamMembers: string,
  socials: string
}

export abstract class LaunсherWorkspaceClassicMenuL {
  public static RU: LaunсherWorkspaceClassicMenuL = {
    profile: 'Личный кабинет',
    projects: 'Мои проекты',
    teamMembers: 'Мои сотрудники',
    socials: 'Социальные сети'
  }


  public static EN: LaunсherWorkspaceClassicMenuL = {
    profile: 'Profile',
    projects: 'Projects',
    teamMembers: 'My team',
    socials: 'Accounts'
  }
}