import { autorun, makeAutoObservable } from 'mobx';
import { Subject } from 'rxjs';
import { PostDataShort, Principal } from '../models/_index';
import { Cookies } from '../_index';
import { Store } from './Store';

export default class TimeStore {
    public timeZoneOffsetInMinutes: number = 0;

    public currentClientTime: number = 0;
    public currentSystemTime: number = 0;
    public currentUserTime: number = 0;

    public systemTimeToClientUtcOffset: number = 0;

    public updateClockTimeout = null as any;

    public readonly rootStore: Store;

    constructor(rootStore: Store) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    public setTimeZoneOffset(timeZoneOffsetInMinutes: number) {
        this.timeZoneOffsetInMinutes = timeZoneOffsetInMinutes;
        this.currentUserTime = this.currentSystemTime - 4 * 60 * 60 + this.timeZoneOffsetInMinutes * 60;
    }

    public initClock() {
        this.currentSystemTime = this.currentSystemTime
            ? this.currentSystemTime
            : (this.rootStore.principalStore.principal as any).CurrentSystemTime;

        this.updateClock();
    }

    public updateClock() {
        let isFirstTimeRun = !this.currentClientTime;

        let currentClientTime = this.currentClientTime;
        let currentSystemTime = this.currentSystemTime;

        let systemTimeToClientUtcOffset = this.systemTimeToClientUtcOffset;

        let now = new Date();
        currentClientTime = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()).getTime() / 1000;
        currentClientTime -= currentClientTime % 60;

        let isTimeChanged = false;

        if (isFirstTimeRun) {
            currentSystemTime -= currentSystemTime % 60;

            systemTimeToClientUtcOffset = currentSystemTime - currentClientTime;

            isTimeChanged = true;
        }
        else {
            let systemTime = systemTimeToClientUtcOffset + currentClientTime;

            if (systemTime !== currentSystemTime) {
                currentSystemTime = systemTime;

                isTimeChanged = true;
            }
        }

        this.currentClientTime = currentClientTime;
        this.currentSystemTime = currentSystemTime;
        this.systemTimeToClientUtcOffset = systemTimeToClientUtcOffset;
        this.currentUserTime = this.currentSystemTime - 4 * 60 * 60 + this.timeZoneOffsetInMinutes * 60;

        if (this.updateClockTimeout) {
            clearTimeout(this.updateClockTimeout);
            this.updateClockTimeout = null;
        }

        this.updateClockTimeout = setTimeout(() => {
            this.updateClock();
        }, 1000);
    }
}