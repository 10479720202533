import React from 'react';
import { observer } from "mobx-react-lite"
import { useLocation, useRouter } from '@happysanta/router';

import './SubUserMenu.css';

import { Principal, StateManager, useLocale, useStore } from '../../../shared/_index';
import { SubUserMenuL } from './SubUserMenu.L';
import { ActionSheet, ActionSheetItem } from '@vkontakte/vkui';
import { Icon28DeleteOutline, Icon28EditOutline, Icon28SortOutline } from '@vkontakte/icons';
import SubUserMenuHeader from '../../components/SubUserMenuHeader/SubUserMenuHeader';

const SubUserMenu = () => {
	let locale: SubUserMenuL = useLocale(SubUserMenuL);

	let location = useLocation();
	let router = useRouter();

	const { routeStore } = useStore();

	let event: any = StateManager.getStateValue(location, 'event');
	let subUser: Principal.SubUser = StateManager.getStateValue(location, 'subUser');

	let toggleRef = event ? event.target : null as any;

	if (!event || !subUser) {
		router.popPage();
		return <></>;
	}

	const showEditModal = () => {
		setTimeout(() => {
			router.pushModal(routeStore.Modals.EditSubUser, {
				state: StateManager.setState({
					subUser: subUser
				})
			})
		}, 200);


	}

	const showSortModal = () => {
		setTimeout(() => {
			router.pushPage(routeStore.Pages.SortSubUsers.Route);
		}, 200);
	}

	const showDeleteModal = () => {
		setTimeout(() => {
			router.pushModal(routeStore.Modals.DeleteSubUser, {
				state: StateManager.setState({
					subUser: subUser
				})
			})
		}, 200);
	}

	return <ActionSheet
		toggleRef={toggleRef}
		onClose={() => router.popPage()}
		header={
			<SubUserMenuHeader subUser={subUser} />
		}
		iosCloseItem={
			<ActionSheetItem autoclose mode="cancel">
				{/* Отменить */}
				{locale.cancel}
			</ActionSheetItem>
		}
	>
		<ActionSheetItem
			before={<Icon28EditOutline />}
			autoclose
			onClick={() => showEditModal()}>
			{/* Редактировать */}
			{locale.edit}
		</ActionSheetItem>
		<ActionSheetItem
			onClick={() => showSortModal()}
			autoclose
			before={<Icon28SortOutline />}>
			{/* Поменять местами */}
			{locale.sort}
		</ActionSheetItem>
		<ActionSheetItem
			before={<Icon28DeleteOutline />}
			onClick={() => showDeleteModal()}
			autoclose
			mode="destructive">
			{/* Удалить */}
			{locale.delete}
		</ActionSheetItem>
	</ActionSheet>;
}

export default React.memo(SubUserMenu);