import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './LaunсherWorkspaces.css';
import SimpleBar from 'simplebar-react';
import { Principal, StateManager, useStore } from '../../shared/_index';
import SubUserIcon from '../../shared/components/SubUserIcon/SubUserIcon';
import { classNamesString } from '@vkontakte/vkui/dist/lib/classNames';
import { useRouter } from '@happysanta/router';
import { observable } from 'mobx';

const LaunсherWorkspaces = () => {
	const { principalStore, subUserStore, routeStore } = useStore();

	const [principal, setPrincipal] = useState<Principal>(principalStore.principal);
	const [subUsers, setSubUsers] = useState<Principal.SubUser[]>([]);
	const [currentSubUser, setCurrentSubUser] = useState<Principal.SubUser>(subUserStore.subUser);
	const [principalImageUrl, setPrincipalImageUrl] = useState<string>(null as any);

	useEffect(() => {
		setPrincipal(principalStore.principal);
		setPrincipalImageUrl(principalStore.principal?.PhotoUrl);
	}, [principalStore.principal])

	const router = useRouter();

	useEffect(() => {
		principalStore.get().then(() => {
			let principal = principalStore.principal;

			setPrincipal(principal);
			setPrincipalImageUrl(principal.PhotoUrl);
			setSubUsers(principal.SubUsers);
		});
	}, []);


	const selectSubUser = (subUser: Principal.SubUser) => {
		subUserStore.subUser = subUser;
		setCurrentSubUser(subUser);

		router.replacePage(routeStore.Pages.SwitchTo.Route, { userId: subUser.UserId.toString() });
	}

	const createSubUser = () => {
		router.pushModal(routeStore.Modals.AddSubUser);
	}

	const askQuestion = () => {
		router.pushModal(routeStore.Modals.AskQuestion);
	}

	const showPrincipalMenu = (event: any) => {
		router.pushPopup(routeStore.Popouts.PrincipalMenu, {
			state: StateManager.setState({
				event: event
			})
		});
	}

	return <>
		<SimpleBar className='launcher_workspaces'>
			<ul className='launcher_workspaces__list'>
				{subUsers.map(subUser =>
					<li
						key={subUser.UserId}
						className={classNamesString('launcher_workspaces__list_item', {
							'launcher_workspaces__list_item_active': currentSubUser && currentSubUser.UserId == subUser.UserId
						})}
					>
						<a
							onClick={() => selectSubUser(subUser)}
						>
							<SubUserIcon
								className='launcher_workspaces__list_item_link_icon'
								name={subUser.WorkspaceName}
								icon={subUser.WorkspaceImageUrl}
								width={32}
								height={32} />
						</a>
					</li>
				)}

				<li
					className='launcher_workspaces__list_item launcher_workspaces__list_item_add'
				>
					<a
						onClick={() => createSubUser()}
					>
						<div className='launcher_workspaces__list_item_add_icon'>
							<svg viewBox="0 0 512 512">
								<path d="m417 224c17 0 31 14 31 32c0 18-14 32-31 32l-129 0l0 129c0 17-14 31-32 31c-18 0-32-14-32-31l0-129l-129 0c-17 0-31-14-31-32c0-18 14-32 31-32l129 0l0-129c0-17 14-31 32-31c18 0 32 14 32 31l0 129z" />
							</svg>
						</div>
					</a>
				</li>
			</ul>
		</SimpleBar>

		<ul className='launcher_workspaces__list'>
			<li
				className='launcher_workspaces__list_item launcher_workspaces__list_item_ask'
			>
				<a
					onClick={() => askQuestion()}
				>
					<div className='launcher_workspaces__list_item_ask_icon'>
						<svg viewBox="0 0 512 512">
							<path d="m345 77c25 19 39 46 39 83c0 22-8 42-19 57c-7 9-21 21-40 36l-21 14c-10 8-17 18-20 28c-2 6-3 19-3 29c0 2-1 6-7 6l-62 0c-7 0-7-3-7-5c1-26 4-49 15-66c15-23 57-48 57-48c6-5 11-9 15-15c7-10 12-21 12-32c0-13-3-26-11-36c-10-12-21-18-41-18c-20 0-32 11-40 24c-8 13-7 29-7 43l-77 0c0-54 14-88 44-108c20-14 45-20 75-20c39 0 70 7 98 28z m-103 293c27 1 47 20 46 48c-1 28-22 46-49 45c-26-1-46-19-45-47c1-28 22-47 48-46z" />
						</svg>
					</div>
				</a>
			</li>
			<li
				className='launcher_workspaces__list_item launcher_workspaces__list_item_principal'
			>
				<a
					onClick={(e) => showPrincipalMenu(e)}
				>
					<div className='launcher_workspaces__list_item_principal_icon'
						style={{ backgroundImage: 'url(' + principalImageUrl + ')' }}
					>

					</div>
				</a>
			</li>
		</ul>
	</>;
}

export default observer(LaunсherWorkspaces);