export interface StartPageL {
    header?: string,
    register?: string,
    login?: string,
    subHeader?: string
  }
  
  export abstract class StartPageL {
    public static RU: StartPageL = {
      header: 'NovaPress - Менеджер публикации и аналитики для бизнеса',
      register: 'Зарегистрироваться',
      login: 'Войти в систему',
      subHeader: 'Инструменты для роста в социальных сетях'
    }
  
  
    public static EN: StartPageL = {
      header: 'NovaPress - Publishing and Analytics for business',
      register: 'Sign Up',
      login: 'Log in',
      subHeader: 'Simple tools for social media growth'
    }
  }