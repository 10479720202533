export interface LinkWithMetaTags {
    LinkUrl: string,
    MetaTags: LinkWithMetaTags.MetaTag[]
}

export namespace LinkWithMetaTags {
    export interface MetaTag {
        Name: string,
        Value: string
    }

    export abstract class MetaTagType {
        public static HtmlTitle = "html-title";
        
        public static OgLocale = "og:locale";
        public static OgType = "og:type";
        public static OgUrl = "og:url";
        public static OgSiteName = "og:site_name";
      
        public static ArticleSection = "article:section";
        public static ArticlePublishedTime = "article:published_time";
      
        public static TwitterCard = "twitter:card";
        public static TwitterUrl = "twitter:url";
        public static TwitterImage = "twitter:image";
        public static TwitterSite = "twitter:site";
        public static TwitterTitle = "twitter:title";
        public static TwitterDescription = "twitter:description";
      
        public static Title = "title";
        public static OgTitle = "og:title";
      
        public static Description = "description";
        public static OgDescription = "og:description";
      
        public static OgImage = "og:image";
        public static OgImageWidth = "og:image:width";
        public static OgImageHeight = "og:image:height";
      
        public static ExternalVideo = "external:video";
      }
}