import '@vkontakte/vkui/dist/vkui.css';
import 'simplebar-react/dist/simplebar.min.css';
import './App.css';

import React, { Profiler, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"
import { Epic, SplitCol, SplitLayout, View } from '@vkontakte/vkui';
import { useLocation, useRouter } from '@happysanta/router';
import { Cookies, Principal, useStore } from './shared/_index';
import PrivateToolbar from './shell/components/PrivateToolbar/PrivateToolbar';
import UserSettings from './settings/pages/UserSettings/UserSettings';
import Login from './landing/modals/Login/Login';
import SendRestorePasswordLetter from './landing/modals/SendRestorePasswordLetter/SendRestorePasswordLetter';
import Register from './landing/modals/Register/Register';
import ConfirmRegistrationCode from './landing/modals/ConfirmRegistrationCode/ConfirmRegistrationCode';
import SelectSubUser from './shell/modals/SelectSubUser/SelectSubUser';
import SubUserMenu from './shell/popouts/SubUserMenu/SubUserMenu';
import DeleteSubUser from './shell/modals/DeleteSubUser/DeleteSubUser';
import SortSubUsers from './shell/pages/SortSubUsers/SortSubUsers';
import StartPage from './landing/pages/StartPage/StartPage';
import UserAnalytics from './analytics/pages/UserAnalytics/UserAnalytics';
import ChangeRestoredPassword from './landing/modals/ChangeRestoredPassword/ChangeRestoredPassword';
import CheckRestorePasswordCode from './landing/modals/CheckRestorePasswordCode/CheckRestorePasswordCode';
import UserPosts from './posts/pages/UserPosts/UserPosts';
import EditSubUser from './shell/modals/EditSubUser/EditSubUser';
import SwitchTo from './shell/pages/SwitchTo/SwitchTo';
import PublishResults from './posts/modals/PublishResults/PublishResults';
import UpdateChecker from './shared/components/UpdateChecker/UpdateChecker';
import { ModalRoot } from './vkui/components/ModalRoot/ModalRootAdaptive';
import SelectPostsContext from './posts/modals/SelectPostsContext/SelectPostsContext';
import PostMenu from './posts/popouts/PostMenu/PostMenu';
import ComposeTimePicker from './shared/modals/ComposeTimePicker/ComposeTimePicker';
import ChangeLanguageMenu from './landing/popouts/ChangeLanguageMenu/ChangeLanguageMenu';
import DeletePost from './posts/modals/DeletePost/DeletePost';
import ReturnToQueued from './posts/modals/ReturnToQueued/ReturnToQueued';
import Logout from './landing/pages/Logout/Logout';
import Launcher from './launcher/Launсher/Launcher';
import AskQuestion from './shared/modals/AskQuestion/AskQuestion';
import PrincipalMenu from './shared/popouts/PrincipalMenu/PrincipalMenu';
import EditPrincipal from './shell/modals/EditPrincipal/EditPrincipal';
import CurrentSubUserMenu from './shell/popouts/CurrentSubUserMenu/CurrentSubUserMenu';

const App = () => {
	const [showTabsBar, setShowTabsBar] = useState(false);
	const [showSideBar, setShowSideBar] = useState(false);

	let location = useLocation();
	let router = useRouter();

	const { principalStore, subUserStore, pageSizeStore, routeStore, languageStore } = useStore();

	useEffect(() => {
		let isTaskBarShown = pageSizeStore.width < 750;
		let isSidebarShown = !isTaskBarShown;

		if (isTaskBarShown) {
			isTaskBarShown = subUserStore.subUser
				&& routeStore.ViewId === routeStore.ViewIds.Primary
				&& +routeStore.Params.showEditor !== 1
				? true
				: false;
		}

		if (isSidebarShown) {
			isSidebarShown = subUserStore.subUser
				&& routeStore.ViewId === routeStore.ViewIds.Primary
				&& +routeStore.Params.showEditor !== 1
				? true
				: false;
		}

		if (!isTaskBarShown && document.body.classList.contains('taskbar__shown')) {
			document.body.classList.remove('taskbar__shown');
		}
		else if (isTaskBarShown && !document.body.classList.contains('taskbar__shown')) {
			document.body.classList.add('taskbar__shown');
		}

		if (showTabsBar != isTaskBarShown) {
			setShowTabsBar(isTaskBarShown);
		}

		if (!isSidebarShown && document.body.classList.contains('sidebar__shown')) {
			document.body.classList.remove('sidebar__shown');
		}
		else if (isSidebarShown && !document.body.classList.contains('sidebar__shown')) {
			document.body.classList.add('sidebar__shown');
		}

		if (showSideBar != isSidebarShown) {
			setShowSideBar(isSidebarShown)
		}
	}, [principalStore.principal, subUserStore.subUser, pageSizeStore.width, routeStore.ViewId, routeStore.Params]);

	const checkChangeLanguageKey = (event) => {
		if (event.code === 'KeyQ' && (event.altKey)) {
			languageStore.setLanguage(languageStore.language === 'RU' ? 'EN' : 'RU', languageStore.use24TimeFormat);
		}
	}

	useEffect(() => {
		document.addEventListener('keydown', checkChangeLanguageKey);

		return () => {
			document.removeEventListener('keydown', checkChangeLanguageKey)
		};
	}, []);

	useEffect(() => {
		principalStore.get().then(() => {
			if (!principalStore.principal && !!Cookies.get('principalId_v3')) {
				router.replacePage(routeStore.Pages.Logout.Route);
			}
		});
	}, [location]);

	const getModals = () => {
		return <ModalRoot activeModal={location.getModalId()}
			onClose={() => router.popPage()}>

			{/* Общие модальные окна */}
			<AskQuestion id={routeStore.Modals.AskQuestion} dynamicContentHeight={true} />

			{/* Модальные окна входа в систему и восстановления пароля */}
			<Login id={routeStore.Modals.Login} dynamicContentHeight={true} />
			<SendRestorePasswordLetter id={routeStore.Modals.SendRestorePasswordLetter} dynamicContentHeight={true} />
			<CheckRestorePasswordCode id={routeStore.Modals.CheckRestorePasswordCode} dynamicContentHeight={true} />
			<ChangeRestoredPassword id={routeStore.Modals.ChangeRestoredPassword} dynamicContentHeight={true} />

			{/* Модальные окна регистрации */}
			<Register id={routeStore.Modals.Register} dynamicContentHeight={true} />
			<ConfirmRegistrationCode id={routeStore.Modals.ConfirmRegistrationCode} dynamicContentHeight={true} />

			{/* Модальные окна оболочки */}
			<SelectSubUser id={routeStore.Modals.SelectSubUser} dynamicContentHeight={true} />
			<EditSubUser id={routeStore.Modals.AddSubUser} dynamicContentHeight={true} />
			<EditSubUser id={routeStore.Modals.EditSubUser} dynamicContentHeight={true} />
			<DeleteSubUser id={routeStore.Modals.DeleteSubUser} dynamicContentHeight={true} />
			<EditPrincipal id={routeStore.Modals.EditPrincipal} dynamicContentHeight={true} />

			{/* Модальные окна постов */}
			<PublishResults id={routeStore.Modals.PublishResults} dynamicContentHeight={true} />
			<SelectPostsContext id={routeStore.Modals.SelectPostsContext} dynamicContentHeight={true} settlingHeight={100} />
			<ComposeTimePicker id={routeStore.Modals.ComposeTimePicker} dynamicContentHeight={true} />
			<DeletePost id={routeStore.Modals.DeletePost} dynamicContentHeight={true} />
			<ReturnToQueued id={routeStore.Modals.ReturnToQueued} dynamicContentHeight={true} />
		</ModalRoot>;
	}

	const getPopouts = () => {
		let popoutId = location.getPopupId();

		if (popoutId === routeStore.Popouts.SubUserMenu) {
			return <SubUserMenu />
		}
		else if (popoutId === routeStore.Popouts.CurrentSubUserMenu) {
			return <CurrentSubUserMenu />
		}
		else if (popoutId === routeStore.Popouts.PostMenu) {
			return <PostMenu />
		}
		else if (popoutId === routeStore.Popouts.ChangeLanguage) {
			return <ChangeLanguageMenu />
		}
		else if (popoutId === routeStore.Popouts.PrincipalMenu) {
			return <PrincipalMenu />
		}
		else {
			return null;
		}
	}

	const modals = getModals();
	const popouts = getPopouts();

	return <SplitLayout modal={modals} popout={popouts}>
		{showSideBar && <SplitCol fixed width={285} maxWidth={285}>
			<Launcher />
		</SplitCol>}
		<SplitCol>
			<Epic
				activeStory={location.getViewId()}
				tabbar={
					showTabsBar && <PrivateToolbar></PrivateToolbar>
				}
			>
				<View id={routeStore.ViewIds.Primary}
					onSwipeBack={() => router.popPage()}
					history={location.hasOverlay() ? [] : location.getViewHistory(routeStore.ViewIds.Primary)}
					activePanel={location.getViewActivePanel(routeStore.ViewIds.Primary) as any}>
					<StartPage id={routeStore.Pages.StartPage.PanelId} />

					<SwitchTo id={routeStore.Pages.SwitchTo.PanelId} />

					<UserPosts id={routeStore.Pages.Posts.PanelId} />
					<UserPosts id={routeStore.Pages.PostDrafts.PanelId} />
					<UserPosts id={routeStore.Pages.PostTrash.PanelId} />

					<UserAnalytics id={routeStore.Pages.Analytics.PanelId} />
					<UserSettings id={routeStore.Pages.Settings.PanelId} />
				</View>

				<View id={routeStore.ViewIds.Secondary}
					onSwipeBack={() => router.popPage()}
					history={location.hasOverlay() ? [] : location.getViewHistory(routeStore.ViewIds.Secondary)}
					activePanel={location.getViewActivePanel(routeStore.ViewIds.Secondary) as any}>
					<SortSubUsers id={routeStore.Pages.SortSubUsers.PanelId} />
					<Logout id={routeStore.Pages.Logout.PanelId} />
				</View>
			</Epic>

			<UpdateChecker />
		</SplitCol>
	</SplitLayout>;
}

export default observer(App);