import React from 'react';
import { observer } from "mobx-react-lite"
import { SocialPage, ProjectFeed } from '../../../shared/_index';

import './SocialIcon.css';
import { DomainUtils } from '../../services/_index';
import SocialNetworkIcon from '../SocialNetworkIcon/SocialNetworkIcon';

export interface SocialIconProps {
	className?: string | undefined,

	width: number | string,
	height: number | string,

	socialPage?: SocialPage,
	feed?: ProjectFeed,

	corners: 'round' | 'squared'
}

const SocialIcon = (props: SocialIconProps) => {
	let socialPage: SocialPage = props.feed
		? props.feed.SourcePage as any
		: props.socialPage;

	let imageUrl = socialPage.PhotoUrl
		? socialPage.PhotoUrl
		: SocialPage.getPageLogoUrl(socialPage);

	let code: string = props.feed
		? props.feed.Type.replace('Feed', '').replace('Channel', '').toLowerCase()
		: socialPage.SocialNetwork.toLowerCase() as any;

	let iconUrl: string = props.feed && props.feed.Type === "SyndicationFeed"
		? `https://favicon.yandex.net/favicon/` + DomainUtils.extractDomain(props.feed.SiteUrl as any)
		: '';

	return <div
		className={"socialIcon " + (props.className ? props.className : "")}
		style={{
			width: props.width,
			height: props.height
		}}
	>
		{imageUrl && <>
			<div className={"socialIcon__logo " + (props.corners === "round" ? "socialIcon__logo_round" : "")}
				style={{
					backgroundImage: 'url(' +imageUrl + ')'
				}}
			/>

			<SocialNetworkIcon
				className='socialIcon__icon'
				code={code}
			/>
		</>}

		{!imageUrl && <>
			<SocialNetworkIcon
				className={"socialIcon__logo " + (props.corners === "round" ? "socialIcon__logo_round" : "")}
				code={code}
			/>

			{iconUrl && <div
				className='socialIcon__icon'
				style={{
					backgroundImage: 'url(' + iconUrl + ')'
				}}
			/>}
		</>}
	</div>;
}

export default React.memo(SocialIcon);