import { useLocation, useRouter } from '@happysanta/router';
import React, { useEffect, useState } from 'react';

import './SendRestorePasswordLetter.css';

import { Icon24Dismiss } from '@vkontakte/icons';
import { Button, FormItem, FormLayout, Group, Input, ModalPage, ModalPageHeader, PanelHeaderBack, PanelHeaderButton, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { Api, ComponentHelper, StateManager, useLocale, useStore } from '../../../shared/_index';
import { SendRestorePasswordLetterL } from './SendRestorePasswordLetter.L';

const SendRestorePasswordLetter = (props: { id: string, dynamicContentHeight: boolean }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let locale: SendRestorePasswordLetterL = useLocale(SendRestorePasswordLetterL);
	let router = useRouter();
	let location = useLocation();

	const { routeStore } = useStore();

	const [email, setEmail] = useState(StateManager.getStateValue(location, 'email') ?? '');

	const [showErrors, setShowErrors] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);


	useEffect(() => {
		ComponentHelper.focusModalInput(props.id);
	}, [props.id]);

	const doSendMail = () => {
		if (!email) {
			setShowErrors(true);
			return;
		}

		setIsSubmitting(true);

		Api.executeMethod('principal.sendRestorePasswordCode', {
			email: email
		}).then((response: any) => {
			let operationId: string = response.OperationId;

			router.pushModal(routeStore.Modals.CheckRestorePasswordCode, {
				state: StateManager.setState({
					email: email,
					operationId: operationId
				})
			});
		});
	}

	return <ModalPage
		id={props.id}
		header={
			<ModalPageHeader
				before={
					<PanelHeaderBack label="" onClick={() => router.popPage()} />
				}
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => router.popPage()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{/* Восстановление пароля */}
				{locale.restorePasswordHeader}
			</ModalPageHeader>
		}
	>
		<Group>
			<FormLayout>
				<FormItem
					top="E-mail"
					status={!email && showErrors ? "error" : "default"}
					bottom={
						!email && showErrors
							// Пожалуйста, введите электронную почту
							? locale.noEmailError
							: null
					}
				>
					<Input
						type="email"
						name="email"
						className='to-focus'
						crossOrigin={"anonymous"}
						value={email}
						onKeyPress={(e) => ComponentHelper.onEnterKeyPress(e, () => doSendMail())}
						onChange={e => setEmail(e.target.value)}
					/>
				</FormItem>

				<FormItem>
					<Button
						size="l"
						stretched
						onClick={() => doSendMail()}
						loading={isSubmitting}>
						{/* Восстановить пароль */}
						{locale.restorePassword}
					</Button>
				</FormItem>
			</FormLayout>
		</Group>
	</ModalPage>;
}

export default React.memo(SendRestorePasswordLetter);