import React from 'react';
import { observer } from "mobx-react-lite"
import { SocialPage } from '../../../shared/_index';

import './ProjectIcon.css';
import { ProjectDataShort } from '../../models/ProjectDataShort';

export interface ProjectIconProps {
	className?: string | undefined,

	width: number | string,
	height: number | string,

	project: ProjectDataShort,

	corners: 'round' | 'squared'
}

const ProjectIcon = (props: ProjectIconProps) => {
	let socialPage: SocialPage = props.project.FirstPage
		? props.project.FirstPage.SourcePage
		: null as any;

	let imageUrl = socialPage ? (socialPage.PhotoUrl
		? socialPage.PhotoUrl
		: SocialPage.getPageLogoUrl(socialPage)) : '';

	let firstLetter = !imageUrl
		? props.project.Name.substring(0, 1).toUpperCase()
		: '';

	return <div
		className={"projectIcon " + (props.className ? props.className : "")}
		style={{
			width: props.width,
			height: props.height
		}}
	>
		{imageUrl && <>
			<div className={"projectIcon__logo " + (props.corners === "round" ? "projectIcon__logo_round" : "")}
				style={{
					backgroundImage: 'url(' + imageUrl + ')'
				}}
			/>
		</>}

		{!imageUrl && <>
			<div className={"projectIcon__logo projectIcon__logo_letters " + (props.corners === "round" ? "projectIcon__logo_round" : "")}>
				<div>
					{firstLetter}
				</div>
			</div>

		</>}
	</div>;
}

export default React.memo(ProjectIcon);