import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './AskQuestion.css';

import { useLocale, useStore } from '../../../shared/_index';
import { AskQuestionL } from './AskQuestion.L';
import { Group, ModalPage, ModalPageHeader, PanelHeaderBack } from '@vkontakte/vkui';
import { useLocation, useRouter } from '@happysanta/router';
import { classNamesString } from '@vkontakte/vkui/dist/lib/classNames';
import { DateUtils } from '../../services/DateUtils';


export interface AskQuestionProps {
	id: string,
	dynamicContentHeight?: boolean
}

const AskQuestion = (props: AskQuestionProps) => {
	let router = useRouter();
	let location = useLocation();

	let locale: AskQuestionL = useLocale(AskQuestionL);

	return <ModalPage
		id={props.id}
		dynamicContentHeight={props.dynamicContentHeight}
		header={
			<ModalPageHeader
				before={
					<PanelHeaderBack label="" onClick={() => router.popPage()} />
				}
			>
				{/* Контакты */}
				{locale.header}
			</ModalPageHeader>
		}
	>
		<Group className='ask_question'>
			<span>
				{/* По любым вопросам пишите, пожалуйста, на */}
				{locale.hint1}
			</span>

			&nbsp;<a href='mailto:support@novapress.com'>support@novapress.com</a>
			<br /><br />
			<div>
				{/* Будем рады ответить на все интересующие Вас вопросы. */}
				{locale.hint2}
			</div >
		</Group >
	</ModalPage >;
}

export default React.memo(AskQuestion);