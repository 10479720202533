/** Авторизованный в системе пользователь */
export interface Principal {
    Id: number,
    Name: string,
    Email: string,
    UniqueId: string,
    PhotoUrl: string,
    IsMultiUser: boolean,
    
    PrincipalHash: string,

    SubUsers: Principal.SubUser[]
}

export namespace Principal {
    export interface SubUser {
        UserId: number,
        TeamMemberId: number,
        BillingAccountId: number,
        WorkspaceName: string,
        WorkspaceImageUrl: string,
        IsDeleted: boolean,
        PricingPlan: number,
        UseSimpleUI: boolean,
        LicenseExpiredAt: number,
        TimeZoneId: string,
        TimeZoneOffsetInMinutes: number,
        Language: string,
        Use24TimeFormat: boolean,
    }

    export enum PricingPlan {
        Professional2010 = 1,
        Professional2015 = 2,

        CorporateS = 3,
        CorporateM = 4,
        CorporateL = 5,

        Mini = 6,
        Standart = 7,
        Enterprise = 8,

        MicroRub = 9,
        MiniRub = 10,
        StandartRub = 11,

        Enterprise1Rub = 12,
        Enterprise2Rub = 13,
        Enterprise3Rub = 14,

        Micro = 15,

        Professional2020 = 16,

        Micro_RUB2020 = 17,
        Basic_RUB2020 = 18,
        Standart_RUB2020 = 19,
        Ultra_RUB2020 = 20,

        Micro_USD2020 = 21,
        Basic_USD2020 = 22,
        Standart_USD2020 = 23,
        Ultra_USD2020 = 24,

        Free = 777
    }
}