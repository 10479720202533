import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './PostEditorPage.css';

import { Api, Cookies, ProjectItem, useLocale, useStore } from '../../../shared/_index';
import { PostEditorPageL } from './PostEditorPage.L';
import { Button, Epic, FormItem, FormLayout, Panel, PanelHeader, PanelHeaderBack, PanelHeaderButton, Placeholder, Spinner, SplitCol, SplitLayout, Textarea, View } from '@vkontakte/vkui';
import { useLocation, useRouter } from '@happysanta/router';
import StartPage from '../../../landing/pages/StartPage/StartPage';
import { Icon24Done } from '@vkontakte/icons';
import AutoSizeTextArea from '../../../shared/components/AutoSizeTextArea/AutoSizeTextArea';


const PostEditorPage = (props: { id: string }) => {
	let locale: PostEditorPageL = useLocale(PostEditorPageL);

	let router = useRouter();
	let location = useLocation();

	const { principalStore, subUserStore } = useStore();

	const [projectId] = useState(+location.getParams().itemProjectId);
	const [itemHash] = useState(location.getParams().itemHash);
	let [at] = useState(location.getParams().at);

	const [isLoading, setIsLoading] = useState(true);

	const [isEditorLoaded, setIsEditorLoaded] = useState(false);

	const [isSaving, setIsSaving] = useState(false);

	const [isSaved, setIsSaved] = useState(false);

	const receiveMessage = (event) => {
		if (event.origin !== "https://localhost:4767" && event.origin !== "https://posteditor.novapress.com") {
			return;
		}

		if (event.data === 'post-editor-closed') {
			if (!isSaved) {
				router.popPage();
			}
		}
		else if (event.data === 'post-editor-saved') {
			setIsSaved(true);
			principalStore.postEditorClosed.next({
				projectId: projectId,
				itemHash: itemHash
			});

			setIsEditorLoaded(false);
			setIsSaving(true);
		}
		else if (event.data === 'post-editor-loaded') {
			setIsEditorLoaded(true);
		}


		console.log(event);
	}

	const onPostEditorPageClosed = () => {
		setIsSaving(false);
		router.popPage();
	};

	useEffect(() => {
		principalStore.get().then(() => {
			setIsLoading(false);
		});

		window.addEventListener("message", receiveMessage, false);

		document.body.classList.add('postEditorPage__shown');

		let $onEditorPageClosed = principalStore.postEditorPageClosed.subscribe(() => onPostEditorPageClosed());

		return () => {
			window.removeEventListener("message", receiveMessage, false);
			document.body.classList.remove('postEditorPage__shown');

			$onEditorPageClosed.unsubscribe();
		}
	}, [props.id]);

	let principalId = +Cookies.get('principalId_v3');
	let principalHash = Cookies.get('principalHash_v3');

	let baseNovaPressUrl = window.location.hostname == 'localhost'
		? 'https://localhost:4767'
		: 'https://posteditor.novapress.com';

	let editorUrl = `${baseNovaPressUrl}/user/${subUserStore.subUser.UserId}/${projectId}/editor-embed/post`;

	if (itemHash !== '-') {
		editorUrl += `/edit/${itemHash}`;
	}
	else if (at) {
		editorUrl += `/create/${at}`;
	}
	else {
		editorUrl += `/create`;
	}

	editorUrl += `?principalId=${principalId}&principalHash=${principalHash}`;

	return <div id={props.id} className='postEditorPage'>
		{!isEditorLoaded &&
			<View id="postEditorView"
				onSwipeBack={() => router.popPage()}
				activePanel="postEditorPanel">
				<Panel
					id="postEditorPanel"
				>

					<PanelHeader
						before={
							<PanelHeaderBack label="" onClick={() => router.popPage()} />
						}
						after={
							<PanelHeaderButton>
								<Icon24Done />
							</PanelHeaderButton>
						}
					>
						{/* Редактирование поста */}
						{locale.header}
					</PanelHeader>

					{(!isEditorLoaded && !isSaving) &&
						<Spinner
							size='medium'
							className='postEditor__spinner'
						/>
					}

					{isSaving &&
						<div className='postEditor__saving'>
							<span>
								{/* Идет сохранение... */}
								{locale.saving}
							</span>
							<Spinner
								size='medium'
								className='postEditor__spinner'
							/>
						</div>
					}
				</Panel>
			</View>
		}

		{(!isLoading && !isSaving) &&
			<iframe
				className={'postEditorPage__iframe ' + (isEditorLoaded ? "postEditorPage__iframe_loaded" : "")}
				src={editorUrl}
			>
			</iframe>
		}
	</div>
}

export default React.memo(PostEditorPage);