import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './PublishResultsTabs.css';

import { PublishResultsResponse } from '../../models/PublishResultsResponse';
import { HorizontalScroll, Tabs, TabsItem } from '@vkontakte/vkui';
import SocialIcon from '../../../../../shared/components/SocialIcon/SocialIcon';


export interface PublishResultsTabsProps {
	id: string,

	publishResults: PublishResultsResponse.PublishResultWithHistory[],
	userPageId: number,

	onPageSelected: (userPageId: number) => void
}

const PublishResultsTabs = (props: PublishResultsTabsProps) => {
	const [userPageId, setUserPageId] = useState(props.userPageId);

	const selectUserPage = (userPageIdToSelect: number) => {
		setUserPageId(userPageIdToSelect);

		props.onPageSelected(userPageIdToSelect);
	}

	const scrollToActiveTab = () => {
		setTimeout(() => {
			let scrollContainer = document.querySelector(`#${props.id} .publishResultsTabs__horizontalScroll>.vkuiHorizontalScroll__in`);

			if (scrollContainer) {
				let tabs = scrollContainer.children[0].children;

				let scrollLeft = 0;

				for (let tab of tabs) {
					if (!tab.classList.contains(`publishResultsTabs__tabsItem_${userPageId}`)) {
						scrollLeft += tab.clientWidth;
					}
					else {
						scrollContainer.scrollTo({
							left: scrollLeft,
							behavior: 'smooth'
						});
						break;
					}
				}
			}
		}, 200);
	};

	useEffect(() => {
		scrollToActiveTab();

		// eslint-disable-next-line
	}, [props.id]);

	return <Tabs>
		<HorizontalScroll
			className='publishResultsTabs__horizontalScroll'
		>
			{props.publishResults.map(publishResult =>
				<TabsItem
					className={"publishResultsTabs__tabsItem publishResultsTabs__tabsItem_" + publishResult.PublishTarget.UserPageId}
					key={publishResult.PublishTarget.UserPageId}
					onClick={() => selectUserPage(publishResult.PublishTarget.UserPageId)}
					selected={publishResult.PublishTarget.UserPageId === userPageId}
				>
					<div className='publishResultsTabs__tabsItem_icon'>
						<SocialIcon
							corners='round'
							height={32}
							width={32}
							socialPage={publishResult.PublishTarget.SourcePage}
						/>

						{publishResult.PublishState === 'Sent' &&
							<div className="publishResultsTabs__tabsItem_status publishResultsTabs__tabsItem_status_sent">
								<svg viewBox="0 0 512 512">
									<path d="m256 32c124 0 224 100 224 224c0 124-100 224-224 224c-124 0-224-100-224-224c0-124 100-224 224-224z m115 149c1-1 1-2 1-3c0-1 0-3-1-4l-31-24c-1-1-3-1-4-1c-1 0-2 0-3 1l-111 143c0 0-44-42-45-43c-1-1-4-3-6-3c-2 0-3 2-4 3l-26 26c-1 1-1 3-1 4c0 1 0 2 1 3l2 1c0 0 78 75 79 76c1 1 3 3 5 3c2 0 4-2 5-3z" />
								</svg>
							</div>
						}

						{publishResult.PublishState === 'Failed' &&
							<div className="publishResultsTabs__tabsItem_status publishResultsTabs__tabsItem_status_failed">
								<svg viewBox="0 0 512 512">
									<path d="m503 400l-219-383c-6-10-17-16-28-16-11 0-22 6-28 16l-219 383c-6 10-6 22 0 32 6 10 16 16 28 16l438 0c12 0 22-6 28-16 6-10 6-22 0-32z m-215-16l-64 0 0-64 64 0z m0-96l-64 0 0-128 64 0z" />
								</svg>
							</div>
						}
					</div>

					<span className='publishResultsTabs__tabsItem_text'>
						{publishResult.PublishTarget.Name}
					</span>
				</TabsItem>)}
		</HorizontalScroll>
	</Tabs>;
}

export default React.memo(PublishResultsTabs);