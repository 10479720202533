import { ImageInfo, ProjectItem, ImageCodecType, FileInfo } from './ProjectItem';
import { OEmbedVideoData } from './OEmbedVideoData';
import { Vk, Fb, Ig, Lj, Tg, Tw } from './social/_index';
import { SocialPage } from './SocialPage';
import { InstagramMedia } from './InstagramMedia';
import { WebFont } from './WebFont';
import { SocialNetworks } from './SocialNetwork';

declare var GlobalUtils: any;

export interface PostToPublish {
    PageId?: string,
    PageType?: string,

    SocialNetwork?: string,

    Attachments: PostToPublish.Attachment[],

    VkPostToPublish?: VkPostToPublish,
    FbPostToPublish?: FbPostToPublish,
    TwPostToPublish?: TwPostToPublish,
    OkPostToPublish?: OkPostToPublish,
    TgPostToPublish?: TgPostToPublish,
    IgPostToPublish?: IgPostToPublish,
    LnPostToPublish?: LnPostToPublish,
    LjPostToPublish?: LjPostToPublish
}

export abstract class PostToPublish {
    public static get(posts: PostToPublish[], page: SocialPage): PostToPublish {
        let targetPost = posts.filter(s => s.PageId === page.Id && s.PageType === page.PageType)[0];

        if (!targetPost) {
            targetPost = posts.filter(s => s.SocialNetwork === page.SocialNetwork && !s.PageId)[0];
        }

        return targetPost;
    }

    public static fillSpecificPostsToPublish(post: PostToPublish) {
        if (post.SocialNetwork === SocialNetworks.Vkontakte) {
            post.VkPostToPublish = VkPostToPublish.fromPostToPublish(post);
        }
        else if (post.SocialNetwork === SocialNetworks.Facebook) {
            post.FbPostToPublish = FbPostToPublish.fromPostToPublish(post);
        }
        else if (post.SocialNetwork === SocialNetworks.Instagram) {
            post.IgPostToPublish = IgPostToPublish.fromPostToPublish(post);
        }
        else if (post.SocialNetwork === SocialNetworks.LinkedIn) {
            post.LnPostToPublish = LnPostToPublish.fromPostToPublish(post);
        }
        else if (post.SocialNetwork === SocialNetworks.LiveJournal) {
            post.LjPostToPublish = LjPostToPublish.fromPostToPublish(post);
        }
        else if (post.SocialNetwork === SocialNetworks.Odnoklassniki) {
            post.OkPostToPublish = OkPostToPublish.fromPostToPublish(post);
        }
        else if (post.SocialNetwork === SocialNetworks.Telegram) {
            post.TgPostToPublish = TgPostToPublish.fromPostToPublish(post);
        }
        else if (post.SocialNetwork === SocialNetworks.Twitter) {
            post.TwPostToPublish = TwPostToPublish.fromPostToPublish(post);
        }
    }

    public static addImageOrVideo(postToPublish: PostToPublish, attachment: PostToPublish.Attachment) {
        var imagesAndVideos = postToPublish.Attachments.filter(s => s.Type === PostToPublish.AttachmentType.ImagesAndVideos)[0];

        if (!imagesAndVideos) {
            imagesAndVideos = {
                Type: PostToPublish.AttachmentType.ImagesAndVideos,
                ImagesAndVideos: []
            };

            postToPublish.Attachments.push(imagesAndVideos);
        }

        (imagesAndVideos.ImagesAndVideos as any).push(attachment);
    }

    public static GetAttachment(postToPublish: PostToPublish, attachmentType: string): PostToPublish.Attachment {
        return postToPublish
            ? postToPublish.Attachments.filter(s => s.Type === attachmentType)[0]
            : null as any;
    }

    public static DeleteAttachment(postToPublish: PostToPublish, attachmentType: string): PostToPublish.Attachment {
        var attachment = PostToPublish.GetAttachment(postToPublish, attachmentType);

        if (attachment) {
            postToPublish.Attachments = postToPublish.Attachments.filter(s => s !== attachment);
        }

        return attachment;
    }

}

// eslint-disable-next-line
export namespace PostToPublish {
    export abstract class AttachmentType {
        public static Unknown: string = 'Unknown';
        public static Link: string = 'Link';
        public static ImageFile: string = 'ImageFile';

        public static VkDocument: string = 'VkDocument';
        public static VkDocumentFile: string = 'VkDocumentFile';
        public static VkVideo: string = 'VkVideo';
        public static VkAudio: string = 'VkAudio';
        public static VkPostSign: string = 'VkPostSign';
        public static VkMarkAsAds: string = 'VkMarkAsAds';
        public static VkDisableComments: string = 'VkDisableComments';
        public static VkPinPost: string = 'VkPinPost';
        public static VkDonut: string = 'VkDonut';
        public static VkCopyright: string = 'VkCopyright';
        public static VkPostAsStory: string = 'VkPostAsStory';

        public static ExternalVideo: string = 'ExternalVideo';
        public static VideoFile: string = 'VideoFile';

        public static Poll: string = 'Poll';

        public static Text: string = 'Text';
        public static Title: string = 'Title';

        public static OkMarkAsPromo: string = 'OkMarkAsPromo';
        public static OkHiddenTheme: string = 'OkHiddenTheme';
        public static OkDisableComments: string = 'OkDisableComments';


        public static LinkedInImageLink: string = 'LinkedInImageLink';

        public static ImagesAndVideos: string = 'ImagesAndVideos';
        public static VkAudios: string = 'VkAudios';
        public static VkDocuments: string = 'VkDocuments';

        public static VkRepost: string = 'VkRepost';
        public static VkPoll: string = 'VkPoll';

        public static VkPlace: string = 'VkPlace';

        public static FbPlace: string = 'FbPlace';

        public static TwPlace: string = 'TwPlace';

        public static IgPlace: string = 'IgPlace';

        public static LjPlace: string = 'LjPlace';

        public static TgPlace: string = 'TgPlace';

        public static IgPublishToStories: string = 'IgPublishToStories';

        public static IgVideoFile: string = 'IgVideoFile';
        public static IgMediaResizeOptions: string = 'IgMediaResizeOptions';
        public static IgDisableComments: string = 'IgDisableComments';

        public static FbGroupPublishFrom: string = 'FbPublishFrom';

        public static TgText: string = 'TgText';
        public static TgDisableNotifications: string = 'TgDisableNotifications';

        public static TgReactions: string = 'TgReactions';

        public static OkPoll: string = 'OkPoll';

        public static VkMuteNotifications: string = 'VkMuteNotifications';

        public static LjText: string = 'LjText';

        public static IgStories: string = 'IgStories';

        public static IgPublishToIGTV: string = 'IgPublishToIGTV';

        public static IgIGTVOptions: string = 'IgIGTVOptions';

        public static IgReels: string = 'IgReels';
    }

    export interface Attachment {
        Type: string,

        Title?: TitleAttachment,

        Text?: TextAttachment,

        Link?: LinkAttachment,

        VideoFile?: VideoFileAttachment,

        ImageFile?: ImageFileAttachment,

        VkDocumentFile?: VkDocumentFileAttachment,

        VkDocument?: Vk.Document,

        VkVideo?: VkVideoAttachment,

        VkAudio?: Vk.Audio,

        ExternalVideo?: ExternalVideoAttachment,

        Poll?: PollAttachment,

        ImageLink?: ImageLinkAttachment,

        ImagesAndVideos?: Attachment[],

        VkDocuments?: Attachment[],

        VkAudios?: Attachment[],

        VkRepost?: VkRepostAttachment,

        VkPoll?: VkPollAttachment,

        VkPlace?: Vk.Place,

        FbPlace?: Fb.Place,

        TwPlace?: Tw.Place,

        IgPlace?: Ig.Place,

        LjPlace?: Lj.Place,

        TgPlace?: Tg.Place,

        IgVideoFile?: IgVideoFileAttachment,

        IgMediaResizeOptions?: IgMediaResizeOptionsAttachment,

        FbGroupPublishFrom?: FbGroupPublishFromAttachment,

        TgText?: TgTextAttachment,

        TgReactions?: TgReactionsAttachment,

        OkPoll?: OkPollAttachment,

        LjText?: LjTextAttachment,

        IgStories?: IgStoriesAttachment,

        IgIGTVOptions?: IgIGTVOptionsAttachment,

        VkCopyright?: VkCopyrightAttachment,

        VkDonut?: VkDonutAttachment,

        IgReels?: IgReelsAttachment
    }

    export abstract class Attachment {
        public static isImageOrVideo(attachment: Attachment): boolean {
            return attachment
                && (attachment.Type === AttachmentType.ImageFile
                    || attachment.Type === AttachmentType.VkVideo
                    || attachment.Type === AttachmentType.ExternalVideo
                    || attachment.Type === AttachmentType.VideoFile
                    || attachment.Type === AttachmentType.IgVideoFile);
        }
    }

    export interface TitleAttachment {
        Value: string
    }

    export interface VkRepostAttachment {
        SocialPage: SocialPage,
        PostId: string,
        CreatedAt: number
    }

    export interface VkDocumentFileAttachment {
        Name: string,
        SourceUrl: string,
        ImageInfo?: ImageInfo,
        Thumbnails?: ImageInfo[]
    }

    export abstract class VkDocumentFileAttachment {
        public static parseFrom(imageAttachment: ProjectItem.ImageAttachment): VkDocumentFileAttachment {
            let imageName = imageAttachment.ImageUrl
                ? imageAttachment.ImageUrl.substring(imageAttachment.ImageUrl.lastIndexOf('/') + 1)
                : 'image.jpg';

            return {
                Name: imageName,
                SourceUrl: imageAttachment.ImageUrl,
                ImageInfo: imageAttachment.ImageInfo,
                Thumbnails: imageAttachment.Thumbnails
            };
        }
    }

    export interface ImageFileAttachment {
        Name?: string,
        ImageUrl?: string,
        ImageInfo?: ImageInfo,
        Thumbnails?: ImageInfo[],
        IsUploading?: boolean,
        UploadingImage?: any,
        UploadingByUrl?: { SourceUrl: string },
        OptimizedImageInfo?: ImageInfo,

        EditorData?: ImageFileAttachment.EditorData
    }

    export abstract class ImageFileAttachment {
        public static parseFrom(imageAttachment: ProjectItem.ImageAttachment): ImageFileAttachment {
            let imageName = imageAttachment.ImageUrl
                ? imageAttachment.ImageUrl.substring(imageAttachment.ImageUrl.lastIndexOf('/') + 1)
                : 'image.jpg';

            return {
                Name: imageName,
                ImageUrl: imageAttachment.ImageUrl,
                ImageInfo: imageAttachment.ImageInfo,
                Thumbnails: imageAttachment.Thumbnails,
                OptimizedImageInfo: imageAttachment.OptimizedImageInfo,

                EditorData: imageAttachment.EditorData
            };
        }

        public static isGif(imageAttachment: ImageFileAttachment): boolean {
            return !!(imageAttachment.ImageInfo
                && imageAttachment.ImageInfo.IsAnimated
                && imageAttachment.ImageInfo.CodecType === ImageCodecType[ImageCodecType.Gif]);
        }
    }

    export namespace ImageFileAttachment {
        export interface EditorData {
            BackgroundImageUrl: string,
            Stickers: EditorSticker[]
        }

        export interface EditorSticker {
            Id?: string,
            Text?: EditorStickerText,
            Image?: EditorStickerImage,
            Gif?: EditorStickerGif,
            Rect?: EditorStickerRect,
            Position?: EditorStickerPosition,

            Opacity?: number,
            BlendingMode?: string
        }

        export interface EditorStickerLayerForFfmpeg {
            RenderedImageUrl: string,
            Sticker: EditorSticker,

            BlendingMode: string,

            X: number,
            Y: number,

            Width: number,
            Height: number,

            Rotate?: number
        }

        export interface EditorStickerPosition {
            X?: number,
            Y?: number,
            Z?: number,
            ScaleX?: number,
            ScaleY?: number,
            Rotate?: number
        }

        export interface EditorStickerText {
            Text: string,
            Color: string,
            FontFamily: string,
            FontSize: number,
            IsBold: boolean,
            IsItalic: boolean,
            IsUnderline: boolean,
            HasShadow: boolean,
            IsBackgroundEnabled: boolean,
            BackgroundColor: string,

            TextAlign: string
        }

        export abstract class EditorStickerTextAlign {
            public static Left = 'Left';
            public static Center = 'Center';
            public static Right = 'Right';
        }

        export abstract class EditorStickerChangeType {
            public static Other = "Other";

            public static TextChange = "TextChange";
            public static PositionChange = "PositionChange";
            public static TransformChange = "TransformChange";

            public static TextOpacityChange = "TextOpacityChange";
            public static BackgroundOpacityChange = "BackgroundOpacityChange";
            public static OpacityChange = "OpacityChange";

            public static BackgroundFilter = "BackgroundFilter";
            public static InstagramFilter = "InstagramFilter";
            public static InstagramFilterForImageEl = "InstagramFilterForImageEl";
        }

        export interface EditorStickerImage {
            ImageUrl: string
        }

        export interface EditorStickerGif {
            ImageUrl: string,
        }

        export interface EditorStickerRect {
            Width: number,
            Height: number,

            Background: EditorStickerBackground,

            IsBorderEnabled?: boolean,
            Border: EditorStickerBorder,

            CornerRadius?: number,
        }

        export interface EditorStickerBorder {
            Width: number,
            Color: string
        }

        export interface EditorStickerBackground {
            Type?: string,

            SolidColor?: string,
            LinearGradient?: EditorStickerLinearGradient,
            RadialGradient?: EditorStickerRadialGradient
        }

        export abstract class EditorStickerBackgroundType {
            public static SolidColor = "SolidColor";
            public static LinearGradient = "LinearGradient";
            public static RadialGradient = "RadialGradient";
        }

        export interface EditorStickerLinearGradient {
            Direction: string,
            ColorStops: EditorStickerGradientColorStop[]
        }

        export interface EditorStickerRadialGradient {
            Position: string,
            Size: string,
            ColorStops: EditorStickerGradientColorStop[]
        }

        export interface EditorStickerGradientColorStop {
            Color: string,
            Position: number
        }

        export abstract class EditorStickerLinearGradientDirection {
            public static ToBottomRight = "ToBottomRight";
            public static ToBottom = "ToBottom";
            public static ToBottomLeft = "ToBottomLeft";
            public static ToRight = "ToRight";
            public static ToLeft = "ToLeft";
            public static ToTopRight = "ToTopRight";
            public static ToTop = "ToTop";
            public static ToTopLeft = "ToTopReft";
        }

        export abstract class EditorStickerRadialGradientPosition {
            public static LeftTop = "LeftTop";
            public static CenterTop = "CenterTop";
            public static RightTop = "RightTop";
            public static LeftCenter = "LeftCenter";
            public static CenterCenter = "CenterCenter";
            public static RightCenter = "RightCenter";
            public static LeftBottom = "LeftBottom";
            public static CenterBottom = "CenterBottom";
            public static RightBottom = "RightBottom";
        }

        export abstract class EditorStickerRadialGradientSize {
            public static ClosestSide = "ClosestSide";
            public static ClosestCorner = "ClosestCorner";
            public static FarthestSide = "FarthestSide";
            public static FarthestCorner = "FarthestCorner";
        }
    }

    export interface VkVideoAttachment {
        VideoId: string,
        OwnerId: string,
        Name: string,
        VideoPreviewUrl: string,
        VideoPlayerUrl: string
    }

    export abstract class VkVideoAttachment {
        public static parseFrom(videoAttachment: ProjectItem.VkVideoAttachment): VkVideoAttachment {
            return {
                VideoId: videoAttachment.VideoId.replace('video', '').split('_')[1],
                OwnerId: videoAttachment.VideoId.replace('video', '').split('_')[0],
                Name: videoAttachment.VideoName,
                VideoPreviewUrl: videoAttachment.VideoPreviewUrl,
                VideoPlayerUrl: videoAttachment.VideoPlayerUrl
            }
        }

        public static isExternalVideo(videoAttachment: VkVideoAttachment): boolean {
            return videoAttachment.VideoPlayerUrl.indexOf('youtube.com/') !== -1
                || videoAttachment.VideoPlayerUrl.indexOf('rutube.ru/') !== -1
                || videoAttachment.VideoPlayerUrl.indexOf('coub.com/') !== -1
                || videoAttachment.VideoPlayerUrl.indexOf('dailymotion.com/') !== -1
                || videoAttachment.VideoPlayerUrl.indexOf('vimeo.com/') !== -1;
        }
    }

    export interface VideoFileAttachment extends ProjectItem.VideoFileAttachment {

    }

    export interface IgVideoFileAttachment extends ProjectItem.VideoFileAttachment {
        CustomThumbnail: FileInfo
    }

    export interface IgMediaResizeOptionsAttachment {
        ImageResizeMethod: string,
        VideoResizeMethod: string,
        SquareMedias: boolean
    }

    export interface FbGroupPublishFromAttachment {
        SocialPage: SocialPage
    }

    export interface ImageLinkAttachment {
        Name: string,
        ImageUrl: string,
        ImageInfo: ImageInfo,
        Thumbnails: ImageInfo[],
        LinkUrlHost: string,
        PhotoTitle: string
    }

    export interface ExternalVideoAttachment {
        VideoData: OEmbedVideoData
    }

    export interface VkDocumentAttachment {
        DocumentId: string,
        OwnerId: string,
        Title: string,
        Size?: number,
        Ext?: string,
        Url?: string,
        Type?: number
    }

    export interface VkDocumentFileAttachment {
        Name: string,
        SourceUrl: string,
        ImageInfo?: ImageInfo,
        Thumbnails?: ImageInfo[]
    }

    export interface PollAttachment {
        Title: string,

        Answers: PollAttachment.Answer[]
    }

    export namespace PollAttachment {
        export interface Answer {
            Text: string
        }
    }

    export interface VkPollAttachment {
        Title: string,
        Answers: PollAttachment.Answer[],

        IsAnonymous: boolean,
        IsMultiple: boolean,

        Background?: Vk.PollBackground,
        BackgoundImage?: PostToPublish.ImageFileAttachment
    }

    export namespace VkPollAttachment {
        export interface Answer {
            Text: string
        }
    }

    export interface OkPollAttachment {
        Title: string,
        Answers: PollAttachment.Answer[],

        MultipleChoice: boolean,
        AnonymousVoting: boolean,
        ResultsAfterVoting: boolean
    }

    export interface TextAttachment {
        Value: string
    }

    export interface TgTextAttachment {
        Value: string
    }

    export interface LjTextAttachment {
        Value: string
    }

    export interface LinkAttachment {
        Url: string;

        Options?: LinkAttachmentOption[];
    }

    export interface TgReactionsAttachment {
        Reactions: TgReaction[],

        ReactionsDeselectedHint?: string
    }

    export interface TgReaction {
        Text: string,
        TextHtml?: string,
        Url?: string,
        IsReaction: boolean,
        IsComments: boolean,

        ReactionSelectedHint?: string
    }

    export interface IgStoriesAttachment {
        Stories: IgStory[]
    }

    export interface IgStory {
        Id: string,

        RenderedBackgroundUrl?: string,
        RenderedForegroundUrl?: string,

        RenderedImageUrl?: string,
        PreviewImageUrl?: string,

        SmallPreviewImageUrl?: string,

        Stickers: IgStory.Sticker[],

        Background: IgStory.StickerBackground,

        LinkUrl?: string
    }

    export interface VkCopyrightAttachment {
        Name: string,
        Url: string
    }

    export interface VkDonutAttachment {
        DonutPaidDuration: number
    }

    export interface VkPostSignAttachment {
        SignUserText: string
    }

    export interface IgIGTVOptionsAttachment {
        Title: string,
        Description: string,

        IgVideoFile: IgVideoFileAttachment
    }

    export interface IgReelsAttachment {
        Caption: string,

        IgVideoFile: IgVideoFileAttachment,

        AlsoShareToFeed?: boolean,

        TaggedUsers?: SocialPage[],

        Place: Ig.Place,

        CustomAudioName?: string
    }

    export abstract class IgStory {
        public static getAttachment(story: PostToPublish.IgStory): PostToPublish.Attachment {
            let attachment: PostToPublish.Attachment;

            if (story.Background.Type === PostToPublish.IgStory.StickerBackgroundType.ImageWithBlur) {
                attachment = (story.Background.ImageWithBlur as any).ImageAttachment;
            }
            else if (story.Background.Type === PostToPublish.IgStory.StickerBackgroundType.VideoWithBlur) {
                attachment = (story.Background.VideoWithBlur as any).VideoAttachment;
            }
            else{ 
                attachment = null as any;
            }

            return attachment;
        }

        public static fromAttachment(attachment: PostToPublish.Attachment): PostToPublish.IgStory {
            let story: PostToPublish.IgStory = {
                Id: GlobalUtils.getRandomText(12),
                Background: null as any,
                Stickers: []
            };

            if (attachment.Type === PostToPublish.AttachmentType.ImageFile) {
                story.Background = {
                    Type: PostToPublish.IgStory.StickerBackgroundType.ImageWithBlur,
                    ImageWithBlur: {
                        ImageAttachment: attachment
                    }
                };
            }
            else if (attachment.Type === PostToPublish.AttachmentType.VideoFile || attachment.Type === PostToPublish.AttachmentType.IgVideoFile) {
                story.Background = {
                    Type: PostToPublish.IgStory.StickerBackgroundType.VideoWithBlur,
                    VideoWithBlur: {
                        VideoAttachment: attachment,
                        Start: 0,
                        End: 0,
                        ThumbnailUrl: null as any,
                    }
                };
            }

            return story;
        }
    }

    export namespace IgStory {
        export interface StickerBackground {
            Type: string,

            ImageWithBlur?: StickerBackground.ImageWithBlurBackground,
            VideoWithBlur?: StickerBackground.VideoWithBlurBackground,

            BackgroundImageEl?: any,
            BackgroundMedia?: InstagramMedia
        }

        export abstract class StickerBackgroundType {
            public static Unknown = 'Unknown';

            public static ImageWithBlur: string = "ImageWithBlur";
            public static VideoWithBlur: string = "VideoWithBlur";
        }

        export namespace StickerBackground {
            export interface ImageWithBlurBackground {
                ImageAttachment: PostToPublish.Attachment
            }

            export interface VideoWithBlurBackground {
                VideoAttachment: PostToPublish.Attachment,

                Start: number,
                End: number,
                ThumbnailUrl: string
            }
        }

        export interface StickerPosition {
            X?: number,
            Y?: number,
            Z?: number,
            Width?: number,
            Height?: number,
            Rotate?: number,
            Scale?: number
        }

        export interface Sticker {
            Type: string,
            Position: StickerPosition,

            HashTag?: StickerHashtag,
            Poll?: StickerPoll,
            Location?: StickerLocation,
            Mention?: StickerMention,
            Question?: StickerQuestion,
            Emoji?: StickerEmoji,
            Image?: StickerImage,
            Slider?: StickerSlider,
            Text?: StickerText,
            Quiz?: StickerQuiz,
            Link?: StickerLink
        }

        export interface StickerEl {
            sticker: PostToPublish.IgStory.Sticker,

            groupElelement: any,
            transformerElement: any,

            isSelected: boolean,

            refresh: () => void,
        }

        export abstract class StickerType {
            public static Unknown = 'Unknown';

            public static HashTag = 'HashTag';
            public static Poll = 'Poll';
            public static Location = 'Location';
            public static Mention = 'Mention';
            public static Question = 'Question';
            public static Emoji = 'Emoji';
            public static Image = 'Image';
            public static Slider = 'Slider';
            public static Text = 'Text';
            public static Quiz = 'Quiz';
            public static Link = 'Link';
        }

        export interface StickerHashtag {
            Text: string,
            Style: string
        }

        export interface StickerText {
            Text: string,
            Color: string,
            FontFamily: string,
            FontSize: number,
            IsBold: boolean,
            IsItalic: boolean,
            IsUnderline: boolean,
            HasShadow: boolean,
            IsBackgroundEnabled: boolean,
            BackgroundColor: string,
            BackgroundOpacity: number,

            FontObj?: WebFont,
            EmojiObjs?: { EmojiId?: string, EmojiImg?: any }[]
        }

        export interface StickerPoll {
            Title: string,

            AnswerYes: string,
            AnswerNo: string,

            Style: string
        }

        export interface StickerLink {
            Text: string,
            Url: string,
            Style: string,

            TextToRender?: string
        }

        export interface StickerImage {
            ImageUrl: string,
            ImageAttachment?: PostToPublish.ImageFileAttachment,

            Image?: any,
        }

        export interface StickerLocation {
            Name: string,
            Location?: Ig.Place,
            Style: string
        }

        export interface StickerMention {
            UserId: number,
            Username: string,
            Style: string,

            IsHiddenMention: boolean
        }

        export interface StickerQuestion {
            Title: string,
            Style: string,

            LogoUrl: string,
            LogoImage?: any,
        }

        export interface StickerEmoji {
            EmojiId: string,
            EmojiCode: string,
            EmojiImageUrl: string,

            EmojiImage?: any
        }

        export interface StickerQuiz {
            Title: string,
            Style: string,
            Answers: StickerQuizAnswer[]
        }

        export abstract class StickerQuizStyle {
            public static default = 'default';
            public static style2 = 'style2';
            public static style3 = 'style3';
            public static style4 = 'style4';
            public static style5 = 'style5';
            public static style6 = 'style6';
            public static style7 = 'style7';
            public static style8 = 'style8';
            public static style9 = 'style9';
            public static style10 = 'style10';
            public static style11 = 'style11';
            public static style12 = 'style12';

            public static getTextColor(style: string) {
                return '#ffffff';
            }

            public static getStartBackgroundColor(style: string) {
                if (style === this.default) {
                    return '#262626';
                }
                else if (style === this.style2) {
                    return '#ca2ee1';
                }
                else if (style === this.style3) {
                    return '#70c050';
                }
                else if (style === this.style4) {
                    return '#ed4956';
                }
                else if (style === this.style5) {
                    return '#ca2ee1';
                }
                else if (style === this.style6) {
                    return '#a307ba';
                }
                else if (style === this.style7) {
                    return '#ed4956';
                }
                else if (style === this.style8) {
                    return '#fd8d32';
                }
                else if (style === this.style9) {
                    return '#fdcb5c';
                }
                else if (style === this.style10) {
                    return '#70c050';
                }
                else if (style === this.style11) {
                    return '#3897f0';
                }
            }

            public static getEndBackgroundColor(style: string) {
                if (style === this.default) {
                    return '#262626';
                }
                else if (style === this.style2) {
                    return '#5eb1ff';
                }
                else if (style === this.style3) {
                    return '#27c4f5';
                }
                else if (style === this.style4) {
                    return '#fdcb5c';
                }
                else if (style === this.style5) {
                    return '#ff6874';
                }
                else if (style === this.style6) {
                    return '#a307ba';
                }
                else if (style === this.style7) {
                    return '#ed4956';
                }
                else if (style === this.style8) {
                    return '#fd8d32';
                }
                else if (style === this.style9) {
                    return '#fdcb5c';
                }
                else if (style === this.style10) {
                    return '#70c050';
                }
                else if (style === this.style11) {
                    return '#3897f0';
                }
            }
        }

        export abstract class StickerLinkStyle {
            public static default = 'default';
            public static subtle = 'subtle';
            public static black = 'black';
        }

        export interface StickerQuizAnswer {
            Text: string,
            IsRightAnswer: boolean
        }

        export interface StickerSlider {
            Title: string,

            EmojiId: string,
            EmojiCode: string,
            EmojiImageUrl: string

            EmojiImage?: any,

            Style?: string
        }

        export abstract class StickerSliderStyle {
            public static default = 'default';
            public static style2 = 'style2';
            public static style3 = 'style3';
            public static style4 = 'style4';
            public static style5 = 'style5';
            public static style6 = 'style6';
            public static style7 = 'style7';
            public static style8 = 'style8';
            public static style9 = 'style9';
        }

        export abstract class StickerQuestionStyle {
            public static default = 'default';
            public static style2 = 'style2';
            public static style3 = 'style3';
            public static style4 = 'style4';
            public static style5 = 'style5';
            public static style6 = 'style6';
            public static style7 = 'style7';
            public static style8 = 'style8';
            public static style9 = 'style9';

            public static getBackgroundColor(style: string) {
                if (style === this.default) {
                    return '#fff';
                }
                else if (style === this.style2) {
                    return '#000000';
                }
                else if (style === this.style3) {
                    return '#3897f0';
                }
                else if (style === this.style4) {
                    return '#70c050';
                }
                else if (style === this.style5) {
                    return '#fdcb5c';
                }
                else if (style === this.style6) {
                    return '#fd8d32';
                }
                else if (style === this.style7) {
                    return '#ed4956';
                }
                else if (style === this.style8) {
                    return '#d10869';
                }
                else if (style === this.style9) {
                    return '#a307ba';
                }
            }
        }

        export abstract class StickerHashTagStyle {
            public static vibrant = 'vibrant';
            public static rainbow = 'rainbow';
            public static subtle = 'subtle';
        }

        export abstract class StickerLocationStyle {
            public static vibrant = 'vibrant';
            public static rainbow = 'rainbow';
            public static subtle = 'subtle';
        }

        export abstract class StickerMentionStyle {
            public static vibrant = 'vibrant';
            public static rainbow = 'rainbow';
            public static subtle = 'subtle';
        }
    }

    export interface LinkAttachmentOption {
        /** [enum=LinkAttachmentOptionType] */
        Type: string;

        BitlyShortener: { Login: string, ApiKey: string };

        Appendix: { Value: string };
    }

    export abstract class LinkAttachmentOptionType {
        public static Unknown: string = 'Unknown';

        public static BitlyShortener: string = 'BitlyShortener';
        public static TinyUrlShortener: string = 'TinyUrlShortener';
        public static GooglShortener: string = 'GooglShortener';
        public static VkCcShortener: string = 'VkCcShortener';

        public static Appendix: string = 'Appendix';
    }
}

export interface VkPostToPublish {
    Text?: string,
    TextShort?: string,

    ViewsCount?: number,
    CommentsCount?: number,
    LikesCount?: number,
    RepostsCount?: number,

    CommentsDisabled?: boolean,
    IsPinned?: boolean,
    IsMarkedAsAds?: boolean,
    MuteNotifications?: boolean,

    Copyright?: PostToPublish.VkCopyrightAttachment,
    Donut?: PostToPublish.VkDonutAttachment,
    PostSign?: PostToPublish.VkPostSignAttachment,

    Documents?: PostToPublish.Attachment[],

    Audios?: PostToPublish.Attachment[],

    Repost?: PostToPublish.VkRepostAttachment,

    Poll?: PostToPublish.VkPollAttachment,

    Place?: Vk.Place,

    ImagesAndVideos?: PostToPublish.Attachment[],

    Link?: PostToPublish.LinkAttachment,
}

export abstract class VkPostToPublish {
    public static fromPostToPublish(postToPublish: PostToPublish): VkPostToPublish {
        let result: VkPostToPublish = {};

        result.Text = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.Text && s.Text)
            .map(s => (s.Text as any).Value)[0];

        if (!result.Text) {
            result.Text = '';
        }

        let maxTextLength = 350;
        let textLengthLeft = maxTextLength;

        if (result.Text.length > maxTextLength) {
            result.TextShort = '';

            let words = result.Text.split(' ');

            for (let word of words) {
                let currentPartLength = word.length + 1;

                if (currentPartLength < textLengthLeft) {
                    result.TextShort += ' ' + word;
                    textLengthLeft -= currentPartLength;
                }
                else {
                    break;
                }
            }
        }

        result.ViewsCount = 0;
        result.CommentsCount = 0;
        result.LikesCount = 0;
        result.RepostsCount = 0;

        result.CommentsDisabled = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.VkDisableComments)
            .length > 0;

        result.IsPinned = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.VkPinPost)
            .length > 0;

        result.IsMarkedAsAds = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.VkMarkAsAds)
            .length > 0;

        result.MuteNotifications = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.VkMuteNotifications)
            .length > 0;

        result.Copyright = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.VkCopyright && s.VkCopyright)
            .map(s => s.VkCopyright)[0];

        result.Donut = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.VkDonut && s.VkDonut)
            .map(s => s.VkDonut)[0];

        if (postToPublish.Attachments.filter(s => s.Type === PostToPublish.AttachmentType.VkPostSign).length) {
            result.PostSign = {
                SignUserText: null as any
            };
        }

        result.Documents = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.VkDocuments && s.VkDocuments)
            .map(s => s.VkDocuments)[0];

        if (!result.Documents) {
            result.Documents = [];
        }

        result.Audios = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.VkAudios && s.VkAudios)
            .map(s => s.VkAudios)[0];

        if (!result.Audios) {
            result.Audios = [];
        }

        result.Repost = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.VkRepost && s.VkRepost)
            .map(s => s.VkRepost)[0];

        result.Poll = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.VkPoll && s.VkPoll)
            .map(s => s.VkPoll)[0];

        result.Place = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.VkPlace && s.VkPlace)
            .map(s => s.VkPlace)[0];

        result.ImagesAndVideos = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.ImagesAndVideos && s.ImagesAndVideos)
            .map(s => s.ImagesAndVideos)[0];

        if (!result.ImagesAndVideos) {
            result.ImagesAndVideos = [];
        }

        result.Link = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.Link && s.Link)
            .map(s => s.Link)[0];

        return result;
    }
}

export interface FbPostToPublish {
    Text?: string,
    TextShort?: string,

    ImpressionsCount?: number,
    CommentsCount?: number,
    LikesCount?: number,
    SharesCount?: number,

    ImagesAndVideos?: PostToPublish.Attachment[],

    Link?: PostToPublish.LinkAttachment,
}

export abstract class FbPostToPublish {
    public static fromPostToPublish(postToPublish: PostToPublish): FbPostToPublish {
        let result: FbPostToPublish = {};

        result.Text = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.Text && s.Text)
            .map(s => (s.Text as any).Value)[0];

        if (!result.Text) {
            result.Text = '';
        }

        let maxTextLength = 350;
        let textLengthLeft = maxTextLength;

        if (result.Text.length > maxTextLength) {
            result.TextShort = '';

            let words = result.Text.split(' ');

            for (let word of words) {
                let currentPartLength = word.length + 1;

                if (currentPartLength < textLengthLeft) {
                    result.TextShort += ' ' + word;
                    textLengthLeft -= currentPartLength;
                }
                else {
                    break;
                }
            }
        }

        result.ImpressionsCount = 0;
        result.CommentsCount = 0;
        result.LikesCount = 0;
        result.SharesCount = 0;

        result.ImagesAndVideos = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.ImagesAndVideos && s.ImagesAndVideos)
            .map(s => s.ImagesAndVideos)[0];

        if (!result.ImagesAndVideos) {
            result.ImagesAndVideos = [];
        }

        result.Link = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.Link && s.Link)
            .map(s => s.Link)[0];

        return result;
    }
}


export interface TwPostToPublish {
    Text?: string,

    ViewsCount?: number,
    CommentsCount?: number,
    LikesCount?: number,
    RetweetsCount?: number,

    ImagesAndVideos?: PostToPublish.Attachment[],

    Link?: PostToPublish.LinkAttachment,
}

export abstract class TwPostToPublish {
    public static fromPostToPublish(postToPublish: PostToPublish): TwPostToPublish {
        let result: TwPostToPublish = {};

        result.Text = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.Text && s.Text)
            .map(s => (s.Text as any).Value)[0];

        if (!result.Text) {
            result.Text = '';
        }

        result.ViewsCount = 0;
        result.CommentsCount = 0;
        result.LikesCount = 0;
        result.RetweetsCount = 0;

        result.ImagesAndVideos = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.ImagesAndVideos && s.ImagesAndVideos)
            .map(s => s.ImagesAndVideos)[0];

        if (!result.ImagesAndVideos) {
            result.ImagesAndVideos = [];
        }

        result.Link = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.Link && s.Link)
            .map(s => s.Link)[0];

        return result;
    }
}

export interface OkPostToPublish {
    ViewsCount?: number,
    CommentsCount?: number,
    LikesCount?: number,
    ResharesCount?: number,


    MarkAsPromo?: boolean,
    HiddenTheme?: boolean,
    DisableComments?: boolean,

    Attachments?: PostToPublish.Attachment[],
    AttachmentsShort?: PostToPublish.Attachment[]
}

export abstract class OkPostToPublish {
    public static fromPostToPublish(postToPublish: PostToPublish): OkPostToPublish {
        let result: OkPostToPublish = {};

        result.ViewsCount = 0;
        result.CommentsCount = 0;
        result.LikesCount = 0;
        result.ResharesCount = 0;

        result.MarkAsPromo = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.OkMarkAsPromo).length > 0;

        result.HiddenTheme = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.OkHiddenTheme).length > 0;


        result.DisableComments = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.OkDisableComments).length > 0;

        result.Attachments = postToPublish.Attachments
            .filter(s => s.Type !== PostToPublish.AttachmentType.OkDisableComments
                && s.Type !== PostToPublish.AttachmentType.OkHiddenTheme
                && s.Type !== PostToPublish.AttachmentType.OkMarkAsPromo);

        if (result.Attachments.length > 2) {
            result.AttachmentsShort = result.Attachments.slice(0, 2);
        }
        else {
            result.AttachmentsShort = result.Attachments;
        }

        return result;
    }
}

export interface TgPostToPublish {
    Text?: string,
    TextShort?: string,

    ImpressionsCount?: number,
    CommentsCount?: number,
    LikesCount?: number,
    SharesCount?: number,

    ImagesAndVideos?: PostToPublish.Attachment[],

    Link?: PostToPublish.LinkAttachment,

    Reactions?: PostToPublish.TgReactionsAttachment
}

export abstract class TgPostToPublish {
    public static fromPostToPublish(postToPublish: PostToPublish): TgPostToPublish {
        let result: TgPostToPublish = {};

        result.Text = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.TgText && s.TgText)
            .map(s => (s.TgText as any).Value)[0];

        if (!result.Text) {
            result.Text = '';
        }

        let maxTextLength = 350;
        let textLengthLeft = maxTextLength;

        if (result.Text.length > maxTextLength) {
            result.TextShort = '';

            let words = result.Text.split(' ');

            for (let word of words) {
                let currentPartLength = word.length + 1;

                if (currentPartLength < textLengthLeft) {
                    result.TextShort += ' ' + word;
                    textLengthLeft -= currentPartLength;
                }
                else {
                    break;
                }
            }
        }

        result.ImpressionsCount = 0;
        result.CommentsCount = 0;
        result.LikesCount = 0;
        result.SharesCount = 0;

        result.ImagesAndVideos = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.ImagesAndVideos && s.ImagesAndVideos)
            .map(s => s.ImagesAndVideos)[0];

        if (!result.ImagesAndVideos) {
            result.ImagesAndVideos = [];
        }

        result.Link = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.Link && s.Link)
            .map(s => s.Link)[0];

        result.Reactions = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.TgReactions && s.TgReactions)
            .map(s => s.TgReactions)[0];

        return result;
    }
}

export interface IgPostToPublish {
    Text?: string,
    TextShort?: string,

    ImpressionsCount?: number,
    CommentsCount?: number,
    LikesCount?: number,
    SharesCount?: number,

    ImagesAndVideos?: PostToPublish.Attachment[],
    Stories?: PostToPublish.IgStory[],
    IGTV?: PostToPublish.IgIGTVOptionsAttachment,
    Reels?: PostToPublish.IgReelsAttachment,

    Place?: Ig.Place,

    DisableComments?: boolean,

    ImageResizeMethod?: string,
    VideoResizeMethod?: string,
    SquareMedias?: boolean
}

export abstract class IgPostToPublish {
    public static fromPostToPublish(postToPublish: PostToPublish): IgPostToPublish {
        let result: IgPostToPublish = {};

        result.Text = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.Text && s.Text)
            .map(s => (s.Text as any).Value)[0];

        if (!result.Text) {
            result.Text = '';
        }

        let maxTextLength = 50;
        let textLengthLeft = maxTextLength;

        if (result.Text.length > maxTextLength) {
            result.TextShort = '';

            let words = result.Text.split(' ');

            for (let word of words) {
                let currentPartLength = word.length + 1;

                if (currentPartLength < textLengthLeft) {
                    result.TextShort += ' ' + word;
                    textLengthLeft -= currentPartLength;
                }
                else {
                    break;
                }
            }
        }

        result.ImpressionsCount = 0;
        result.CommentsCount = 0;
        result.LikesCount = 0;
        result.SharesCount = 0;

        result.ImagesAndVideos = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.ImagesAndVideos && s.ImagesAndVideos)
            .map(s => s.ImagesAndVideos)[0];

        if (!result.ImagesAndVideos) {
            result.ImagesAndVideos = [];
        }

        result.Stories = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.IgStories && s.IgStories)
            .map(s => (s.IgStories as any).Stories)[0];

        if (!result.Stories) {
            result.Stories = [];
        }

        result.IGTV = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.IgIGTVOptions && s.IgIGTVOptions)
            .map(s => s.IgIGTVOptions)[0];

        result.Reels = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.IgReels && s.IgReels)
            .map(s => s.IgReels)[0];

        result.Place = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.IgPlace && s.IgPlace)
            .map(s => s.IgPlace)[0];

        let mediaResizeOptions = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.IgMediaResizeOptions && s.IgMediaResizeOptions)
            .map(s => s.IgMediaResizeOptions)[0];

        if (mediaResizeOptions) {
            result.ImageResizeMethod = mediaResizeOptions.ImageResizeMethod;
            result.VideoResizeMethod = mediaResizeOptions.VideoResizeMethod;
            result.SquareMedias = mediaResizeOptions.SquareMedias;
        }

        result.DisableComments = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.IgDisableComments).length > 0;

        return result;
    }
}

export interface LnPostToPublish {
    Text?: string,
    TextShort?: string,

    ImpressionsCount?: number,
    CommentsCount?: number,
    LikesCount?: number,
    SharesCount?: number,

    ImagesAndVideos?: PostToPublish.Attachment[],

    Link?: PostToPublish.LinkAttachment,
}

export abstract class LnPostToPublish {
    public static fromPostToPublish(postToPublish: PostToPublish): LnPostToPublish {
        let result: LnPostToPublish = {};

        result.Text = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.Text && s.Text)
            .map(s => (s.Text as any).Value)[0];

        if (!result.Text) {
            result.Text = '';
        }

        let maxTextLength = 350;
        let textLengthLeft = maxTextLength;

        if (result.Text.length > maxTextLength) {
            result.TextShort = '';

            let words = result.Text.split(' ');

            for (let word of words) {
                let currentPartLength = word.length + 1;

                if (currentPartLength < textLengthLeft) {
                    result.TextShort += ' ' + word;
                    textLengthLeft -= currentPartLength;
                }
                else {
                    break;
                }
            }
        }

        result.ImpressionsCount = 0;
        result.CommentsCount = 0;
        result.LikesCount = 0;
        result.SharesCount = 0;

        result.ImagesAndVideos = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.ImagesAndVideos && s.ImagesAndVideos)
            .map(s => s.ImagesAndVideos)[0];

        if (!result.ImagesAndVideos) {
            result.ImagesAndVideos = [];
        }

        result.Link = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.Link && s.Link)
            .map(s => s.Link)[0];

        return result;
    }
}

export interface LjPostToPublish {
    ViewsCount?: number,
    CommentsCount?: number,
    LikesCount?: number,
    ResharesCount?: number,


    MarkAsPromo?: boolean,
    HiddenTheme?: boolean,
    DisableComments?: boolean,

    Title?: string,

    Attachments?: PostToPublish.Attachment[],
    AttachmentsShort?: PostToPublish.Attachment[]
}

export abstract class LjPostToPublish {
    public static fromPostToPublish(postToPublish: PostToPublish): LjPostToPublish {
        let result: LjPostToPublish = {};

        result.ViewsCount = 0;
        result.CommentsCount = 0;
        result.LikesCount = 0;
        result.ResharesCount = 0;

        result.Title = postToPublish.Attachments
            .filter(s => s.Type === PostToPublish.AttachmentType.Title && s.Title)
            .map(s => (s.Title as any).Value)[0];

        result.Attachments = postToPublish.Attachments
            .filter(s => s.Type !== PostToPublish.AttachmentType.Title);

        if (result.Attachments.length > 2) {
            result.AttachmentsShort = result.Attachments.slice(0, 2);
        }
        else {
            result.AttachmentsShort = result.Attachments;
        }

        return result;
    }
}