import * as moment from 'moment';

/** День недели. */
export enum DayOfWeek {
    /** Понедельник. */
    Monday = 0,

    /** Вторник. */
    Tuesday = 1,

    /** Среда. */
    Wednesday = 2,

    /** Четверг. */
    Thursday = 3,

    /** Пятница. */
    Friday = 4,

    /** Суббота. */
    Saturday = 5,

    /** Воскресение. */
    Sunday = 6
}

export class DateUtils {
    public static getMonthStart(unixTime: number): number {
        var date = moment.unix(unixTime).utc();

        date.set('date', 1);
        date.set('hour', 0);
        date.set('minute', 0);
        date.set('second', 0);
        date.set('millisecond', 0);

        return date.unix();
    }

    public static getDaysInMonth(unixTime: number): number {
        var date = moment.unix(unixTime).utc();

        return date.daysInMonth();
    }

    public static formatDate(unixTime: number, format: string = 'DD MMM YYYY HH:mm:ss'): string {
        return moment.unix(unixTime).utc().format(format);
    }

    public static to12TimeFormat(source: string): string {
        if (source.indexOf('AM') !== -1 || source.indexOf('PM') !== -1) {
            return source;
        }

        let hours = +(source.split(':')[0]);
        let minutes = +(source.split(':')[1]);

        let appendix = 'AM';

        if (hours >= 12) {
            appendix = 'PM';
        }

        if (hours > 12) {
            hours -= 12;
        }

        if (hours === 0 && appendix === 'AM') {
            hours = 12;
        }

        let hoursString = hours >= 10
            ? '' + hours
            : '0' + hours;

        let minutesString = minutes >= 10
            ? '' + minutes
            : '0' + minutes;

        return `${hoursString}:${minutesString} ${appendix}`;
    }

    public static to24TimeFormat(source: string): string {
        if (source.indexOf('AM') === -1 && source.indexOf('PM') === -1) {
            return source;
        }

        let appendix = source.indexOf('AM') !== -1 ? 'AM' : 'PM';

        source = source
            .replace(' AM', '')
            .replace(' PM', '')
            .replace('AM', '')
            .replace('PM', '');

        let hours = +(source.split(':')[0]);
        let minutes = +(source.split(':')[1]);

        if (appendix === 'PM' && hours < 12) {
            hours += 12;
        }

        if (appendix === 'AM' && hours === 12) {
            hours = 0;
        }

        let hoursString = hours >= 10
            ? '' + hours
            : '0' + hours;

        let minutesString = minutes >= 10
            ? '' + minutes
            : '0' + minutes;

        return `${hoursString}:${minutesString}`;
    }

    public static dateAdd(unixTime: number, valueToAdd: any, valueType: string) {
        var date = moment.unix(unixTime).utc();

        date.add(valueToAdd, valueType);

        return date.unix();
    }

    public static setTime(unixTime: number, hours: number, minutes: number, seconds?: number, milliseconds?: number) {
        var date = moment.unix(unixTime).utc();

        date.set('hour', hours ? hours : 0);
        date.set('minute', minutes ? minutes : 0);
        date.set('second', seconds ? seconds : 0);
        date.set('millisecond', milliseconds ? milliseconds : 0);

        return date.unix();
    }

    public static getDayStart(unixTime: number): number {
        return this.setTime(unixTime, 0, 0, 0, 0);
    }

    public static getDayOfWeekIndex(unixTime: number) {
        var date = moment.unix(unixTime).utc();

        var day = date.day();

        if (day === 0) {
            day = 6;
        }
        else {
            day = day - 1;
        }

        return day;
    }

    public static setDayOfWeekIndex(unixTime: number, dayOfWeekIndexTo) {
        var date = moment.unix(unixTime).utc();

        var dayOfWeekIndexFrom = this.getDayOfWeekIndex(unixTime);

        date.add(dayOfWeekIndexTo - dayOfWeekIndexFrom, 'days');

        return date.unix();
    }

    public static setDayOfWeek(unixTime: number, dayOfWeek: string) {
        return this.setDayOfWeekIndex(unixTime, DayOfWeek[dayOfWeek]);
    }

    public static getWeekStart(unixTime: number): number {
        unixTime = this.setDayOfWeek(unixTime, 'Monday');
        unixTime = this.getDayStart(unixTime);

        return unixTime;
    }

    public static getDateInfo(unixTime: number): { Year: number, Month: number, Day: number, DayOfWeek: string, DayOfWeekIndex: number, Hour: number, Minute: number, Second: number } {
        let date = moment.unix(unixTime).utc();

        let dayOfWeekIndex = date.day();

        if (dayOfWeekIndex === 0) {
            dayOfWeekIndex = 6;
        }
        else {
            dayOfWeekIndex = dayOfWeekIndex - 1;
        }

        return {
          Year: date.year(),
          Month: date.month(),
          Day: date.date(),
          
          DayOfWeek: DayOfWeek[dayOfWeekIndex],
          DayOfWeekIndex: dayOfWeekIndex,

          Hour: date.hour(),
          Minute: date.minute(),
          Second: date.second()
        };
    }

    public static getDatePart(unixTime: number, datePart: any) {
        var date = moment.unix(unixTime).utc();

        return date.get(datePart);
    }

    public static setDatePart(unixTime: number, datePart: any, value: number) {
        var date = moment.unix(unixTime).utc();

        date.set(datePart, value);

        return date.unix();
    }

    public static getCurrentUnixTime(utcOffsetMinutes: number) {
        var now = new Date();

        var utcTime: any = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

        return utcTime.getTime() / 1000 + utcOffsetMinutes * 60;
    }

    public static getShortTime(time) {
        var timeParts = time.split(':');

        return timeParts[0] + ':' + timeParts[1];
    }

    public static getNextDayOfWeek(dayOfWeek: string) {

        var dayOfWeekIndex = DayOfWeek[dayOfWeek] + 1;

        if (dayOfWeekIndex === 7) {
            dayOfWeekIndex = 0;
        }

        return DayOfWeek[dayOfWeekIndex];
    }
}