import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"
import { useLocation, useRouter } from '@happysanta/router';

import './UserSettings.css';

import { Cookies, useLocale, useStore } from '../../../shared/_index';
import { Panel, PanelHeader, PanelHeaderBack, PanelHeaderButton, Spinner, View } from '@vkontakte/vkui';
import { Icon24Done } from '@vkontakte/icons';
import { UserSettingsL } from './UserSettings.L';

const UserSettings = (props: { id: string }) => {
	let locale: UserSettingsL = useLocale(UserSettingsL);

	let router = useRouter();
	let location = useLocation();

	const [isLoading, setIsLoading] = useState(true);

	const [isEmbedLoaded, setIsEmbedLoaded] = useState(false);

	const [embedUrl, setEmbedUrl] = useState('');

	const { principalStore, subUserStore, routeStore, languageStore } = useStore();

	const receiveMessage = (event) => {
		if (event.origin !== "https://localhost:4767" && event.origin !== "https://settings-embed.novapress.com") {
			return;
		}

		if (event.data === 'do-logout') {
			principalStore.logout();

			router.pushPage(routeStore.Pages.StartPage.Route);
		}
		else if (event.data === 'embed-loaded') {
			setIsEmbedLoaded(true);
		}
		else if (event.data === 'change-language-RU' || event.data === 'change-language-EN') {
			languageStore.setLanguage(event.data === 'change-language-RU' ? 'RU' : 'EN', languageStore.use24TimeFormat);
		}
		else if (event.data === 'set-time-format-24' || event.data === 'set-time-format-12') {
			languageStore.setLanguage(languageStore.language, event.data === 'set-time-format-24');
		}

		console.log(event);
	}

	useEffect(() => {
		principalStore.get().then(() => {
			let principal = principalStore.principal;

			let principalId = +Cookies.get('principalId_v3');
			let principalHash = Cookies.get('principalHash_v3');

			let baseNovaPressUrl = window.location.hostname == 'localhost'
				? 'https://localhost:4767'
				: 'https://settings-embed.novapress.com';

			let currentdate = new Date();

			let cookieOptions = {
				domain: window.location.hostname === 'm.novapress.com' || window.location.hostname === 'mobile.novapress.com'
					? '.novapress.com'
					: window.location.hostname,
				path: '/',
				expires: new Date(currentdate.setMonth(currentdate.getMonth() + 1))
			};

			Cookies.set('principalSubUser_v3', `${subUserStore.subUser.UserId}_${subUserStore.subUser.TeamMemberId}`, cookieOptions);

			let embedUrl = `${baseNovaPressUrl}/user/${subUserStore.subUser.UserId}/profile`;

			embedUrl += `?principalId=${principalId}&principalHash=${principalHash}`;

			setEmbedUrl(embedUrl);
			setIsLoading(false);
		});

		principalStore.selectedUserTab = 'settings';
		principalStore.selectedUserTabChanged.next();

		window.addEventListener("message", receiveMessage, false);

		return () => {
			window.removeEventListener("message", receiveMessage, false);
		}
	}, [props.id]);

	return <Panel
		id={props.id}>
			
		{!isEmbedLoaded &&
			<Spinner
				size='medium'
				className='userSettings__spinner'
			/>
		}

		{!isLoading &&
			<iframe
				className={'userSettings__iframe ' + (isEmbedLoaded ? "userSettings__iframe_loaded" : "")}
				src={embedUrl}
			>
			</iframe>
		}
	</Panel>
}

export default React.memo(UserSettings);