export interface ConfirmRegistrationCodeL {
  createAccount: string,
  confirmEmailHeader: string,
  emailSentHint: string,
  noCodeError: string,
  codeExpiredError: string,
  back: string
}

export abstract class ConfirmRegistrationCodeL {
  public static RU: ConfirmRegistrationCodeL = {
    createAccount: 'Создать аккаунт',
    confirmEmailHeader: 'Подтверждение почты',
    emailSentHint: 'Введите код, который мы отправили на [email]',
    noCodeError: 'Пожалуйста, введите код',
    codeExpiredError: 'Код введен неверно или истек срок его действия',
    back: 'Назад'
  }


  public static EN: ConfirmRegistrationCodeL = {
    createAccount: 'Create account',
    confirmEmailHeader: 'Confirm Email',
    emailSentHint: 'Enter the code we sent to [email]',
    noCodeError: 'Please enter code',
    codeExpiredError: 'Code entered is incorrect or expired',
    back: 'Back'
  }
}