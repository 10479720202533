export interface CalendarSliderL {
  monday: string,
  thuesday: string,
  wednesday: string,
  thursday: string,
  friday: string,
  saturday: string,
  sunday: string
}

export abstract class CalendarSliderL {
  public static RU: CalendarSliderL = {
    monday: 'пн',
    thuesday: 'вт',
    wednesday: 'ср',
    thursday: 'чт',
    friday: 'пт',
    saturday: 'сб',
    sunday: 'вс'
  }


  public static EN: CalendarSliderL = {
    monday: 'Mon',
    thuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun'
  }
}