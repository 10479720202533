export interface UserPostTrashL {
  noPosts: string,
  loadMore: string
}

export abstract class UserPostTrashL {
  public static RU: UserPostTrashL = {
    noPosts: 'Нет постов для отображения',
    loadMore: 'Загрузить ещё'
  }


  public static EN: UserPostTrashL = {
    noPosts: 'No posts to display',
    loadMore: 'Load more'
  }
}