import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"
import { useLocation, useRouter } from '@happysanta/router';

import './UserPostTrash.css';

import { Api, PostDataShort, ProjectDataShort, useLocale, UserPage, useStore } from '../../../../../../shared/_index';
import { Button, CardGrid, Group, Placeholder, Spinner } from '@vkontakte/vkui';
import PostCard from '../../../../../components/PostCard/PostCard';
import { Icon56NotePenOutline } from '@vkontakte/icons';
import { UserPostTrashL } from './UserPostTrash.L';

interface UserPostTrashProps {
	project: ProjectDataShort,
	page: UserPage
}

const UserPostTrash = (props: UserPostTrashProps) => {
	let locale: UserPostTrashL = useLocale(UserPostTrashL);

	const getGridSize = (): "s" | "m" | "l" => {
		if (window.innerWidth > 750) {
			return 's';
		}
		else if (window.innerWidth > 500) {
			return 'm';
		}
		else {
			return 'l';
		}
	}

	let location = useLocation();
	let router = useRouter();

	const [isLoading, setIsLoading] = useState(true);

	const [posts, setPosts] = useState([] as PostDataShort[]);
	const [userPages, setUserPages] = useState([] as UserPage[]);

	const [canLoadMorePosts, setCanLoadMorePosts] = useState(true);
	const [areMorePostsLoading, setAreMorePostsLoading] = useState(false);

	const [gridSize, setGridSize] = useState(getGridSize());

	const { principalStore, subUserStore, routeStore } = useStore();

	const pageSize = 20;

	const updateGridSize = () => {
		let currentGridSize = getGridSize();

		if (currentGridSize !== gridSize) {
			setGridSize(currentGridSize);
		}
	};

	const loadPosts = () => {
		setIsLoading(true);

		let projectId = location.getParams().projectId
			? +location.getParams().projectId
			: 0;

		let pageId = location.getParams().pageId
			? +location.getParams().pageId
			: 0;

		Api.executeMethod<any>('posts.getTrash', {
			userId: subUserStore.subUser.UserId,
			dateFrom: 0,
			count: pageSize,
			projectId: projectId,
			userPageId: pageId
		}).then((response: { Posts: PostDataShort[], UserPages: UserPage[] }) => {
			setPosts(response.Posts);

			setUserPages(response.UserPages);

			if (response.Posts.length < pageSize) {
				setCanLoadMorePosts(false);
			}

			setIsLoading(false);
		});
	}

	const loadMorePosts = () => {
		if (areMorePostsLoading || !posts || !posts.length || !canLoadMorePosts) {
			return;
		}

		setAreMorePostsLoading(true);

		let projectId = location.getParams().projectId
			? +location.getParams().projectId
			: 0;

		let pageId = location.getParams().pageId
			? +location.getParams().pageId
			: 0;

		Api.executeMethod<any>('posts.getTrash', {
			userId: subUserStore.subUser.UserId,
			dateFrom: posts[posts.length - 1].CreatedTime,
			count: pageSize,
			projectId: projectId,
			userPageId: pageId
		}).then((response: { Posts: PostDataShort[], UserPages: UserPage[] }) => {
			setPosts(posts.concat(response.Posts));

			let resultUserPages = [...userPages];

			for (let userPage of response.UserPages) {
				if (!resultUserPages.filter(s => s.Id === userPage.Id).length) {
					resultUserPages = [...userPages, userPage];
				}
			}

			setUserPages(resultUserPages);

			if (response.Posts.length < pageSize) {
				setCanLoadMorePosts(false);
			}

			setAreMorePostsLoading(false);
		});
	}

	const checkNeedLoadMorePosts = () => {
		if ((window.innerHeight + window.pageYOffset) >= document.body.scrollHeight - 40) {
			loadMorePosts();
		}
	}

	const onPostReturnedToQueued = (projectId: number, itemHash: string) => {
		setPosts(posts.filter(s => !(s.ProjectId == projectId && s.ItemHash == itemHash)));
	}

	useEffect(() => {
		principalStore.get().then(() => {
			loadPosts();

			let parameters: any = {
				userId: subUserStore.subUser.UserId.toString(),
			};

			if (location.getParams().projectId) {
				parameters.projectId = location.getParams().projectId;
			}

			if (location.getParams().pageId) {
				parameters.pageId = location.getParams().pageId;
			}

			router.pushPage(routeStore.Pages.PostTrash.Route, parameters);
		});

		window.addEventListener('resize', updateGridSize, true);

		return () => {
			window.removeEventListener('resize', updateGridSize, true);
		}
		// eslint-disable-next-line
	}, [props.page, props.project]);

	useEffect(() => {
		let $postReturnedToQueuedSub = principalStore.postReturnedToQueued.subscribe((e) => onPostReturnedToQueued(e.projectId, e.itemHash));
		let $postChangedSub = principalStore.postsChanged.subscribe(() => refreshPosts());
		window.addEventListener('scroll', checkNeedLoadMorePosts, true);

		return () => {
			$postChangedSub.unsubscribe();
			$postReturnedToQueuedSub.unsubscribe();
			
			window.removeEventListener('scroll', checkNeedLoadMorePosts, true);
		}
	// eslint-disable-next-line
	}, [posts, userPages, areMorePostsLoading, canLoadMorePosts])

	const refreshPosts = (): Promise<void> => {
		let postRowkeys = posts.map(s => `${s.ProjectId}_${s.ItemHash}`);
		
		return new Promise<void>(resolve => {
			Api.executeMethod<{ Posts: PostDataShort[] }>('posts.getByIds', {
				userId: subUserStore.subUser.UserId,
				rowKeys: JSON.stringify(postRowkeys)
			}).then(response => {
				for (let post of response.Posts) {
					principalStore.postChanged.next(post);
				}

				resolve();
			});
		});
	};

	return <div className='userPostTrash__panel'>
		{isLoading &&
			<Spinner
				size='medium'
				className='userPostTrash__spinner'
			/>
		}
		{!isLoading &&
			<Group className='userPostTrash__panel_group'>
				{!posts.length && <Placeholder
					className='userPostTrash__placeholder'
					icon={<Icon56NotePenOutline />}
				>
					{/* Нет постов для отображения */}
					{locale.noPosts}
				</Placeholder>}

				{posts.length > 0 && <>
					<CardGrid
						size={gridSize}
						className="userPostTrash__panel_grid userPostTrash__panel_grid_expanded"
					>
						{posts.map(post =>
							<PostCard
								onChanged={() => refreshPosts()}
								key={`${post.ProjectId}_${post.ItemHash}`}
								post={post}
								userPages={userPages}
							/>
						)}
					</CardGrid>
				</>}

				{canLoadMorePosts && <div className='userPostTrash__loadMore'>
					<Button
						appearance="overlay"
						mode="overlay_primary"
						stretched={true}
						size="l"
						loading={areMorePostsLoading}
						onClick={() => loadMorePosts()}
					>
						{/* Загрузить ещё */}
						{locale.loadMore}
					</Button>
				</div>}
			</Group>
		}
	</div>;
}

export default React.memo(UserPostTrash);