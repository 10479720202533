export class Cookies {
  public static get(name: string): string {
    // eslint-disable-next-line
    var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));

    return matches ? decodeURIComponent(matches[1]) : undefined as any;
  }

  public static set(name: string, value: string, props: any) {
    props = props || {}
    var exp = props.expires
    if (typeof exp === "number" && exp) {
      var d = new Date()
      d.setTime(d.getTime() + exp * 1000)
      exp = props.expires = d
    }
    if (exp && exp.toUTCString) { props.expires = exp.toUTCString() }

    value = encodeURIComponent(value)
    var updatedCookie = name + "=" + value
    for (var propName in props) {
      updatedCookie += "; " + propName
      var propValue = props[propName]
      if (propValue !== true) { updatedCookie += "=" + propValue }
    }
    document.cookie = updatedCookie
  }

  public static remove(name: string, props: any) {
    props.expires = -1;

    this.set(name, '', props);
  }
}