export interface EditPrincipalL {
  back: string,
  noNameError: string,
  name: string,
  ctaEdit: string,
  error: string,
  header: string,
  userpic: string,
  selectPhoto: string
}

export abstract class EditPrincipalL {
  public static RU: EditPrincipalL = {
    back: 'Назад',
    noNameError: 'Пожалуйста, введите Ваше имя',
    name: 'Ваше имя',
    ctaEdit: 'Сохранить',
    error: 'Не удается сохранить изменения. Попробуйте, пожалуйста, позже.',
    header: 'Редактирование профиля',
    userpic: 'Ваше фото',
    selectPhoto: 'Выбрать фото'
  }


  public static EN: EditPrincipalL = {
    back: 'Back',
    noNameError: 'Please enter your name',
    name: 'Your name',
    ctaEdit: 'Save',
    error: 'Unable to save changes. Please try later.',
    header: 'Your profile',
    userpic: 'Your photo',
    selectPhoto: 'Upload photo'
  }
}