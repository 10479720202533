import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './Moment.css';

import { DateFormats, MomentL } from './Moment.L';

import * as moment from 'moment';
import { useStore } from '../../_index';

export interface MomentProps {
	format?: "time" | "fullDate" | "fullDateNoYear" | "shortDate" | "shortDateNoYear" | "shortDateWeekdayNoYear" | "fullDatetime" | "mediumDateWeekdayNoYear" | "weekday" | "fullDateWeekdayNoYear" | "fullDatetimeNoYear" | "datetimeAt" | "datetimeFullAt" | "datetimeFullAt_withYear" | "shortMonth_withYear"
	value?: number,
	useSystemTime?: boolean
}

const Moment = (props: MomentProps) => {
	const { languageStore, timeStore } = useStore();

	const [dateFormats, setDateFormats] = useState<any>(null);

	useEffect(() => {
		let locale = MomentL[languageStore.language];

		setDateFormats(languageStore.use24TimeFormat
			? locale.dateFormats24
			: locale.dateFormats12);
	}, [languageStore.language, languageStore.use24TimeFormat]);

	const getValueString = (): string => {
		if (!props.value || !dateFormats) return '';

		let value = props.value;

		if (!props.useSystemTime) {
			value = value - 4 * 60 * 60 + timeStore.timeZoneOffsetInMinutes * 60;
		}

		let format: string = dateFormats[props.format as any];

		return moment.unix(value).utc().format(format);
	}

	return <>
		{getValueString()}
	</>;
}

export default React.memo(Moment);