import React from 'react';
import { observer } from "mobx-react-lite"

import './PostMenuHeader.css';

import { PostDataShort } from '../../../shared/_index';
import { classNamesString } from '@vkontakte/vkui/dist/lib/classNames';


const PostMenuHeader = (props: { post: PostDataShort }) => {
	return <div className={classNamesString('postMenuHeader', {
		'postMenuHeader__withIcon': !!props.post.ImageUrl
	})}>
		<div
			className='postMenuHeader__icon'
			style={{
				backgroundImage: props.post.ImageUrl ? 'url(' + props.post.ImageUrl + ')' : null as any
			}}
		>

		</div>

		<span className='postMenuHeader__text'>
			{props.post.TextShort}
		</span>
	</div>;
}

export default React.memo(PostMenuHeader);