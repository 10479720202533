export interface PrincipalMenuL {
  cancel: string,
  editProfile: string,
  editCredentials: string,
  topup: string,
  switchAccount: string,
  exit: string
}

export abstract class PrincipalMenuL {
  public static RU: PrincipalMenuL = {
    cancel: 'Отменить',
    editProfile: 'Редактировать профиль',
    editCredentials: 'Изменить e-mail/пароль',
    topup: 'Пополнить счет',
    switchAccount: 'Сменить аккаунт',
    exit: 'Выход'
  }


  public static EN: PrincipalMenuL = {
    cancel: 'Cancel',
    editProfile: 'Edit profile',
    editCredentials: 'Change email/password',
    topup: 'Add funds',
    switchAccount: 'Switch account',
    exit: 'Logout'
  }
}