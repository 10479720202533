import React from 'react';
import { observer } from "mobx-react-lite"

import './UnsupportedAnalyticsPricingPlanAlert.css';

import { useLocale } from '../../../shared/_index';
import { UnsupportedAnalyticsPricingPlanAlertL } from './UnsupportedAnalyticsPricingPlanAlert.L';
import { Button, ButtonGroup, Placeholder } from '@vkontakte/vkui';
import { Icon28InfoCircleOutline } from '@vkontakte/icons';


export interface UnsupportedAnalyticsPricingPlanAlertProps {

}

const UnsupportedAnalyticsPricingPlanAlert = (props: UnsupportedAnalyticsPricingPlanAlertProps) => {
	let locale: UnsupportedAnalyticsPricingPlanAlertL = useLocale(UnsupportedAnalyticsPricingPlanAlertL);

	return <div className='unsupportedAnalyticsPricingPlanAlert'>
		<Placeholder
			icon={
				<Icon28InfoCircleOutline
					width={56}
					height={56}
					className='unsupportedAnalyticsPricingPlanAlert__icon' />
			}
			// Ваш тарифный план не поддерживает аналитику
			header={locale.header}
		>
			{/* Сменить тарифный план можно */}
			{locale.subHeader}

			<a target='_blank' href='https://novapress.com/user/profile'>
				{/* здесь */}
				{locale.here}
			</a>
		</Placeholder>
	</div>;
}

export default React.memo(UnsupportedAnalyticsPricingPlanAlert);