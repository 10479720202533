import React, { useEffect, useState } from 'react';

import './LaunсherWorkspaceContents.css';
import SimpleBar from 'simplebar-react';
import SubUserIcon from '../../shared/components/SubUserIcon/SubUserIcon';
import { Principal, useStore } from '../../shared/_index';
import { observer } from 'mobx-react-lite';
import LaunсherWorkspaceHeader from '../LaunсherWorkspaceHeader/LaunсherWorkspaceHeader';
import LaunсherWorkspaceClassicMenu from '../LaunсherWorkspaceClassicMenu/LaunсherWorkspaceClassicMenu';

const LaunсherWorkspaceContents = () => {
	const { principalStore, subUserStore, routeStore } = useStore();

	const [principal, setPrincipal] = useState<Principal>(null as any);
	const [currentSubUser, setCurrentSubUser] = useState<Principal.SubUser>(null as any);

	useEffect(() => {
		setPrincipal(principalStore.principal);
		setCurrentSubUser(subUserStore.subUser);
	}, [principalStore.principal, subUserStore.subUser]);

	return <SimpleBar className='launсherWorkspaceContents'>
		<LaunсherWorkspaceHeader />
		<LaunсherWorkspaceClassicMenu />
	</SimpleBar>;
}

export default observer(LaunсherWorkspaceContents);