export interface UnsupportedAnalyticsPricingPlanAlertL {
  header: string,
  subHeader: string,
  here: string
}

export abstract class UnsupportedAnalyticsPricingPlanAlertL {
  public static RU: UnsupportedAnalyticsPricingPlanAlertL = {
    header: 'Ваш тарифный план не поддерживает аналитику',
    subHeader: 'Сменить тарифный план можно ',
    here: 'здесь'
  }


  public static EN: UnsupportedAnalyticsPricingPlanAlertL = {
    header: 'Your plan does not support analytics',
    subHeader: 'You can change your plan ',
    here: 'here'
  }
}