import { Cookies } from "./Cookies";
import { Credentials } from "./Credentials";
import { ParamDeparam } from "./ParamDeparam";

/** Тип ответа от API (успешно, ошибка, редирект, доступ-запрещен) */
export enum ApiResultType {
  /** Команда успешно выполнена. */
  Success,

  /** Ошибка при выполнении операции. */
  Error,
  
  /** Пользователь не авторизован. */
  NotAuthorized,

  /** Редирект на другой метод. */
  Redirect,

  /** Доступ к методу запрещен. */
  AccessDenied
}

export class Api {
  private static _baseApiUrl: string | null = null;

  public static get baseApiUrl(): string | null {
    if (!this._baseApiUrl) {
      this._baseApiUrl = 'https://api.novapress.com/v2/';

      if (Cookies.get('useLocalApiServer') === 'true') {
        this._baseApiUrl = "https://api-local.novapress.com:4765/v2/";
      }
    }

    return this._baseApiUrl;
  }

  private static executedRequestIds: string[] = [];

  /** Возвращает случайную строку заданной длины. */
  public static getRandomText(length: number): string {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  /** Построить url для выполнения запроса к API */
  public static buildRequestUrl(address: string, queryParameters?: any): string {
    var url: string = this.baseApiUrl + address;

    if (queryParameters) {
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += ParamDeparam.param(queryParameters);
    }

    let accessToken = Credentials.getAccessToken();

    if (accessToken) {
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += 'accessToken=' + accessToken;
    }

    return url;
  }

  public static getBoundary(): string {
    var length = 16;
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  /** Выполнить запрос к API. */
  public static executeMethod<T>(address: string, body?: any): Promise<T> {
    var url = this.buildRequestUrl(address, {});

    var requestId = this.getRandomText(10);
    this.executedRequestIds.push(requestId);

    return new Promise<T>((resolve, reject) => {
      let hasFile = false;

      if (body && typeof body === 'object' && 'File' in window) {
        for (let key in body) {
          if (body[key] instanceof File) {
            hasFile = true;
          }
        }
      }

      if (hasFile) {
        var data = new FormData();

        for (let key in body) {
          data.append(key, body[key])
        }

        body = data;
      }

      let headers: any = {};

      headers['X-Request-ID'] = requestId;

      if (!hasFile){
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
      }

      let promise = fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: headers,
        body: body ?
          (hasFile ? body : ParamDeparam.param(body))
          : null
      });

      promise
        .then(response => {
          response.json().then((apiResult: ApiResult) => {
            if (apiResult.result === ApiResultType[ApiResultType.Success].toLowerCase()) {
              if (!apiResult.data || !apiResult.data.CustomSerializedData) {
                resolve(apiResult.data as T);
              }
              else {
                resolve(JSON.parse(apiResult.data.CustomSerializedData) as T);
              }
            }
            else {
              reject(apiResult);
            }
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}

/** Результат запроса к API. */
export interface ApiResult {
  /** Тип ответа (успешно, ошибка, редирект, доступ-запрещен) */
  result: string,

  /** Сообщение об ошибке (если есть) */
  message: string,

  /** Обьект с результатом операции */
  data: any
}