export interface UserSettingsL {
  header: string,
  saving: string
}

export abstract class UserSettingsL {
  public static RU: UserSettingsL = {
    header: 'Настройки',
    saving: 'Идет сохранение...'
  }


  public static EN: UserSettingsL = {
    header: 'Settings',
    saving: 'Saving...'
  }
}