import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite"

import './Logout.css';

import { useRouter } from '@happysanta/router';
import { Panel } from '@vkontakte/vkui';
import { useStore } from '../../../shared/_index';


const Logout = (props: { id: string }) => {
	let router = useRouter();

	const { principalStore, routeStore } = useStore();

	useEffect(() => {
		principalStore.logout();

		router.replacePage(routeStore.Pages.StartPage.Route);
	}, []);

	return <Panel
		id={props.id}
		className='logout'>
	</Panel>;
}

export default React.memo(Logout);