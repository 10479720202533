import React from 'react';
import { observer } from "mobx-react-lite"

import './Launcher.css';
import LaunсherWorkspaces from '../LaunсherWorkspaces/LaunсherWorkspaces';
import LaunсherWorkspaceContents from '../LaunсherWorkspaceContents/LaunсherWorkspaceContents';

const Launcher = () => {
	return <div className='launcher'>
		<div className='launcher__workspaces'>
			<LaunсherWorkspaces />
		</div>

		<div className='launcher__workspace_contents'>
			<LaunсherWorkspaceContents />
		</div>
	</div>;
}

export default React.memo(Launcher);