import { useRouter } from '@happysanta/router';
import React, { useEffect, useState } from 'react';

import './Register.css';

import { Icon24Dismiss } from '@vkontakte/icons';
import { Button, FormItem, FormLayout, Group, Input, Link, ModalPage, ModalPageHeader, PanelHeaderButton, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { Api, ComponentHelper, Cookies, StateManager, useLocale, useStore } from '../../../shared/_index';
import { RegisterL } from './Register.L';

export interface RegisterState {
	isMobile: boolean,

	name: string,
	email: string,
	password: string,
	promoCode: string,
	partnerId: number,

	showPromoCodeField: boolean,

	isSubmitting: boolean,
	showErrors: boolean,

	locale: RegisterL
}

const Register = (props: { id: string, dynamicContentHeight: boolean }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	const locale: RegisterL = useLocale(RegisterL);
	const router = useRouter();

	const { routeStore } = useStore();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [promoCode, setPromoCode] = useState('');

	const [showPromoCodeField, setShowPromoCodeField] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showErrors, setShowErrors] = useState(false);

	const partnerId = Cookies.get('PartnerId')
		? parseInt(Cookies.get('PartnerId'))
		: 0;

	useEffect(() => {
		ComponentHelper.focusModalInput(props.id);
	}, [props.id]);

	const doRegister = () => {
		if (!name || !email || !password) {
			setShowErrors(true);

			return;
		}

		setIsSubmitting(true);

		Api.executeMethod('principal.sendConfirmEmailForRegister', {
			email: email,
		}).then((response: any) => {
			let operationId = response.OperationId;

			router.pushModal(routeStore.Modals.ConfirmRegistrationCode, {
				state: StateManager.setState({
					email: email,
					password: password,
					promoCode: promoCode,
					name: name,
					partnerId: partnerId,
					confirmationOperationId: operationId,
				})
			});
		});
	}

	return <ModalPage
		id={props.id}
		dynamicContentHeight={true}
		header={
			<ModalPageHeader
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => router.popPage()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{/* Регистрация */}
				{locale.registerHeader}
			</ModalPageHeader>
		}
	>
		<Group>
			<FormLayout>
				<FormItem
					// Полное имя
					top={locale.name}
					status={!name && showErrors ? "error" : "default"}
					bottom={
						!name && showErrors
							// Пожалуйста, введите Ваше имя
							? locale.noNameError
							: null
					}
				>
					<Input
						type="text"
						name="name"
						className='to-focus'
						crossOrigin={"anonymous"}
						// Ваше имя
						placeholder={locale.namePlaceholder}
						value={name}
						onChange={e => setName(e.target.value)}
					/>
				</FormItem>

				<FormItem
					top="E-mail"
					status={!email && showErrors ? "error" : "default"}
					bottom={
						!email && showErrors
							// Пожалуйста, введите электронную почту
							? locale.noEmailError
							: null
					}
				>
					<Input
						type="email"
						name="email"
						crossOrigin={"anonymous"}
						// Ваш E-mail
						placeholder={locale.emailPlaceholder}
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
				</FormItem>

				<FormItem
					// Пароль
					top={locale.password}
					status={!password && showErrors ? "error" : "default"}
					bottom={
						!password && showErrors
							// Пожалуйста, введите пароль
							? locale.noPasswordError
							: null
					}
				>
					<Input
						type="password"
						name="password"
						autoComplete="true"
						crossOrigin={"anonymous"}
						value={password}
						onKeyPress={e => ComponentHelper.onEnterKeyPress(e, () => doRegister())}
						onChange={e => setPassword(e.target.value)}
						// Придумайте пароль
						placeholder={locale.passwordPlaceholder}

					/>
				</FormItem>

				{showPromoCodeField && <FormItem
					// Промокод
					top={locale.promoCode}
				>
					<Input
						type="text"
						name="promoCode"
						crossOrigin={"anonymous"}
						// Введите промокод (если есть)
						placeholder={locale.promoCodePlaceholder}
						value={promoCode}
						onKeyPress={e => ComponentHelper.onEnterKeyPress(e, () => doRegister())}
						onChange={e => setPromoCode(e.target.value)}
					/>
				</FormItem>}

				{!showPromoCodeField &&
					<Button
						className='registerModal-showPromoCode'
						size='l'
						mode='tertiary'
						onClick={() => setShowPromoCodeField(true)}>
						{/* У меня есть промокод */}
						{locale.iHavePromoCode}
					</Button>}

				<FormItem bottom={
					<div>
						{/* Нажав «Создать аккаунт», Вы принимаете условия */}
						{locale.termsHint}
						&nbsp;
						<Link href='https://novapress.com/doc/terms' target='_blank'>
							{/* Публичной оферты */}
							{locale.terms}
						</Link>
						{/* и */}
						{locale.and}
						&nbsp;
						<Link href='https://novapress.com/doc/privacy' target='_blank'>
							{/* Политики конфиденциальности */}
							{locale.privacy}
						</Link>
					</div>
				}>
					<Button size="l" stretched onClick={() => doRegister()} loading={isSubmitting}>
						{/* Создать аккаунт */}
						{locale.createAccount}
					</Button>
				</FormItem>
			</FormLayout>
		</Group>
	</ModalPage>;
}

export default React.memo(Register);