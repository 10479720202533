import { makeAutoObservable } from 'mobx';
import { Subject } from 'rxjs';
import { PostDataShort, Principal } from '../models/_index';
import { Cookies } from '../_index';
import { Store } from './Store';
import { Api } from '../services/_index';

export default class PageSizeStore {
    width: number;
    height: number;

    public readonly rootStore: Store;

    constructor(rootStore: Store) {
        this.rootStore = rootStore;
        
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        window.addEventListener('resize', () => {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        }, true);

        makeAutoObservable(this);
    }
}