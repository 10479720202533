import React from 'react';
import { observer } from "mobx-react-lite"
import { useLocation, useRouter } from '@happysanta/router';

import './PrincipalMenu.css';

import { PostDataShort, StateManager, useLocale, useStore } from '../../../shared/_index';
import { PrincipalMenuL } from './PrincipalMenu.L';
import { ActionSheet, ActionSheetItem } from '@vkontakte/vkui';
import { Icon28DoorArrowLeftOutline, Icon28EditOutline, Icon28LockOpenOutline, Icon28MoneyTransfer, Icon28SwitchOutlineAlt } from '@vkontakte/icons';

const PrincipalMenu = () => {
	let locale: PrincipalMenuL = useLocale(PrincipalMenuL);

	let location = useLocation();
	let router = useRouter();

	const { principalStore, routeStore } = useStore();

	let event: any = StateManager.getStateValue(location, 'event');

	let toggleRef = event ? event.target : null as any;

	const editProfile = () => {
		setTimeout(() => {
			router.pushModal(routeStore.Modals.EditPrincipal);
		}, 200);
	}

	const doLogout = () => {
		principalStore.logout();

		router.pushPage(routeStore.Pages.StartPage.Route);
	}

	if (!event) {
		router.popPage();
		return <></>;
	}

	return <ActionSheet
		toggleRef={toggleRef}
		onClose={() => router.popPage()}
		iosCloseItem={
			<ActionSheetItem autoclose mode="cancel">
				{/* Отменить */}
				{locale.cancel}
			</ActionSheetItem>
		}
	>
		<ActionSheetItem
			before={<Icon28EditOutline />}
			autoclose
			onClick={() => editProfile()}
		>
			{/* Редактировать профиль */}
			{locale.editProfile}
		</ActionSheetItem>

		<ActionSheetItem
			before={<Icon28LockOpenOutline />}
			autoclose
		// onClick={() => showEditModal()}
		>
			{/* Изменить e-mail/пароль */}
			{locale.editCredentials}
		</ActionSheetItem>

		<ActionSheetItem
			before={<Icon28MoneyTransfer />}
			autoclose
		// onClick={() => showEditModal()}
		>
			{/* Пополнить счет */}
			{locale.topup}
		</ActionSheetItem>

		<ActionSheetItem
			before={<Icon28SwitchOutlineAlt />}
			autoclose
		// onClick={() => showEditModal()}
		>
			{/* Сменить аккаунт */}
			{locale.switchAccount}
		</ActionSheetItem>

		<ActionSheetItem
			before={<Icon28DoorArrowLeftOutline />}
			onClick={() => doLogout()}
			autoclose
			mode="destructive">
			{/* Выход */}
			{locale.exit}
		</ActionSheetItem>
	</ActionSheet>;
}

export default React.memo(PrincipalMenu);