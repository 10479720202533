import { useLocation, useRouter } from '@happysanta/router';
import React, { useEffect, useState } from 'react';

import './EditSubUser.css';

import { Icon24Camera, Icon24Dismiss, Icon28DeleteOutline } from '@vkontakte/icons';
import { Button, File, FormItem, FormLayout, Group, Input, ModalPage, ModalPageHeader, PanelHeaderBack, PanelHeaderButton, Spinner, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { Api, ComponentHelper, Principal, StateManager, useLocale, useStore } from '../../../shared/_index';
import SubUserIcon from '../../../shared/components/SubUserIcon/SubUserIcon';
import { EditSubUserL } from './EditSubUser.L';

const EditSubUser = (props: { id: string, dynamicContentHeight: boolean }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let locale: EditSubUserL = useLocale(EditSubUserL);

	let router = useRouter();
	let location = useLocation();

	const { principalStore, routeStore } = useStore();

	let subUser: Principal.SubUser = StateManager.getStateValue(location, 'subUser');

	const [name, setName] = useState(subUser ? subUser.WorkspaceName : locale.newWorkspaceName);
	const [logoUrl, setLogoUrl] = useState(subUser ? subUser.WorkspaceImageUrl : null as any);

	const [showErrors, setShowErrors] = useState(false);
	const [couldNotSave, setCouldNotSave] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isPhotoUploading, setIsPhotoUploading] = useState(false);

	const closeModal = () => {
		router.replaceModal(routeStore.Modals.SelectSubUser);
	}

	useEffect(() => {
		ComponentHelper.focusModalInput(props.id);
	}, [props.id]);

	if (!subUser && props.id !== routeStore.Modals.AddSubUser) {
		closeModal();
		return <></>;
	}


	const saveChanges = () => {
		if (isSubmitting) {
			return;
		}

		if (!name) {
			setShowErrors(true);
			return;
		}

		setIsSubmitting(true);
		setShowErrors(false);

		if (props.id === routeStore.Modals.AddSubUser) {
			Api.executeMethod('subUsers.create', {
				name: name,
				logoUrl: logoUrl
			}).then((response: any) => {
				principalStore.principal.SubUsers = [...principalStore.principal.SubUsers, response.SubUser];

				setIsSubmitting(false);
				closeModal();
			}).catch(() => {
				setIsSubmitting(false);
				setCouldNotSave(true);
			});
		}
		else {
			Api.executeMethod('subUsers.edit', {
				userId: subUser.UserId,
				name: name,
				logoUrl: logoUrl
			}).then(() => {
				subUser.WorkspaceName = name;
				subUser.WorkspaceImageUrl = logoUrl;

				closeModal();
			}).catch(() => {
				setIsSubmitting(false);
				setCouldNotSave(true);
			});
		}
	}

	const uploadLogo = (input: any) => {
		if (!input || !input.files || !input.files.length) {
			return;
		}

		setIsPhotoUploading(true);

		Api.executeMethod('subUsers.uploadLogo', {
			file: input.files[0]
		}).then((response: any) => {
			setIsPhotoUploading(false);
			setLogoUrl(response.ImageUrl);
		});
	}

	return <ModalPage
		id={props.id}
		header={(subUser || props.id === routeStore.Modals.AddSubUser) &&
			<ModalPageHeader
				before={
					<PanelHeaderBack onClick={() => closeModal()} />
				}
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => closeModal()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{/* Создание рабочей области */}
				{props.id === routeStore.Modals.AddSubUser && locale.headerAdd}

				{/* Редактирование рабочей области */}
				{props.id === routeStore.Modals.EditSubUser && locale.headerEdit}
			</ModalPageHeader>
		}
	>
		{(subUser || props.id === routeStore.Modals.AddSubUser) && <Group>
			<FormLayout>
				<FormItem
					// Название
					top={locale.name}
					status={!name && showErrors ? "error" : "default"}
					bottom={
						!name && showErrors
							// Пожалуйста, введите название рабочей области
							? locale.noNameError
							: null
					}
				>
					<Input
						type="text"
						name="name"
						crossOrigin={"anonymous"}
						className='to-focus'
						value={name}
						onChange={e => setName(e.target.value)}
						onKeyPress={e => ComponentHelper.onEnterKeyPress(e, () => saveChanges())}
					/>
				</FormItem>

				<FormItem
					// Логотип
					top={locale.logo}
					className='editSubUser-panel-logo'
				>
					<SubUserIcon
						className='editSubUser-logo'
						name={name}
						icon={logoUrl}
						width={32}
						height={32} />

					<File
						before={!isPhotoUploading && <Icon24Camera />}
						controlSize="m"
						mode="outline"
						className="editSubUser-selectLogo"
						onInput={(e) => uploadLogo(e.target)}
					>

						{isPhotoUploading && <Spinner className='editSubUser-selectLogo__spinner' />}

						{!isPhotoUploading && <>
							{/* Выбрать лого */}
							{locale.selectLogo}
						</>}
					</File>

					{logoUrl && <Button
						size="m"
						className="editSubUser-removeLogo"
						appearance="accent"
						mode="tertiary"
						onClick={() => setLogoUrl(null as any)}
						before={<Icon28DeleteOutline />}
					>
					</Button>}
				</FormItem>

				<FormItem status={couldNotSave ? "error" : "default"}
					top={
						couldNotSave
							// Не удается сохранить изменения. Попробуйте, пожалуйста, позже.
							? locale.error
							: null
					}>
					<Button
						size="l"
						stretched
						onClick={() => saveChanges()}
						loading={isSubmitting}>

						{/* Создать */}
						{props.id === routeStore.Modals.AddSubUser && locale.ctaAdd}

						{/* Сохранить */}
						{props.id === routeStore.Modals.EditSubUser && locale.ctaEdit}
					</Button>
				</FormItem>
			</FormLayout>

			{false &&
				<span>
					{/* Новая рабочая область */}
					{locale.newWorkspaceName}
				</span>}
		</Group>}
	</ModalPage>;
}

export default React.memo(EditSubUser);