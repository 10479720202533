export interface UserPostsHeaderL {
  calendarHeader: string,
  draftHeader: string,
  trashHeader: string
}

export abstract class UserPostsHeaderL {
  public static RU: UserPostsHeaderL = {
    calendarHeader: 'Календарь',
    draftHeader: 'Черновики',
    trashHeader: 'Корзина'
  }


  public static EN: UserPostsHeaderL = {
    calendarHeader: 'Calendar',
    draftHeader: 'Drafts',
    trashHeader: 'Trash'
  }
}