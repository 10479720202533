import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './PublishResults.css';

import { Api, useLocale, useStore } from '../../../shared/_index';
import { PublishResultsL } from './PublishResults.L';
import { Group, ModalPage, ModalPageHeader, PanelHeaderButton, Spinner, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { useLocation, useRouter } from '@happysanta/router';
import { Icon24Dismiss } from '@vkontakte/icons';
import { PublishResultsResponse } from './models/PublishResultsResponse';
import PublishResultsTabs from './children/PublishResultsTabs/PublishResultsTabs';
import PublishResultsList from './children/PublishResultsList/PublishResultsList';

const PublishResults = (props: { id: string, dynamicContentHeight: boolean }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let locale: PublishResultsL = useLocale(PublishResultsL);

	let router = useRouter();
	let location = useLocation();

	const [projectId] = useState(+location.getParams().itemProjectId);
	const [itemHash] = useState(location.getParams().itemHash);

	const [isLoading, setIsLoading] = useState(true);
	const [isPublishing, setIsPublishing] = useState(false);
	const [userPageId, setUserPageId] = useState(+location.getParams().itemPageId);
	const [publishResults, setPublishResults] = useState([] as PublishResultsResponse.PublishResultWithHistory[]);
	const [pagePublishResult, setPagePublishResult] = useState((null as any) as PublishResultsResponse.PublishResultWithHistory);

	const { principalStore, subUserStore } = useStore();

	const selectUserPage = (pageId) => {
		setPagePublishResult(publishResults.filter(s => s.PublishTarget.UserPageId === pageId)[0]);
		setUserPageId(pageId);
	}

	const doPublishAgain = (pageId: number) => {
		setIsPublishing(true);

		principalStore.get().then(() => {
			let principal = principalStore.principal;

			Api.executeMethod('posts.doPublish', {
				userId: subUserStore.subUser.UserId,
				projectId: projectId,
				itemHash: itemHash,
				userPageId: pageId
			}).then(() => {
				principalStore.postsChanged.next();

				refreshPublishResults();
			});
		});
	}

	const refreshPublishResults = () => {
		principalStore.get().then(() => {
			let principal = principalStore.principal;

			Api.executeMethod<PublishResultsResponse>('posts.getPublishResults', {
				userId: subUserStore.subUser.UserId,
				projectId: projectId,
				itemHash: itemHash
			}).then((response) => {
				setIsLoading(false);
				setIsPublishing(false);

				setPublishResults(response.PublishResults);
				setPagePublishResult(response.PublishResults.filter(s => s.PublishTarget.UserPageId === userPageId)[0]);
			});
		});
	};

	useEffect(() => {
		refreshPublishResults();
		// eslint-disable-next-line
	}, [props.id]);

	return <ModalPage
		id={props.id}
		header={
			<ModalPageHeader
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => router.popPage()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{!isPublishing && <>
					{/* Результаты публикации */}
					{locale.publishResultHeader}
				</>}

				{isPublishing && <>
					{/* Идет публикация... */}
					{locale.publishingHeader}
				</>}
			</ModalPageHeader>
		}
	>
		{(isLoading || isPublishing) &&
			<Spinner
				size='medium'
				className='publishResults__spinner'
			/>
		}

		{!isLoading && !isPublishing &&
			<Group className='publishResults__body'>
				{publishResults && publishResults.length > 1 &&
					<PublishResultsTabs
						id={props.id}
						userPageId={userPageId}
						publishResults={publishResults}
						onPageSelected={(pageId) => selectUserPage(pageId)}
					/>
				}

				{pagePublishResult &&
					<PublishResultsList
						publishResults={pagePublishResult.AllPublishResults}
						onPublishAgain={() => doPublishAgain(pagePublishResult.PublishTarget.UserPageId)}
					/>
				}
			</Group >
		}
	</ModalPage >;
}

export default React.memo(PublishResults);