import React from 'react';
import { observer } from "mobx-react-lite"

import './PostCardPage.css';

import { PostDataShort, UserPage, useStore } from '../../../shared/_index';
import SocialIcon from '../../../shared/components/SocialIcon/SocialIcon';
import { useRouter } from '@happysanta/router';


export interface PostCardPageProps {
	post: PostDataShort,
	publishResult: PostDataShort.PublishResult,
	userPages: UserPage[],

	className?: string | undefined,
	width: number,
	height: number
}

const PostCardPage = (props: PostCardPageProps) => {
	let userPage = props.userPages.filter(s => s.Id === props.publishResult.UserPageId)[0];

	let socialPage = <SocialIcon
		corners='round'
		height={props.height}
		width={props.width}
		socialPage={userPage.SourcePage}
	/>;

	let router = useRouter();
	const{routeStore} = useStore();

	const showPublishResults = () => {
		router.pushModal(routeStore.Modals.PublishResults, {
			itemProjectId: props.post.ProjectId.toString(),
			itemHash: props.post.ItemHash,
			itemPageId: props.publishResult.UserPageId.toString()
		});
	};

	return <li
		className={"postCardPage " + (props.className ? props.className : "")}
		style={{
			width: props.width,
			height: props.height
		}}
	>
		{/* eslint-disable jsx-a11y/anchor-is-valid */}
		<a onClick={() => showPublishResults()}>
			{socialPage}

			{props.publishResult.PublishState === 'Sent' &&
				<div className="postCardPage__status postCardPage__status_sent">
					<svg viewBox="0 0 512 512">
						<path d="m256 32c124 0 224 100 224 224c0 124-100 224-224 224c-124 0-224-100-224-224c0-124 100-224 224-224z m115 149c1-1 1-2 1-3c0-1 0-3-1-4l-31-24c-1-1-3-1-4-1c-1 0-2 0-3 1l-111 143c0 0-44-42-45-43c-1-1-4-3-6-3c-2 0-3 2-4 3l-26 26c-1 1-1 3-1 4c0 1 0 2 1 3l2 1c0 0 78 75 79 76c1 1 3 3 5 3c2 0 4-2 5-3z" />
					</svg>
				</div>
			}

			{props.publishResult.PublishState === 'Failed' &&
				<div className="postCardPage__status postCardPage__status_failed">
					<svg viewBox="0 0 512 512">
						<path d="m503 400l-219-383c-6-10-17-16-28-16-11 0-22 6-28 16l-219 383c-6 10-6 22 0 32 6 10 16 16 28 16l438 0c12 0 22-6 28-16 6-10 6-22 0-32z m-215-16l-64 0 0-64 64 0z m0-96l-64 0 0-128 64 0z" />
					</svg>
				</div>
			}
		</a>
	</li >;
}

export default React.memo(PostCardPage);