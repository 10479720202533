import { autorun, makeAutoObservable } from 'mobx';
import { Subject } from 'rxjs';
import { PostDataShort, Principal } from '../models/_index';
import { Cookies } from '../_index';
import { Store } from './Store';
import { Api } from '../services/_index';

export default class SubUserStore {
    private _subUser: Principal.SubUser = null as any;

    /** Выбранная рабочая область. */
    public get subUser(): Principal.SubUser {
        return this._subUser;
    }

    public set subUser(subUser: Principal.SubUser){
        this._subUser = subUser;

        if (subUser) {
            this.rootStore.languageStore.setLanguage(subUser.Language, subUser.Use24TimeFormat);
            this.rootStore.timeStore.setTimeZoneOffset(subUser.TimeZoneOffsetInMinutes);
            this.rootStore.timeStore.initClock();
        }
    }

    public readonly rootStore: Store;

    constructor(rootStore: Store) {
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }
}