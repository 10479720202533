export interface CheckRestorePasswordCodeL {
  restorePasswordHeader: string,
  restorePassword: string,
  emailSentHint: string,
  noCodeError: string,
  codeExpiredError: string,
  back: string
}

export abstract class CheckRestorePasswordCodeL {
  public static RU: CheckRestorePasswordCodeL = {
    restorePasswordHeader: 'Восстановление пароля',
    restorePassword: 'Восстановить пароль',
    emailSentHint: 'Введите код, который мы отправили на [email]',
    noCodeError: 'Пожалуйста, введите код',
    codeExpiredError: 'Код введен неверно или истек срок его действия',
    back: 'Назад'
  }


  public static EN: CheckRestorePasswordCodeL = {
    restorePasswordHeader: 'Restore password',
    restorePassword: 'Restore password',
    emailSentHint: 'Enter the code we sent to [email]',
    noCodeError: 'Please enter code',
    codeExpiredError: 'Code is incorrect or expired',
    back: 'Back'
  }
}