import { PostToPublish } from '../PostToPublish';
import { ProjectItem } from '../ProjectItem';

export namespace Vk {

    export interface PollBackground {
        Id: number,
        Type: string,
        Angle: number,
        Color: string,
        Points: PollBackground.Point[],

        BackgroundCSS?: string
    }

    export abstract class PollBackground {
        public static fillBackground(bg: PollBackground) {
            let backgroundCSS = `linear-gradient(${360 - bg.Angle}deg, `;

            for (var i = 0; i < bg.Points.length; i++) {
                backgroundCSS += `#${bg.Points[i].Color} ${bg.Points[i].Position * 100}%`;

                if (i < bg.Points.length - 1) {
                    backgroundCSS += ", ";
                }
            }

            backgroundCSS += ")";

            bg.BackgroundCSS = backgroundCSS;
        }
    }

    export namespace PollBackground {
        export interface Point {
            Position: number,
            Color: string
        }
    }

    export interface Document {
        Id?: string,
        OwnerId?: string,
        Title?: string,
        Size?: number,
        Ext?: string,
        Url?: string,
        CreatedAt?: number,
        Type?: string,
        AccessKey?: string,

        PreviewPhotos?: Document.PreviewPhoto[],
        PreviewVideo?: Document.PreviewVideo
    }

    export abstract class Document {
        public static equals(source: Document, target: PostToPublish.Attachment) {
            return target.Type === PostToPublish.AttachmentType.VkDocument
                && target.VkDocument
                && target.VkDocument.Id === source.Id
                && target.VkDocument.OwnerId === source.Id;
        }
    }

    export namespace Document {
        export interface PreviewPhoto {
            Url?: string,
            Width?: number,
            Height?: number,
            Type?: string,
        }

        export interface PreviewVideo {
            Url?: string,
            Width?: number,
            Height?: number,
            FileSize?: number,
        }
    }

    export interface Audio {
        Id?: string,
        OwnerId?: string,
        CreatedAt?: number,
        Artist?: string,
        Title?: string,
        Duration?: number,
        IsHQ?: boolean,
        IsContentRestricted?: boolean,
    }

    export abstract class Audio {
        public static parseFrom(audioAttachment: ProjectItem.VkAudioAttachment): Audio {
            return {
                Id: audioAttachment.AudioId.replace('audio', '').split('_')[1],
                OwnerId: audioAttachment.AudioId.replace('audio', '').split('_')[0],
                Title: audioAttachment.AudioName
            }
        }
    }

    export interface Place {
        Address?: string,
        Checkins?: number,
        City?: string,
        CityId?: number,
        Country?: string,
        CountryId?: number,
        Distance?: number,
        Icon?: string,
        Id?: number,
        Latitude?: number,
        Longitude?: number,
        Title?: string,
        Type: Place.Type
        TypeId?: number,
    }

    export namespace Place {
        export interface Type {
            Id?: number,
            Title?: string,
            Icon?: string
        }
    }
}