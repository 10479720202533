import { makeAutoObservable } from 'mobx';
import { Subject } from 'rxjs';
import { PostDataShort, Principal } from '../models/_index';
import { Cookies } from '../_index';
import { Store } from './Store';
import { Api, Credentials } from '../services/_index';
import { Router, Location, RouteList, Page } from '@happysanta/router';
import PrincipalStore from './PrincipalStore';
import { Pages, router } from '../../routers';

export interface PanelRoute {
    Route: string,
    PanelId: string,
    ViewId: string
}

export default class RouteStore {
    public readonly ViewIds = {
        Primary: 'view_primary',
        Secondary: 'view_secondary'
    }

    public readonly Popouts = {
        Confirm: 'popout_confirm',

        SubUserMenu: 'subUserMenu',
        CurrentSubUserMenu: 'currentSubUserMenu',
        PostMenu: 'postMenu',
        ChangeLanguage: 'changeLanguage',

        PrincipalMenu: 'principalMenu'
    }

    public readonly Modals = {
        //  Общие модальные окна
        AskQuestion: 'AskQuestion',

        //  Модальные окна входа в систему и восстановления пароля 
        Login: 'login',
        SendRestorePasswordLetter: 'sendRestorePasswordLetter',
        CheckRestorePasswordCode: 'checkRestorePasswordCode',
        ChangeRestoredPassword: 'changeRestoredPassword',

        // Модальные окна регистрации
        Register: 'register',
        ConfirmRegistrationCode: 'confirmRegistrationCode',

        // Модальный окна оболочки
        SelectSubUser: 'selectSubUser',
        AddSubUser: 'addSubUser',
        EditSubUser: 'editSubUser',
        DeleteSubUser: 'deleteSubUser',
        EditPrincipal: 'editPrincipal',

        // Модальные окна постов
        PublishResults: 'publishResults',
        SelectPostsContext: 'selectPostsContext',
        ComposeTimePicker: 'composeTimePicker',
        DeletePost: 'deletePost',
        ReturnToQueued: 'returnToQueued',

        PostEditor: {
            show: (userId: number, projectId: number, itemHash: string, location: Location, router: Router) => {
                let route: string = null as any;

                if (location.getPanelId() === this.Pages.Posts.PanelId) {
                    route = this.Pages.Posts.Route;
                }
                else if (location.getPanelId() === this.Pages.PostDrafts.PanelId) {
                    route = this.Pages.PostDrafts.Route;
                }
                else {
                    route = this.Pages.PostTrash.Route;
                }

                let parameters: any = {
                    userId: userId,
                    itemProjectId: projectId.toString(),
                    itemHash: itemHash,
                    showEditor: 1
                };

                if (location.getParams().projectId) {
                    parameters.projectId = location.getParams().projectId;
                }

                if (location.getParams().pageId) {
                    parameters.pageId = location.getParams().pageId;
                }

                if (location.getParams().day) {
                    parameters.day = location.getParams().day;
                }

                router.pushPage(route, parameters);
            }
        }
    };

    public readonly Pages = Pages;
    public router: Router;

    public readonly rootStore: Store;

    constructor(rootStore: Store) {
        this.rootStore = rootStore;

        this.router = router;

        this.router.onEnterPage(this.Pages.StartPage.Route, () => this.onEnterStartPage());
        this.router.on('update', (nextRoute, oldRoute) => this.onUpdateRoute(nextRoute, oldRoute));

        this.router.start();

        makeAutoObservable(this);
    }

    public onEnterStartPage() {
        let userIsLogged = Credentials.getAccessToken() !== null;

        if (userIsLogged) {
            this.router.replacePage(this.Pages.SwitchTo.Route, { userId: '0' });
            return;
        }
    }

    /** /product/:id([0-9]+) */
    public PageId: string = null as any;

    /** { id: "12" } */
    public Params: any = null as any;

    /** panel_product */
    public PanelId: string = null as any;

    /** view_main */
    public ViewId: string = null as any;

    /** /product/12 */
    public Location: string = null as any;

    public IsModal: boolean = false;

    public IsPopup: boolean = false;

    public HasOverlay: boolean = false;

    public QueryParams: any = {};

    public onUpdateRoute(nextRoute, oldRoute) {
        this.PageId = nextRoute.getPageId(); // /product/:id([0-9]+)
        this.Params = nextRoute.getParams() // { id: "12" }
        this.PanelId = nextRoute.getPanelId() // panel_product
        this.ViewId = nextRoute.getViewId() // view_main
        this.Location = nextRoute.getLocation() // /product/12
        this.IsModal = nextRoute.isModal() // false
        this.IsPopup = nextRoute.isPopup() // false
        this.HasOverlay = nextRoute.hasOverlay() // false

        if (nextRoute && nextRoute.getPanelId() !== this.Pages.StartPage.PanelId) {
            let userIsLogged = Credentials.getAccessToken() !== null;

            if (!userIsLogged) {
                this.router.replacePage(this.Pages.StartPage.Route);
                return;
            }
        }
    }
}