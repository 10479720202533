import React from 'react';
import { observer } from "mobx-react-lite"
import { useLocation, useRouter } from '@happysanta/router';

import './PostMenu.css';

import { PostDataShort, StateManager, useLocale, useStore } from '../../../shared/_index';
import { PostMenuL } from './PostMenu.L';
import { ActionSheet, ActionSheetItem } from '@vkontakte/vkui';
import { Icon24ArrowUturnLeftOutline, Icon24ShareExternal, Icon28ArrowUturnLeftOutline, Icon28DeleteOutline, Icon28EditOutline, Icon28ShareExternalOutline, Icon28SortOutline } from '@vkontakte/icons';
import PostMenuHeader from '../../components/PostMenuHeader/PostMenuHeader';

const PostMenu = () => {
	let locale: PostMenuL = useLocale(PostMenuL);

	let location = useLocation();
	let router = useRouter();

	const { subUserStore, routeStore } = useStore();

	let event: any = StateManager.getStateValue(location, 'event');
	let post: PostDataShort = StateManager.getStateValue(location, 'post');

	let toggleRef = event ? event.target : null as any;

	if (!event || !post) {
		router.popPage();
		return <></>;
	}

	const showPublishTo = () => {
		setTimeout(() => {
			router.pushModal(routeStore.Modals.PublishResults, {
				itemProjectId: post.ProjectId.toString(),
				itemHash: post.ItemHash,
				itemPageId: post.PublishResults.map(s => s.UserPageId.toString())[0]
			});
		}, 200);
	}

	const showEditModal = () => {
		setTimeout(() => {
			routeStore.Modals.PostEditor.show(subUserStore.subUser.UserId, post.ProjectId, post.ItemHash, location, router);
		}, 200);
	}

	const returnToQueued = () => {
		setTimeout(() => {
			router.pushModal(routeStore.Modals.ReturnToQueued, {
				itemProjectId: post.ProjectId.toString(),
				itemHash: post.ItemHash,
			});
		}, 200);
	}

	const showDeleteModal = () => {
		setTimeout(() => {
			router.pushModal(routeStore.Modals.DeletePost, {
				itemProjectId: post.ProjectId.toString(),
				itemHash: post.ItemHash,
			});
		}, 200);
	}

	return <ActionSheet
		toggleRef={toggleRef}
		onClose={() => router.popPage()}
		header={
			<PostMenuHeader post={post} />
		}
		iosCloseItem={
			<ActionSheetItem autoclose mode="cancel">
				{/* Отменить */}
				{locale.cancel}
			</ActionSheetItem>
		}
	>
		<ActionSheetItem
			before={<Icon28ShareExternalOutline />}
			autoclose
			onClick={() => showPublishTo()}>
			{/* Опубликовать в... */}
			{locale.publishTo}
		</ActionSheetItem>

		{(post.PublishState == "Archived" || post.PublishState == "Sent" || post.PublishState == "Failed") &&
			<ActionSheetItem
				before={<Icon28ArrowUturnLeftOutline />}
				autoclose
				onClick={() => returnToQueued()}>
				{/* Вернуть в неопубликованные */}
				{locale.returnToQueued}
			</ActionSheetItem>
		}

		<ActionSheetItem
			before={<Icon28EditOutline />}
			autoclose
			onClick={() => showEditModal()}>
			{/* Редактировать */}
			{locale.edit}
		</ActionSheetItem>

		{post.PublishState !== "Archived" &&
			<ActionSheetItem
				before={<Icon28DeleteOutline />}
				onClick={() => showDeleteModal()}
				autoclose
				mode="destructive">
				{/* Удалить */}
				{locale.delete}
			</ActionSheetItem>
		}
	</ActionSheet>;
}

export default React.memo(PostMenu);