import { useLocation, useRouter } from '@happysanta/router';
import React, { useEffect, useState } from 'react';

import './EditPrincipal.css';

import { Icon24Camera, Icon24Dismiss, Icon28DeleteOutline } from '@vkontakte/icons';
import { Button, File, FormItem, FormLayout, Group, Input, ModalPage, ModalPageHeader, PanelHeaderBack, PanelHeaderButton, Spinner, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { Api, ComponentHelper, useLocale, useStore } from '../../../shared/_index';
import { EditPrincipalL } from './EditPrincipal.L';

const EditPrincipal = (props: { id: string, dynamicContentHeight: boolean }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let locale: EditPrincipalL = useLocale(EditPrincipalL);

	let router = useRouter();
	let location = useLocation();

	const { principalStore, routeStore } = useStore();

	const [name, setName] = useState('');
	const [photoUrl, setPhotoUrl] = useState(null as any);
	const [defaultPhotoUrl] = useState(principalStore.getDefaultImageUrl());

	const [showErrors, setShowErrors] = useState(false);
	const [couldNotSave, setCouldNotSave] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isPhotoUploading, setIsPhotoUploading] = useState(false);

	const closeModal = () => {
		router.popPage();
	}

	useEffect(() => {
		principalStore.get().then(() => {
			setName(principalStore.principal.Name);
			setPhotoUrl(principalStore.principal.PhotoUrl);
		});

		ComponentHelper.focusModalInput(props.id);
	}, [props.id]);

	const saveChanges = () => {
		if (isSubmitting) {
			return;
		}

		if (!name) {
			setShowErrors(true);
			return;
		}

		setIsSubmitting(true);
		setShowErrors(false);

		Api.executeMethod('principal.editProfile', {
			name: name,
			userpicImageUrl: photoUrl
		}).then(() => {
			principalStore.principal = {
				...principalStore.principal,

				PhotoUrl: photoUrl,
				Name: name
			};

			closeModal();
		}).catch(() => {
			setIsSubmitting(false);
			setCouldNotSave(true);
		});
	}

	const uploadPhoto = (input: any) => {
		if (!input || !input.files || !input.files.length) {
			return;
		}

		setIsPhotoUploading(true);

		Api.executeMethod('subUsers.uploadLogo', {
			file: input.files[0]
		}).then((response: any) => {
			setIsPhotoUploading(false);
			setPhotoUrl(response.ImageUrl);
		});
	}

	return <ModalPage
		id={props.id}
		header={<ModalPageHeader
			before={
				<PanelHeaderBack onClick={() => closeModal()} />
			}
			after={
				isMobile &&
				// Назад
				<PanelHeaderButton aria-label={locale.back} onClick={() => closeModal()}>
					<Icon24Dismiss />
				</PanelHeaderButton>
			}
		>
			{/* Редактирование профиля */}
			{locale.header}
		</ModalPageHeader>
		}
	>
		{<Group>
			<FormLayout>
				<FormItem
					// Ваше имя
					top={locale.name}
					status={!name && showErrors ? "error" : "default"}
					bottom={
						!name && showErrors
							// Пожалуйста, введите Ваше имя
							? locale.noNameError
							: null
					}
				>
					<Input
						type="text"
						name="name"
						className='to-focus'
						crossOrigin={"anonymous"}
						value={name}
						onChange={e => setName(e.target.value)}
						onKeyPress={e => ComponentHelper.onEnterKeyPress(e, () => saveChanges())}
					/>
				</FormItem>

				<FormItem
					// Ваше фото
					top={locale.userpic}
					className='editPrincipal-panel-photo'
				>
					<div className='editPrincipal-photo'
						style={{ backgroundImage: 'url(' + photoUrl + ')' }}>

					</div>

					<File
						before={!isPhotoUploading && <Icon24Camera />}
						controlSize="m"
						mode="outline"
						className="editPrincipal-selectPhoto"
						onInput={(e) => uploadPhoto(e.target)}
					>
						{isPhotoUploading && <Spinner className='editPrincipal-selectPhoto__spinner' />}

						{!isPhotoUploading && <>
							{/* Выбрать фото */}
							{locale.selectPhoto}
						</>}

					</File>

					{(!!photoUrl && photoUrl != defaultPhotoUrl) && <Button
						size="m"
						className="editPrincipal-removePhoto"
						appearance="accent"
						mode="tertiary"
						onClick={() => setPhotoUrl(defaultPhotoUrl)}
						before={<Icon28DeleteOutline />}
					>
					</Button>}
				</FormItem>

				<FormItem status={couldNotSave ? "error" : "default"}
					top={
						couldNotSave
							// Не удается сохранить изменения. Попробуйте, пожалуйста, позже.
							? locale.error
							: null
					}>
					<Button
						size="l"
						stretched
						onClick={() => saveChanges()}
						loading={isSubmitting}>

						{/* Сохранить */}
						{locale.ctaEdit}
					</Button>
				</FormItem>
			</FormLayout>
		</Group>}
	</ModalPage>;
}

export default React.memo(EditPrincipal);