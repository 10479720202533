import React, { HTMLAttributeAnchorTarget } from 'react';
import { observer } from "mobx-react-lite"

import './LightButton.css';


export interface LightButtonProps {
	href?: string,
	target?: HTMLAttributeAnchorTarget | undefined,

	mode: "default" | "error" | "success",
	className?: string,
	onClick?: React.MouseEventHandler<HTMLDivElement> | undefined,

	hasCaretUp?: boolean | undefined,
	hasCaretDown?: boolean | undefined
}

const LightButton = (props: React.PropsWithChildren<LightButtonProps>) => {
	const getClassName = (): string => {
		let className = 'lightButton';

		if (props.mode === 'error') {
			className += ' lightButton__error';
		}
		else if (props.mode === 'success') {
			className += ' lightButton__success';
		}

		if (props.href || props.onClick) {
			className += ' lightButton__clickable';
		}

		if (props.className) {
			className += ' ' + className;
		}

		return className;
	}

	let getCaret = (): JSX.Element => {
		if (props.hasCaretUp || props.hasCaretDown) {
			return <>
				&nbsp;

				<svg
					className={props.hasCaretUp ? "lightButton__caret_up" : "lightButton__caret_down"}
					viewBox="0 0 512 512"
				>
					<path d="m99 185c-2-2-3-6-3-9c0-9 8-16 17-16l286 0c9 0 17 7 17 16c0 3-1 6-3 8l-2 3l-136 156c-5 5-11 9-19 9c-8 0-14-4-19-9l-136-156z" />
				</svg>
			</>
		}
		else {
			return <></>;
		}
	}

	if (props.href) {
		return <a
			className={getClassName()}
			href={props.href}
			target={props.target}
			rel={props.target === '_blank' ? "noopener noreferrer" : ""}
		>
			{props.children}

			{getCaret()}
		</a>
	}
	else {
		return <div
			onClick={props.onClick}
			className={getClassName()}
		>
			{props.children}

			{getCaret()}
		</div>
	}
}

export default React.memo(LightButton);