export interface ChangeLanguageMenuL {
    cancel: string,
    edit: string,
    sort: string,
    delete: string
  }
  
  export abstract class ChangeLanguageMenuL {
    public static RU: ChangeLanguageMenuL = {
      cancel: 'Отменить',
      edit: 'Редактировать',
      sort: 'Поменять местами',
      delete: 'Удалить'
    }
  
  
    public static EN: ChangeLanguageMenuL = {
      cancel: 'Cancel',
      edit: 'Edit workspace',
      sort: 'Change workspaces order',
      delete: 'Delete workspace'
    }
  }