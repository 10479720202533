import React, { useState } from 'react';
import { observer } from "mobx-react-lite"

import './CardGridHeader.css';

import { classNamesString } from '@vkontakte/vkui/dist/lib/classNames';

export interface CardGridHeaderProps {
	isCollapsed: boolean,
	onCollapsedChanged: (value: boolean) => void
}

const CardGridHeader = (props: React.PropsWithChildren<CardGridHeaderProps>) => {
	const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed);

	const toggleCollapsed = () => {
		props.onCollapsedChanged(!isCollapsed);
		setIsCollapsed(!isCollapsed);
	};

	return <div
		className={classNamesString(
			'cardGridHeader',
			{
				'cardGridHeader__collapsed': isCollapsed
			}
		)}
		onClick={() => toggleCollapsed()}
	>
		<div className='cardGridHeader__content'>
			{props.children}
		</div>
		<div className='cardGridHeader__rollUp'>
			{isCollapsed &&
				<svg viewBox="0 0 512 512">
					<path d="m397 160l19 21l-160 171l-160-171l19-21l141 150z" />
				</svg>
			}
			{!isCollapsed &&
				<svg viewBox="0 0 512 512">
					 <path d="m397 352l-141-150l-141 150l-19-21l160-171l160 171z"/>
				</svg>
			}
		</div>
	</div>;
}

export default React.memo(CardGridHeader);