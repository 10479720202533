import React, { useEffect, useState } from 'react';
import { useRouter } from '@happysanta/router';
import { StateManager, useLocale, useStore } from '../../../shared/_index';
import { StartPageL } from './StartPage.L';

import './StartPage.css';
import { Button, ButtonGroup, Panel, Placeholder } from '@vkontakte/vkui';

export interface StartPageProps {
	id: string
}

const StartPage = (props: StartPageProps) => {
	const { routeStore, languageStore } = useStore();

	let locale: StartPageL = useLocale(StartPageL);

	let router = useRouter();
	
	const showLanguageMenu = (event: any) => {
		router.pushPopup(routeStore.Popouts.ChangeLanguage, {
			state: StateManager.setState({
				event: event
			})
		});
	}

	return <Panel
		id={props.id}
		className='startPage'>
		<Placeholder
			icon={
				<img
					className='startPage-logo'
					alt='logo'
					width={'96px'}
					height={'96px'}
					src='/images/logo/logo512.png' />
			}
			// NovaPress - Менеджер публикации и аналитики для бизнеса
			header={locale.header}
			action={<div className='startPage-buttons'
			>
				<ButtonGroup
					mode="vertical"
					gap="m"
				>
					<Button
						size="l"
						appearance="accent"
						stretched
						onClick={() => router.pushModal(routeStore.Modals.Register)}
					>
						{/* Зарегистрироваться */}
						{locale.register}
					</Button>
					<Button
						size="l"
						appearance="accent"
						mode="secondary"
						stretched
						onClick={() => router.pushModal(routeStore.Modals.Login)}
					>
						{/* Войти в систему */}
						{locale.login}
					</Button>
				</ButtonGroup>
			</div>}
		>
			{/* Инструменты для роста в социальных сетях */}
			{locale.subHeader}
		</Placeholder>

		<div className='startPage__language' onClick={(e) => showLanguageMenu(e)}>
			{languageStore.language}
		</div>
	</Panel>;
}

export default React.memo(StartPage);