export interface PublishResultsL {
  back: string,
  publishResultHeader: string,
  publishingHeader: string
}

export abstract class PublishResultsL {
  public static RU: PublishResultsL = {
    back: 'Назад',
    publishResultHeader: 'Результаты публикации',
    publishingHeader: 'Идет публикация...'
  }


  public static EN: PublishResultsL = {
    back: 'Back',
    publishResultHeader: 'Publication results',
    publishingHeader: 'Publishing...'
  }
}