export interface SortSubUsersL {
  cta: string,
  cancel: string,
  header: string
}

export abstract class SortSubUsersL {
  public static RU: SortSubUsersL = {
    cta: 'Сохранить изменения',
    cancel: 'Отмена',
    header: 'Сортировка областей'
  }


  public static EN: SortSubUsersL = {
    cta: 'Save Changes',
    cancel: 'Cancel',
    header: 'Sort workspaces'
  }
}