import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './SelectPostsContext.css';

import { Api, ProjectDataShort, useLocale, UserPage, useStore } from '../../../shared/_index';
import { SelectPostsContextL } from './SelectPostsContext.L';
import { Group, HorizontalScroll, ModalPage, ModalPageHeader, PanelHeaderButton, Spinner, Tabs, TabsItem, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { useLocation, useRouter } from '@happysanta/router';
import { Icon20CalendarOutline, Icon20DeleteOutlineAndroid, Icon20ListPenOutline, Icon24Dismiss, Icon28AllCategoriesOutline } from '@vkontakte/icons';
import ProjectIcon from '../../../shared/components/ProjectIcon/ProjectIcon';
import SocialIcon from '../../../shared/components/SocialIcon/SocialIcon';

const SelectPostsContext = (props: { id: string, dynamicContentHeight: boolean, settlingHeight: number }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let location = useLocation();

	const [isLoading, setIsLoading] = useState(true);

	const { principalStore, subUserStore, routeStore } = useStore();

	const [activeTab] = useState((): "calendar" | "draft" | "deleted" => {
		if (location.getPanelId() === routeStore.Pages.Posts.PanelId) {
			return "calendar";
		}
		else if (location.getPanelId() === routeStore.Pages.PostDrafts.PanelId) {
			return "draft";
		}
		else {
			return "deleted";
		}
	});

	const [activeProjectId] = useState(() => {
		if (location.getParams().projectId) {
			return +location.getParams().projectId;
		}
		else {
			return 0;
		}
	});

	const [activePageId] = useState(() => {
		if (location.getParams().pageId) {
			return +location.getParams().pageId;
		}
		else {
			return 0;
		}
	});

	const [activeDay] = useState(() => {
		if (location.getParams().day) {
			return +location.getParams().day;
		}
		else {
			return 0;
		}
	});

	const scrollToActiveTab = (panel: "project" | "page") => {
		setTimeout(() => {
			let scrollContainer = document.querySelector(`#${props.id} .selectPostsContext__horizontalScroll_${panel}s>.vkuiHorizontalScroll__in`);

			if (scrollContainer) {
				let tabs = scrollContainer.children[0].children;

				let scrollLeft = 0;

				let activeKey = panel === "project"
					? (location.getParams().projectId ? +location.getParams().projectId : 0)
					: (location.getParams().pageId ? +location.getParams().pageId : 0);

				for (let tab of tabs) {
					if (!tab.classList.contains(`selectPostsContext__tabsItem_${activeKey}`)) {
						scrollLeft += tab.clientWidth;
					}
					else {
						scrollContainer.scrollTo({
							left: scrollLeft,
							behavior: 'smooth'
						});
						break;
					}
				}
			}
		}, 200);
	};

	const [projects, setProjects] = useState([] as ProjectDataShort[]);
	const [pages, setPages] = useState([] as UserPage[]);

	let locale: SelectPostsContextL = useLocale(SelectPostsContextL);

	let router = useRouter();

	const loadProjects = (): Promise<void> => {
		return new Promise<void>((resolve, reject) => {
			principalStore.get().then(() => {
				let principal = principalStore.principal;

				Api.executeMethod<any>("projects.getAllPlain", {
					userId: subUserStore.subUser.UserId
				}).then(response => {
					setProjects(response.Projects);
					resolve();
				}).catch((e) => {
					reject(e);
				});
			});
		});
	};

	const loadUserPages = (): Promise<void> => {
		return new Promise<void>((resolve, reject) => {
			principalStore.get().then(() => {
				let principal = principalStore.principal;

				Api.executeMethod<any>("pages.getAll", {
					userId: subUserStore.subUser.UserId
				}).then(response => {
					setPages(response.Pages);
					resolve();
				}).catch((e) => {
					reject(e);
				});
			});
		});
	};

	useEffect(() => {
		Promise.all([
			loadProjects(),
			loadUserPages()
		]).then(() => {
			setIsLoading(false);
		});

		scrollToActiveTab("page");
		scrollToActiveTab("project");
		// eslint-disable-next-line
	}, [props.id]);

	const navigateToTab = (tab: "calendar" | "draft" | "deleted") => {
		navigateTo(tab, activeProjectId, activePageId)
	}

	const navigateToProject = (project: ProjectDataShort) => {
		navigateTo(activeTab, project ? project.Id : 0, 0);
	}

	const navigateToPage = (page: UserPage) => {
		navigateTo(activeTab, 0, page ? page.Id : 0);
	}

	const navigateTo = (activeTab: "calendar" | "draft" | "deleted", projectId: number, userPageId: number) => {
		let userId = subUserStore.subUser.UserId.toString();
		let route: string = null as any;

		if (activeTab === "calendar") {
			route = routeStore.Pages.Posts.Route;
		}
		else if (activeTab === "draft") {
			route = routeStore.Pages.PostDrafts.Route;
		}
		else if (activeTab === "deleted") {
			route = routeStore.Pages.PostTrash.Route;
		}

		let parameters: any = {
			userId: userId
		};

		if (projectId) {
			parameters.projectId = projectId;
		}

		if (userPageId) {
			parameters.pageId = userPageId;
		}

		if (activeDay) {
			parameters.day = activeDay;
		}

		setTimeout(() => {
			router.pushPage(route, parameters);
		}, 300);

		router.popPage();
	}

	return <ModalPage
		id={props.id}
		dynamicContentHeight={props.dynamicContentHeight}
		settlingHeight={props.settlingHeight}
		header={
			<ModalPageHeader
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => router.popPage()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{/* Фильтр постов */}
				{locale.header}
			</ModalPageHeader>
		}
	>
		{isLoading && <Spinner />}

		{!isLoading && <>
			<Group>
				<Tabs>
					<HorizontalScroll
						className='selectPostsContext__horizontalScroll'
					>
						<TabsItem
							className="selectPostsContext__tabsItem"
							onClick={() => navigateToTab("calendar")}
							selected={activeTab === "calendar"}
						>
							<div className='selectPostsContext__tabsItem_icon'>
								<Icon20CalendarOutline
									width={28}
									height={28}
									className='selectPostsContext__cell_icon_calendar'
								/>
							</div>

							<span className='selectPostsContext__tabsItem_text'>
								{/* Календарь */}
								{locale.calendar}
							</span>
						</TabsItem>

						<TabsItem
							className="selectPostsContext__tabsItem"
							onClick={() => navigateToTab("draft")}
							selected={activeTab === "draft"}
						>
							<div className='selectPostsContext__tabsItem_icon'>
								<Icon20ListPenOutline
									width={28}
									height={28}
									className='selectPostsContext__cell_icon_draft'
								/>
							</div>

							<span className='selectPostsContext__tabsItem_text'>
								{/* Черновики */}
								{locale.draft}
							</span>
						</TabsItem>

						<TabsItem
							className="selectPostsContext__tabsItem"
							onClick={() => navigateToTab("deleted")}
							selected={activeTab === "deleted"}
						>
							<div className='selectPostsContext__tabsItem_icon'>
								<Icon20DeleteOutlineAndroid
									width={28}
									height={28}
									className='selectPostsContext__cell_icon_deleted'
								/>
							</div>

							<span className='selectPostsContext__tabsItem_text'>
								{/* Корзина */}
								{locale.deleted}
							</span>
						</TabsItem>
					</HorizontalScroll>
				</Tabs>
			</Group>

			<Group>
				<Tabs>
					<HorizontalScroll
						className='selectPostsContext__horizontalScroll selectPostsContext__horizontalScroll_pages'
					>
						<TabsItem
							className={"selectPostsContext__tabsItem selectPostsContext__tabsItem_page selectPostsContext__tabsItem_0"}
							key={0}
							onClick={() => navigateToPage(null as any)}
							selected={activePageId === 0}
						>
							<div className='selectPostsContext__tabsItem_icon'>
								<Icon28AllCategoriesOutline
									width={28}
									height={28}
								/>
							</div>

							<span className='selectPostsContext__tabsItem_text'>
								Все страницы
							</span>
						</TabsItem>

						{pages.map(page => <TabsItem
							className={"selectPostsContext__tabsItem selectPostsContext__tabsItem_page selectPostsContext__tabsItem_" + page.Id}
							key={page.Id}
							onClick={() => navigateToPage(page)}
							selected={activePageId === page.Id}
						>
							<div className='selectPostsContext__tabsItem_icon'>
								<SocialIcon
									socialPage={page.SourcePage}
									width={44}
									height={44}
									corners="round"
								/>
							</div>

							<span className='selectPostsContext__tabsItem_text'>
								{page.SourcePage.Name}
							</span>
						</TabsItem>)}
					</HorizontalScroll>
				</Tabs>
			</Group>

			<Group>
				<Tabs>
					<HorizontalScroll
						className='selectPostsContext__horizontalScroll selectPostsContext__horizontalScroll_projects'
					>
						<TabsItem
							className={"selectPostsContext__tabsItem selectPostsContext__tabsItem_project selectPostsContext__tabsItem_0"}
							key={0}
							onClick={() => navigateToProject(null as any)}
							selected={activeProjectId === 0}
						>
							<div className='selectPostsContext__tabsItem_icon'>
								<Icon28AllCategoriesOutline
									width={28}
									height={28}
								/>
							</div>

							<span className='selectPostsContext__tabsItem_text'>
								Все проекты
							</span>
						</TabsItem>

						{projects.map(project => <TabsItem
							className={"selectPostsContext__tabsItem selectPostsContext__tabsItem_project selectPostsContext__tabsItem_" + project.Id}
							key={project.Id}
							onClick={() => navigateToProject(project)}
							selected={activeProjectId === project.Id}
						>
							<div className='selectPostsContext__tabsItem_icon'>
								<ProjectIcon
									width={44}
									height={44}
									project={project}
									corners="round"
								/>
							</div>

							<span className='selectPostsContext__tabsItem_text'>
								{project.Name}
							</span>
						</TabsItem>)}
					</HorizontalScroll>
				</Tabs>
			</Group>
		</>}
	</ModalPage >;
}

export default React.memo(SelectPostsContext);