export interface PublishResultsListL {
  publishedEarlier: string,
  publishOnceAgain: string,
  openPost: string,
  tryAgain: string,
  publishNow: string
}

export abstract class PublishResultsListL {
  public static RU: PublishResultsListL = {
    publishedEarlier: 'Опубликованное ранее',
    publishOnceAgain: 'Опубликовать еще раз',
    openPost: 'Открыть запись',
    tryAgain: 'Попробовать еще раз',
    publishNow: 'Опубликовать сейчас'
  }


  public static EN: PublishResultsListL = {
    publishedEarlier: 'Previously published',
    publishOnceAgain: 'Publish again',
    openPost: 'Open post',
    tryAgain: 'Try again',
    publishNow: 'Publish Now'
  }
}