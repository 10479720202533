export interface InstagramMedia {
    Attachment: any,
    IsVideo: boolean,
    PreviewImageUrl: string,

    OriginalWidth?: number,
    OriginalHeight?: number,

    ResultWidth?: number,
    ResultHeight?: number,

    SrcRect?: InstagramMedia.Rectangle,
    DstRect?: InstagramMedia.Rectangle,
    OutputCanvas?: InstagramMedia.Canvas,

    ResizeMethod?: string
}

export abstract class InstagramMedia {
    public static calculate(media: InstagramMedia, operation: InstagramMedia.ConvertOperation, options: InstagramMedia.ConvertOptions) {
        // Get the dimensions of the original input file.
        let inputCanvas = new InstagramMedia.Canvas(
            media.OriginalWidth as any,
            media.OriginalHeight as any
        );

        let minWidth = media.IsVideo ? 480 : 320;
        let maxWidth = media.IsVideo ? 720 : 1080;

        // Whether this processor requires Mod2 width and height canvas dimensions.
        //      
        // If this is FALSE, the calculated `InstagramMedia` canvas passed to
        // this processor _may_ contain uneven width and/or height as the selected
        // output dimensions.
        //      
        // Therefore, this parameter must be TRUE if (and ONLY IF) perfectly even
        // dimensions are necessary for this particular processor's output format.
        //      
        // For example, JPEG images accept any dimensions and must therefore be
        // FALSE. But H264 videos require EVEN dimensions and must be TRUE.
        let isMod2CanvasRequired = media.IsVideo;

        // Initialize target canvas to original input dimensions & aspect ratio.
        // NOTE: MUST `float`-cast to FORCE float even when dividing EQUAL ints.
        let targetWidth = inputCanvas.Width;
        let targetHeight = inputCanvas.Height;
        let targetAspectRatio = (targetWidth + 0.0) / targetHeight;

        // Check aspect ratio and crop/expand the canvas to fit aspect if needed.
        if (targetAspectRatio < options.minAspectRatio ||
            (options.forceAspectRatio && targetAspectRatio < options.forceAspectRatio)) {
            // Determine target ratio; uses forced aspect ratio if set,
            // otherwise we target the MINIMUM allowed ratio (since we're < it)).
            targetAspectRatio = options.forceAspectRatio !== 0 ? options.forceAspectRatio : options.minAspectRatio;

            if (operation === InstagramMedia.ConvertOperation.Crop) {
                // We need to limit the height, so floor is used intentionally to
                // AVOID rounding height upwards to a still-too-low aspect ratio.
                targetHeight = Math.floor(targetWidth / targetAspectRatio);
            }
            else {
                // We need to expand the width with left/right borders. We use
                // ceil to guarantee that the final media is wide enough to be
                // above the minimum allowed aspect ratio.
                targetWidth = Math.ceil(targetHeight * targetAspectRatio);
            }
        }
        else if (targetAspectRatio > options.maxAspectRatio ||
            (options.forceAspectRatio !== 0 && targetAspectRatio > options.forceAspectRatio)) {
            // Determine target ratio; uses forced aspect ratio if set,
            // otherwise we target the MAXIMUM allowed ratio (since we're > it)).
            targetAspectRatio = options.forceAspectRatio !== 0 ? options.forceAspectRatio : options.maxAspectRatio;

            if (operation === InstagramMedia.ConvertOperation.Crop) {
                // We need to limit the width. We use floor to guarantee cutting
                // enough pixels, since our width exceeds the maximum allowed ratio.
                targetWidth = Math.floor(targetHeight * targetAspectRatio);
            }
            else {
                // We need to expand the height with top/bottom borders. We use
                // ceil to guarantee that the final media is tall enough to be
                // below the maximum allowed aspect ratio.
                targetHeight = Math.ceil(targetWidth / targetAspectRatio);
            }
        }

        // Determine whether the final target ratio is closest to either the
        // legal MINIMUM or the legal MAXIMUM aspect ratio limits.
        // NOTE: The target ratio will actually still be set to the original
        // input media's ratio in case of no aspect ratio adjustments above.
        // NOTE: If min and/or max ratios were not provided, we default min to
        // `0` and max to `9999999` to ensure that we properly detect the "least
        // distance" direction even when only one (or neither) of the two "range
        // limit values" were provided.
        var minAspectDistance = Math.abs(options.minAspectRatio - targetAspectRatio);
        var maxAspectDistance = Math.abs(options.maxAspectRatio - targetAspectRatio);
        var isClosestToMinAspect = minAspectDistance <= maxAspectDistance;

        // We MUST now set up the correct height re-calculation behavior for the
        // later algorithm steps. This is used whenever our canvas needs to be
        // re-scaled by any other code below. If our chosen, final target ratio
        // is closest to the minimum allowed legal ratio, we'll always use
        // floor() on the height to ensure that the height value becomes as low
        // as possible (since having LESS height compared to width is what
        // causes the aspect ratio value to grow), to ensure that the final
        // result's ratio (after any additional adjustments) will ALWAYS be
        // ABOVE the minimum legal ratio (minAspectRatio). Otherwise we'll
        // instead use ceil() on the height (since having more height causes the
        // aspect ratio value to shrink), to ensure that the result is always
        // BELOW the maximum ratio (maxAspectRatio).
        var useFloorHeightRecalc = isClosestToMinAspect;

        // Verify square target ratios by ensuring canvas is now a square.
        // NOTE: This is just a sanity check against wrong code above. It will
        // never execute, since all code above took care of making both
        // dimensions identical already (if they differed in any way, they had a
        // non-1 ratio and invoked the aspect ratio cropping/expansion code). It
        // then made identical thanks to the fact that X / 1 = X, and X * 1 = X.
        // NOTE: It's worth noting that our squares are always the size of the
        // shortest side when cropping or the longest side when expanding.
        // WARNING: Comparison MUST use `==` (NOT strict `===`) to support both
        // int(1) and float(1.0) values!
        // eslint-disable-next-line
        if (targetAspectRatio == 1.0 && targetWidth != targetHeight) {
            targetWidth = targetHeight =
                operation === InstagramMedia.ConvertOperation.Crop
                    ? Math.min(targetWidth, targetHeight)
                    : Math.max(targetWidth, targetHeight);
        }

        // Lastly, enforce minimum and maximum width limits on our final canvas.
        // NOTE: Instagram only enforces width & aspect ratio, which in turn
        // auto-limits height (since we can only use legal height ratios).
        // NOTE: Yet again, if the target ratio is 1 (square), we'll get
        // identical width & height, so NO NEED to MANUALLY "fix square" here.
        if (targetWidth > maxWidth) {
            targetWidth = maxWidth;
            targetHeight = useFloorHeightRecalc
                ? Math.floor(targetWidth / targetAspectRatio)
                : Math.ceil(targetWidth / targetAspectRatio);
        }
        else if (targetWidth < minWidth) {
            targetWidth = minWidth;
            targetHeight = useFloorHeightRecalc
                ? Math.floor(targetWidth / targetAspectRatio)
                : Math.ceil(targetWidth / targetAspectRatio);
        }

        // All of the main canvas algorithms are now finished, and we are now
        // able to check Mod2 compatibility and accurately readjust if needed.
        var mod2WidthDiff = 0;
        var mod2HeightDiff = 0;

        if (isMod2CanvasRequired
            && (targetWidth % 2 !== 0 || targetHeight % 2 !== 0)) {
            // Calculate the Mod2-adjusted final canvas size.
            // Initialize to the calculated canvas size.
            var mod2Width = targetWidth;
            var mod2Height = targetHeight;

            // Determine if we're able to cut an extra pixel from the width if
            // necessary, or if cutting would take us below the minimum width.
            var canCutWidth = mod2Width > minWidth;

            // To begin, we must correct the width if it's uneven. We'll only do
            // this once, and then we'll leave the width at its new number. By
            // keeping it static, we don't risk going over its min/max width
            // limits. And by only varying one dimension (height) if multiple Mod2
            // offset adjustments are needed, then we'll properly get a steadily
            // increasing/decreasing aspect ratio (moving towards the target ratio).
            if (mod2Width % 2 !== 0) {
                // Always prefer cutting an extra pixel, rather than stretching
                // by +1. But use +1 if cutting would take us below minimum width.
                // NOTE: Another IMPORTANT reason to CUT width rather than extend
                // is because in narrow cases (canvas close to original input size),
                // the extra width proportionally increases total area (thus height
                // too), and gives us less of the original pixels on the height-axis
                // to play with when attempting to fix the height (and its ratio).
                mod2Width += canCutWidth ? -1 : 1;

                // Calculate the new relative height based on the new width.
                mod2Height = useFloorHeightRecalc
                    ? Math.floor(targetWidth / targetAspectRatio)
                    : Math.ceil(targetWidth / targetAspectRatio);
            }

            // Ensure that the calculated height is also Mod2, but totally ignore
            // the aspect ratio at this moment (we'll fix that later). Instead,
            // we'll use the same pattern we'd use for width above. That way, if
            // both width and height were uneven, they both get adjusted equally.
            if (mod2Height % 2 !== 0) {
                mod2Height += canCutWidth ? -1 : 1;
            }

            // We will now analyze multiple different height alternatives to find
            // which one gives us the best visual quality. This algorithm looks
            // for the best qualities (with the most pixel area) first. It first
            // tries the current height (offset 0, which is the closest to the
            // pre-Mod2 adjusted canvas), then +2 pixels (gives more pixel area if
            // this is possible), then -2 pixels (cuts but may be our only choice).
            // After that, it checks 4, -4, 6 and -6 as well.
            // NOTE: Every increased offset (+/-2, then +/-4, then +/- 6) USUALLY
            // (but not always) causes more and more deviation from the intended
            // cropping aspect ratio. So don't add any more steps after 6, since
            // NOTHING will be THAT far off! Six was chosen as a good balance.
            // NOTE: Every offset is checked for visual stretching and aspect ratio,
            // and then rated into one of 3 categories: "perfect" (legal aspect
            // ratio, no stretching), "stretch" (legal aspect ratio, but stretches),
            // or "bad" (illegal aspect ratio).
            var heightAlternatives: {
                Offset: number,
                Height: number,
                Ratio: number,
                IsLegalRatio: boolean,
                StretchAmount: number,
                RatioDeviation: number,
                Rating: string
            }[] = [];

            let offsetPriorities: number[] = [0, 2, -2, 4, -4, 6, -6];

            for (let offset of offsetPriorities) {
                // Calculate the new height and its resulting aspect ratio.
                // NOTE: MUST `float`-cast to FORCE float even when dividing EQUAL ints.
                var offsetMod2Height = mod2Height + offset;
                var offsetMod2AspectRatio = (mod2Width + 0.0) / offsetMod2Height;

                // Check if the aspect ratio is legal.
                var isLegalRatio = offsetMod2AspectRatio >= options.minAspectRatio
                    && offsetMod2AspectRatio <= options.maxAspectRatio;

                // Detect whether the height would need stretching. Stretching is
                // defined as "not enough pixels in the input media to reach".
                // NOTE: If the input media has been upscaled (such as a 64x64 image
                // being turned into 320x320), then we will ALWAYS detect that media
                // as needing stretching. That's intentional and correct, because
                // such media will INDEED need stretching, so there's never going to
                // be a perfect rating for it (where aspect ratio is legal AND zero
                // stretching is needed to reach those dimensions).
                // NOTE: The max() gets rid of negative values (cropping).
                var stretchAmount = Math.max(0, offsetMod2Height - inputCanvas.Height);

                // Calculate the deviation from the target aspect ratio. The larger
                // this number is, the further away from "the ideal canvas". The
                // "perfect" answers will always deviate by different amount, and
                // the most perfect one is the one with least deviation.
                var ratioDeviation = Math.abs(offsetMod2AspectRatio - targetAspectRatio);

                // Rate this height alternative and store it according to rating.
                var rating = isLegalRatio && stretchAmount === 0 ? "perfect" : (isLegalRatio ? "stretch" : "bad");

                heightAlternatives.push({
                    Offset: offset,
                    Height: offsetMod2Height,
                    Ratio: offsetMod2AspectRatio,
                    IsLegalRatio: isLegalRatio,
                    StretchAmount: stretchAmount,
                    RatioDeviation: ratioDeviation,
                    Rating: rating
                });
            }

            // Now pick the BEST height from our available choices (if any). We will
            // pick the LEGAL height that has the LEAST amount of deviation from the
            // ideal aspect ratio. In other words, the BEST-LOOKING aspect ratio!
            // NOTE: If we find no legal (perfect or stretch) choices, we'll pick
            // the most accurate (least deviation from ratio) of the bad choices.
            var bestAlternative = heightAlternatives[0];
            bestAlternative = null as any;

            for (let rating of ["perfect", "stretch", "bad"]) {
                // Sort all alternatives by their amount of ratio deviation.
                // Pick the 1st array element, which has the least deviation!
                bestAlternative = heightAlternatives
                    .filter(s => s.Rating === rating)
                    .sort((a, b) => a.RatioDeviation > b.RatioDeviation ? 1 : -1)[0];

                if (bestAlternative != null) {
                    break;
                }
            }

            // Process and apply the best-possible height we found.
            mod2Height = bestAlternative.Height;

            mod2WidthDiff = mod2Width - targetWidth;
            mod2HeightDiff = mod2Height - targetHeight;

            targetWidth = mod2Width;
            targetHeight = mod2Height;
        }

        // Create an output canvas with the desired dimensions.
        // WARNING: This creates a LEGAL canvas which MUST be followed EXACTLY.
        media.OutputCanvas = new InstagramMedia.Canvas(
            targetWidth,
            targetHeight
        );

        // Determine the media operation's resampling parameters and perform it.
        // NOTE: This section is EXCESSIVELY commented to explain each step. The
        // algorithm is pretty easy after you understand it. But without the
        // detailed comments, future contributors may not understand any of it!
        // "We'd rather have a WaLL oF TeXt for future reference, than bugs due
        // to future misunderstandings!" - SteveJobzniak ;-)
        if (operation === InstagramMedia.ConvertOperation.Crop) {
            // Determine the IDEAL canvas dimensions as if Mod2 adjustments were
            // not applied. That's NECESSARY for calculating an ACCURATE scale-
            // change compared to the input, so that we can calculate how much
            // the canvas has rescaled. WARNING: These are 1-dimensional scales,
            // and only ONE value (the uncropped side) is valid for comparison.
            let idealCanvas = new InstagramMedia.Canvas(
                media.OutputCanvas.Width - mod2WidthDiff,
                media.OutputCanvas.Height - mod2HeightDiff
            );

            var idealWidthScale = (idealCanvas.Width + 0.0) / inputCanvas.Width;
            var idealHeightScale = (idealCanvas.Height + 0.0) / inputCanvas.Height;

            // eslint-disable-next-line
            let hasCropped: string = null as any;
            let overallRescale: number = 0;

            // Now determine HOW the IDEAL canvas has been cropped compared to
            // the INPUT canvas. But we can't just compare dimensions, since our
            // algorithms may have cropped and THEN scaled UP the dimensions to
            // legal values far above the input values, or scaled them DOWN and
            // then Mod2-cropped at the new scale, etc. There are so many
            // possibilities. That's also why we couldn't "just keep track of
            // amount of pixels cropped during main algorithm". We MUST figure
            // it out ourselves accurately HERE. We can't do it at any earlier
            // stage, since cumulative rounding errors from width/height
            // readjustments could drift us away from the target aspect ratio
            // and could prevent pixel-perfect results UNLESS we calc it HERE.
            //
            // There's IS a great way to figure out the cropping. When the WIDTH
            // of a canvas is reduced (making it more "portraity"), its aspect
            // ratio number decreases. When the HEIGHT of a canvas is reduced
            // (making it more "landscapey"), its aspect ratio number increases.
            //
            // And our canvas cropping algorithm only crops in ONE DIRECTION
            // (width or height), so we only need to detect the aspect ratio
            // change of the IDEAL (non-Mod2-adjusted) canvas, to know what
            // happened. However, note that this CAN also trigger if the input
            // had to be up/downscaled (to an imperfect final aspect), but that
            // doesn't matter since this algorithm will STILL figure out the
            // proper scale and croppings to use for the canvas. Because uneven,
            // aspect-affecting scaling basically IS cropping the INPUT canvas!
            if (idealCanvas.AspectRatio === inputCanvas.AspectRatio) {
                // No sides have been cropped. So both width and height scales
                // WILL be IDENTICAL, since NOTHING else would be able to create
                // an identical aspect ratio again (otherwise the aspect ratio
                // would have been warped (not equal)). So just pick either one.
                // NOTE: Identical (uncropped ratio) DOESN'T mean that scale is
                // going to be 1.0. It MAY be. Or the canvas MAY have been
                // evenly expanded or evenly shrunk in both dimensions.
                hasCropped = "nothing";
                overallRescale = idealWidthScale; // $idealHeightScale IS identical.
            }
            else if (idealCanvas.AspectRatio < inputCanvas.AspectRatio) {
                // The horizontal width has been cropped. Grab the height's
                // scale, since that side is "unaffected" by the main cropping
                // and should therefore have a scale of 1. Although it may have
                // had up/down-scaling. In that case, the height scale will
                // represent the amount of overall rescale change.
                hasCropped = "width";
                overallRescale = idealHeightScale;
            }
            else {
                // Output aspect is > input.
                // The vertical height has been cropped. Just like above, the
                // "unaffected" side is what we'll use as our scale reference.
                hasCropped = "height";
                overallRescale = idealWidthScale;
            }

            // Alright, now calculate the dimensions of the "IDEALLY CROPPED
            // INPUT canvas", at INPUT canvas scale. These are the scenarios:
            //
            // - "hasCropped: nothing, scale is 1.0" = Nothing was cropped, and
            //   nothing was scaled. Treat as "use whole INPUT canvas". This is
            //   pixel-perfect.
            //
            // - "hasCropped: nothing, scale NOT 1.0" = Nothing was cropped, but
            //   the whole canvas was up/down-scaled. We don't have to care at
            //   all about that scaling and should treat it as "use whole INPUT
            //   canvas" for crop calculation purposes. The cropped result will
            //   later be scaled/stretched to the canvas size (up or down).
            //
            // - "hasCropped: width/height, scale is 1.0" = A single side was
            //   cropped, and nothing was scaled. Treat as "use IDEALLY CROPPED
            //   canvas". This is pixel-perfect.
            //
            // - "hasCropped: width/height, scale NOT 1.0" = A single side was
            //   cropped, and then the whole canvas was up/down-scaled. Treat as
            //   "use scale-fixed version of IDEALLY CROPPED canvas". The
            //   cropped result will later be scaled/stretched to the canvas
            //   size (up or down).
            //
            // There's an easy way to handle ALL of those scenarios: Just
            // translate the IDEALLY CROPPED canvas back into INPUT-SCALED
            // dimensions. Then we'll get a pixel-perfect "input crop" whenever
            // scale is 1.0, since a scale of 1.0 gives the same result back.
            // And we'll get a properly re-scaled result in all other cases.
            //
            // NOTE: This result CAN deviate from what was "actually cropped"
            // during the main algorithm. That is TOTALLY INTENTIONAL AND IS THE
            // INTENDED, PERFECT BEHAVIOR! Do NOT change this code! By always
            // re-calculating here, we'll actually FIX rounding errors caused by
            // the main algorithm's multiple steps, and will create better
            // looking rescaling, and pixel-perfect unscaled croppings and
            // pixel-perfect unscaled Mod2 adjustments!

            // First calculate the overall IDEAL cropping applied to the INPUT
            // canvas. If scale is 1.0 it will be used as-is (pixel-perfect).
            // NOTE: We tell it to use round() so that the rescaled pixels are
            // as close to the perfect aspect ratio as possible.
            var croppedInputCanvas = new InstagramMedia.Canvas(
                Math.round((1.0 / overallRescale) * idealCanvas.Width),
                Math.round((1.0 / overallRescale) * idealCanvas.Height));

            // Now re-scale the Mod2 adjustments to the INPUT canvas coordinate
            // space too. If scale is 1.0 they'll be used as-is (pixel-perfect).
            // If the scale is up/down, they'll be rounded to the next whole
            // number. The rounding is INTENTIONAL, because if scaling was used
            // for the IDEAL canvas then it DOESN'T MATTER how many exact pixels
            // we crop, but round() gives us the BEST APPROXIMATION!
            var rescaledMod2WidthDiff = Math.round((1.0 / overallRescale) * mod2WidthDiff);
            var rescaledMod2HeightDiff = Math.round((1.0 / overallRescale) * mod2HeightDiff);

            // Apply the Mod2 adjustments to the input cropping that we'll
            // perform. This ensures that ALL of the Mod2 croppings (in ANY
            // dimension) will always be pixel-perfect when we're at scale 1.0!
            croppedInputCanvas = new InstagramMedia.Canvas(
                croppedInputCanvas.Width + rescaledMod2WidthDiff,
                croppedInputCanvas.Height + rescaledMod2HeightDiff
            );

            // The "CROPPED INPUT canvas" is in the same dimensions/coordinate
            // space as the "INPUT canvas". So ensure all dimensions are valid
            // (don't exceed INPUT) and create the final "CROPPED INPUT canvas".
            // NOTE: This is it... if the media is at scale 1.0, we now have a
            // pixel-perfect, cropped canvas with ALL of the cropping and Mod2
            // adjustments applied to it! And if we're at another scale, we have
            // a perfectly recalculated, cropped canvas which took into account
            // cropping, scaling and Mod2 adjustments. Advanced stuff! :-)
            croppedInputCanvas = new InstagramMedia.Canvas(
                croppedInputCanvas.Width <= inputCanvas.Width
                    ? croppedInputCanvas.Width
                    : inputCanvas.Width,
                croppedInputCanvas.Height <= inputCanvas.Height
                    ? croppedInputCanvas.Height
                    : inputCanvas.Height
            );

            // Initialize the crop-shifting variables. They control the range of
            // X/Y coordinates we'll copy from ORIGINAL INPUT to OUTPUT canvas.
            // NOTE: This properly selects the entire INPUT media canvas area.
            var x1 = 0;
            var y1 = 0;
            var x2 = inputCanvas.Width;
            var y2 = inputCanvas.Height;

            // Calculate the width and height diffs between the original INPUT
            // canvas and the new CROPPED INPUT canvas. Negative values mean the
            // output is smaller (which we'll handle by cropping), and larger
            // values would mean the output is larger (which we'll handle by
            // letting the OUTPUT canvas stretch the 100% uncropped original
            // pixels of the INPUT in that direction, to fill the whole canvas).
            // NOTE: Because of clamping of the CROPPED INPUT canvas above, this
            // will actually never be a positive ("scale up") number. It will
            // only be 0 or less. That's good, just be aware of it if editing!
            var widthDiff = croppedInputCanvas.Width - inputCanvas.Width;
            var heightDiff = croppedInputCanvas.Height - inputCanvas.Height;

            // Horizontal and vertical cropping. Focus on the center by default.
            var horCropFocus = 0;

            // Vertical cropping. Focus on top by default (to keep faces).
            var verCropFocus = -50;

            var absWidthDiff = 0;
            var absHeightDiff = 0;

            // After ALL of that work... we finally know how to crop the input
            // canvas! Alright... handle cropping of the INPUT width and height!
            // NOTE: The main canvas-creation algorithm only crops a single
            // dimension (width or height), but its Mod2 adjustments may have
            // caused BOTH to be cropped, which is why we MUST process both.
            if (widthDiff < 0) {
                // Calculate amount of pixels to crop and shift them as-focused.
                // NOTE: Always use floor() to make uneven amounts lean at left.
                absWidthDiff = Math.abs(widthDiff);
                x1 = Math.floor(absWidthDiff * (50.0 + horCropFocus) / 100.0);
                x2 = x2 - (absWidthDiff - x1);
            }

            if (heightDiff < 0) {
                // Calculate amount of pixels to crop and shift them as-focused.
                // NOTE: Always use floor() to make uneven amounts lean at left.
                absHeightDiff = Math.abs(heightDiff);
                y1 = Math.floor(absHeightDiff * (50.0 + verCropFocus) / 100.0);
                y2 = y2 - (absHeightDiff - y1);
            }

            // Create a source rectangle which starts at the start-offsets
            // (x1/y1) and lasts until the width and height of the desired area.
            media.SrcRect = new InstagramMedia.Rectangle(x1, y1, x2 - x1, y2 - y1);

            // Create a destination rectangle which completely fills the entire
            // output canvas from edge to edge. This ensures that any undersized
            // or oversized input will be stretched properly in all directions.
            //
            // NOTE: Everything about our cropping/canvas algorithms is
            // optimized so that stretching won't happen unless the media is so
            // tiny that it's below the minimum width or so wide that it must be
            // shrunk. Everything else WILL use sharp 1:1 pixels and pure
            // cropping instead of stretching/shrinking. And when stretch/shrink
            // is used, the aspect ratio is always perfectly maintained!
            media.DstRect = new InstagramMedia.Rectangle(0, 0, media.OutputCanvas.Width, media.OutputCanvas.Height);
        }
        else {
            // We'll copy the entire original input media onto the new canvas.
            // Always copy from the absolute top left of the original media.
            media.SrcRect = new InstagramMedia.Rectangle(0, 0, inputCanvas.Width, inputCanvas.Height);

            // Determine the target dimensions to fit it on the new canvas,
            // because the input media's dimensions may have been too large.
            // This will not scale anything (uses scale=1) if the input fits.
            var outputWidthScale = (media.OutputCanvas.Width + 0.0) / inputCanvas.Width;
            var outputHeightScale = (media.OutputCanvas.Height + 0.0) / inputCanvas.Height;
            var scale = Math.min(outputWidthScale, outputHeightScale);

            // Calculate the scaled destination rectangle. Note that X/Y remain.
            // NOTE: We tell it to use ceil(), which guarantees that it'll
            // never scale a side badly and leave a 1px gap between the media
            // and canvas sides. Also note that ceil will never produce bad
            // values, since PHP allows the dst_w/dst_h to exceed beyond canvas!
            media.DstRect = new InstagramMedia.Rectangle(
                media.SrcRect.X,
                media.SrcRect.Y,
                Math.ceil(scale * media.SrcRect.Width),
                Math.ceil(scale * media.SrcRect.Height)
            );

            // Now calculate the centered destination offset on the canvas.
            // NOTE: We use floor() to ensure that the result gets left-aligned
            // perfectly, and prefers to lean towards towards the top as well.
            media.DstRect.X = Math.floor((media.OutputCanvas.Width - media.DstRect.Width + 0.0) / 2);
            media.DstRect.Y = Math.floor((media.OutputCanvas.Height - media.DstRect.Height + 0.0) / 2);

            if (media.DstRect.X === -1
                && media.DstRect.Y === -1
                && media.OutputCanvas.Width === media.DstRect.Width - 1
                && media.OutputCanvas.Height === media.DstRect.Height - 1) {
                media.DstRect.X = 0;
                media.DstRect.Y = 0;

                media.DstRect.Width = media.OutputCanvas.Width;
                media.DstRect.Height = media.OutputCanvas.Height;
            }
        }
    }
}

// eslint-disable-next-line
export namespace InstagramMedia {
    export enum ConvertOperation {
        Crop = 1,
        Expand = 2
    }

    export interface ConvertOptions {
        minAspectRatio: number,
        maxAspectRatio: number,
        forceAspectRatio: number
    }

    export class Rectangle {
        public X: number;
        public Y: number;
        public Width: number;
        public Height: number;

        public constructor(x: number, y: number, width: number, height: number) {
            this.X = x;
            this.Y = y;
            this.Width = width;
            this.Height = height;
        }
    }

    export class Canvas {
        public Width: number;
        public Height: number;

        public constructor(width: number, height: number) {
            this.Width = width;
            this.Height = height;
        }

        public get AspectRatio(): number {
            return this.Height
                ? (this.Width + 0.0) / this.Height
                : 0;
        }
    }
}