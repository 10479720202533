import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './ComposeButton.css';

import { useLocale, useStore } from '../../../../../../shared/_index';
import { ComposeButtonL } from './ComposeButton.L';
import { classNames } from '@vkontakte/vkjs';
import { Icon36Users3Outline, Icon56NotePenOutline } from '@vkontakte/icons';


export interface ComposeButtonProps {
	onAddPost: () => void
}

const ComposeButton = (props: ComposeButtonProps) => {
	let locale: ComposeButtonL = useLocale(ComposeButtonL);
	const { principalStore, subUserStore } = useStore();

	const [showButtons, setShowButtons] = useState(false);

	const [connectPagesHref, setConnectPagesHref] = useState('');

	useEffect(() => {
		principalStore.get().then(() => {
			let principal = principalStore.principal;

			setConnectPagesHref(`https://novapress.com/user/${subUserStore.subUser.UserId}/socials`);
		});
	}, []);

	const createPost = () =>{
		setShowButtons(false);
		props.onAddPost();
	};

	return <>
		{showButtons &&
			<div className='composeButton__backdrop' onClick={() => setShowButtons(false)}>

			</div>
		}

		<div className={classNames('composeButton', {
			'composeButton__expanded': showButtons
		})}>
			{!showButtons && <>
				<div className='composeButton__addButton' onClick={() => setShowButtons(true)}>
					+
				</div>
			</>}

			{showButtons && <>
				<a 
					className='composeButton__addButton' 
					target="_blank" 
					rel="noopener noreferrer" 
					href={connectPagesHref}
					onClick={() => setShowButtons(false)}
					>
					<Icon36Users3Outline width={32} height={32} color={'white'} />

					<div className='composeButton__addButton_desc'>
						{/* Подключить страницы */}
						{locale.connectPages}
					</div>
				</a>

				<div className='composeButton__addButton' onClick={() => createPost()}>
					<Icon56NotePenOutline width={32} height={32} color={'white'} />

					<div className='composeButton__addButton_desc'>
						{/* Создать пост */}
						{locale.createPost}
					</div>
				</div>
			</>}
		</div>
	</>
}

export default React.memo(ComposeButton);