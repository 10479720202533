export interface DeleteSubUserL {
  header: string,
  confirmation: string,
  error: string,
  cta: string,
  back: string
}

export abstract class DeleteSubUserL {
  public static RU: DeleteSubUserL = {
    header: 'Подтверждение удаления',
    confirmation: 'Вы уверены, что хотите удалить рабочую область [workspace]?',
    error: 'Не удается удалить рабочую область. Попробуйте, пожалуйста, позже.',
    cta: 'Удалить',
    back: 'Назад'
  }


  public static EN: DeleteSubUserL = {
    header: 'Confirm delete',
    confirmation: 'Are you sure you want to delete the [workspace]?',
    error: 'Cannot delete workspace. Please try later.',
    cta: 'Delete',
    back: 'Back'
  }
}