import { useLocation, useRouter } from '@happysanta/router';
import React, { useEffect, useState } from 'react';

import './CheckRestorePasswordCode.css';

import { Icon24Dismiss } from '@vkontakte/icons';
import { Button, FormItem, FormLayout, Group, Input, ModalPage, ModalPageHeader, PanelHeaderBack, PanelHeaderButton, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { Api, ComponentHelper, StateManager, useLocale, useStore } from '../../../shared/_index';
import { CheckRestorePasswordCodeL } from './CheckRestorePasswordCode.L';


const CheckRestorePasswordCode = (props: { id: string, dynamicContentHeight: boolean }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let locale: CheckRestorePasswordCodeL = useLocale(CheckRestorePasswordCodeL);
	let router = useRouter();
	let location = useLocation();

	const { routeStore } = useStore();

	const email = StateManager.getStateValue(location, 'email');
	const restorePasswordOperationId = StateManager.getStateValue(location, 'operationId');

	const [restorePasswordCode, setRestorePasswordCode] = useState('');

	const [isRestorePasswordCodeValid, setIsRestorePasswordCodeValid] = useState(true);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showErrors, setShowErrors] = useState(false);

	useEffect(() => {
		ComponentHelper.focusModalInput(props.id);
	}, [props.id]);


	if (!email || !restorePasswordOperationId) {
		router.replaceModal(routeStore.Modals.SendRestorePasswordLetter);
		return <></>;
	}

	const doCheckRestoreCode = () => {
		if (!restorePasswordCode) {
			setShowErrors(true);
			return;
		}

		setIsSubmitting(true);

		Api.executeMethod('principal.checkRestorePasswordCode', {
			email: email,
			operationId: restorePasswordOperationId,
			code: restorePasswordCode
		}).then((response: any) => {
			let isCodeValid: boolean = response.IsCodeValid;

			setIsRestorePasswordCodeValid(isCodeValid);

			if (!isCodeValid) {
				setShowErrors(true);
				setIsSubmitting(false);

				return;
			}

			router.pushModal(routeStore.Modals.ChangeRestoredPassword, {
				state: StateManager.setState({
					email: email,
					operationId: restorePasswordOperationId,
					restorePasswordCode: restorePasswordCode
				})
			});
		});
	}

	return <ModalPage
		id={props.id}
		header={
			<ModalPageHeader
				before={
					<PanelHeaderBack label="" onClick={() => router.popPage()} />
				}
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => router.popPage()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{/* Восстановление пароля */}
				{locale.restorePasswordHeader}
			</ModalPageHeader>
		}
	>
		<Group>
			<FormLayout>
				<FormItem
					// Введите код, который мы отправили на [email]
					top={locale.emailSentHint.replace('[email]', email)}
					status={(!restorePasswordCode || !isRestorePasswordCodeValid) && showErrors ? "error" : "default"}
					bottom={
						!restorePasswordCode && showErrors
							// Пожалуйста, введите код
							? locale.noCodeError
							: (!isRestorePasswordCodeValid && showErrors
								// Код введен неверно или истек срок его действия
								? locale.codeExpiredError
								: null)
					}
				>
					<Input
						type="text"
						name="restorePasswordCode"
						crossOrigin={"anonymous"}
						className='to-focus'
						value={restorePasswordCode}
						onKeyPress={(e) => ComponentHelper.onEnterKeyPress(e, () => doCheckRestoreCode())}
						onChange={e => setRestorePasswordCode(e.target.value)}
					/>
				</FormItem>

				<FormItem>
					<Button
						size="l"
						stretched
						onClick={() => doCheckRestoreCode()}
						loading={isSubmitting}>
						{/* Восстановить пароль */}
						{locale.restorePassword}
					</Button>
				</FormItem>
			</FormLayout>
		</Group>
	</ModalPage>;
}

export default React.memo(CheckRestorePasswordCode);