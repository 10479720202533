export interface DeletePostL {
  back: string,
  header: string
}

export abstract class DeletePostL {
  public static RU: DeletePostL = {
    back: 'Назад',
    header: 'Удаление поста...'
  }


  public static EN: DeletePostL = {
    back: 'Back',
    header: 'Deleting post...'
  }
}