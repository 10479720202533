export interface PostEditorPageL {
  header: string,
  saving: string
}

export abstract class PostEditorPageL {
  public static RU: PostEditorPageL = {
    header: 'Редактирование поста',
    saving: 'Идет сохранение...'
  }


  public static EN: PostEditorPageL = {
    header: 'Post editing',
    saving: 'Saving in progress...'
  }
}