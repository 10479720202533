export interface UserPostsCalendarL {
    noPosts: string,
    sentPosts: string,
    queuedPosts: string
  }
  
  export abstract class UserPostsCalendarL {
    public static RU: UserPostsCalendarL = {
      noPosts: 'Нет постов в этот день',
      sentPosts: 'Опубликованные',
      queuedPosts: 'Ожидающие публикации'
    }
  
  
    public static EN: UserPostsCalendarL = {
      noPosts: 'No posts on this day',
      sentPosts: 'Published',
      queuedPosts: 'Queued'
    }
  }