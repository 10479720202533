import { useRouter } from '@happysanta/router';
import React, { useEffect, useState } from 'react';

import './Login.css';

import { Icon24Dismiss } from '@vkontakte/icons';
import { Button, FormItem, FormLayout, Input, ModalPage, ModalPageHeader, PanelHeaderButton, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { Api, ComponentHelper, Principal, StateManager, useLocale, useStore } from '../../../shared/_index';
import { LoginL } from './Login.L';

// Модальное окно входа в систему.
const Login = (props: { id: string, dynamicContentHeight: boolean }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let locale: LoginL = useLocale(LoginL);
	let router = useRouter();

	// Поле "Email".
	const [email, setEmail] = useState('');
	// Поле "Пароль".
	const [password, setPassword] = useState('');

	// Значение, показывающее, отображать ошибки валидации или нет.
	const [showErrors, setShowErrors] = useState(false);
	// Значение, показывающее, что идет авторизация.
	const [isSubmitting, setIsSubmitting] = useState(false);
	// Значение, показывающее, что авторизация совершилась с ошибкой.
	const [couldNotLogin, setCouldNotLogin] = useState(false);

	const { principalStore, routeStore } = useStore();

	useEffect(() => {
		ComponentHelper.focusModalInput(props.id);
	}, [props.id]);

	// Входим в систему.
	const doLogin = () => {
		setCouldNotLogin(false);

		// Если не введен email или пароль, выдаем ошибку.
		if (!email || !password) {
			setShowErrors(true);

			return;
		}

		// Включаем спиннер "Идет вход..."
		setIsSubmitting(true);

		// Пробуем войти в систему c заданным логином и паролем.
		Api.executeMethod('principal.login', {
			email: email,
			password: password
		}).then((response: any) => {
			let principal: Principal = response.Principal;

			if (principal) {
				principalStore.loginAs(principal);
				router.replacePage(routeStore.Pages.SwitchTo.Route, { userId: '0' });
			}
			else {
				setIsSubmitting(false);
				setShowErrors(true);
				setCouldNotLogin(true);
			}
		});
	};

	// Открыть окно "Забыли пароль?"
	const goToRestorePassword = () => {
		router.pushModal(routeStore.Modals.SendRestorePasswordLetter, {
			state: StateManager.setState({
				email: email
			})
		});
	};

	return <ModalPage
		id={props.id}
		header={
			<ModalPageHeader
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => router.popPage()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{/* Вход в систему */}
				{locale.logInHeader}
			</ModalPageHeader>
		}
	>
		<FormLayout>
			<FormItem
				top="E-mail"
				status={
					!email && showErrors
						? "error"
						: "default"
				}
				bottom={
					!email && showErrors
						// Пожалуйста, введите электронную почту
						? locale.noEmailError
						: null
				}
			>
				<Input
					type="email"
					name="email"
					value={email}
					crossOrigin={"anonymous"}
					className='to-focus'
					onChange={e => setEmail(e.target.value)}
				/>
			</FormItem>

			<FormItem
				// Пароль
				top={locale.password}
				status={
					(!password || couldNotLogin) && showErrors
						? "error"
						: "default"
				}
				bottom={
					!password && showErrors
						// Пожалуйста, введите пароль
						? locale.noPasswordError
						: (couldNotLogin && showErrors
							// Не удается войти в систему. Проверьте, что логин и пароль введены верно и попробуйте снова.
							? locale.couldNotLoginError
							: null)
				}
			>
				<Input
					type="password"
					name="password"
					autoComplete="true"
					crossOrigin={"anonymous"}
					value={password}
					onChange={e => setPassword(e.target.value)}
					onKeyPress={e => ComponentHelper.onEnterKeyPress(e, () => doLogin())} />
			</FormItem>

			<Button
				size='l'
				mode='tertiary'
				onClick={() => goToRestorePassword()}>
				{/* Забыли пароль? */}
				{locale.forgotPassword}
			</Button>

			<FormItem>
				<Button
					size="l"
					stretched
					onClick={() => doLogin()}
					loading={isSubmitting}>
					{/* Войти */}
					{locale.logIn}
				</Button>
			</FormItem>
		</FormLayout>
	</ModalPage>;
}

export default React.memo(Login);