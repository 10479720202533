export interface UserPostsL {
  discountInfo: string
}

export abstract class UserPostsL {
  public static RU: UserPostsL = {
    discountInfo: 'Спасибо за установку! При оплате до 10 июля Вам доступна скидка 15% (30% при оплате на год).'
  }


  public static EN: UserPostsL = {
    discountInfo: 'Thanks for the install! If you pay before July 10, you can get a 15% discount (30% if you pay for a year).'
  }
}