import 'core-js/features/map';
import 'core-js/features/set';
import React from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';
import App from './App';
import { RouterContext } from '@happysanta/router';
import { router } from './routers';
import { AdaptivityProvider, AppRoot, ConfigProvider } from '@vkontakte/vkui';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Init VK  Mini App
bridge.send('VKWebAppInit');


ReactDOM.render(<RouterContext.Provider value={router}>
	<ConfigProvider platform="ios" appearance="light" isWebView={true}>
		<AdaptivityProvider>
			<AppRoot mode='full'>
				<App></App>
			</AppRoot>
		</AdaptivityProvider>
	</ConfigProvider>
</RouterContext.Provider>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
