import React from 'react';
import { observer } from "mobx-react-lite"

import './SubUserIcon.css';

export interface SubUserIconProps {
  name: string,
  icon: string,
  width: number,
  height: number,

  className?: string | undefined
}

const SubUserIcon = (props: SubUserIconProps) => {
  let firstTwoLetters = !props.icon && props.name
    ? props.name.substring(0, 2).toUpperCase()
    : 'H';

  return <div className={"subUserIcon " + (props.className ? props.className : "")} style={{
    width: props.width + 'px',
    height: props.height + 'px',
    backgroundImage: props.icon
      ? 'url(' + props.icon + ')'
      : null as any
  }}>
    <div>
      {!props.icon && firstTwoLetters}
    </div>
  </div >
}

export default React.memo(SubUserIcon);