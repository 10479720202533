import React from 'react';
import { observer } from "mobx-react-lite"

import './SubUserMenuHeader.css';

import { Principal } from '../../../shared/_index';
import SubUserIcon from '../../../shared/components/SubUserIcon/SubUserIcon';


const SubUserMenuHeader = (props: { subUser: Principal.SubUser }) => {
	return <div className='subUserMenuHeader'>
		<div className='subUserMenuHeader__icon'>
			<SubUserIcon
				name={props.subUser.WorkspaceName}
				icon={props.subUser.WorkspaceImageUrl}
				width={24}
				height={24} />
		</div>

		<span>
			{props.subUser.WorkspaceName}
		</span>
	</div>;
}

export default React.memo(SubUserMenuHeader);