import { Location } from "@happysanta/router";
import { Api } from "./Api";

export class StateManager {
    public static setState(state: any): string {
        let stateKey = Api.getRandomText(10);

        (window as any)[`State_${stateKey}`] = state;

        return stateKey;
    }

    public static getState(location: Location): any {
        let stateKey: string = location.getParams().state;

        return stateKey
            ? (window as any)[`State_${stateKey}`]
            : null;
    }

    public static getStateValue(location: Location, propertyName: string): any {
        let state = this.getState(location);

        return state
            ? state[propertyName]
            : null;
    }
}