export interface SelectSubUserL {
  header: string,
  back: string,
  createWorkspace: string
}

export abstract class SelectSubUserL {
  public static RU: SelectSubUserL = {
    header: 'Выберите рабочую область',
    back: 'Назад',
    createWorkspace: 'Создать новую область'
  }


  public static EN: SelectSubUserL = {
    header: 'Select workspace',
    back: 'Back',
    createWorkspace: 'Create new workspace'
  }
}