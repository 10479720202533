export interface ComposeTimePickerL {
  back: string,
  header: string
}

export abstract class ComposeTimePickerL {
  public static RU: ComposeTimePickerL = {
    back: 'Назад',
    header: 'Выберите время поста'
  }


  public static EN: ComposeTimePickerL = {
    back: 'Back',
    header: 'Choose post time'
  }
}