import React from 'react';
import { observer } from "mobx-react-lite"

import './SocialNetworkIcon.css';

export interface SocialNetworkIconProps {
	className?: string,
	code: "twitter" | "vkontakte" | "google" | "syndication" | "youtube" | "vimeo" | "rutube" | "dailymotion" | "instagram" | "livejournal" | "facebook" | "odnoklassniki" | "linkedin" | "pinterest" | "telegram" | string
}

const SocialNetworkIcon = (props: SocialNetworkIconProps) => {
	return <div className={"socialNetworkIcon " + (props.className ? props.className : "")}>
		{props.code === "twitter" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-twitter">
			<svg viewBox="0 0 512 512">
				<path d="m481 117c-13 18-28 34-46 47 0 3 0 7 0 12 0 25-3 50-11 74-7 25-18 49-33 71-14 23-32 43-52 61-21 17-45 31-74 41-29 11-60 16-92 16-52 0-99-14-142-42 7 1 14 2 22 2 43 0 81-14 115-40-20 0-38-6-54-18-16-12-27-27-33-46 7 1 13 2 18 2 8 0 16-1 24-4-21-4-39-15-53-31-14-17-21-37-21-59l0-1c13 7 27 11 42 11-13-8-23-19-30-32-8-14-11-29-11-44 0-17 4-33 12-47 23 28 51 51 84 68 33 17 69 27 107 29-2-8-3-15-3-22 0-25 9-47 27-65 18-18 40-27 66-27 26 0 49 10 67 29 21-4 40-11 59-22-7 22-21 39-41 51 18-2 35-7 53-14z" />
			</svg>
		</div>}


		{props.code === "vkontakte" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-vkontakte">
			<svg viewBox="0 0 512 512">
				<path d="m529 149c5 12-9 40-42 84-5 6-11 14-19 24-15 19-23 31-26 37-3 8-2 16 4 23 4 4 11 12 23 24l1 0 0 0 1 1c27 25 45 46 54 63 1 1 2 2 2 4 1 1 2 4 2 7 1 4 1 7 0 10-1 3-3 5-7 8-4 2-10 3-17 3l-73 2c-5 1-10 0-16-2-6-2-11-4-15-6l-6-4c-5-4-12-10-20-18-7-8-14-15-19-22-6-7-11-12-18-17-6-4-11-6-16-4 0 0-1 1-2 1-1 0-3 2-5 4-2 2-4 5-6 9-2 3-3 8-5 14-1 7-2 14-2 23 0 2 0 5-1 7 0 3-1 5-2 6l-1 1c-3 4-8 6-15 6l-33 0c-14 1-27 0-42-4-14-4-27-9-37-15-11-7-21-13-30-19-9-7-15-12-20-17l-7-7c-2-1-5-4-8-8-3-4-10-13-20-26-11-14-21-28-31-43-9-16-21-36-35-61-13-25-25-50-37-77-1-3-2-6-2-8 0-2 1-4 1-5l1-1c3-4 9-6 17-6l78 0c2 0 4 1 7 2 2 0 3 1 4 2l2 1c3 2 5 5 6 9 4 10 9 19 14 30 5 10 8 18 11 23l5 8c5 12 11 21 16 30 5 8 10 15 14 19 4 5 8 9 12 11 3 3 7 4 9 4 3 0 6 0 8-1 0 0 1-1 1-1 1-1 2-3 4-7 2-3 3-8 4-13 1-6 1-13 2-23 1-10 1-22 0-36 0-8-1-15-2-21-1-6-3-11-4-13l-2-3c-5-7-13-11-24-13-3 0-2-2 1-7 4-3 7-6 11-8 10-5 33-7 68-7 16 0 29 1 39 4 4 1 7 2 10 4 2 1 4 3 5 6 2 3 3 6 3 10 1 3 1 7 1 13 0 5 0 10 0 15 0 5 0 12-1 20 0 9 0 17 0 24 0 2 0 6 0 12 0 6-1 10 0 14 0 3 0 7 1 11 0 5 1 8 3 11 1 3 4 6 6 7 2 1 3 1 5 2 2 0 4-1 8-4 3-2 6-5 10-9 4-5 9-11 15-20 6-8 13-18 20-30 11-20 21-42 30-65 1-1 2-3 3-5 1-1 2-2 3-3l1 0 2-1 4-1 5 0 83-1c7-1 13 0 18 1 5 1 8 3 9 5z" />
			</svg>
		</div>}

		{props.code === "google" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-google">
			<svg viewBox="0 0 512 512">
				<path d="m228 227c0 7 3 14 9 21 7 6 14 13 23 19 8 6 17 13 25 21 9 8 16 18 22 30 7 12 10 25 10 40 0 17-5 34-14 50-14 23-34 40-60 51-27 11-55 16-86 16-25 0-48-4-70-11-22-8-38-21-49-40-7-11-11-24-11-37 0-16 5-30 13-43 9-13 20-24 34-33 25-15 63-25 115-28-6-8-10-15-13-22-3-6-5-13-5-20 0-7 2-15 6-25-8 1-15 1-19 1-28 0-52-9-71-27-20-19-29-42-29-70 0-16 3-31 10-45 7-15 16-28 28-38 15-12 32-22 52-28 21-6 41-9 63-9l119 0-39 25-38 0c14 12 25 25 32 38 7 13 11 29 11 46 0 14-2 26-7 37-5 11-10 20-17 26-6 7-13 13-20 19-6 6-12 11-17 18-4 6-7 12-7 18z m-41-27c7 0 14-2 22-5 7-3 14-7 19-12 10-11 15-26 15-46 0-11-2-23-5-35-3-13-8-25-14-37-6-12-14-22-24-30-10-8-21-12-33-12-8 0-16 2-24 6-8 4-14 9-19 15-9 11-13 26-13 46 0 8 1 18 3 28 2 9 5 19 9 29 4 10 9 19 15 26 5 8 13 15 21 20 8 5 18 7 28 7z m0 249c11 0 22-1 32-3 10-3 20-6 28-11 9-5 16-12 21-21 5-9 8-20 8-31 0-5-1-10-2-14-1-5-3-9-4-12-2-4-4-8-8-12-4-5-6-8-8-10-2-2-6-6-11-10-6-5-9-7-11-8-1-2-5-4-12-9-6-5-9-7-10-7-3-1-8-1-14-1-10 0-20 1-30 2-10 1-20 4-30 7-11 4-20 8-28 13-8 6-15 13-20 22-5 8-7 18-7 30 0 13 3 25 10 35 6 10 15 18 26 24 10 5 22 10 34 12 12 3 24 4 36 4z m232-250l60 0 0 31-60 0 0 63-30 0 0-63-61 0 0-31 61 0 0-62 30 0z" />
			</svg>
		</div>}

		{props.code === "syndication" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-syndication">
			<svg viewBox="0 0 512 512">
				<path d="m165 384c0 15-6 28-16 39-11 11-24 16-39 16-16 0-28-5-39-16-11-11-16-24-16-39 0-15 5-28 16-39 11-11 23-16 39-16 15 0 28 5 39 16 10 11 16 24 16 39z m146 35c0 5-1 10-5 14-3 4-8 6-13 6l-39 0c-5 0-9-2-12-5-4-3-6-7-6-12-4-43-22-81-53-112-31-31-68-48-112-52-4-1-8-3-11-6-4-3-5-8-5-12l0-39c0-5 2-10 6-13 3-4 7-5 12-5l2 0c30 2 59 10 87 23 28 13 52 30 74 52 22 21 39 46 52 74 13 28 20 57 23 87z m146 1c1 5-1 9-5 13-3 4-8 6-13 6l-41 0c-5 0-9-2-13-5-3-3-5-8-5-12-3-41-12-80-29-117-17-37-39-69-66-96-27-27-59-49-96-66-37-17-76-27-117-29-5 0-9-2-12-6-3-3-5-8-5-12l0-41c0-5 2-10 6-13 3-4 7-5 12-5l1 0c50 2 98 13 143 34 46 20 86 48 122 84 35 35 63 76 84 121 20 46 32 94 34 144z" />
			</svg>
		</div>}

		{props.code === "youtube" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-youtube">
			<svg viewBox="0 0 512 512">
				<path d="m366 256c0-7-3-12-9-15l-146-92c-6-4-12-4-19 0-6 3-9 8-9 16l0 182c0 8 3 13 9 16 3 2 6 3 9 3 4 0 7-1 10-3l146-92c6-3 9-8 9-15z m146 0c0 18 0 33 0 43 0 10-1 23-3 39-1 16-3 30-6 42-3 14-10 26-20 35-10 10-22 15-35 17-43 4-106 7-192 7-86 0-149-3-192-7-13-2-25-7-35-17-10-9-17-21-20-35-3-12-5-26-6-42-2-16-3-29-3-39 0-10 0-25 0-43 0-18 0-33 0-43 0-10 1-23 3-39 1-16 3-30 6-42 3-14 10-26 20-35 10-10 22-15 35-17 43-4 106-7 192-7 86 0 149 3 192 7 13 2 25 7 35 17 10 9 17 21 20 35 3 12 5 26 6 42 2 16 3 29 3 39 0 10 0 25 0 43z" />
			</svg>
		</div>}

		{props.code === "vimeo" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-vimeo">
			<svg viewBox="0 0 512 512">
				<path d="m477 114c7 33 1 66-13 97c-14 31-31 59-51 87c-26 36-52 71-85 101c-19 18-41 35-66 44c-31 11-52 5-71-22c-14-19-22-40-28-63c-12-45-25-90-38-134c-4-12-9-22-15-33c-3-5-8-10-12-14c-5-4-11-3-17 0c-10 6-28 18-28 18l-21-27c26-24 82-71 82-71c11-9 33-25 48-27c21-3 38 4 50 22c11 17 15 37 18 57c7 41 11 82 23 122c3 10 8 20 13 29c7 11 15 13 25 5c4-3 7-6 10-10c20-24 37-52 48-81c3-9 3-17 3-27c0-13-10-25-25-26c-15-1-24 1-39 7c6-24 24-62 54-84c29-21 71-24 94-16c23 8 36 23 41 46z" />
			</svg>
		</div>}

		{props.code === "rutube" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-rutube">
			<svg viewBox="0 0 512 512">
				<path d="m128 96l256 160l-256 160z" />
			</svg>
		</div>}

		{props.code === "dailymotion" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-dailymotion">
			<svg viewBox="0 0 512 512">
				<path d="M221.895,0H174.316V-35.684h-.82Q150.527,4.922,102.744,4.922q-38.76,0-62.036-28.2t-23.276-76.8q0-52.09,25.737-83.467t68.6-31.377q42.451,0,61.729,34.043h.82V-310.9h47.578ZM174.932-95.977v-27.48q0-22.353-14.561-37.939t-37.119-15.586q-26.66,0-41.938,19.893T66.035-101.924q0,31.992,14.663,50.552t39.478,18.56q24.4,0,39.58-17.944T174.932-95.977Z"
					transform="translate(119 407)" />
			</svg>
		</div>}

		{props.code === "instagram" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-instagram">
			<svg viewBox="0 0 132 132">
				<path fill="#fff" d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z" />
			</svg>
		</div>}

		{props.code === "livejournal" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-livejournal">
			<svg enableBackground="new 0 0 550 550" version="1.1" viewBox="0 0 550 550">
				<g>
					<g>
						<g>
							<path d="M330.7,156c-22.5,1.9-43.3,9.5-63.2,19.9c-5.3,2.8-10.5,5.9-16.2,9c-9.1-12.4-18.3-24.5-27-36.8      c-4.7-6.7-11-10-18.9-9.7c-26.4,1-46,13.5-59.1,36.2c-7,12.1-6.4,23.8,2.8,34.9c7.3,8.7,14,17.9,20.9,27c2.8,3.6,7.5,7.3,7.5,11      c0,3.9-4.5,7.8-7.2,11.6c-15.2,21.7-28.2,44.5-34.2,70.6c-0.3,1.2,0.3,2.6,0.4,3.9c0.9-1.1,2.2-2.2,2.6-3.4      c7.1-25.9,19.2-49.3,35.4-70.6c2.1-2.7,4.4-5.1,6.9-8c4.9,6.6,9.3,12.6,13.8,18.6c-2.1,2.8-4.2,5.5-6.1,8.2      c-21.8,30.1-32.9,63.3-28.1,100.6c3.2,25.1,16.1,43.9,40.4,53.3c22.3,8.6,45.2,7.3,67.7,1c23.5-6.5,43.7-19.5,62.3-34.8      c48.3-39.9,83.5-88,91.7-152.1c2.5-19.8-0.4-39.2-11.9-56.1C391.9,161.6,363.5,153.2,330.7,156z M153.3,205.3      c-6.9-9-7.6-17.8-2.2-27.3c11.7-20.6,29.4-32.1,53.2-33.7c6.4-0.5,11.3,2.2,15.4,7.7c27.5,37,55.3,73.7,83.2,110.4      c2.7,3.6,3.2,7.4,3.7,11.4c3.1,24.5,6.1,49,9.1,73.5c0.1,1.2,0,2.4,0,4c-10-4.3-19.8-8.5-29.5-12.7c-15-6.5-30-13-44.9-19.6      c-2-0.9-3.9-2.3-5.2-4C208.5,278.4,181.1,241.7,153.3,205.3z M272.1,212c11.9-6.5,24.2-10.8,37.3-12.9      c17.7-2.8,34.6,0.2,50.6,8.3c13.8,7,20.4,18.8,22.2,33.7c3.4,28.9-7.6,53.2-25.4,74.7c-10,12.1-21.7,22.8-32.7,34      c-0.4,0.4-1,0.6-2.1,1.3c-2.6-21.5-5.1-42.3-7.6-63.1c-0.9-7.3-1.6-14.7-2.9-22c-0.4-2.5-2.3-4.9-3.9-7      c-11-14.5-22-28.9-33.1-43.4C273.8,214.5,273.1,213.4,272.1,212z M395,309.5c-22,44.4-55,78.8-98.3,103.1      c-15.2,8.6-31.8,13.9-49.3,14.1c-24.9,0.2-47.7-6.3-65.1-25.6c-9.1-10.1-12.9-22.6-13.5-35.9c-1.5-32.9,10.4-61.6,28.6-88.2      c0.3-0.5,0.6-1.1,1.2-2c1.5,1.9,2.5,3.2,3.5,4.5c10,13.4,19.9,26.8,29.9,40.1c1.1,1.5,2.7,3.1,4.3,3.8      c27.9,12.2,55.8,24.2,83.7,36.3c0.5,0.2,1.1,0.3,1,0.3c2-2.3,3.5-4.6,5.5-6.3c20.6-16.8,38.8-35.7,52-59.1      c9.8-17.3,14.6-35.9,13-55.8c-2-24.6-14.8-42.4-44.6-48.6c-21.6-4.5-41.7,1.1-61.5,8.8c-4.8,1.9-9.4,4.2-14,6.5      c-2.2,1.1-3.8,1.3-5.4-1c-3.2-4.6-6.7-9-10.5-14c13.7-7.9,27.6-13.8,42.3-17.6c24.1-6.3,48-7.3,71.5,3.1      c23.6,10.4,36.6,28.6,40,53.8C413.3,257.9,407.4,284.5,395,309.5z"
								fill="#16539A" />
							<path d="M241.3,318.9c14.9,6.6,29.9,13.1,44.9,19.6c9.7,4.2,19.4,8.4,29.5,12.7c0-1.7,0.1-2.9,0-4      c-3-24.5-6-49-9.1-73.5c-0.5-4-1-7.8-3.7-11.4c-27.9-36.7-55.7-73.5-83.2-110.4c-4.1-5.5-9-8.2-15.4-7.7      c-23.7,1.7-41.4,13.1-53.2,33.7c-5.4,9.6-4.7,18.3,2.2,27.3c27.8,36.4,55.2,73.1,82.8,109.6      C237.4,316.6,239.4,318.1,241.3,318.9z M301.4,308.6c0.1,0.6,0.1,1.2,0.2,1.8c1.9,12.5,1.6,13.2-8.7,19.7      c-0.7,0.4-1.8,0.6-2.5,0.3c-14.1-6.2-28.1-12.5-42.6-18.9c6.5-11.7,11-23.1,9.9-36.4c13.6,4.4,25.8,1.2,37.9-5.5      C297.6,283,299.5,295.8,301.4,308.6z M234.9,188.5c18.9,25.2,37.7,50.5,56.5,75.8c0.2,0.2,0.1,0.6,0.3,1.2      c-4.5,4.5-10.7,5-16.6,5.7c-11,1.3-20.1-0.9-27.2-11.1c-13.1-18.7-27.3-36.7-41.1-54.9c-1-1.3-1.9-2.7-3.2-4.4      c8.5-7.3,18.2-11,28.4-13.6C232.8,186.9,234.3,187.7,234.9,188.5z M208.9,155.9c-22.6,5.9-39.4,18.4-49.9,39.4      C154.6,171.7,191.2,148.4,208.9,155.9z M166.4,205.9c-0.5-0.8-0.4-2.4,0-3.3c9.6-21,26.6-32.8,48.5-38.2c1-0.2,2.7,0.5,3.4,1.4      c3.3,4.2,6.3,8.5,9.8,13.3c-23.2,6-40.8,18.1-51.8,40.1C172.8,214.4,169.5,210.2,166.4,205.9z M184.6,223.1      c4.1-7.3,8.4-14.4,16.3-18.9c15.8,21.3,31.3,42,46.5,63c7.5,10.3,4.8,30.4-4.9,39.8c-1-1.2-1.9-2.3-2.8-3.4      c-18.1-24.3-36.2-48.6-54.5-72.8C183.2,228.1,183,226,184.6,223.1z"
								fill="#1B3763" />
							<path d="M206.8,205.2c13.8,18.3,28,36.2,41.1,54.9c7.1,10.1,16.2,12.3,27.2,11.1c5.9-0.7,12-1.1,16.6-5.7      c-0.1-0.6-0.1-1-0.3-1.2c-18.8-25.3-37.6-50.6-56.5-75.8c-0.6-0.8-2.1-1.6-2.9-1.4c-10.2,2.6-19.8,6.3-28.4,13.6      C204.9,202.5,205.8,203.9,206.8,205.2z"
								fill="#9FC9EB" />
							<path d="M242.6,307c9.7-9.5,12.4-29.5,4.9-39.8c-15.2-21-30.8-41.7-46.5-63c-7.9,4.6-12.2,11.6-16.3,18.9      c-1.6,2.9-1.4,5,0.6,7.7c18.2,24.2,36.3,48.5,54.5,72.8C240.6,304.8,241.6,305.9,242.6,307z"
								fill="#9FC9EB" />
							<path d="M257.7,275.2c1.1,13.3-3.5,24.7-9.9,36.4c14.4,6.4,28.5,12.7,42.6,18.9c0.7,0.3,1.9,0.1,2.5-0.3      c10.3-6.5,10.6-7.2,8.7-19.7c-0.1-0.6-0.1-1.2-0.2-1.8c-1.9-12.8-3.8-25.6-5.8-39C283.5,276.4,271.3,279.5,257.7,275.2z"
								fill="#FDCB9A" />
							<path d="M228.1,179c-3.5-4.9-6.6-9.2-9.8-13.3c-0.7-0.8-2.4-1.6-3.4-1.4c-21.9,5.4-38.9,17.2-48.5,38.2      c-0.4,0.9-0.5,2.6,0,3.3c3.1,4.4,6.4,8.6,9.9,13.3C187.3,197.2,204.9,185,228.1,179z"
								fill="#CECCCC" />
							<path d="M208.9,155.9c-17.7-7.5-54.3,15.8-49.9,39.4C169.5,174.3,186.3,161.8,208.9,155.9z" fill="#F7A1A2" />
						</g>
					</g>
				</g>
			</svg>
		</div>}

		{props.code === "facebook" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-facebook">
			<svg viewBox="0 0 512 512">
				<path d="m384 3l0 76-45 0c-17 0-28 3-33 10-6 7-9 17-9 31l0 54 84 0-11 85-73 0 0 216-87 0 0-216-73 0 0-85 73 0 0-62c0-36 10-63 29-83 20-19 47-29 80-29 28 0 49 1 65 3z" />
			</svg>
		</div>}

		{props.code === "odnoklassniki" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-odnoklassniki">
			<svg viewBox="0 0 512 512">
				<path d="m256 259c-36 0-66-13-92-38-25-25-38-56-38-91 0-36 13-67 38-92 26-25 56-38 92-38 36 0 66 13 92 38 25 25 38 56 38 92 0 35-13 66-38 91-26 25-56 38-92 38z m0-193c-18 0-33 6-45 18-12 13-19 28-19 46 0 17 7 32 19 45 12 12 27 18 45 18 18 0 33-6 45-18 12-13 19-28 19-45 0-18-7-33-19-46-12-12-27-18-45-18z m149 209c3 5 4 10 5 14 0 4 0 8-2 12-1 3-3 7-7 11-4 3-8 7-12 10-5 3-10 7-18 12-22 14-52 23-90 27l21 20 76 77c6 6 9 13 9 21 0 8-3 15-9 21l-3 3c-6 6-13 9-21 9-8 0-16-3-21-9-13-13-39-38-77-76l-76 76c-6 6-13 9-21 9-8 0-15-3-21-9l-3-3c-6-6-9-13-9-21 0-8 3-15 9-21l96-97c-38-4-68-13-90-27-8-5-13-9-18-12-4-3-8-7-12-10-4-4-6-8-7-11-2-4-2-8-2-12 1-4 2-9 5-14 1-4 4-7 8-10 3-3 7-5 12-6 4-2 9-2 16 0 6 2 12 5 18 10 1 1 3 2 4 3 2 2 6 4 13 7 6 4 13 7 19 9 7 2 16 5 27 7 10 2 21 3 32 3 17 0 34-2 50-7 16-5 27-10 34-15l11-7c6-5 12-8 18-10 7-2 12-2 16 0 5 1 9 3 12 6 4 3 7 6 8 10z" />
			</svg>
		</div>}

		{props.code === "linkedin" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-linkedin">
			<svg viewBox="0 0 512 512">
				<path d="m136 179l0 283-94 0 0-283z m6-88c0 14-4 26-14 35-10 9-23 14-39 14l0 0c-16 0-29-5-38-14-10-9-14-21-14-35 0-14 4-26 14-35 10-9 23-14 39-14 16 0 28 5 38 14 9 9 14 21 14 35z m333 208l0 163-94 0 0-152c0-20-3-35-11-47-8-11-20-17-36-17-12 0-22 4-30 10-9 7-15 15-19 25-2 5-3 13-3 23l0 158-94 0c1-76 1-138 1-185 0-47 0-76 0-85l-1-13 94 0 0 41 0 0c4-6 7-12 11-16 4-5 10-10 17-15 6-5 15-10 24-13 10-3 21-4 33-4 33 0 59 11 79 32 20 22 29 54 29 95z" />
			</svg>
		</div>}

		{props.code === "viber" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-viber">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 631.99 666.43">
				<path className="socialNetworkIcon-icon-viber-cls-1"
					d="M560.65,65C544.09,49.72,477.17,1.14,328.11.48c0,0-175.78-10.6-261.47,68C18.94,116.19,2.16,186,.39,272.55S-3.67,521.3,152.68,565.28l.15,0-.1,67.11s-1,27.17,16.89,32.71c21.64,6.72,34.34-13.93,55-36.19,11.34-12.22,27-30.17,38.8-43.89,106.93,9,189.17-11.57,198.51-14.61,21.59-7,143.76-22.65,163.63-184.84C646.07,218.4,615.64,112.66,560.65,65Zm18.12,308.58C562,509,462.91,517.51,444.64,523.37c-7.77,2.5-80,20.47-170.83,14.54,0,0-67.68,81.65-88.82,102.88-3.3,3.32-7.18,4.66-9.77,4-3.64-.89-4.64-5.2-4.6-11.5.06-9,.58-111.52.58-111.52s-.08,0,0,0C38.94,485.05,46.65,347,48.15,274.71S63.23,143.2,103.57,103.37c72.48-65.65,221.79-55.84,221.79-55.84,126.09.55,186.51,38.52,200.52,51.24C572.4,138.6,596.1,233.91,578.77,373.54Z" />
				<path className="socialNetworkIcon-icon-viber-cls-2" d="M389.47,268.77q-2.46-49.59-50.38-52.09" />
				<path className="socialNetworkIcon-icon-viber-cls-2" d="M432.72,283.27q1-46.2-27.37-77.2c-19-20.74-45.3-32.16-79.05-34.63" />
				<path className="socialNetworkIcon-icon-viber-cls-2" d="M477,300.59q-.61-80.17-47.91-126.28t-117.65-46.6" />
				<path className="socialNetworkIcon-icon-viber-cls-1"
					d="M340.76,381.68s11.85,1,18.23-6.86l12.44-15.65c6-7.76,20.48-12.71,34.66-4.81A366.67,366.67,0,0,1,437,374.1c9.41,6.92,28.68,23,28.74,23,9.18,7.75,11.3,19.13,5.05,31.13,0,.07-.05.19-.05.25a129.81,129.81,0,0,1-25.89,31.88c-.12.06-.12.12-.23.18q-13.38,11.18-26.29,12.71a17.39,17.39,0,0,1-3.84.24,35,35,0,0,1-11.18-1.72l-.28-.41c-13.26-3.74-35.4-13.1-72.27-33.44a430.39,430.39,0,0,1-60.72-40.11,318.31,318.31,0,0,1-27.31-24.22l-.92-.92-.92-.92h0l-.92-.93c-.31-.3-.61-.61-.92-.92a318.31,318.31,0,0,1-24.22-27.31,430.83,430.83,0,0,1-40.11-60.71c-20.34-36.88-29.7-59-33.44-72.28l-.41-.28a35,35,0,0,1-1.71-11.18,16.87,16.87,0,0,1,.23-3.84Q141,181.42,152.12,168c.06-.11.12-.11.18-.23a129.53,129.53,0,0,1,31.88-25.88c.06,0,.18-.06.25-.06,12-6.25,23.38-4.13,31.12,5,.06.06,16.11,19.33,23,28.74a366.67,366.67,0,0,1,19.74,30.94c7.9,14.17,2.95,28.68-4.81,34.66l-15.65,12.44c-7.9,6.38-6.86,18.23-6.86,18.23S254.15,359.57,340.76,381.68Z" />
			</svg>
		</div>}

		{props.code === "pinterest" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-pinterest">
			<svg viewBox="0 0 512 512">
				<path d="m475 256c0 40-9 77-29 110-20 34-46 60-80 80-33 20-70 29-110 29-21 0-42-3-62-9 11-17 18-33 22-47 2-6 7-26 15-60 4 8 11 14 21 19 10 6 21 9 33 9 23 0 43-7 62-20 18-13 32-31 42-54 9-23 14-48 14-77 0-22-5-42-17-61-11-19-27-35-49-47-21-12-46-18-73-18-20 0-38 3-56 9-17 5-32 12-44 22-12 9-22 19-31 31-9 12-15 24-19 37-4 13-6 26-6 38 0 20 4 38 11 53 8 15 19 25 34 31 5 3 9 1 10-5 1-2 2-5 3-9 1-5 2-8 2-9 1-4 0-8-3-12-10-12-15-26-15-43 0-29 10-54 30-74 20-21 46-31 78-31 29 0 51 8 68 23 16 16 24 36 24 61 0 32-7 60-20 83-13 22-30 34-50 34-12 0-21-5-28-13-7-8-9-18-7-30 2-6 4-15 8-26 4-12 6-21 9-30 2-8 3-16 3-21 0-10-3-18-8-24-5-6-12-10-22-10-12 0-22 6-30 17-8 11-12 24-12 40 0 14 2 26 7 35l-28 120c-4 13-5 30-4 50-39-17-71-44-95-80-24-36-36-77-36-121 0-40 9-77 29-110 20-34 46-60 80-80 33-20 70-29 110-29 40 0 77 9 110 29 34 20 60 46 80 80 20 33 29 70 29 110z" />
			</svg>
		</div>}

		{props.code === "telegram" && <div className="socialNetworkIcon-icon socialNetworkIcon-icon-telegram">

		</div>}
	</div>;
}

export default React.memo(SocialNetworkIcon);