import React from 'react';
import { observer } from "mobx-react-lite"

import './PublishResultsList.css';

import { useLocale } from '../../../../../shared/_index';
import { PublishResultsListL } from './PublishResultsList.L';
import { PublishResultsResponse } from '../../models/PublishResultsResponse';
import PublishResultsListItem from '../PublishResultsListItem/PublishResultsListItem';
import LightButton from '../../../../../shared/components/LightButton/LightButton';


export interface PublishResultsListProps {
	publishResults: PublishResultsResponse.PublishResult[],

	onPublishAgain: () => void
}

const PublishResultsList = (props: PublishResultsListProps) => {
	let locale: PublishResultsListL = useLocale(PublishResultsListL);

	return <ul className='publishResultsList'>
		{props.publishResults.map((publishResult, index) =>
			<li key={index}>
				{index === 1 && <div className="publishResultsList__publishedEarlier">
					{/* Опубликованное ранее */}
					{locale.publishedEarlier}
				</div>}

				<PublishResultsListItem
					isFirstPublishResult={index === 0}
					publishResult={publishResult}
				/>

				{index === 0 &&
					<div className='publishResultsList__actions'>
						{publishResult.PublishState === "Sent" &&
							<>
								<LightButton
									mode='default'
									onClick={() => props.onPublishAgain()}
								>
									<svg viewBox="0 0 512 512">
										<path d="m455 245c0 14-1 27-4 40-3 13-7 25-12 36-4 12-11 23-18 34-7 10-15 20-24 28l58 58-159 0 0-159 59 59c12-12 22-27 30-43 7-17 10-35 10-54 0-13-1-25-5-37-3-12-8-23-14-34-6-10-14-20-22-28-9-9-18-17-29-23-10-6-21-11-33-14-12-3-24-5-36-5-20 0-38 3-55 10-17 7-32 17-44 29-13 13-23 27-30 44-7 16-11 34-11 53 0 13 2 24 5 36 3 11 7 22 13 32 6 10 12 19 20 28 8 8 17 16 26 22l-42 42c-12-9-23-19-33-31-10-12-19-25-26-39-7-13-13-28-16-43-4-16-6-31-6-48 0-18 2-35 7-52 4-17 11-32 20-47 8-14 18-27 30-39 12-12 26-22 40-30 15-9 31-15 48-20 17-5 35-7 53-7 18 0 36 2 53 7 16 5 32 12 47 21 15 9 28 19 41 32 12 12 22 26 31 41 9 15 16 31 21 48 5 17 7 35 7 53z" />
									</svg>

									<span>
										{/* Опубликовать еще раз */}
										{locale.publishOnceAgain}
									</span>
								</LightButton>

								<LightButton
									mode='default'
									href={publishResult.PostUrl}
									target='_blank'
								>
									<span>
										{/* Открыть запись */}
										{locale.openPost}
									</span>
								</LightButton>
							</>
						}

						{publishResult.PublishState === "Failed" &&
							<>
								<LightButton
									mode='default'
									onClick={() => props.onPublishAgain()}
								>
									<svg viewBox="0 0 512 512">
										<path d="m455 245c0 14-1 27-4 40-3 13-7 25-12 36-4 12-11 23-18 34-7 10-15 20-24 28l58 58-159 0 0-159 59 59c12-12 22-27 30-43 7-17 10-35 10-54 0-13-1-25-5-37-3-12-8-23-14-34-6-10-14-20-22-28-9-9-18-17-29-23-10-6-21-11-33-14-12-3-24-5-36-5-20 0-38 3-55 10-17 7-32 17-44 29-13 13-23 27-30 44-7 16-11 34-11 53 0 13 2 24 5 36 3 11 7 22 13 32 6 10 12 19 20 28 8 8 17 16 26 22l-42 42c-12-9-23-19-33-31-10-12-19-25-26-39-7-13-13-28-16-43-4-16-6-31-6-48 0-18 2-35 7-52 4-17 11-32 20-47 8-14 18-27 30-39 12-12 26-22 40-30 15-9 31-15 48-20 17-5 35-7 53-7 18 0 36 2 53 7 16 5 32 12 47 21 15 9 28 19 41 32 12 12 22 26 31 41 9 15 16 31 21 48 5 17 7 35 7 53z" />
									</svg>

									<span>
										{/* Попробовать еще раз */}
										{locale.tryAgain}
									</span>
								</LightButton>
							</>
						}

						{publishResult.PublishState !== "Sent" && publishResult.PublishState !== "Failed" &&
							<>
								<LightButton
									mode='success'
									onClick={() => props.onPublishAgain()}
								>
									<svg viewBox="0 0 512 512">
										<path d="m256 32l-192 192l96 0l0 64l192 0l0-64l96 0z m-96 288l192 0l0 64l-192 0z m0 96l192 0l0 32l-192 0z m0 64l192 0l0 32l-192 0z" />
									</svg>

									<span>
										{/* Опубликовать сейчас */}
										{locale.publishNow}
									</span>
								</LightButton>
							</>
						}
					</div>
				}
			</li>)
		}
	</ul>;
}

export default React.memo(PublishResultsList);