import React, { useEffect, useState } from 'react';
import { useLocation, useRouter } from '@happysanta/router';

import './UserPosts.css';

import { Api, ProjectDataShort, useLocale, UserPage, useStore } from '../../../shared/_index';
import { AppearanceProvider, Panel, Snackbar, Spinner } from '@vkontakte/vkui';
import UserPostsHeader from './children/components/UserPostsHeader/UserPostsHeader';
import UserPostsCalendar from './children/components/UserPostsCalendar/UserPostsCalendar';
import UserPostDrafts from './children/components/UserPostDrafts/UserPostDrafts';
import UserPostTrash from './children/components/UserPostTrash/UserPostTrash';
import PostEditorPage from '../../components/PostEditorPage/PostEditorPage';
import { UserPostsL } from './UserPosts.L';
import UnsupportedAccountAlert from '../../../shared/components/UnsupportedAccountAlert/UnsupportedAccountAlert';

const UserPosts = (props: { id: string }) => {
	let location = useLocation();
	let router = useRouter();

	const { principalStore, subUserStore, routeStore } = useStore();

	let locale: UserPostsL = useLocale(UserPostsL);

	const [isLoading, setIsLoading] = useState(true);

	const [project, setProject] = useState((null as any) as ProjectDataShort);
	const [userPage, setUserPage] = useState((null as any) as UserPage);

	const [isMultiUser, setIsMultiUser] = useState(false);

	const [activeTab] = useState((): "calendar" | "draft" | "deleted" => {
		if (location.getPanelId() === routeStore.Pages.Posts.PanelId) {
			return "calendar";
		}
		else if (location.getPanelId() === routeStore.Pages.PostDrafts.PanelId) {
			return "draft";
		}
		else {
			return "deleted";
		}
	});

	const [showEditor, setShowEditor] = useState(false);

	const [showDiscountInfo, setShowDiscountInfo] = useState(false);

	let projectId = location.getParams().projectId
		? +location.getParams().projectId
		: 0;

	let userPageId = location.getParams().pageId
		? +location.getParams().pageId
		: 0;

	let day = location.getParams().day
		? +location.getParams().day
		: 0;

	const loadProject = (): Promise<void> => {
		return new Promise<void>((resolve) => {
			principalStore.get().then(() => {
				let principal = principalStore.principal;

				if (!projectId) {
					setProject(null as any);
					resolve();
				}
				else {
					setIsLoading(true);

					Api.executeMethod<any>('projects.get', {
						userId: subUserStore.subUser.UserId,
						projectId: projectId
					}).then(response => {
						setProject(response.Project);
						setUserPage(null as any);

						setIsLoading(false);
					});
				}
			});
		});
	};

	const loadPage = (): Promise<void> => {
		return new Promise<void>((resolve) => {
			principalStore.get().then(() => {
				let principal = principalStore.principal;

				if (!userPageId) {
					setUserPage(null as any);
					resolve();
				}
				else {
					setIsLoading(true);

					Api.executeMethod<any>('pages.get', {
						userId: subUserStore.subUser.UserId,
						userPageId: userPageId
					}).then(response => {
						setUserPage(response.Page);
						setProject(null as any);

						setIsLoading(false);
					});
				}
			});
		});
	};

	useEffect(() => {
		principalStore.get().then(() => {
			let principal = principalStore.principal;

			setIsMultiUser(principal.IsMultiUser);

			if (projectId !== (project ? project.Id : 0)) {
				loadProject();
			}

			if (userPageId !== (userPage ? userPage.Id : 0)) {
				loadPage();
			}

			if (!(projectId !== (project ? project.Id : 0))
				&& !(userPageId !== (userPage ? userPage.Id : 0))) {
				setIsLoading(false);
			}

			try {
				let isAppInstalled = matchMedia('(display-mode: standalone)').matches;

				if (isAppInstalled) {
					Api.executeMethod<any>(`principal.checkMobileDiscount`, {
						userId: subUserStore.subUser.UserId
					}).then(response => {
						if (!response.HasAlready) {
							setShowDiscountInfo(true);
						}
					});
				}
			}
			catch (e) { }
		});

		principalStore.selectedUserTab = 'posts';
		principalStore.selectedUserTabChanged.next();

		// eslint-disable-next-line
	}, [props.id, projectId, userPageId]);

	useEffect(() => {
		let showEditorToSet = +location.getParams().showEditor === 1;

		if (showEditor !== showEditorToSet) {
			principalStore.postEditorShownChanged.next();
			setShowEditor(showEditorToSet);
		}
	});

	const selectDay = (day: number) => {
		let parameters: any = {
			userId: subUserStore.subUser.UserId.toString(),
			day: day.toString()
		};

		if (location.getParams().projectId) {
			parameters.projectId = location.getParams().projectId;
		}

		if (location.getParams().pageId) {
			parameters.pageId = location.getParams().pageId;
		}

		router.pushPage(routeStore.Pages.Posts.Route, parameters);
	}

	return <Panel
		id={props.id}>
		{isLoading &&
			<Spinner
				size='medium'
				className='userPosts__spinner'
			/>
		}
		
		{(!isLoading && isMultiUser) && <>
			<UserPostsHeader
				activeTab={activeTab}
				project={project}
				page={userPage}
				selectedDay={day}
				onDaySelected={(dayToSelect) => selectDay(dayToSelect)}
			/>

			{activeTab === "calendar" && <UserPostsCalendar
				day={day}
				project={project}
				page={userPage}
			/>}

			{activeTab === "draft" && <UserPostDrafts
				project={project}
				page={userPage}
			/>}

			{activeTab === "deleted" && <UserPostTrash
				project={project}
				page={userPage}
			/>}

			{showEditor && <PostEditorPage id="postEditorPage" />}
		</>}

		{(!isLoading && !isMultiUser) && <>
			<UnsupportedAccountAlert />
		</>}
	</Panel>;
}

export default UserPosts;