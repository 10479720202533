export interface LoginL {
  noEmailError: string,
  password: string,
  noPasswordError: string,
  forgotPassword: string,
  logIn: string,
  logInHeader: string,
  couldNotLoginError: string,
  back: string
}

export abstract class LoginL {
  public static RU: LoginL = {
    noEmailError: 'Пожалуйста, введите электронную почту',
    password: 'Пароль',
    noPasswordError: 'Пожалуйста, введите пароль',
    forgotPassword: 'Забыли пароль?',
    logIn: 'Войти',
    logInHeader: 'Вход в систему',
    couldNotLoginError: 'Не удается войти в систему. Проверьте, что логин и пароль введены верно и попробуйте снова.',
    back: 'Назад'
  }


  public static EN: LoginL = {
    noEmailError: 'Please enter your email',
    password: 'Password',
    noPasswordError: 'Please enter a password',
    forgotPassword: 'Forgot your password?',
    logIn: 'Sign in',
    logInHeader: 'Sign in to your account',
    couldNotLoginError: 'Unable to sign in. Check that the username and password are correct and try again.',
    back: 'Back'
  }
}