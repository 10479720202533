import { useLocation, useRouter } from '@happysanta/router';
import React, { useEffect, useState } from 'react';

import './ChangeRestoredPassword.css';

import { Icon24Dismiss } from '@vkontakte/icons';
import { Button, FormItem, FormLayout, Group, Input, ModalPage, ModalPageHeader, PanelHeaderBack, PanelHeaderButton, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { Api, ComponentHelper, Principal, StateManager, useLocale, useStore } from '../../../shared/_index';
import { ChangeRestoredPasswordL } from './ChangeRestoredPassword.L';

const ChangeRestoredPassword = (props: { id: string, dynamicContentHeight: boolean }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let locale: ChangeRestoredPasswordL = useLocale(ChangeRestoredPasswordL);
	let router = useRouter();
	let location = useLocation();

	const email = StateManager.getStateValue(location, 'email');
	const restorePasswordOperationId = StateManager.getStateValue(location, 'operationId');
	const restorePasswordCode = StateManager.getStateValue(location, 'restorePasswordCode');

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showErrors, setShowErrors] = useState(false);

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const { principalStore, routeStore } = useStore();

	useEffect(() => {
		ComponentHelper.focusModalInput(props.id);
	}, [props.id]);

	if (!email || !restorePasswordOperationId || !restorePasswordCode) {
		router.replaceModal(routeStore.Modals.SendRestorePasswordLetter);
		return <></>;
	}

	const doChangePassword = () => {
		if (!password || !confirmPassword || password !== confirmPassword) {
			setShowErrors(true);
			return;
		}

		setIsSubmitting(true);

		Api.executeMethod('principal.editRestoredPassword', {
			email: email,
			password: password,
			operationId: restorePasswordOperationId,
			code: restorePasswordCode
		}).then((response: any) => {
			let principal: Principal = response.Principal;

			if (principal) {
				principalStore.loginAs(principal);
				router.replacePage(routeStore.Pages.SwitchTo.Route, { userId: '0' });
			}
		});
	}

	return <ModalPage
		id={props.id}
		dynamicContentHeight={true}
		header={
			<ModalPageHeader
				before={
					<PanelHeaderBack label="" onClick={() => router.popPage()} />
				}
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => router.popPage()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{/* Смена пароля */}
				{locale.changePasswordHeader}
			</ModalPageHeader>
		}
	>
		<Group>
			<FormLayout>
				<FormItem
					// Введите новый пароль
					top={locale.enterNewPassword}
					status={!password && showErrors ? "error" : "default"}
					bottom={
						!password && showErrors
							// Пожалуйста, введите пароль
							? locale.noPasswordError
							: null
					}
				>
					<Input
						type="password"
						name="password"
						autoComplete="true"
						crossOrigin={"anonymous"}
						className='to-focus'
						value={password}
						onChange={e => setPassword(e.target.value)} />
				</FormItem>

				<FormItem
					// Повторите пароль
					top={locale.confirmPassword}
					status={(!confirmPassword || password !== confirmPassword) && showErrors ? "error" : "default"}
					bottom={
						!password && showErrors
							// Пожалуйста, повторите пароль
							? locale.noConfirmPasswordError
							: (password !== confirmPassword && showErrors
								// Пароли должны совпадать
								? locale.passwordMustMatchError
								: null)
					}
				>
					<Input
						type="password"
						name="confirmPassword"
						autoComplete="true"
						crossOrigin={"anonymous"}
						value={confirmPassword}
						onKeyPress={e => ComponentHelper.onEnterKeyPress(e, () => doChangePassword())}
						onChange={e => setConfirmPassword(e.target.value)} />
				</FormItem>
				<FormItem>
					<Button size="l" stretched onClick={() => doChangePassword()} loading={isSubmitting}>
						{/* Сменить пароль */}
						{locale.changePassword}
					</Button>
				</FormItem>
			</FormLayout>
		</Group>
	</ModalPage>;
}

export default React.memo(ChangeRestoredPassword);