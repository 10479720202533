import { SocialPage } from './SocialPage';
import { SocialAccountToUse } from './SocialAccountToUse';

/** Канал, из которого проект копирует записи в другие соц сети. */
export interface ProjectFeed {
    /** Идентификатор канала, из которого проект копирует записи в другие соц сети. */
    Id?: number,

    /** Имя канала, из которого проект копирует записи в другие соц сети. */
    Name?: string,

    /** Описание канала, из которого проект копирует записи в другие соц сети. */
    Description?: string,

    /** Дата подключения к сервису канала, из которого проект копирует записи в другие соц сети. */
    CreatedAt?: number,

    /** Сколько максимум записей копировать из этого канала. */
    MaxItemsCount?: number,

    /** Уникальный идентификатор такого канала в сервисе (без привязки к проекту). */
    UserFeedId?: number,

    /** Как часто (в минутах) канал нужно будет автоматически сканировать на наличие новых записей. */
    UpdateInterval?: number,

    /** Сканировать ли канал автоматически на наличие новых записей каждые несколько минут. */
    IsAutoUpdateEnabled?: boolean,

    DefaultMaxItemsCount?: number,

    DefaultUpdateInterval?: number,

    ItemsToPublishImmediately?: number,

    DoNotDeleteOldItems?: boolean,

    /** Ссылка на страницу канала, из которого проект копирует записи в другие соц сети. */
    SiteUrl?: string,

    /** Тип канала, из которого проект копирует записи в другие соц сети. */
    Type: "Unknown" | "SyndicationFeed" | "VkontakteFeed" | "FacebookFeed" | "TwitterFeed" | "InstagramFeed" | "YoutubeChannelFeed" | "RutubeChannelFeed" | "VimeoChannelFeed" | "DailyMotionChannelFeed",

    /** Как называется тип канала, из которого проект копирует записи в другие соц сети. */
    TypeName?: string,

    /** Информация о странице в соц сетях, откуда нужно копировать записи (если этот канал копирует записи со страницы в соц. сетях.) */
    SourcePage?: SocialPage,

    /** Настройки импорта из RSS/Atom (если этот канал копирует записи из RSS/Atom ленты). */
    SyndicationFeed?: SyndicationFeed,

    /** Настройки импорта из Youtube (если этот канал копирует записи из Youtube пользователя/канала/плейлиста). */
    YoutubeChannel?: YoutubeChannelFeed,

    /** Настройки импорта из Rutube (если этот канал копирует записи из Rutube канала). */
    RutubeChannel?: RutubeChannelFeed,

    /** Настройки импорта из Vimeo (если этот канал копирует записи из Vimeo канала). */
    VimeoChannel?: VimeoChannelFeed,

    /** Настройки импорта из DailyMotion (если этот канал копирует записи из DailyMotion канала). */
    DailyMotionChannel?: DailyMotionChannelFeed,

    /** Настройки импорта из Instagram (если этот канал копирует записи из Instagram ленты). */
    InstagramFeed?: InstagramFeed,

    /** Настройки импорта из Вконтакте  (если этот канал копирует записи со стены Вконтакте). */
    VkontakteFeed?: VkontakteFeed,

    /** Настройки импорта из Facebook  (если этот канал копирует записи со стены Facebook). */
    FacebookFeed?: FacebookFeed,

    /** Настройки импорта из Telegram  (если этот канал копирует записи со стены Telegram). */
    TelegramFeed?: TelegramFeed,

    /** Настройки импорта из Twitter  (если этот канал копирует записи со стены Twitter). */
    TwitterFeed?: TwitterFeed,

    AccountsToUse?: SocialAccountToUse[],

    /** Описание канала, из которого проект копирует записи в другие соц сети. */
    DescriptionLocalized?: string,
}

/** Тип источника, из которого проект будет копировать записи. */
export enum ProjectFeedType {
    /** Тип неизвестен */
    Unknown = 0,

    /** RSS/Atom канал, откуда сервис будет копировать записи. */
    SyndicationFeed = 1,

    /** Стена Вконтакте, откуда сервис будет копировать записи. */
    VkontakteFeed = 2,

    /** Страница Facebook, откуда сервис будет копировать записи. */
    FacebookFeed = 3,

    /** Страница Twitter, откуда сервис будет копировать записи. */
    TwitterFeed = 4,

    /** Страница Instagram, откуда сервис будет копировать записи. */
    InstagramFeed = 5,

    /** Пользователь/канал/плейлист Youtube, откуда сервис будет копировать записи.  */
    YoutubeChannelFeed = 6,

    /** Канал Rutube, откуда сервис будет копировать записи. */
    RutubeChannelFeed = 7,

    /** Канал Vimeo, откуда сервис будет копировать записи. */
    VimeoChannelFeed = 8,

    /** Канал Dailymotion, откуда сервис будет копировать записи. */
    DailyMotionChannelFeed = 9
}

/** Настройки импорта из RSS/Atom (если этот канал копирует записи из RSS/Atom ленты). */
export interface SyndicationFeed {
    /** Ссылка на RSS/Atom ленту. */
    FeedUrl?: string,

    /** [enum=SyndicationFeedHashMethod] Как определять, какая запись опубликована, а какая нет. */
    HashMethod?: string,

    /** Искать ли видеозаписи в канале. */
    ScanForVideos?: boolean,

    /** [enum=SyndicationFeed.LoadImagesFromOpenGraph] Искать ли изображения в Open Graph. */
    LoadImagesFromOpenGraph?: string,

    /** [enum=SyndicationFeed.TextSource] */
    TextSource?: string,

    /** [enum=SyndicationFeed.ScanWebSiteForVideos] */
    ScanWebSiteForVideos?: string,

    UseNewHtmlToPlainTextParser?: boolean,
    DoNotHighlightLinksOnNewParser?: boolean,

    UseNovaPressFeedParser?: boolean
}

export namespace SyndicationFeed {
    export abstract class LoadImagesFromOpenGraph {
        public static Never = 'Never';
        public static IfNoImagesOrVideos = 'IfNoImagesOrVideos';
        public static ReplaceAlways = 'ReplaceAlways';
    }

    export abstract class TextSource {
        public static FeedDescription = 'FeedDescription';
        public static FeedContentEncoded = 'FeedContentEncoded';
        public static FeedYandexFullText = 'FeedYandexFullText';
        public static FeedTurboContent = 'FeedTurboContent';
        public static SiteDescription = 'SiteDescription';
        public static SiteOgDescription = 'SiteOgDescription';
    }

    export abstract class ScanWebSiteForVideos {
        public static Never = 'Never';
        public static IfNoImagesOrVideos = 'IfNoImagesOrVideos';
        public static Always = 'Always';
    }
}

/** Как определять, какая запись опубликована, а какая нет. */
export enum SyndicationFeedHashMethod {
    /** По заголовку и ссылке */
    TitleLink = 1,

    /** По заголовку, ссылке и дате. */
    TitleLinkDate = 2,

    /** По ссылке. */
    Link = 3,

    /** По полю GUID. */
    Guid = 4,

    /** По заголовку */
    Title = 5
}

/** Настройки импорта из Youtube (если этот канал копирует записи из Youtube пользователя/канала/плейлиста). */
export interface YoutubeChannelFeed {
    /** Код канала Youtube (если импорт идет из канала Youtube). */
    ChannelCode: string,

    /** [enum=YoutubeChannelFeedType] Тип источника, откуда идет импорт (канал/пользователь/плейлист Youtube). */
    ChannelType: string,

    /** Идентификатор плейлиста Youtube (если импорт идет из плейлиста Youtube). */
    PlaylistId: string,

    /** Код пользователя Youtube (если импорт идет из видеозаписей пользователя Youtube). */
    UserCode: string,

    IncludeDescription: boolean
}

/** Тип источника, откуда идет импорт (канал/пользователь/плейлист Youtube). */
export enum YoutubeChannelFeedType {
    /** Последние видеозаписи, добавленные пользователем. */
    UserVideos = 0,

    /** Последние видеозаписи канала. */
    SpecificChannelVideos = 1,

    /** Видеозаписи из плейлиста Youtube. */
    SpecificPlaylistVideos = 2
}

/** Настройки импорта из Rutube (если этот канал копирует записи из Rutube канала). */
export interface RutubeChannelFeed {
    /** Код канала Rutube */
    ChannelCode: string,

    /** [enum=RutubeChannelFeedType] Тип канала Rutube */
    ChannelType: string,

    /** Номер сезона (эсли это канал сериала). */
    SeasonNum?: number,

    /** Тип видео (серия/промо ит.д. эсли это канал сериала). */
    VideoType?: number
}

/** Тип канала Rutube */
export enum RutubeChannelFeedType {
    /** Канал пользователя */
    PersonalChannel = 0,

    /** Канал сериала */
    TvProgramChannel = 1
}

/** Настройки импорта из Vimeo (если этот канал копирует записи из Vimeo канала). */
export interface VimeoChannelFeed {
    /** Код канала Vimeo */
    ChannelCode: string,

    /** [enum=VimeoChannelFeedType] Тип канала Vimeo */
    ChannelType: string
}

/** Тип канала Vimeo */
export enum VimeoChannelFeedType {
    /** Последние видеозаписи пользователя */
    UserVideos = 0,

    /** Последние видеозаписи на канале */
    ChannelVideos = 1
}

/** Настройки импорта из DailyMotion (если этот канал копирует записи из DailyMotion канала). */
export interface DailyMotionChannelFeed {
    /** Код канала Dailymotion.  */
    ChannelCode: string
}

/** Настройки импорта из Instagram (если этот канал копирует записи из Instagram ленты). */
export interface InstagramFeed {
    /** Включать ли ссылку на исходную запись Instagram. */
    IncludePostLink: boolean
}

/** Настройки импорта из Вконтакте  (если этот канал копирует записи со стены Вконтакте). */
export interface VkontakteFeed {
    /** Включать ли ссылку на исходную запись Вконтакте. */
    IncludePostLink?: boolean,

    /** Пропускать ли записи с репостами или ссылками. */
    SkipRepostsOrLinks?: boolean,

    /** Пропускать записи, помеченные как Реклама */
    SkipAds?: boolean,

    /** Удалять [id***|теги] из постов Вконтакте */
    RemoveVkTags?: boolean
}

/** Настройки импорта из Facebook  (если этот канал копирует записи из ленты Facebook). */
export interface FacebookFeed {
    /** Включать ли ссылку на исходную запись Facebook. */
    IncludePostLink?: boolean
}

/** Настройки импорта из Twitter (если этот канал копирует записи из ленты Twitter). */
export interface TwitterFeed {
    /** Включать ли ссылку на исходную запись Twitter. */
    IncludePostLink?: boolean
}

/** Настройки импорта из Telegram  (если этот канал копирует записи из ленты Telegram). */
export interface TelegramFeed {
    /** Включать ли ссылку на исходную запись Telegram. */
    IncludePostLink?: boolean
}
