import React from 'react';
import { observer } from "mobx-react-lite"
import { useLocation, useRouter } from '@happysanta/router';

import './ChangeLanguageMenu.css';

import { Principal, StateManager, useLocale, useStore } from '../../../shared/_index';
import { ChangeLanguageMenuL } from './ChangeLanguageMenu.L';
import { ActionSheet, ActionSheetItem } from '@vkontakte/vkui';

const ChangeLanguageMenu = () => {
	let locale: ChangeLanguageMenuL = useLocale(ChangeLanguageMenuL);

	let location = useLocation();
	let router = useRouter();

	const { languageStore } = useStore();

	let event: any = StateManager.getStateValue(location, 'event');

	let toggleRef = event ? event.target : null as any;

	const setLanguage = (language: string) => {
		languageStore.setLanguage(language, languageStore.use24TimeFormat);
	}

	return <ActionSheet
		toggleRef={toggleRef}
		onClose={() => router.popPage()}
		iosCloseItem={
			<ActionSheetItem autoclose mode="cancel">
				{/* Отменить */}
				{locale.cancel}
			</ActionSheetItem>
		}
	>
		<ActionSheetItem
			autoclose
			onClick={() => setLanguage('EN')}>
			English
		</ActionSheetItem>
		<ActionSheetItem
			onClick={() => setLanguage('RU')}
			autoclose>
			Русский
		</ActionSheetItem>
	</ActionSheet>;
}

export default React.memo(ChangeLanguageMenu);