export class Base64 {
  private static padchar: string = "=";
  private static alpha: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  private static getbyte64(s: string, i: number): number {
    var idx = this.alpha.indexOf(s.charAt(i));

    if (idx === -1) {
      // eslint-disable-next-line
      throw "Cannot decode base64";
    }

    return idx;
  }

  private static getbyte(s: string, i: number): number {
    var x = s.charCodeAt(i);
    if (x > 255) {
      // eslint-disable-next-line
      throw "INVALID_CHARACTER_ERR: DOM Exception 5";
    }
    return x;
  }

  /** Преобразует данную строку в base64. */
  public static encode(s: string): string {
    if (arguments.length !== 1) {
      // eslint-disable-next-line
      throw "SyntaxError: Not enough arguments";
    }

    var i, b10;
    var x: any[] = [];

    // convert to string
    s = "" + s;

    var imax = s.length - s.length % 3;

    if (s.length === 0) {
      return s;
    }
    for (i = 0; i < imax; i += 3) {
      b10 = (this.getbyte(s, i) << 16) | (this.getbyte(s, i + 1) << 8) | this.getbyte(s, i + 2);
      x.push(this.alpha.charAt(b10 >> 18));
      x.push(this.alpha.charAt((b10 >> 12) & 0x3F));
      x.push(this.alpha.charAt((b10 >> 6) & 0x3f));
      x.push(this.alpha.charAt(b10 & 0x3f));
    }
    switch (s.length - imax) {
      case 1:
        b10 = this.getbyte(s, i) << 16;
        x.push(this.alpha.charAt(b10 >> 18) + this.alpha.charAt((b10 >> 12) & 0x3F) +
          this.padchar + this.padchar);
        break;
      case 2:
        b10 = (this.getbyte(s, i) << 16) | (this.getbyte(s, i + 1) << 8);
        x.push(this.alpha.charAt(b10 >> 18) + this.alpha.charAt((b10 >> 12) & 0x3F) +
          this.alpha.charAt((b10 >> 6) & 0x3f) + this.padchar);
        break;
    }
    return x.join('');
  }


  /** Преобразует данный base64 в исходную строку. */
  public static decode(s: string): string {
    // convert to string
    s = "" + s;
    var pads, i, b10;
    var imax = s.length;
    if (imax === 0) {
      return s;
    }

    if (imax % 4 !== 0) {
      // eslint-disable-next-line
      throw "Cannot decode base64";
    }

    pads = 0;
    if (s.charAt(imax - 1) === this.padchar) {
      pads = 1;
      if (s.charAt(imax - 2) === this.padchar) {
        pads = 2;
      }
      // either way, we want to ignore this last block
      imax -= 4;
    }

    var x: any[] = [];
    for (i = 0; i < imax; i += 4) {
      b10 = (this.getbyte64(s, i) << 18) | (this.getbyte64(s, i + 1) << 12) |
        (this.getbyte64(s, i + 2) << 6) | this.getbyte64(s, i + 3);
      x.push(String.fromCharCode(b10 >> 16, (b10 >> 8) & 0xff, b10 & 0xff));
    }

    switch (pads) {
      case 1:
        b10 = (this.getbyte64(s, i) << 18) | (this.getbyte64(s, i + 1) << 12) | (this.getbyte64(s, i + 2) << 6);
        x.push(String.fromCharCode(b10 >> 16, (b10 >> 8) & 0xff));
        break;
      case 2:
        b10 = (this.getbyte64(s, i) << 18) | (this.getbyte64(s, i + 1) << 12);
        x.push(String.fromCharCode(b10 >> 16));
        break;
    }
    return x.join('');
  }
}