import React, { useEffect, useState } from 'react';
import { Tabbar, TabbarItem } from "@vkontakte/vkui";

import './PrivateToolbar.css';

import { PrivateToolbarL } from './PrivateToolbar.L';
import { useLocale, useStore } from '../../../shared/_index';
import { Icon16DropdownFlipped, Icon28GraphOutline, Icon28NewsfeedOutline, Icon28SettingsOutline } from '@vkontakte/icons';
import { useRouter } from '@happysanta/router';
import SubUserIcon from '../../../shared/components/SubUserIcon/SubUserIcon';

const PrivateToolbar = () => {
  let locale: PrivateToolbarL = useLocale(PrivateToolbarL);

  const router = useRouter();

  const { principalStore, subUserStore, routeStore } = useStore();

  const [activeTab, setActiveTab] = useState(principalStore.selectedUserTab);

  useEffect(() => {
    let $selectedUserTabChanged = principalStore.selectedUserTabChanged.subscribe(() => {
      setActiveTab(principalStore.selectedUserTab);
    });

    return () => {
      $selectedUserTabChanged.unsubscribe();
    }
  }, []);

  const navigateTo = (tab: string, page: string, params: any) => {
    setActiveTab(tab);

    router.replacePage(page, { userId: subUserStore.subUser.UserId.toString() })
  }

  if (!subUserStore.subUser) {
    return <></>;
  }

  return <section className='privateToolbar'>
    <Tabbar className='privateToolbar__tabbar'>
      <TabbarItem
        onClick={() => router.pushModal(routeStore.Modals.SelectSubUser)}
        text={subUserStore.subUser.WorkspaceName}
      >
        <SubUserIcon
          className='privateToolbar__workspaceIcon'
          width={24}
          height={24}
          icon={subUserStore.subUser.WorkspaceImageUrl}
          name={subUserStore.subUser.WorkspaceName}
        />

        <div className='privateToolbar__up'>
          <Icon16DropdownFlipped></Icon16DropdownFlipped>
        </div>

      </TabbarItem>

      <TabbarItem
        selected={activeTab === "posts"}
        onClick={() => navigateTo('posts', routeStore.Pages.Posts.Route, { userId: subUserStore.subUser.UserId.toString() })}
        // Посты
        text={locale.posts}
      >
        <Icon28NewsfeedOutline />
      </TabbarItem>

      <TabbarItem
        selected={activeTab === "analytics"}
        onClick={() => navigateTo('analytics', routeStore.Pages.Analytics.Route, { userId: subUserStore.subUser.UserId.toString() })}
        // Аналитика
        text={locale.analytics}
      >
        <Icon28GraphOutline />
      </TabbarItem>
      <TabbarItem
        selected={activeTab === "settings"}
        onClick={() => navigateTo('settings', routeStore.Pages.Settings.Route, { userId: subUserStore.subUser.UserId.toString() })}
        // Настройки
        text={locale.settings}
      >
        <Icon28SettingsOutline />
      </TabbarItem>
    </Tabbar>
  </section>
}

export default PrivateToolbar;