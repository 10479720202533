import React from 'react';
import { observer } from "mobx-react-lite"

import './UnsupportedAccountAlert.css';

import { useLocale } from '../../../shared/_index';
import { UnsupportedAccountAlertL } from './UnsupportedAccountAlert.L';
import { Button, ButtonGroup, Placeholder } from '@vkontakte/vkui';
import { Icon28InfoCircleOutline } from '@vkontakte/icons';


export interface UnsupportedAccountAlertProps {

}

const UnsupportedAccountAlert = (props: UnsupportedAccountAlertProps) => {
	let locale: UnsupportedAccountAlertL = useLocale(UnsupportedAccountAlertL);

	return <div className='unsupportedAccountAlert'>
		<Placeholder
			icon={
				<Icon28InfoCircleOutline
					width={56}
					height={56}
					className='unsupportedAccountAlert__icon' />
			}
			// Аккаунт пока не поддерживается
			header={locale.header}
		>
			{/* Для работы в мобильной версии нужно обновить Ваш аккаунт NovaPress до последней версии. Для обновления напишите, пожалуйста нам на */}
			{locale.subHeader}

			<a target='_blank' href='mailto:support@novapress.com'>support@novapress.com</a>
		</Placeholder>
	</div>;
}

export default React.memo(UnsupportedAccountAlert);