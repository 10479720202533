import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"
import { useRouter } from '@happysanta/router';

import './SortSubUsers.css';

import { Principal, Api, useLocale, useStore } from '../../../shared/_index';
import { SortSubUsersL } from './SortSubUsers.L';
import { Button, Cell, Group, List, Panel, PanelHeader, PanelHeaderButton, Spinner } from '@vkontakte/vkui';
import SubUserIcon from '../../../shared/components/SubUserIcon/SubUserIcon';

const SortSubUsers = (props: { id: string }) => {
	let locale: SortSubUsersL = useLocale(SortSubUsersL);

	let router = useRouter();

	const [subUsers, setSubUsers] = useState((null as any) as Principal.SubUser[]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { principalStore } = useStore();

	useEffect(() => {
		principalStore.get().then(() => {
			let principal = principalStore.principal;
			
			setSubUsers(principal.SubUsers);
		});
	}, [props.id]);

	const onOrderChanged = (from: number, to: number) => {
		let results: Principal.SubUser[] = [];

		for (let i = 0; i < subUsers.length; i++) {
			if (i === from) {
				continue;
			}

			if (from < to) {
				results.push(subUsers[i]);
			}

			if (i === to) {
				results.push(subUsers[from]);
			}

			if (from > to) {
				results.push(subUsers[i]);
			}
		}

		setSubUsers(results);
	}

	const saveChanges = () => {
		setIsSubmitting(true);

		Api.executeMethod<any>('subUsers.sort', {
			userIds: JSON.stringify(subUsers.map(s => s.UserId))
		}).then(() => {
			principalStore.principal.SubUsers = subUsers;

			router.popPage();
		});
	}

	return <Panel id={props.id} className='sortSubUsers'>
		<PanelHeader
			before={<PanelHeaderButton onClick={() => router.popPage()}>
				{/* Отмена */}
				{locale.cancel}
			</PanelHeaderButton>}
		>
			{/* Сортировка областей */}
			{locale.header}
		</PanelHeader>
		{!subUsers && <Spinner />}
		{subUsers && <Group>
			<List className='sortSubUsers__body'>
				{subUsers.map(subUser =>
					<Cell
						key={subUser.UserId}
						draggable
						className='sortSubUsers__cell'
						onDragFinish={({ from, to }) =>
							onOrderChanged(from, to)
						}
						before={
							<SubUserIcon
								name={subUser.WorkspaceName}
								icon={subUser.WorkspaceImageUrl}
								width={32}
								height={32} />}
					>
						<div className='sortSubUsers__cell_name'>
							{subUser.WorkspaceName}
						</div>
					</Cell>
				)}
				<div className='sortSubUsers__footer'>
					<Button
						size="l"
						stretched
						className='sortSubUsers__cta'
						onClick={() => saveChanges()}
						loading={isSubmitting}>
						{/* Сохранить изменения */}
						{locale.cta}
					</Button>
				</div>
			</List>
		</Group>}
	</Panel>
}

export default React.memo(SortSubUsers);