import { autorun, makeAutoObservable } from 'mobx';
import { Subject } from 'rxjs';
import { PostDataShort, Principal } from '../models/_index';
import { Cookies } from '../_index';
import { Store } from './Store';
import { Api } from '../services/_index';

export default class PrincipalStore {
    /** Значение, показывающее, идет ли в данный момент получение данных о текущем пользователе. */
    isLoading: boolean = true;

    private _principal: Principal = null as any;

    /** Подключенный в данный момент пользователь. */
    public get principal(): Principal {
        return this._principal;
    }

    public set principal(principal: Principal) {
        this._principal = principal;

        if (principal) {
            this.rootStore.timeStore.initClock();
        }
    }

    /** Получение данных текущего пользователя в процессе. */
    principalPromise: Promise<void> = null as any;

    public selectedUserTab: string = null as any;
    public selectedUserTabChanged = new Subject<void>();

    public timeChanged = new Subject<void>();

    public postsChanged = new Subject<void>();
    public postChanged = new Subject<PostDataShort>();
    public postReturnedToQueued = new Subject<{ projectId: number, itemHash: string }>();
    public postDeleted = new Subject<{ projectId: number, itemHash: string }>();

    public postEditorClosed = new Subject<{ projectId: number, itemHash: string }>();
    public postEditorPageClosed = new Subject<void>();

    public postEditorShownChanged = new Subject<void>();

    public readonly rootStore: Store;

    constructor(rootStore: Store) {
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }

    public setPrincipal(principal: Principal) {
        if (principal) {
            principal.PhotoUrl = this.getImageUrl(principal);
        }

        this.principal = principal;

        if (principal) {
            let userId = this.tryGetUserId();

            if (!this.rootStore.subUserStore.subUser) {
                this.rootStore.subUserStore.subUser = userId
                    ? principal.SubUsers.filter(s => s.UserId === userId)[0]
                    : principal.SubUsers[0];
            }
        }
    }

    public tryGetUserId(): number | null {
        let windowObj = window as any;
        let path = windowObj.location.hash.replace('#', '');

        if (path.indexOf('/user/') === 0 && path.indexOf('/user/0/') === -1) {
            return +path.replace('/user/', '').split('/')[0];
        }
        else if (path.indexOf('/project/') === 0) {
            return +path.replace('/project/', '').split('/')[0];
        }
        else if (path.indexOf('/page/') === 0) {
            return +path.replace('/page/', '').split('/')[0];
        }
        else if (Cookies.get('principalSubUser_v3')) {
            let subUserData = Cookies.get('principalSubUser_v3');

            let userId = +subUserData.split('_')[0];

            return userId;
        }
        else {
            return null;
        }
    }

    /** Получить информацию о подключенном в данный момент пользователе. */
    public get(): Promise<void> {
        if (!this.principalPromise) {
            this.principalPromise = new Promise<void>((resolve) => {
                // Если данные о текущем пользователе уже получены, выдаем сразу же их.
                if (!this.isLoading) {
                    this.principalPromise = null as any;
                    resolve();
                }
                // Если же каких-либо из куки-файлов не хватает, сразу же говорим, что пользователь не авторизован.
                else if (!Cookies.get('principalId_v3') || !Cookies.get('principalHash_v3')) {
                    this.setPrincipal(null as any);
                    this.isLoading = false;

                    this.principalPromise = null as any;
                    resolve();
                }
                else {
                    Api.executeMethod('principal.get').then((response: any) => {
                        let principal = response.Principal;

                        // Пользователь авторизован и вот его данные.
                        this.setPrincipal(principal);
                        this.isLoading = false;

                        this.principalPromise = null as any;
                        resolve();
                    });
                }
            });
        }

        return this.principalPromise;
    }

    public refreshCurrent(): Promise<Principal> {
        return new Promise<Principal>((resolve) => {
            // Если же куки-файлы пользователя есть, пытаемся получить информацию о нем из API.
            Api.executeMethod('principal.get').then((response: any) => {
                let principal = response.Principal;

                if (!principal) {
                    this.logout();

                    resolve(null as any);
                }

                // Пользователь авторизован и вот его данные.
                this.setPrincipal(principal);
                this.isLoading = false;

                resolve(this.principal);
            }).catch(response => {
                // Пользователь не авторизован.
                this.setPrincipal(null as any);
                this.isLoading = false;

                resolve(this.principal);
            });
        });
    }

    public getDefaultImageUrl(): string {
        return '/images/default-userpic.png';
    }

    public getImageUrl(principal: Principal): string {
        return principal.PhotoUrl
            ? principal.PhotoUrl
            : this.getDefaultImageUrl();
    }

    /** Войти в систему под заданным пользователем. */
    public loginAs(principal: Principal) {
        let currentdate = new Date();

        let cookieOptions = {
            domain: window.location.hostname === 'm.novapress.com' || window.location.hostname === 'mobile.novapress.com'
                ? '.novapress.com'
                : window.location.hostname,
            path: '/',
            expires: new Date(currentdate.setMonth(currentdate.getMonth() + 1))
        };

        Cookies.set('principalId_v3', principal.Id.toString(), cookieOptions);
        Cookies.set('principalHash_v3', principal.PrincipalHash, cookieOptions);

        if (this.rootStore.subUserStore.subUser) {
            Cookies.set('principalSubUser_v3', `${this.rootStore.subUserStore.subUser.UserId}_${this.rootStore.subUserStore.subUser.TeamMemberId}`, cookieOptions);
        }

        this.setPrincipal(principal);
        this.principalPromise = null as any;
        this.isLoading = false;
    };

    public logout(): void {
        var cookieOptions = {
            domain: window.location.hostname === 'm.novapress.com' || window.location.hostname === 'mobile.novapress.com'
                ? '.novapress.com'
                : window.location.hostname,
            path: '/'
        };

        Cookies.remove('principalId_v3', cookieOptions);
        Cookies.remove('principalHash_v3', cookieOptions);
        Cookies.remove('principalSubUser_v3', cookieOptions);

        Cookies.remove('userId_v2', cookieOptions);
        Cookies.remove('hash_v2', cookieOptions);
        Cookies.remove('teamMemberId_v2', cookieOptions);
        Cookies.remove('teamMemberHash_v2', cookieOptions);
        Cookies.remove('auth_version_v2', cookieOptions);

        this.setPrincipal(null as any);
        this.rootStore.subUserStore.subUser = null as any;
        
        this.isLoading = false;
    }
}