import React from 'react';
import { observer } from "mobx-react-lite"

import './PostCardPages.css';

import { PostDataShort, UserPage } from '../../../shared/_index';
import PostCardPage from '../PostCardPage/PostCardPage';


export interface PostCardPagesProps {
	post: PostDataShort,
	userPages: UserPage[],

	className?: string | undefined
}

const PostCardPages = (props: PostCardPagesProps) => {
	return <ul className={"postCardPages " + (props.className ? props.className : "")}>
		{props.post.PublishResults.map(publishResult =>
			<PostCardPage
				key={publishResult.UserPageId}
				post={props.post}
				publishResult={publishResult}
				userPages={props.userPages}
				width={20}
				height={20}
			/>
		)}
	</ul>;
}

export default React.memo(PostCardPages);