import React, { useEffect, useState } from 'react';

import './LaunсherWorkspaceHeader.css';

import SubUserIcon from '../../shared/components/SubUserIcon/SubUserIcon';
import { Principal, StateManager, useLocale, useStore } from '../../shared/_index';
import { observer } from 'mobx-react-lite';
import { LaunсherWorkspaceHeaderL } from './LaunсherWorkspaceHeader.L';
import { useLocation, useRouter } from '@happysanta/router';
import Moment from '../../shared/components/Moment/Moment';

const LaunсherWorkspaceHeader = () => {
	const { subUserStore, routeStore } = useStore();

	let locale: LaunсherWorkspaceHeaderL = useLocale(LaunсherWorkspaceHeaderL);

	let location = useLocation();
	let router = useRouter();

	const [currentSubUser, setCurrentSubUser] = useState<Principal.SubUser>(null as any);

	const showSubUserMenu = (event: any) => {
		router.pushPopup(routeStore.Popouts.CurrentSubUserMenu, {
			state: StateManager.setState({
				subUser: currentSubUser,
				event: event
			})
		});
	}

	useEffect(() => {
		setCurrentSubUser(subUserStore.subUser);
		console.log(JSON.stringify(subUserStore.subUser));

	}, [subUserStore.subUser]);

	return <>
		{!!currentSubUser && <ul className='launсherWorkspaceHeader__header'>
			<li>
				<a onClick={(e) => showSubUserMenu(e)}>
					<SubUserIcon
						className='launсherWorkspaceHeader__subUser_icon'
						name={currentSubUser.WorkspaceName}
						icon={currentSubUser.WorkspaceImageUrl}
						width={32}
						height={32} />

					<div className='launсherWorkspaceHeader__subUser_name'>
						{currentSubUser.WorkspaceName}
					</div>

					<div className='launсherWorkspaceHeader__subUser_subtext'>
						{/* Оплачен до */}
						{locale.paidUntil}
						&nbsp;
						<Moment value={subUserStore.subUser.LicenseExpiredAt} format="fullDate" />
					</div>
				</a>
			</li>
		</ul>
		}
	</>
}

export default observer(LaunсherWorkspaceHeader);