import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './ComposeTimePicker.css';

import { useLocale, useStore } from '../../../shared/_index';
import { ComposeTimePickerL } from './ComposeTimePicker.L';
import { Group, ModalPage, ModalPageHeader, PanelHeaderBack } from '@vkontakte/vkui';
import { useLocation, useRouter } from '@happysanta/router';
import { classNamesString } from '@vkontakte/vkui/dist/lib/classNames';
import { DateUtils } from '../../services/DateUtils';


export interface ComposeTimePickerProps {
	id: string,
	dynamicContentHeight?: boolean
}

const ComposeTimePicker = (props: ComposeTimePickerProps) => {
	let router = useRouter();
	let location = useLocation();

	const { principalStore, subUserStore, routeStore } = useStore();

	let locale: ComposeTimePickerL = useLocale(ComposeTimePickerL);

	const [value, setValue] = useState('--:--');

	const [isAMPM, setIsAMPM] = useState(false);

	const [isAM, setIsAm] = useState(false);

	const backspace = () => {
		let chars = Array.from(value);
		let charRemoved = false;

		let resultChars: string[] = [];

		for (let i = chars.length - 1; i >= 0; i--) {
			let char = chars[i];

			if (!charRemoved && char !== '-' && char !== ':') {
				charRemoved = true;
				resultChars.push('-');
			}
			else {
				resultChars.push(char);
			}
		}

		resultChars = resultChars.reverse();

		setValue(resultChars.join(''));
	};

	const onSelectTime = (currentValue: string) => {
		let hours = +currentValue.split(':')[0];
		let minutes = +currentValue.split(':')[1];

		if (isAMPM) {
			if (isAM && hours === 12) {
				hours = 0;
			}
			else if (!isAM) {
				hours += 12;
			}
		}

		let currentTime = DateUtils.getCurrentUnixTime(0);

		let currentDayString = location.getParams().day;

		let year = +currentDayString.substring(0, 4);
		let month = +currentDayString.substring(4, 6);
		let day = +currentDayString.substring(6, 8);

		currentTime = DateUtils.setDatePart(currentTime, 'year', year);
		currentTime = DateUtils.setDatePart(currentTime, 'month', month - 1);
		currentTime = DateUtils.setDatePart(currentTime, 'date', day);

		currentTime = DateUtils.getDayStart(currentTime) + hours * 60 * 60 + minutes * 60;
		currentTime = currentTime - subUserStore.subUser.TimeZoneOffsetInMinutes * 60;
		currentTime = currentTime + 4 * 60 * 60;

		let userId = subUserStore.subUser.UserId.toString();
		let route: string = null as any;

		if (location.getPanelId() === routeStore.Pages.Posts.PanelId) {
			route = routeStore.Pages.Posts.Route;
		}
		else if (location.getPanelId() === routeStore.Pages.PostDrafts.PanelId) {
			route = routeStore.Pages.PostDrafts.Route;
		}
		else {
			route = routeStore.Pages.PostTrash.Route;
		}

		let parameters: any = {
			userId: userId,
			itemHash: '-',
			showEditor: 1
		};

		if (location.getParams().projectId) {
			parameters.projectId = location.getParams().projectId;
			parameters.itemProjectId = location.getParams().projectId;
		}
		else {
			parameters.itemProjectId = "0";
		}

		if (location.getParams().pageId) {
			parameters.pageId = location.getParams().pageId;
		}

		if (location.getParams().day) {
			parameters.day = location.getParams().day;
		}

		parameters.at = currentTime;

		setTimeout(() => {
			router.pushPage(route, parameters);
		}, 200);

		router.popPage();
	};

	const numClick = (num: any) => {
		let currentValue = value;

		let nums: number[] = Array.from(currentValue).filter(s => s !== ':' && s !== '-').map(s => +s);

		if (nums.length === 0) {
			if (isAMPM && num === 0) {
				return;
			}

			currentValue = `${num}:--`;
		}
		else if (nums.length === 1) {
			let hours = nums[0] * 10 + num;

			if (hours > 23
				|| (isAMPM && isAM && hours > 12)
				|| (isAMPM && !isAM && hours > 11)) {
				if (num < 6) {
					currentValue = `${nums[0]}:${num}-`;
				}
			}
			else {
				currentValue = `${nums[0]}${num}:--`;
			}
		}
		else if (nums.length === 2) {
			let hours = +currentValue.split(':')[0];

			if (currentValue.split(':')[1] === '--') {
				if (num < 6) {
					currentValue = `${hours}:${num}-`;
				}
			}
			else {
				currentValue = `${nums[0]}:${nums[1]}${num}`;
			}
		}
		else if (nums.length === 3 && currentValue.indexOf('-') !== -1) {
			currentValue = `${nums[0]}${nums[1]}:${nums[2]}${num}`;
		}

		setValue(currentValue);

		if (currentValue.indexOf('-') === -1) {
			onSelectTime(currentValue);
		}
	}

	const onKeyDown = (e: any) => {
		console.log(e);

		let key: string = e.key;

		if (+key || key === '0') {
			numClick(+key);
		}
		else if (key.toLowerCase() === 'delete' || key.toLowerCase() === 'backspace') {
			backspace();
		}
	}

	useEffect(() => {
		principalStore.get().then(() => {
			let principal = principalStore.principal;

			setIsAMPM(!subUserStore.subUser.Use24TimeFormat);
		});
	}, [props.id]);

	useEffect(() => {
		let group = document.querySelector(`#${props.id}`) as any;

		group.addEventListener('keydown', onKeyDown);

		setTimeout(() => {
			(document.querySelector(`#${props.id} .composeTimePicker__numpad>li>a`) as any).focus();
		}, 300);

		return () => {
			group.removeEventListener('keydown', onKeyDown);
		};
	}, [props.id, value]);

	return <ModalPage
		id={props.id}
		dynamicContentHeight={props.dynamicContentHeight}
		header={
			<ModalPageHeader
				before={
					<PanelHeaderBack label="" onClick={() => router.popPage()} />
				}
			>
				{/* Выберите время поста */}
				{locale.header}
			</ModalPageHeader>
		}
	>
		<Group className='composeTimePicker__group'>
			<div className="composeTimePicker__value">
				{value}

				{isAMPM &&
					<div className="composeTimePicker__ampm">
						<a
							tabIndex={0}
							className={classNamesString("composeTimePicker__ampm_am", {
								"composeTimePicker__ampm_active": isAM
							})}
							onClick={() => setIsAm(true)}>
							AM
						</a>
						<a
							tabIndex={0}
							className={classNamesString("composeTimePicker__ampm_pm", {
								"composeTimePicker__ampm_active": isAM
							})}
							onClick={() => setIsAm(false)}>
							PM
						</a>
					</div>
				}


				<a
					className="composeTimePicker__backspace"
					tabIndex={0}
					onClick={() => backspace()}
				>
					<svg viewBox="0 0 512 512">
						<path d="m413 332c2 2 3 4 3 6 0 2-1 4-3 6l-21 22c-2 1-4 2-6 2-2 0-4-1-6-2l-76-77-76 77c-2 1-4 2-6 2-2 0-4-1-6-2l-21-22c-2-2-3-4-3-6 0-2 1-4 3-6l76-76-77-76c-3-3-3-9 0-12l22-22c2-1 4-2 6-2 2 0 4 1 6 2l76 76 76-76c2-1 4-2 6-2 2 0 4 1 6 2l22 22c3 3 3 9 0 12l-77 76z m86-238c-9-9-20-14-33-14l-298 0c-24 0-44 9-58 28l-110 148 110 146 1 1 0 0c7 9 14 16 23 20 10 6 21 9 34 9l298 0c26 0 46-22 46-49l0-256c0-13-4-25-13-33z m-19 289c0 9-5 17-14 17l-298 0c-15 0-24-7-32-16l-96-128 96-128c10-13 21-16 32-16l298 0c8 0 14 6 14 15z" />
					</svg>
				</a>
			</div>

			<ul className="composeTimePicker__numpad">
				<li>
					<a onClick={() => numClick(1)} tabIndex={0}>
						1
					</a>
				</li>
				<li>
					<a onClick={() => numClick(2)} tabIndex={0}>
						2
					</a>
				</li>
				<li>
					<a onClick={() => numClick(3)} tabIndex={0}>
						3
					</a>
				</li>
				<li>
					<a onClick={() => numClick(4)} tabIndex={0}>
						4
					</a>
				</li>
				<li>
					<a onClick={() => numClick(5)} tabIndex={0}>
						5
					</a>
				</li>
				<li>
					<a onClick={() => numClick(6)} tabIndex={0}>
						6
					</a>
				</li>
				<li>
					<a onClick={() => numClick(7)} tabIndex={0}>
						7
					</a>
				</li>
				<li>
					<a onClick={() => numClick(8)} tabIndex={0}>
						8
					</a>
				</li>
				<li>
					<a onClick={() => numClick(9)} tabIndex={0}>
						9
					</a>
				</li>
				<li className="composeTimePicker__num_disabled">
					&nbsp;
				</li>
				<li>
					<a onClick={() => numClick(0)} tabIndex={0}>
						0
					</a>
				</li>
				<li className="composeTimePicker__num_disabled">
					&nbsp;
				</li>
			</ul >
		</Group >
	</ModalPage >;
}

export default React.memo(ComposeTimePicker);