export interface ChangeRestoredPasswordL {
  noPasswordError: string,
  changePasswordHeader: string,
  enterNewPassword: string,
  confirmPassword: string,
  noConfirmPasswordError: string,
  passwordMustMatchError: string,
  changePassword: string,
  back: string
}

export abstract class ChangeRestoredPasswordL {
  public static RU: ChangeRestoredPasswordL = {
    noPasswordError: 'Пожалуйста, введите пароль',
    changePasswordHeader: 'Смена пароля',
    enterNewPassword: 'Введите новый пароль',
    confirmPassword: 'Повторите пароль',
    noConfirmPasswordError: 'Пожалуйста, повторите пароль',
    passwordMustMatchError: 'Пароли должны совпадать',
    changePassword: 'Сменить пароль',
    back: 'Назад'
  }


  public static EN: ChangeRestoredPasswordL = {
    noPasswordError: 'Please enter a password',
    changePasswordHeader: 'Change Password',
    enterNewPassword: 'Enter a new password',
    confirmPassword: 'Repeat password',
    noConfirmPasswordError: 'Please repeat password',
    passwordMustMatchError: 'Passwords must match',
    changePassword: 'Change password',
    back: 'Back'
  }
}