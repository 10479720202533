import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './CalendarSlider.css';

import { DateUtils, DayOfWeek, useLocale, useStore } from '../../../../../../shared/_index';
import { CalendarSliderL } from './CalendarSlider.L';
import { Gallery, Tabs, TabsItem } from '@vkontakte/vkui';
import Moment from '../../../../../../shared/components/Moment/Moment';

export interface Week {
	id: string,
	days: {
		id: number,
		dayStart: number,
		day: number
	}[]
}

export interface CalendarSliderProps {
	weeksCount?: number,

	selectedDay?: number,
	onDaySelected: (day: number) => void
}

const CalendarSlider = (props: CalendarSliderProps) => {
	let weeksCount = props.weeksCount ? props.weeksCount : 20;

	const { principalStore, subUserStore, timeStore } = useStore();

	let locale: CalendarSliderL = useLocale(CalendarSliderL);

	const [selectedDayId, setSelectedDayId] = useState(0);
	const [weeks, setWeeks] = useState([] as Week[]);
	const [monthLabelVisibleTimer, setMonthLabelVisibleTimer] = useState(null as any);

	const [weekIndex, setWeekIndex] = useState(weeksCount);

	// Инициализация календаря.
	useEffect(() => {
		// Если уже инициализировано, ничене не делаем.
		if (weeks && weeks.length) {
			return;
		}

		// Если еще не подгрузились данные, ничего не делаем
		if (!principalStore.principal || !subUserStore.subUser || !timeStore.currentUserTime) {
			return;
		}

		// Берем день из выбранного на календаре или текущий день.
		let currentTime = props.selectedDay
			? dayToUnix(props.selectedDay)
			: timeStore.currentUserTime;

		// Превращаем его в формат YYYYMMDD.
		let selectedDay = +DateUtils.formatDate(currentTime, 'YYYYMMDD');
		setSelectedDayId(selectedDay);

		// Задаем список недель.
		{
			let weeks: Week[] = [];

			for (let i = -1 * weeksCount; i < weeksCount; i++) {
				let week = createWeek(currentTime + i * 7 * 24 * 60 * 60);

				weeks.push(week);
			}

			setWeeks(weeks);
		}

		showMonthLabel();
	}, [principalStore.principal, subUserStore.subUser, timeStore.currentUserTime, props.selectedDay]);

	const createWeek = (date: number): Week => {
		let dayStart = DateUtils.getDayStart(date);

		var currentWeekStart = DateUtils.setDayOfWeek(dayStart, DayOfWeek[DayOfWeek.Monday]);
		var currentWeekEnd = DateUtils.setDayOfWeek(dayStart, DayOfWeek[DayOfWeek.Sunday]);

		let currentWeek: Week = {
			id: `${DateUtils.formatDate(currentWeekStart, 'YYYYMMDD')}-${DateUtils.formatDate(currentWeekEnd, 'YYYYMMDD')}`,
			days: []
		};

		for (let i = 0; i < 7; i++) {
			let day = currentWeekStart + i * 24 * 60 * 60;

			currentWeek.days.push({
				id: +DateUtils.formatDate(day, 'YYYYMMDD'),
				dayStart: day,
				day: +DateUtils.formatDate(day, 'DD')
			});
		}

		return currentWeek;
	}

	const dayToUnix = (currentDay: number): number => {
		let currentTime = DateUtils.getCurrentUnixTime(0);

		let currentDayString = currentDay.toString();

		let year = +currentDayString.substring(0, 4);
		let month = +currentDayString.substring(4, 6);
		let day = +currentDayString.substring(6, 8);

		currentTime = DateUtils.setDatePart(currentTime, 'year', year);
		currentTime = DateUtils.setDatePart(currentTime, 'month', month - 1);
		currentTime = DateUtils.setDatePart(currentTime, 'date', day);

		return currentTime;
	}

	const showMonthLabel = () => {
		resetMonthLabelVisibleTimer();

		var timer = setTimeout(() => {
			setMonthLabelVisibleTimer(null);
		}, 2000);

		setMonthLabelVisibleTimer(timer);
	}

	const resetMonthLabelVisibleTimer = () => {
		if (monthLabelVisibleTimer) {
			clearTimeout(monthLabelVisibleTimer);
		}
	}

	const selectDay = (dayId: number) => {
		if (DateUtils.formatDate(dayToUnix(selectedDayId), 'MMM YYYY')
			!== DateUtils.formatDate(dayToUnix(dayId), 'MMM YYYY')) {
			showMonthLabel();
		}

		setSelectedDayId(dayId);

		props.onDaySelected(dayId);
	}

	const onSlideChange = (index: number) => {
		let week = weeks[index];
		let previousWeek = weeks.filter(s => s.days[0].id <= selectedDayId && selectedDayId <= s.days[6].id)[0];

		let dayIndex = previousWeek.days.map(s => s.id).indexOf(selectedDayId);

		if (dayIndex !== -1) {
			let dayToSelect = week.days[dayIndex];

			selectDay(dayToSelect.id);

			showMonthLabel();
		}

		setWeekIndex(index);
	}

	return <div className='calendarSlider'>
		<div className='calendarSlider__day_names'>
			<div className='calendarSlider__day_name'>
				{/* пн */}
				{locale.monday}
			</div>
			<div className='calendarSlider__day_name'>
				{/* вт */}
				{locale.thuesday}
			</div>
			<div className='calendarSlider__day_name'>
				{/* ср */}
				{locale.wednesday}
			</div>
			<div className='calendarSlider__day_name'>
				{/* чт */}
				{locale.thursday}
			</div>
			<div className='calendarSlider__day_name'>
				{/* пт */}
				{locale.friday}
			</div>
			<div className='calendarSlider__day_name'>
				{/* сб */}
				{locale.saturday}
			</div>
			<div className='calendarSlider__day_name'>
				{/* вс */}
				{locale.sunday}
			</div>
		</div>
		{weeks.length > 0 && <Gallery
			slideWidth="100%"
			className='calendarSlider__gallery'
			initialSlideIndex={weeksCount}
			onChange={(index) => onSlideChange(index)}
		>
			{weeks.map((week, index) =>
				<div key={week.id} className='calendarSlider__week'>
					{(weekIndex == index || weekIndex == index - 1 || weekIndex == index + 1) &&
						<Tabs className='calendarSlider__tabs'>
							{week.days.map(day =>
								<TabsItem
									key={day.id}
									className='calendarSlider__tab'
									onClick={() => selectDay(day.id)}
									selected={selectedDayId === day.id}
								>
									{day.day}
								</TabsItem>)}
						</Tabs>
					}
				</div>)}
		</Gallery>}

		<div className={"calendarSlider__month_label " + (monthLabelVisibleTimer ? "calendarSlider__month_label--visible" : "")}>
			<Moment value={dayToUnix(selectedDayId)} format="shortMonth_withYear"></Moment>
		</div>
	</div>;
}

export default React.memo(CalendarSlider);