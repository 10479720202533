import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './UpdateChecker.css';

import { useLocale } from '../../../shared/_index';
import { UpdateCheckerL } from './UpdateChecker.L';
import { AppearanceProvider, Avatar, Snackbar } from '@vkontakte/vkui';
import { Icon16Sync } from '@vkontakte/icons';
import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration';


const UpdateChecker = () => {
	let locale: UpdateCheckerL = useLocale(UpdateCheckerL);

	const [newVersionAvailable, setNewVersionAvailable] = useState(false);
	const [isInstalling, setIsInstalling] = useState(false);

	const checkUpdates = () => {
		fetch('/GetCurrentVersion').then(response => {
			response.text().then(newVersion => {
				let currentVersionSpan = document.getElementById('novapressVersion') as any;
				let currentVersion = currentVersionSpan.innerText;

				if (currentVersion === '%novapressVersion%' || newVersion === '0.0') {
					return;
				}

				if (currentVersion !== newVersion) {
					currentVersionSpan.innerHTML = newVersion;

					serviceWorkerRegistration.unregister();
					caches.keys().then(cacheNames => {
						cacheNames.forEach(cacheName => {
							caches.delete(cacheName);
						});
					}).then(() => {
						serviceWorkerRegistration.register();
					})

					setNewVersionAvailable(true);
				}
			});
		});

		setTimeout(() => {
			checkUpdates();
		}, 10000);
	};

	const restartToUpdate = () => {
		setIsInstalling(true);

		setTimeout(() => {
			window.location.reload();
		}, 100);
	};

	useEffect(() => {
		checkUpdates();

		// eslint-disable-next-line
	}, [])

	if (!newVersionAvailable) {
		return <></>;
	}
	else {
		return <AppearanceProvider appearance="dark">
			<Snackbar
				duration={10000}
				onClose={() => setNewVersionAvailable(false)}
				// Установить
				action={!isInstalling ? locale.installNow : null as any}
				onActionClick={() => restartToUpdate()}
				before={
					<Avatar size={24} style={{ background: "var(--accent)" }}>
						<Icon16Sync fill="#fff" width={14} height={14} />
					</Avatar>
				}
			>
				{/* Доступна новая версия NovaPress */}
				{!isInstalling && locale.newVersionAvailable}

				{/* Идет установка... */}
				{isInstalling && locale.installing}
			</Snackbar>
		</AppearanceProvider>
	};
}

export default React.memo(UpdateChecker);