export interface SelectPostsContextL {
  back: string,
  header: string,
  calendar: string,
  draft: string,
  deleted: string
}

export abstract class SelectPostsContextL {
  public static RU: SelectPostsContextL = {
    back: 'Назад',
    header: 'Фильтр постов',
    calendar: 'Календарь',
    draft: 'Черновики',
    deleted: 'Корзина'
  }


  public static EN: SelectPostsContextL = {
    back: 'Back',
    header: 'Posts filter',
    calendar: 'Calendar',
    draft: 'Drafts',
    deleted: 'Trash'
  }
}