import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"
import { RouterProps, useRouter } from '@happysanta/router';

import './SelectSubUser.css';

import { Principal, StateManager, useLocale, useStore } from '../../../shared/_index';
import { SelectSubUserL } from './SelectSubUser.L';
import { Group, IconButton, List, ModalPage, ModalPageHeader, PanelHeaderButton, SimpleCell, Spinner, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { Icon20AddCircleFillGreen, Icon24Dismiss, Icon28ChevronDownOutline } from '@vkontakte/icons';
import SubUserIcon from '../../../shared/components/SubUserIcon/SubUserIcon';
import UnsupportedAccountAlert from '../../../shared/components/UnsupportedAccountAlert/UnsupportedAccountAlert';


export interface SelectSubUserProps extends RouterProps {
	id: string
}

export interface SelectSubUserState {
	isMobile: boolean,

	subUsers: Principal.SubUser[],
	currentSubUser: Principal.SubUser,

	locale: SelectSubUserL
}

const SelectSubUser = (props: { id: string, dynamicContentHeight: boolean }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let locale: SelectSubUserL = useLocale(SelectSubUserL);

	let router = useRouter();

	const { principalStore, subUserStore, routeStore } = useStore();

	const [subUsers, setSubUsers] = useState((null as any) as Principal.SubUser[]);

	const [isMultiUser, setIsMultiUser] = useState(false);

	useEffect(() => {
		principalStore.get().then(() => {
			let principal = principalStore.principal;

			setIsMultiUser(principal.IsMultiUser);

			setSubUsers(principal.SubUsers);
		});
	}, [props.id]);

	const selectSubUser = (event: any, subUser: Principal.SubUser) => {
		let target = event.target;

		while (true) {
			if (target.classList.contains('selectSubUser-icon')) {
				return;
			}
			else if (target.id === props.id) {
				break;
			}
			else {
				target = target.parentElement;
			}
		}

		subUserStore.subUser = subUser;

		router.replacePage(routeStore.Pages.SwitchTo.Route, { userId: subUser.UserId.toString() });
	}

	const showSubUserMenu = (event: any, subUser: Principal.SubUser) => {
		router.pushPopup(routeStore.Popouts.SubUserMenu, {
			state: StateManager.setState({
				subUser: subUser,
				event: event
			})
		});
	}

	const createSubUser = () => {
		router.pushModal(routeStore.Modals.AddSubUser);
	}

	return <ModalPage
		id={props.id}
		header={
			<ModalPageHeader
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => router.popPage()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{/* Выберите рабочую область */}
				{locale.header}
			</ModalPageHeader>
		}
	>
		{!subUsers && <Spinner />}

		{(!!subUsers && !isMultiUser) && <>
			<UnsupportedAccountAlert />
		</>}

		{(!!subUsers && isMultiUser) && <Group>
			<List>
				{subUsers.map(subUser =>
					<SimpleCell
						onClick={(e) => selectSubUser(e, subUser)}
						key={subUser.UserId}
						className='selectSubUser-cell'
						before={
							<SubUserIcon
								name={subUser.WorkspaceName}
								icon={subUser.WorkspaceImageUrl}
								width={32}
								height={32} />}
						after={
							<IconButton
								className='selectSubUser-icon'
								onClick={(e) => showSubUserMenu(e, subUser)}>
								<Icon28ChevronDownOutline />
							</IconButton>
						}
					>
						<div className='selectSubUser-cell-name'>
							{subUser.WorkspaceName}
						</div>
					</SimpleCell>
				)}
				<SimpleCell
					onClick={(e) => createSubUser()}
					key={0}
					className='selectSubUser-cell'
					before={
						<Icon20AddCircleFillGreen
							width={32}
							height={32}
							className='selectSubUser-cell-add'
						/>}
				>
					<div className='selectSubUser-cell-name'>
						{/* Создать новую область */}
						{locale.createWorkspace}
					</div>
				</SimpleCell>
			</List>
		</Group>}
	</ModalPage >;
}

export default React.memo(SelectSubUser);