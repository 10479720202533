import { useLocation, useRouter } from '@happysanta/router';
import React, { useEffect, useState } from 'react';

import './ConfirmRegistrationCode.css';

import { Icon24Dismiss } from '@vkontakte/icons';
import { Button, FormItem, FormLayout, Group, Input, ModalPage, ModalPageHeader, PanelHeaderBack, PanelHeaderButton, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { Api, ComponentHelper, Principal, StateManager, useLocale, useStore } from '../../../shared/_index';
import { ConfirmRegistrationCodeL } from './ConfirmRegistrationCode.L';


const ConfirmRegistrationCode = (props: { id: string, dynamicContentHeight: boolean }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let locale: ConfirmRegistrationCodeL = useLocale(ConfirmRegistrationCodeL);
	let router = useRouter();
	let location = useLocation();

	const name = StateManager.getStateValue(location, 'name');
	const email = StateManager.getStateValue(location, 'email');
	const password = StateManager.getStateValue(location, 'password');
	const promoCode = StateManager.getStateValue(location, 'promoCode');
	const partnerId = StateManager.getStateValue(location, 'partnerId');
	const confirmationOperationId = StateManager.getStateValue(location, 'confirmationOperationId');

	const [confirmationCode, setConfirmationCode] = useState('');
	const [isConfirmationCodeValid, setIsConfirmationCodeValid] = useState(true);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showErrors, setShowErrors] = useState(false);

	const { principalStore, routeStore } = useStore();

	useEffect(() => {
		ComponentHelper.focusModalInput(props.id);
	}, [props.id]);


	if (!name || !email || !password) {
		router.replaceModal(routeStore.Modals.Register);
		return <></>;
	}


	const doConfirmRegistration = () => {
		if (!confirmationCode) {
			setShowErrors(true);
			return;
		}

		setIsSubmitting(true);

		Api.executeMethod('principal.confirmRegistration', {
			name: name,
			email: email,
			password: password,
			promoCode: promoCode,
			operationId: confirmationOperationId,
			code: confirmationCode,
			partnerId: partnerId
		}).then((response: any) => {
			let isCodeValid: boolean = response.IsCodeValid;
			setIsConfirmationCodeValid(isCodeValid);

			if (!isCodeValid) {
				setShowErrors(true);
				setIsSubmitting(false);

				return;
			}

			let principal: Principal = response.Principal;

			if (principal) {
				principalStore.loginAs(principal);
				router.replacePage(routeStore.Pages.SwitchTo.Route, { userId: '0' });
			}
		});
	}

	return <ModalPage
		id={props.id}
		dynamicContentHeight={true}
		header={
			<ModalPageHeader
				before={
					<PanelHeaderBack label="" onClick={() => router.popPage()} />
				}
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => router.popPage()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{/* Подтверждение почты */}
				{locale.confirmEmailHeader}
			</ModalPageHeader>
		}
	>
		<Group>
			<FormLayout>
				<FormItem
					// Введите код, который мы отправили на [email]
					top={locale.emailSentHint.replace('[email]', email)}
					status={(!confirmationCode || !isConfirmationCodeValid) && showErrors ? "error" : "default"}
					bottom={
						!confirmationCode && showErrors
							// Пожалуйста, введите код
							? locale.noCodeError
							: (!isConfirmationCodeValid && showErrors
								// Код введен неверно или истек срок его действия
								? locale.codeExpiredError
								: null)
					}
				>
					<Input
						type="text"
						name="confirmationCode"
						crossOrigin={"anonymous"}
						className='to-focus'
						value={confirmationCode}
						onKeyPress={(e) => ComponentHelper.onEnterKeyPress(e, () => doConfirmRegistration())}
						onChange={e => setConfirmationCode(e.target.value)}
					/>
				</FormItem>

				<FormItem>
					<Button
						size="l"
						stretched
						onClick={() => doConfirmRegistration()}
						loading={isSubmitting}>
						{/* Создать аккаунт */}
						{locale.createAccount}
					</Button>
				</FormItem>
			</FormLayout>
		</Group>
	</ModalPage>;
}

export default React.memo(ConfirmRegistrationCode);