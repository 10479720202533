import { PublishEventPublishResult } from './PublishEventData';
import { PostToPublish } from './PostToPublish';
import { OEmbedVideoData } from './OEmbedVideoData';
import { Vk } from './social/_index';
import { LinkWithMetaTags } from './LinkWithMetaTags';
import { DomainUtils, HtmlUtils } from '../_index';

/** Пост для публикации. */
export interface ProjectItem extends FeedItem {
  /** Идентификатор созданного вручную поста (если пост был добавлен пользователем вручную). */
  UserPostId?: string,

  /** Идентификатор канала, из которого был импортирован пост (если пост был импортирован из канала). */
  FeedId?: number,

  /** Имя канала, из которого был импортирован пост (если пост был импортирован из канала). */
  FeedName?: string,

  /** Адрес канала, из которого был импортирован пост (если пост был импортирован из канала). */
  FeedSiteUrl?: string,

  /** Иконка канала, из которого был импортирован пост (если пост был импортирован из канала). */
  FeedFaviconUrl?: string,

  /** Измененная дата создания поста (если пост был перемещен в списке с помощью смены даты). */
  OverridenCreatedDate?: number,

  /** [enum=ProjectItem.PublishState] Статус публикации записи (опубликован с ошибками или успешно или не опубликован) */
  PublishState: string,

  /** Дата и время публикации поста (если пост уже опубликован) */
  PublishDate?: number,

  /** Дата и время, на которое запланирована публикация поста (если пост запланирован к отправке на конкретное время). */
  ScheduledPublishDate?: number,

  /** Ожидаемые дата и время публикации с учетом всех настроек. */
  ExpectedPublishDate?: number,

  /** Идентификатор поста в очереди на отправку (если пост запланирован к отправке на конкретное время) */
  ScheduledPublishQueueItemUid?: string,

  /** [enum=ProjectItem.DeleteState] Статус удаления поста из соц сетей после публикации (удален успешно или с ошибкой или не удален) */
  DeleteState: string,

  /** Через сколько минут после публикации нужно будет удалить запись.  */
  DeleteInterval?: number,

  /** В какое конкретно время нужно будет удалить запись (поле заполнено, если запись уже опубликована). */
  ScheduledDeleteDate?: number,

  /** Показывает, что запись удалена из проекта. */
  IsDeletedFromProject?: boolean,

  /** Поле для сортировки записей. */
  SortIndex: number,

  /** Домен ссылки, прикрепленной к записи. */
  LinkUrlHost?: string,

  /** Поле, показывающее, что запись не нужно удалять, даже если канал будет отключен от сервиса. */
  KeepDuringFeedUpdate?: boolean,

  /** Идентификатор сотрудника, создавшего запись (если запись была добавлена в сервис вручную). */
  TeamMemberId?: number,

  /** Имя сотрудника, создавшего запись (если запись была добавлена в сервис вручную). */
  TeamMemberName?: string,

  /** Ссылка на первое изображение. */
  FirstImageUrl?: string,

  /** Уникальный ключ события публикации (если запись публиковалась). */
  EventRowKey?: string,

  /** Результаты публикации записи (если запись публиковалась). */
  PublishResults?: PublishEventPublishResult[],

  /** [enum=ProjectItem.SaveState] Статус сохранения редактированной записи (сохранена успешно или с ошибкой) */
  SaveState?: string,

  /** Значение, показывающее, не находится ли эта запись на этапе редактирования. */
  IsEditing?: boolean,

  ProjectItemToEdit?: ProjectItem.ToEdit,

  IsNewItem?: boolean,

  IsBulkMessage?: boolean
}

export abstract class ProjectItem {
  public static AddPostToPublish(projectItem: ProjectItem, postToPublish: PostToPublish) {
    var postsToPublishAttachment = projectItem.Attachments.filter(s => s.Type === ProjectItem.AttachmentTypes.SocialPostsToPublish)[0];

    if (!postsToPublishAttachment) {
      postsToPublishAttachment = {
        Type: ProjectItem.AttachmentTypes.SocialPostsToPublish,
        SocialPostsToPublish: {
          PostsToPublish: [
          ]
        }
      };

      projectItem.Attachments.push(postsToPublishAttachment);
    }

    let socialPostsToPublish = postsToPublishAttachment.SocialPostsToPublish as any;

    socialPostsToPublish.PostsToPublish
      = socialPostsToPublish.PostsToPublish
        .filter(s => s.SocialNetwork !== postToPublish.SocialNetwork);

    socialPostsToPublish.PostsToPublish.push(postToPublish);
  }
}

export interface OpenGraphData {
  LinkUrl?: string,
  LinkUrlHost?: string,

  HtmlTitle?: string,

  OgLocale?: string,
  OgType?: string,
  OgUrl?: string,
  OgSiteName?: string,

  ArticleSection?: string,
  ArticlePublishedTime?: string,

  TwitterCard?: string,
  TwitterUrl?: string,
  TwitterImage?: string,
  TwitterSite?: string,
  TwitterTitle?: string,
  TwitterDescription?: string,

  Title?: string,
  OgTitle?: string,

  Description?: string,
  OgDescription?: string,

  OgImage?: string,
  OgImageWidth?: string,
  OgImageHeight?: string,

  ExternalVideo?: OEmbedVideoData,
}

export abstract class OpenGraphData {
  public static FromLinkWithMeta(link: LinkWithMetaTags): OpenGraphData {
    if (!link.MetaTags) {
      return null as any;
    }

    let openGraphData: OpenGraphData = {
      LinkUrl: link.LinkUrl,
      LinkUrlHost: DomainUtils.extractDomain(link.LinkUrl)
    };

    for (let metaTag of link.MetaTags) {
      if (metaTag.Name === LinkWithMetaTags.MetaTagType.HtmlTitle && metaTag.Value && metaTag.Value.length) {
        openGraphData.HtmlTitle = HtmlUtils.decodeHtml(metaTag.Value);
      }

      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.OgLocale && metaTag.Value && metaTag.Value.length) {
        openGraphData.OgLocale = HtmlUtils.decodeHtml(metaTag.Value);
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.OgType && metaTag.Value && metaTag.Value.length) {
        openGraphData.OgType = HtmlUtils.decodeHtml(metaTag.Value);
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.OgUrl && metaTag.Value && metaTag.Value.length) {
        openGraphData.OgUrl = metaTag.Value;
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.OgSiteName && metaTag.Value && metaTag.Value.length) {
        openGraphData.OgSiteName = HtmlUtils.decodeHtml(metaTag.Value);
      }

      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.ArticleSection && metaTag.Value && metaTag.Value.length) {
        openGraphData.ArticleSection = metaTag.Value;
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.ArticlePublishedTime && metaTag.Value && metaTag.Value.length) {
        openGraphData.ArticlePublishedTime = metaTag.Value;
      }

      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.TwitterCard && metaTag.Value && metaTag.Value.length) {
        openGraphData.TwitterCard = metaTag.Value;
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.TwitterUrl && metaTag.Value && metaTag.Value.length) {
        openGraphData.TwitterUrl = metaTag.Value;
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.TwitterImage && metaTag.Value && metaTag.Value.length) {
        openGraphData.TwitterImage = metaTag.Value;
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.TwitterSite && metaTag.Value && metaTag.Value.length) {
        openGraphData.TwitterSite = metaTag.Value;
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.TwitterTitle && metaTag.Value && metaTag.Value.length) {
        openGraphData.TwitterTitle = HtmlUtils.decodeHtml(metaTag.Value);
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.TwitterDescription && metaTag.Value && metaTag.Value.length) {
        openGraphData.TwitterDescription = HtmlUtils.decodeHtml(metaTag.Value);
      }

      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.Title && metaTag.Value && metaTag.Value.length) {
        openGraphData.Title = HtmlUtils.decodeHtml(metaTag.Value);
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.OgTitle && metaTag.Value && metaTag.Value.length) {
        openGraphData.OgTitle = HtmlUtils.decodeHtml(metaTag.Value);
      }

      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.Description && metaTag.Value && metaTag.Value.length) {
        openGraphData.Description = HtmlUtils.decodeHtml(metaTag.Value);
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.OgDescription && metaTag.Value && metaTag.Value.length) {
        openGraphData.OgDescription = HtmlUtils.decodeHtml(metaTag.Value);
      }

      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.OgImage && metaTag.Value && metaTag.Value.length) {
        openGraphData.OgImage = metaTag.Value;
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.OgImageWidth && metaTag.Value && metaTag.Value.length) {
        openGraphData.OgImageWidth = metaTag.Value;
      }
      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.OgImageHeight && metaTag.Value && metaTag.Value.length) {
        openGraphData.OgImageHeight = metaTag.Value;
      }

      else if (metaTag.Name === LinkWithMetaTags.MetaTagType.ExternalVideo && metaTag.Value && metaTag.Value.length) {
        try {
          openGraphData.ExternalVideo = JSON.parse(metaTag.Value);
        }
        catch { }
      }
    }

    return openGraphData;
  }

  public static FromProjectItem(projectItem: ProjectItem): OpenGraphData[] {
    var items: OpenGraphData[] = [];

    if (projectItem) {
      var openGraphAttachment = projectItem.Attachments.filter(s => s.Type === ProjectItem.AttachmentTypes.LinkMetaTags)[0];

      if (openGraphAttachment
        && openGraphAttachment.LinkMetaTags
        && openGraphAttachment.LinkMetaTags.Links
        && openGraphAttachment.LinkMetaTags.Links.length) {
        for (let link of openGraphAttachment.LinkMetaTags.Links) {
          let item = OpenGraphData.FromLinkWithMeta(link);

          if (item) {
            items.push(item);
          }
        }
      }
    }

    return items;
  }
}

// eslint-disable-next-line
export namespace ProjectItem {
  export interface ToEdit {
    /** Заголовок поста */
    Title: string,

    /** Текст поста */
    Summary: string,

    /** Прикрепленная ссылка */
    LinkUrl: string,

    LinkUrlHost: string

    ImagesOrVideos: ProjectItem.Attachment[],

    VkAudios: VkAudioAttachment[],

    VkDocuments: VkDocumentAttachment2[],

    Poll: PollAttachment,

    Location: LocationAttachment,

    IsOkPromo: boolean,

    IsVkAds: boolean,

    IsBulkSaving?: boolean,

    SpecificGroupsToPublish?: SpecificGroupsToPublishAttachmentItem[],

    /** Обьект с оригинальными значениями полей из RSS (если обьект был импортирован из RSS).  */
    SyndicationData?: SyndicationDataAttachment,

    IsNewItem: boolean,

    IsBulkMessage?: boolean,

    /** Ожидаемые дата и время публикации с учетом всех настроек. */
    ExpectedPublishDate?: number,

    /** [enum=ProjectItem.PublishState] Статус публикации записи (опубликован с ошибками или успешно или не опубликован) */
    PublishState: string,

    /** Дата и время, на которое запланирована публикация поста (если пост запланирован к отправке на конкретное время). */
    ScheduledPublishDate?: number,

    /** [enum=ProjectItem.DeleteState] Статус удаления поста из соц сетей после публикации (удален успешно или с ошибкой или не удален) */
    DeleteState: string,

    /** Через сколько минут после публикации нужно будет удалить запись.  */
    DeleteInterval?: number,

    /** В какое конкретно время нужно будет удалить запись (поле заполнено, если запись уже опубликована). */
    ScheduledDeleteDate?: number,
  }

  /** Вложение, которое прикреплено к посту (фото, аудио, видео) */
  export interface Attachment {
    /** [enum=ProjectItem.AttachmentType] Тип вложения (фото, аудио, видео) */
    Type: string,

    /** Прикрепленное к посту изображение. */
    Image?: ImageAttachment,

    /** Прикрепленная к посту аудиозапись Вконтакте. */
    VkAudio?: VkAudioAttachment,

    /** Прикрепленная к посту видеозапись Вконтакте. */
    VkVideo?: VkVideoAttachment,

    /** Прикрепленная к посту видеозапись Вконтакте. */
    VkDocument?: VkDocumentAttachment,

    /** Прикрепленный к посту опрос. */
    Poll?: PollAttachment,

    /** Обьект с оригинальными значениями полей из RSS (если обьект был импортирован из RSS).  */
    SyndicationData?: SyndicationDataAttachment,

    /** Прикрепленное к посту видео Youtube/Rutube/Vimeo/Dailymotion/Coub. */
    ExternalVideo?: ExternalVideoAttachment,

    /** Список групп для публикации (если нужно опубликовать не во все группы проекта). */
    SpecificGroupsToPublish?: SpecificGroupsToPublishAttachment,

    /** Прикрепленный к посту видеофайл. */
    VideoFile?: VideoFileAttachment,

    /** Пометка "ПРОМО" при публикации в Одноклассники. */
    OdnoklassnikiPromo?: OdnoklassnikiPromoAttachment,

    /** Пометка "Реклама" при публикации в Вконтакте. */
    VkontakteAds?: VkontakteAdsAttachment,

    // Прикрепленная к посту геопозиция определенного места
    Location?: LocationAttachment,

    SocialPostsToPublish?: SocialPostsToPublishAttachment,

    LinkMetaTags?: LinkMetaTagsAttachment
  }

  /** Тип вложения, которое прикреплено к посту (фото, аудио, видео) */
  export enum AttachmentType {
    /** Тип вложения неизвестен. */
    Unknown = 0,

    /** Прикрепленное к посту изображение. */
    Image = 1,

    /** Прикрепленная к посту аудиозапись Вконтакте. */
    VkAudio = 2,

    /** Прикрепленная к посту видеозапись Вконтакте. */
    VkVideo = 3,

    /** Прикрепленный к посту опрос. */
    Poll = 4,

    /** Обьект с оригинальными значениями полей из RSS (если обьект был импортирован из RSS). */
    SyndicationData = 5,

    /** Прикрепленное к посту видео Youtube/Rutube/Vimeo/Dailymotion/Coub. */
    ExternalVideo = 6,

    /** Пометка "ПРОМО" при публикации в Одноклассники. */
    OdnoklassnikiPromo = 7,

    /** Список групп для публикации (если нужно опубликовать не во все группы проекта).  */
    SpecificGroupsToPublish = 8,

    /** Пометка "Реклама" при публикации в Вконтакте. */
    VkontakteAds = 9,

    /**  */
    VideoFile = 10,

    // Прикрепленная к посту геопозиция определенного места
    Location = 11,

    /** Точно заданный вид поста для каждой соц сети. */
    SocialPostsToPublish = 12,

    LinkMetaTags = 13
  }

  export abstract class AttachmentTypes {
    /** Тип вложения неизвестен. */
    public static Unknown: string = 'Unknown';

    /** Прикрепленное к посту изображение. */
    public static Image: string = 'Image';

    /** Прикрепленная к посту аудиозапись Вконтакте. */
    public static VkAudio: string = 'VkAudio';

    /** Прикрепленная к посту видеозапись Вконтакте. */
    public static VkVideo: string = 'VkVideo';

    /** Прикрепленный к посту опрос. */
    public static Poll: string = 'Poll';

    /** Обьект с оригинальными значениями полей из RSS (если обьект был импортирован из RSS). */
    public static SyndicationData: string = 'SyndicationData';

    /** Прикрепленное к посту видео Youtube/Rutube/Vimeo/Dailymotion/Coub. */
    public static ExternalVideo: string = 'ExternalVideo';

    /** Пометка "ПРОМО" при публикации в Одноклассники. */
    public static OdnoklassnikiPromo: string = 'OdnoklassnikiPromo';

    /** Список групп для публикации (если нужно опубликовать не во все группы проекта).  */
    public static SpecificGroupsToPublish: string = 'SpecificGroupsToPublish';

    /** Пометка "Реклама" при публикации в Вконтакте. */
    public static VkontakteAds: string = 'VkontakteAds';

    /**  */
    public static VideoFile: string = 'VideoFile';

    // Прикрепленная к посту геопозиция определенного места
    public static Location: string = 'Location';

    /** Точно заданный вид поста для каждой соц сети. */
    public static SocialPostsToPublish: string = 'SocialPostsToPublish';

    public static LinkMetaTags: string = 'LinkMetaTags';

    public static VkDocument: string = 'VkDocument';
  }

  /** Статус сохранения редактированной записи (сохранена успешно или с ошибкой) */
  export enum SaveState {
    IsSaved = 0,
    IsFailed = 1,
    IsSaving = 2
  }

  /** Статус сохранения редактированной записи (сохранена успешно или с ошибкой) */
  export abstract class SaveStates {
    public static IsSaved = "IsSaved";
    public static IsFailed = "IsFailed";
    public static IsSaving = "IsSaving";
  }

  /** Статус удаления поста из соц сетей после публикации (удален успешно или с ошибкой или не удален) */
  export enum DeleteState {
    /** Автоудаление поста отключено. */
    DeleteDisabled = 0,

    /** Пост запланирован к удалению. */
    DeleteScheduled = 1,

    /** Пост в процессе удаления. */
    IsDeleting = 2,

    /** Пост удален. */
    Deleted = 3,
  }

  /** Статус удаления поста из соц сетей после публикации (удален успешно или с ошибкой или не удален) */
  export abstract class DeleteStates {
    /** Автоудаление поста отключено. */
    public static DeleteDisabled = 'DeleteDisabled';

    /** Пост запланирован к удалению. */
    public static DeleteScheduled = 'DeleteScheduled';

    /** Пост в процессе удаления. */
    public static IsDeleting = 'IsDeleting';

    /** Пост удален. */
    public static Deleted = 'Deleted';
  }


  /** Статус публикации записи (опубликован с ошибками или успешно или не опубликован) */
  export enum PublishState {
    /** Пост еще не опубликован */
    NotSent = -1,

    /** Пост уже опубликован */
    Sent = 0,

    /** Пост отправлен в архив */
    Archived = 1,

    /** Публикация поста прошла с ошибкой */
    Failed = 2,

    /** Пост запланирован к отправке на конкретное время */
    ExactTimePlanned = 3,

    /** Пост в процессе отправки */
    IsSending = 4
  }

  /** Статус публикации записи (опубликован с ошибками или успешно или не опубликован) */
  export abstract class PublishStates {
    /** Пост еще не опубликован */
    public static NotSent = 'NotSent';

    /** Пост уже опубликован */
    public static Sent = 'Sent';

    /** Пост отправлен в архив */
    public static Archived = 'Archived';

    /** Публикация поста прошла с ошибкой */
    public static Failed = 'Failed';

    /** Пост запланирован к отправке на конкретное время */
    public static ExactTimePlanned = 'ExactTimePlanned';

    /** Пост в процессе отправки */
    public static IsSending = 'IsSending';

    /** Пост запланирован к отправке после сохранения */
    public static PublishNow = 'PublishNow';
  }

  /** Прикрепленное к посту изображение. */
  export interface ImageAttachment {
    /** Ссылка на исходное изображение */
    ImageUrl: string,

    /** Информаци об изображении */
    ImageInfo?: ImageInfo,

    /** Оптимизированное изображение */
    OptimizedImageInfo?: ImageInfo,

    /** Миниатюры */
    Thumbnails?: ImageInfo[],

    IsUploading?: boolean,

    UploadingImage?: any,

    UploadingByUrl?: { SourceUrl: string },

    EditorData?: PostToPublish.ImageFileAttachment.EditorData
  }

  export abstract class ImageAttachment {
    public static parseFrom(from: PostToPublish.ImageFileAttachment): ProjectItem.ImageAttachment {
      return {
        ImageUrl: from.ImageUrl as any,
        ImageInfo: from.ImageInfo,
        Thumbnails: from.Thumbnails,
        OptimizedImageInfo: from.OptimizedImageInfo,

        EditorData: from.EditorData
      };
    }
  }


  /** Прикрепленная к посту видеозапись Вконтакте */
  export interface VkVideoAttachment {
    /** Идентификатор видеозаписи Вконтакте */
    VideoId: string,

    /** Название видеозаписи */
    VideoName: string,

    /** Ссылка на миниатюру видеозаписи */
    VideoPreviewUrl: string,

    /** Ссылка на видеоплеер для встраивания */
    VideoPlayerUrl: string
  }



  export abstract class VkVideoAttachment {
    public static parseFrom(from: PostToPublish.VkVideoAttachment): ProjectItem.VkVideoAttachment {
      return {
        VideoId: `video${from.VideoId}_${from.OwnerId}`,
        VideoName: from.Name,
        VideoPreviewUrl: from.VideoPreviewUrl,
        VideoPlayerUrl: from.VideoPlayerUrl
      }
    }

    public static isExternalVideo(videoAttachment: VkVideoAttachment): boolean {
      return videoAttachment.VideoPlayerUrl.indexOf('youtube.com/') !== -1
        || videoAttachment.VideoPlayerUrl.indexOf('rutube.ru/') !== -1
        || videoAttachment.VideoPlayerUrl.indexOf('coub.com/') !== -1
        || videoAttachment.VideoPlayerUrl.indexOf('dailymotion.com/') !== -1
        || videoAttachment.VideoPlayerUrl.indexOf('vimeo.com/') !== -1;
    }
  }


  /** Прикрепленный к сервису опрос */
  export interface PollAttachment {
    /** Вопрос */
    Title: string,

    /** Варианты ответа */
    Answers: { Text: string }[]
  }

  /** Прикрепленная к сервису геометка */
  export interface LocationAttachment {
    FacebookPlace: FacebookPlace,
    InstagramPlace: InstagramPlace
  }

  export interface SocialPostsToPublishAttachment {
    PostsToPublish: PostToPublish[]
  }

  export interface LinkMetaTagsAttachment {
    Links: LinkWithMetaTags[]
  }

  /** Обьект с оригинальными значениями полей из RSS (если обьект был импортирован из RSS). */
  export interface SyndicationDataAttachment {
    /** Оригинальное поле <guid> из RSS */
    Id: string,

    /** Оригинальное поле <title> из RSS */
    Title: string,

    /** Оригинальное поле <description> из RSS */
    Description: string,

    /** Оригинальное поле <link> из RSS */
    LinkUrl: string,

    /** Оригинальное поле <content:encoded> из RSS */
    ContentEncoded: string,

    TurboContent: string,

    YandexFullText: string,

    /** Оригинальное поле <pubDate> из RSS */
    PublishDate?: number
  }

  /** Прикрепленное к посту видео Youtube/Rutube/Vimeo/Dailymotion/Coub. */
  export interface ExternalVideoAttachment {
    /** Обьект с данными видео, полученный от видеохостинга по протоколу oembed */
    VideoData: OEmbedVideoData
  }

  /** Список групп для публикации (если нужно опубликовать не во все группы проекта).  */
  export interface SpecificGroupsToPublishAttachment {
    /** Выбранные для публикации группы (если нужно опубликовать не во все группы проекта) */
    SelectedGroups: SpecificGroupsToPublishAttachmentItem[]
  }

  /** Выбранная для публикации группа (если нужно опубликовать не во все группы проекта) */
  export interface SpecificGroupsToPublishAttachmentItem {
    /** Идентификатор выбранной для публикации группы в соц сетях */
    SocialPageId: string,

    /** Название выбранной для публикации группы в соц сетях */
    SocialPageName: string,

    /** [enum=SocialNetwork] Социальная сеть, к которой относится выбранная для публикации группа в соц сетях */
    SocialNetwork: string,

    /** Адрес выбранной для публикации группы в соц сетях */
    SocialPageUrl: string
  }

  /** Прикрепленный к посту видеофайл */
  export interface VideoFileAttachment {
    /** Уникальный идентификатор данного видео в сервисе */
    UserVideoId: string,

    /** Ключ поля в таблице видеозаписей пользователя.  */
    UserVideoRowKey: string,

    /** Значение, показывающее, конвертируется ли еще видео, или уже сконвертировано */
    IsConverting: boolean,

    /** Значение, показывающее, не столкнулись ли мы в процессе конвертирования этого видео с ошибкой. */
    HasConvertingError: boolean,

    /** Название данного видео */
    Name: string,

    /** Значение, показывающее, не является ли данное видео gif-файлом */
    IsGifFile: boolean,

    /** Миниатюра данного видео. */
    Thumbnail: FileInfo,

    /** Файл mp4 данного видео для просмотра в браузере. */
    Mp4File: FileInfo,

    /** Файл webm данного видео для просмотра в браузере. */
    WebMFile: FileInfo,

    /** Оригинальный файл видео в облаке */
    OriginalFile: FileInfo,

    /** Файл mp4 данного видео для публикации в Instagram. */
    Mp4FileInstagram: FileInfo,

    /** Информация об этой видеозаписи */
    VideoInfo: VideoInfo,

    UploadingState: VideoFileUploadingStateObject
  }

  /** Пометка "ПРОМО" при публикации в Одноклассники. */
  export interface OdnoklassnikiPromoAttachment {

  }

  /** Пометка "Реклама" при публикации в Вконтакте. */
  export interface VkontakteAdsAttachment {

  }

  export interface VkDocumentAttachment {
    Document: Vk.Document
  }

  /** Прикрепленный к посту документ Вконтакте */
  export interface VkDocumentAttachment2 {
    /** Идентификатор документа Вконтакте */
    DocumentId: string,

    /** Идентификатор владельца Вконтакте */
    OwnerId: string,

    /** Название документа */
    DocumentName: string,

    /** Ссылка на миниатюру документа */
    DocumentPreviewUrl: string
  }

  /** Прикрепленная к посту аудиозапись Вконтакте */
  export interface VkAudioAttachment {
    /** Идентификатор аудиозаписи в системе Вконтакте */
    AudioId: string,

    /** Идентификатор владельца Вконтакте */
    OwnerId: string,

    /** Название аудиозаписи */
    AudioName: string
  }
}



/** Пост (базовый класс, без привязки к проекту) */
export interface FeedItem {
  /** Заголовок поста */
  Title: string,

  /** Текст поста */
  Summary: string,

  /** Прикрепленная ссылка */
  LinkUrl: string,

  /** Дата создания поста. */
  CreatedDate?: number,

  /** Дата скачивания поста в сервис. */
  DownloadedDate?: number,

  /** Строка, уникально идентифицирующая данный пост (чтобы случайно не опубликовать его повторно). */
  Hash?: string,

  /** Что прикреплено к посту (фото, аудио, видео) */
  Attachments: ProjectItem.Attachment[]
}



export interface VkAudio {
  Artist: string,
  CreatedAt: string,
  Duration: number,
  Id: number,
  IsContentRestricted: boolean,
  IsHQ: boolean,
  OwnerId: number,
  Title: string
}


export interface VkVideo {
  Id: string,
  OwnerId: string,
  Title: string,
  Link: string,
  Image: string,
  DurationMinutes: number,
  PlayerUrl: string
}

export interface FacebookPlace {
  name: string,
  checkins: number,
  location: FacebookLocation,
  id: number
}

export interface FacebookLocation {
  city: string,
  country: string,
  latitude: number,
  longitude: number,
  street: string,
  zip: string
}

export interface InstagramPlace {
  lat: number,
  lng: number,
  address: string,
  external_id: string,
  external_id_source: string,
  name: string,
  minimum_age: number
}


/** Информация об изображении */
export interface ImageInfo {
  /** Название файла изображения */
  Name: string,

  /** Ссылка на изображение */
  Url: string,

  /** Ширина изображения в пикселях */
  Width: number,

  /** Высота изображения в пикселях */
  Height: number,

  /** [enum=ImageCodecType] Формат изображения (jpeg/gif/png) */
  CodecType: string,

  /** Анимированное ли это изображение */
  IsAnimated: boolean,

  /** Размер файла изображения в байтах */
  FileSize: number
}

/** Формат изображения (jpeg/gif/png) */
export enum ImageCodecType {
  /** Формат изображения неизвестен */
  Unknown = 0,

  /** Это изображение в формате jpeg */
  Jpeg = 1,

  /** Это изображение в формате png */
  Png = 2,

  /** Это изображение в формате gif */
  Gif = 3,

  /** Это изображение в формате exif */
  Exif = 4,

  /** Это изображение в формате ico */
  Icon = 5,

  /** Это изображение в формате tiff */
  Tiff = 6,

  /** Это изображение в формате bmp */
  Bmp = 7,

  /** Это изображение в формате wmf */
  Wmf = 8,

  /** Это изображение в формате emf */
  Emf = 9
}


export interface VideoFileUploadingStateObject {
  Status: string,
  ProgressPercent: number,
  TotalProgress: number,
  ThumbnailUrl: string
}

/** Обьект с общей информацией о хранящемся в облаке файле. */
export interface FileInfo {
  /** Имя файла */
  Name: string,

  /** Размер файла в байтах */
  Size: string,

  /** Ссылка на скачивание этого файла */
  Url: string
}

/** Информация о видеозаписи */
export interface VideoInfo {
  /** Формат видео (короткая запись) */
  FormatName: string,

  /** Формат видео (длинная запись) */
  FormatLongName: string,

  /** Кодек видео (короткая запись) */
  VideoCodecName: string,

  /** Кодек видео (длинная запись) */
  VideoCodecLongName: string,

  /** Кодек аудио (короткая запись) */
  AudioCodecName: string,

  /** Кодек аудио (длинная запись) */
  AudioCodecLongName: string,

  /** Высота видео в пикселях */
  Height: number,

  /** Ширина видео в пикселях */
  Width: number,

  /** Кадров в секунду */
  FrameRate: number,

  /** Длина видео в миллисекундах */
  Duration: number
}

