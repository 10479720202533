import { Subscription } from "rxjs";

export class ComponentHelper {
    public static focusModalInput(id: string) {
        setTimeout(() => {
            let firstInput = document.querySelector(`#${id} .to-focus>input`) as any;

            if (firstInput) {
                firstInput.focus();
            }
        }, 200);
    }

    public static onInputChange(component: any, e: any) {
        const { name, value } = e.currentTarget;
        component.setState({ [name]: value });
    }

    public static onEnterKeyPress(event: any, doSubmit: () => void) {
        if (event.key === 'Enter') {
            doSubmit();
        }
    }

    public static unsubscribeAll(component: { subscriptions: Subscription[] }) {
        for (let subscription of component.subscriptions) {
            subscription.unsubscribe();
        }

        component.subscriptions = [];
    }

    public static isMobile(): boolean {
        return window.innerWidth < 768;
    }
}