import React from 'react';
import { observer } from "mobx-react-lite"

import './UserPostsHeader.css';

import { ProjectDataShort, useLocale, UserPage, useStore } from '../../../../../../shared/_index';
import { UserPostsHeaderL } from './UserPostsHeader.L';
import { FixedLayout } from '@vkontakte/vkui';
import CalendarSlider from '../CalendarSlider/CalendarSlider';
import { Icon28ChevronDownOutline } from '@vkontakte/icons';
import { useRouter } from '@happysanta/router';
import { classNamesString } from '@vkontakte/vkui/dist/lib/classNames';


export interface UserPostsHeaderProps {
	activeTab: string,

	project?: ProjectDataShort,
	page?: UserPage,

	selectedDay?: number,
	onDaySelected: (day: number) => void
}

const UserPostsHeader = (props: UserPostsHeaderProps) => {
	let locale: UserPostsHeaderL = useLocale(UserPostsHeaderL);

	let router = useRouter();

	const { routeStore } = useStore();

	const showPostsContextModal = () => {
		router.pushModal(routeStore.Modals.SelectPostsContext);
	};

	return <FixedLayout vertical="top">
		<div
			className={classNamesString('userPostsHeader__header', {
				'userPostsHeader__header_withContext': (props.project || props.page ? true : false)
			})}
			onClick={() => showPostsContextModal()}
		>

			{!!props.project && <>
				<span className='userPostsHeader__context'>
					{props.project.Name}
				</span>
				<span className='userPostsHeader__separator'>/</span>
			</>}

			{!!props.page && <>
				<span className='userPostsHeader__context'>
					{props.page.SourcePage.Name}
				</span>

				<span className='userPostsHeader__separator'>/</span>
			</>}

			{props.activeTab === 'calendar' && <>
				{/* Календарь */}
				{locale.calendarHeader}
			</>}

			{props.activeTab === 'draft' && <>
				{/* Черновики */}
				{locale.draftHeader}
			</>}

			{props.activeTab === 'deleted' && <>
				{/* Корзина */}
				{locale.trashHeader}
			</>}

			<Icon28ChevronDownOutline
				width={18}
				height={18}
				className='userPostsHeader__chevron'
			/>
		</div>

		{props.activeTab === "calendar" && <CalendarSlider
			selectedDay={props.selectedDay}
			onDaySelected={(value) => props.onDaySelected(value)} />}
	</FixedLayout>;
}

export default React.memo(UserPostsHeader);