import { makeAutoObservable } from 'mobx';
import { Store } from './Store';
import { Cookies } from '../_index';

import * as moment from 'moment';
import 'moment/locale/ru';

export default class LanguageStore {
    public language: string;

    public use24TimeFormat: boolean = false;
    
    public readonly rootStore: Store;

    constructor(rootStore: Store) {
        this.rootStore = rootStore;

        this.language = this.getSelectedLanguage() ?? this.getDefaultLanguage();

        moment.updateLocale('ru', {
            months: {
                format: 'января_февраля_марта_апреля_мая_июня_июля_августа_сентября_октября_ноября_декабря'.split('_'),
                standalone: 'январь_февраль_март_апрель_май_июнь_июль_август_сентябрь_октябрь_ноябрь_декабрь'.split('_')
            },
            monthsShort: {
                format: 'янв_фев_мар_апр_мая_июн_июл_авг_сент_окт_ноя_дек'.split('_'),
                standalone: 'янв_фев_мар_апр_май_июн_июл_авг_сен_окт_ноя_дек'.split('_')
            },
            weekdays: {
                standalone: 'воскресенье_понедельник_вторник_среда_четверг_пятница_суббота'.split('_'),
                format: 'воскресенье_понедельник_вторник_среду_четверг_пятницу_субботу'.split('_'),
                isFormat: /\[ ?[Вв] ?(?:прошлую|следующую|эту)? ?\] ?dddd/
            },
            weekdaysShort: 'вс_пн_вт_ср_чт_пт_сб'.split('_'),
            weekdaysMin: 'вс_пн_вт_ср_чт_пт_сб'.split('_')
        });

        makeAutoObservable(this);
    }

    public getSelectedLanguage(): string {
        var lang = Cookies.get('lang');

        if (!lang || lang === '') {
            return null as any;
        }

        return lang === 'ru' ? 'RU' : 'EN'
    }

    public getDefaultLanguage(): string {
        return Cookies.get('accept-lang') === 'ru'
            ? 'RU'
            : 'EN'
    }

    public setLanguage(language: string, use24TimeFormat: boolean) {
        var currentdate = new Date();

        var cookieOptions = {
            domain: window.location.hostname,
            path: '/',
            expires: new Date(currentdate.setMonth(currentdate.getMonth() + 1))
        };


        if (language === 'RU') {
            Cookies.set('lang', 'ru', cookieOptions);

            this.language = 'RU';
            moment.locale('ru');
        }
        else {
            Cookies.set('lang', 'en', cookieOptions);

            this.language = 'EN';
            moment.locale('en');
        }

        this.use24TimeFormat = use24TimeFormat;
    }
}