export interface EditSubUserL {
  back: string,
  noNameError: string,
  name: string,
  logo: string,
  selectLogo: string,
  headerAdd: string,
  headerEdit: string,
  ctaAdd: string,
  ctaEdit: string,
  newWorkspaceName: string,
  error: string
}

export abstract class EditSubUserL {
  public static RU: EditSubUserL = {
    back: 'Назад',
    noNameError: 'Пожалуйста, введите название рабочей области',
    name: 'Название',
    logo: 'Логотип',
    selectLogo: 'Выбрать лого',
    headerAdd: 'Создание рабочей области',
    headerEdit: 'Редактирование рабочей области',
    ctaAdd: 'Создать',
    ctaEdit: 'Сохранить',
    newWorkspaceName: 'Новая рабочая область',
    error: 'Не удается сохранить изменения. Попробуйте, пожалуйста, позже.'
  }


  public static EN: EditSubUserL = {
    back: 'Back',
    noNameError: 'Please enter workspace name',
    name: 'Name',
    logo: 'Logo',
    selectLogo: 'Choose logo',
    headerAdd: 'Create workspace',
    headerEdit: 'Edit workspace',
    ctaAdd: 'Create',
    ctaEdit: 'Save',
    newWorkspaceName: 'New workspace',
    error: 'Unable to save changes. Please try later.'
  }
}