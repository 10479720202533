import React, { useState } from 'react';
import { observer } from "mobx-react-lite"

import './PublishResultsListItem.css';

import { useLocale } from '../../../../../shared/_index';
import { PublishResultsListItemL } from './PublishResultsListItem.L';
import SocialIcon from '../../../../../shared/components/SocialIcon/SocialIcon';
import Moment from '../../../../../shared/components/Moment/Moment';
import { PublishResultsResponse } from '../../models/PublishResultsResponse';
import LightButton from '../../../../../shared/components/LightButton/LightButton';


export interface PublishResultsListItemProps {
	publishResult: PublishResultsResponse.PublishResult,
	isFirstPublishResult: boolean
}

const PublishResultsListItem = (props: PublishResultsListItemProps) => {
	let locale: PublishResultsListItemL = useLocale(PublishResultsListItemL);

	const [showErrors, setShowErrors] = useState(props.isFirstPublishResult);

	return <div className='publishResultsListItem'>
		<a
			className='publishResultsListItem_mainIcon'
			target="_blank"
			rel="noopener noreferrer"
			href={props.publishResult.PublishTarget.SourcePage.Url}
		>
			<SocialIcon
				corners='round'
				height={48}
				width={48}
				socialPage={props.publishResult.PublishTarget.SourcePage}
			/>
		</a>

		<a
			className='publishResultsListItem_pageTitle'
			target="_blank"
			rel="noopener noreferrer"
			href={props.publishResult.PublishTarget.SourcePage.Url}
		>
			{props.publishResult.PublishTarget.SourcePage.Name}
		</a>

		{props.publishResult.PublishState === 'Sent' &&
			<LightButton
				mode='success'
				href={props.publishResult.PostUrl}
				target='_blank'
			>
				<svg viewBox="0 0 512 512">
					<path d="m256 32c124 0 224 100 224 224c0 124-100 224-224 224c-124 0-224-100-224-224c0-124 100-224 224-224z m115 149c1-1 1-2 1-3c0-1 0-3-1-4l-31-24c-1-1-3-1-4-1c-1 0-2 0-3 1l-111 143c0 0-44-42-45-43c-1-1-4-3-6-3c-2 0-3 2-4 3l-26 26c-1 1-1 3-1 4c0 1 0 2 1 3l2 1c0 0 78 75 79 76c1 1 3 3 5 3c2 0 4-2 5-3z" />
				</svg>

				<span>
					{/* Опубликовано */}
					{locale.published}
					&nbsp;
					<Moment value={props.publishResult.PublishDate} format='datetimeAt' />
				</span>
			</LightButton>
		}

		{props.publishResult.PublishState === 'Failed' &&
			<>
				<LightButton mode='error'>
					<svg viewBox="0 0 512 512">
						<path d="m477 422l-207-349c-3-5-8-9-14-9-6 0-11 4-14 9l-207 349c-2 5-4 13-2 18 3 5 9 8 14 8l418 0c5 0 11-3 14-8 3-5 1-13-2-18z m-189-22l-64 0 0-48 64 0z m0-80l-64 0 0-144 64 0z" />
					</svg>

					<span>
						{/* Ошибка */}
						{locale.failed}
						&nbsp;
						<Moment value={props.publishResult.PublishDate} format='datetimeAt' />
					</span>
				</LightButton>

				<LightButton
					mode='default'
					hasCaretUp={showErrors}
					hasCaretDown={!showErrors}
					onClick={() => setShowErrors(!showErrors)}
				>
					<svg viewBox="0 0 512 512">
						<path d="m235 351c-21 0-38 16-38 38c0 20 17 37 38 37c21 0 38-17 38-37c0-21-17-38-38-38z m9-245c-30 0-83 14-83 26l1 5l12 30l4 5c5 0 28-15 56-15c24 0 37 11 37 31c0 25-15 33-32 39c-15 6-31 7-31 18l0 56c0 8 1 9 9 9l33 0c8 0 9-1 9-9l0-31c26-8 72-24 72-81c0-49-33-83-87-83z m2-86c-136 0-246 110-246 246c0 136 110 246 246 246c136 0 246-110 246-246c0-136-110-246-246-246z m0 456c-116 0-210-94-210-210c0-116 94-210 210-210c116 0 210 94 210 210c0 116-94 210-210 210z" />
					</svg>
				</LightButton>

				{showErrors &&
					<div className='publishResultsListItem__errorMessage'>
						{props.publishResult.ErrorMessage}
					</div>}
			</>
		}

		{props.publishResult.PublishState !== 'Failed' && props.publishResult.PublishState !== 'Sent' &&
			<LightButton mode='default'>
				<svg viewBox="0 0 512 512">
					<path d="m256 32c124 0 224 100 224 224c0 124-100 224-224 224c-124 0-224-100-224-224c0-124 100-224 224-224z m136 360c36-36 56-85 56-136c0-51-20-100-56-136c-36-36-85-56-136-56c-51 0-100 20-136 56c-36 36-56 85-56 136c0 51 20 100 56 136c36 36 85 56 136 56c51 0 100-20 136-56z m-168-120l16-176l24 0l8 160l80 96l-16 16z" />
				</svg>

				<span>
					{/* Не опубликовано */}
					{locale.notPublished}
				</span>
			</LightButton>
		}
	</div>;
}

export default React.memo(PublishResultsListItem);