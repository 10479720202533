import { autorun } from 'mobx';
import React, { createContext, useContext } from 'react';
import LanguageStore from './LanguageStore';
import PageSizeStore from './PageSizeStore';
import PrincipalStore from "./PrincipalStore";
import RouteStore from './RouteStore';
import SubUserStore from './SubUserStore';
import TimeStore from './TimeStore';

export class Store {
    pageSizeStore: PageSizeStore;
    principalStore: PrincipalStore;
    routeStore: RouteStore;
    subUserStore: SubUserStore;
    languageStore: LanguageStore;
    timeStore: TimeStore;

    constructor() {
        this.timeStore = new TimeStore(this);
        this.pageSizeStore = new PageSizeStore(this);
        
        this.routeStore = new RouteStore(this);
        this.languageStore = new LanguageStore(this);
        this.subUserStore = new SubUserStore(this);
        this.principalStore = new PrincipalStore(this);
    }
}

export const store: Store = new Store();

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext)
}