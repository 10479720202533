import React, { Profiler, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"
import { useLocation, useRouter } from '@happysanta/router';

import './UserPostsCalendar.css';

import { Api, DateUtils, PostDataShort, ProjectDataShort, useLocale, UserPage, useStore } from '../../../../../../shared/_index';
import { CardGrid, Group, Placeholder, Spinner } from '@vkontakte/vkui';
import PostCard from '../../../../../components/PostCard/PostCard';
import { Icon56NotePenOutline } from '@vkontakte/icons';
import { UserPostsCalendarL } from './UserPostsCalendar.L';
import CardGridHeader from '../../../../../components/CardGridHeader/CardGridHeader';
import { classNamesString } from '@vkontakte/vkui/dist/lib/classNames';
import ComposeButton from '../ComposeButton/ComposeButton';

interface UserPostsCalendarProps {
	day: number,

	project: ProjectDataShort,
	page: UserPage
}

const UserPostsCalendar = (props: UserPostsCalendarProps) => {
	let locale: UserPostsCalendarL = useLocale(UserPostsCalendarL);

	const getGridSize = (): "s" | "m" | "l" => {
		if (window.innerWidth > 750) {
			return 's';
		}
		else if (window.innerWidth > 500) {
			return 'm';
		}
		else {
			return 'l';
		}
	}

	let location = useLocation();
	let router = useRouter();

	const { principalStore, subUserStore, routeStore, timeStore } = useStore();

	const [isLoading, setIsLoading] = useState(true);

	const [posts, setPosts] = useState([] as PostDataShort[]);
	const [userPages, setUserPages] = useState([] as UserPage[]);

	const [arePublishedPostsShown, setArePublishedPostsShown] = useState(true);
	const [publishedPosts, setPublishedPosts] = useState([] as PostDataShort[]);
	const [publishSucceedPostsCount, setPublishSucceedPostsCount] = useState(0);
	const [publishFailedPostsCount, setPublishFailedPostsCount] = useState(0);

	const [areQueuedPostsShown, setAreQueuedPostsShown] = useState(true);
	const [queuedPosts, setQueuedPosts] = useState([] as PostDataShort[]);

	const [gridSize, setGridSize] = useState(getGridSize());

	const updateGridSize = () => {
		let currentGridSize = getGridSize();

		if (currentGridSize !== gridSize) {
			setGridSize(currentGridSize);
		}
	};

	const onPostDeleted = (projectId: number, itemHash: string) => {
		let resultQueuedPosts = queuedPosts.filter(s => !(s.ProjectId == projectId && s.ItemHash == itemHash));
		let resultPublishedPosts = publishedPosts.filter(s => !(s.ProjectId == projectId && s.ItemHash == itemHash));

		setPosts(posts.filter(s => !(s.ProjectId == projectId && s.ItemHash == itemHash)));
		setQueuedPosts(resultQueuedPosts);
		setPublishedPosts(resultPublishedPosts);
		setPublishSucceedPostsCount(resultPublishedPosts.filter(s => s.PublishState === "Sent").length);
		setPublishFailedPostsCount(resultPublishedPosts.filter(s => s.PublishState === "Failed").length);
	}


	useEffect(() => {
		if (window.location.hash.indexOf('/posts') === -1) {
			return;
		}

		principalStore.get().then(() => {
			let principal = principalStore.principal;

			let day = props.day;

			if (!day) {
				let currentTime = timeStore.currentUserTime;

				day = +DateUtils.formatDate(currentTime, 'YYYYMMDD');
			}

			let currentDay = getCurrentDay();

			if (day === currentDay) {
				setArePublishedPostsShown(false);
				setAreQueuedPostsShown(true);
			}
			else if (day < currentDay) {
				setArePublishedPostsShown(true);
			}
			else {
				setAreQueuedPostsShown(true);
			}

			loadDailyPosts(day);

			let currentParameters: { userId?: string, day?: string, projectId?: string, pageId?: string } = routeStore.Params;

			let parameters: { userId?: string, day?: string, projectId?: string, pageId?: string } = {
				userId: subUserStore.subUser.UserId.toString(),
				day: day.toString()
			};

			if (currentParameters.projectId) {
				parameters.projectId = routeStore.Params.projectId;
			}

			if (currentParameters.pageId) {
				parameters.pageId = routeStore.Params.pageId;
			}

			if (`${currentParameters.userId}` != `${parameters.userId}`
				|| `${currentParameters.day}` != `${parameters.day}`
				|| `${currentParameters.projectId}` != `${parameters.projectId}`
				|| `${currentParameters.pageId}` != `${parameters.pageId}`)
				router.pushPage(routeStore.Pages.Posts.Route, parameters);
		});

		window.addEventListener('resize', updateGridSize, true);

		return () => {
			window.removeEventListener('resize', updateGridSize, true);
		}
		// eslint-disable-next-line
	}, [props.day, props.page, props.project]);

	useEffect(() => {
		let $postChangedSub = principalStore.postsChanged.subscribe(() => refreshPosts());
		let $postDeletedSub = principalStore.postDeleted.subscribe((e) => onPostDeleted(e.projectId, e.itemHash));
		let $postEditorClosed = principalStore.postEditorClosed.subscribe(args => onPostEditorClosed(args.projectId, args.itemHash));

		return () => {
			$postChangedSub.unsubscribe();
			$postDeletedSub.unsubscribe();
			$postEditorClosed.unsubscribe();
		}
		// eslint-disable-next-line
	}, [posts, userPages])

	const getCurrentDay = (): number => {
		let currentTime = timeStore.currentUserTime;

		return +DateUtils.formatDate(currentTime, 'YYYYMMDD');
	};

	const loadDailyPosts = (day?: number): Promise<void> => {
		return new Promise<void>(resolve => {
			setIsLoading(true);

			let projectId = location.getParams().projectId
				? +location.getParams().projectId
				: 0;

			let pageId = location.getParams().pageId
				? +location.getParams().pageId
				: 0;

			Api.executeMethod<any>('posts.getDailyPosts', {
				userId: subUserStore.subUser.UserId,
				date: day,
				projectId: projectId,
				userPageId: pageId
			}).then((response: { Posts: PostDataShort[], UserPages: UserPage[] }) => {
				setPosts(response.Posts);
				setPublishedPosts(response.Posts.filter(s => s.PublishState === "Sent" || s.PublishState === "Failed"));
				setQueuedPosts(response.Posts.filter(s => s.PublishState === "NotSent" || s.PublishState === "ExactTimePlanned"));
				setPublishSucceedPostsCount(response.Posts.filter(s => s.PublishState === "Sent").length);
				setPublishFailedPostsCount(response.Posts.filter(s => s.PublishState === "Failed").length);

				setUserPages(response.UserPages);

				setIsLoading(false);

				resolve();
			});
		});
	}

	const onPostEditorClosed = (projectId: number, itemHash: string) => {
		if (itemHash !== '-') {
			refreshPosts().then(() => {
				principalStore.postEditorPageClosed.next();
			});
		}
		else {
			loadDailyPosts(props.day).then(() => {
				principalStore.postEditorPageClosed.next();
			});
		}
	}

	const refreshPosts = (): Promise<void> => {
		let postRowkeys = posts.map(s => `${s.ProjectId}_${s.ItemHash}`);

		return new Promise<void>(resolve => {
			Api.executeMethod<{ Posts: PostDataShort[] }>('posts.getByIds', {
				userId: subUserStore.subUser.UserId,
				rowKeys: JSON.stringify(postRowkeys)
			}).then(response => {
				for (let post of response.Posts) {
					principalStore.postChanged.next(post);
				}

				resolve();
			});
		});
	};

	const showComposeTimePicker = () => {
		router.pushModal(routeStore.Modals.ComposeTimePicker);
	}

	// function onRenderCallback(
	// 	id, // проп "id" из дерева компонента Profiler, для которого было зафиксировано изменение
	// 	phase, // либо "mount" (если дерево было смонтировано), либо "update" (если дерево было повторно отрендерено)
	// 	actualDuration, // время, затраченное на рендер зафиксированного обновления
	// 	baseDuration, // предполагаемое время рендера всего поддерева без кеширования
	// 	startTime, // когда React начал рендерить это обновление
	// 	commitTime, // когда React зафиксировал это обновление
	// 	interactions // Множество «взаимодействий» для данного обновления 
	// ) {
	// 	// Обработка или логирование результатов...
	// 	console.log({
	// 		id,
	// 		phase,
	// 		actualDuration,
	// 		baseDuration,
	// 		startTime,
	// 		commitTime,
	// 		interactions
	// 	});
	// }

	return <div className='userPostsCalendar__panel'>
			{isLoading &&
				<Spinner
					size='medium'
					className='userPostsCalendar__spinner'
				/>
			}
			{!isLoading &&
				<Group className='userPostsCalendar__panel_group'>
					{!posts.length && <Placeholder
						className='userPostsCalendar__placeholder'
						icon={<Icon56NotePenOutline />}
					>
						{/* Нет постов в этот день */}
						{locale.noPosts}
					</Placeholder>}

					{publishedPosts.length > 0 && <>
						<CardGridHeader
							isCollapsed={!arePublishedPostsShown}
							onCollapsedChanged={(isCollapsed) => setArePublishedPostsShown(!isCollapsed)}>
							{/* Опубликованные */}
							{locale.sentPosts}

							<div className='userPostsCalendar__header_status'>
								{publishSucceedPostsCount > 0 && <>
									<svg
										className='userPostsCalendar__header_status_icon userPostsCalendar__header_status_icon_success'
										viewBox="0 0 512 512">
										<path d="m176 464l-176-176l80-83l112 107l248-248l72 72z" />
									</svg>

									<span>
										{publishSucceedPostsCount}
									</span>
								</>}

								{publishFailedPostsCount > 0 && <>
									<svg
										className='userPostsCalendar__header_status_icon userPostsCalendar__header_status_icon_failed'
										viewBox="0 0 512 512">
										<path d="m503 400l-219-383c-6-10-17-16-28-16-11 0-22 6-28 16l-219 383c-6 10-6 22 0 32 6 10 16 16 28 16l438 0c12 0 22-6 28-16 6-10 6-22 0-32z m-215-16l-64 0 0-64 64 0z m0-96l-64 0 0-128 64 0z" />
									</svg>

									<span>
										{publishFailedPostsCount}
									</span>
								</>}
							</div>
						</CardGridHeader>

						<CardGrid
							size={gridSize}
							className={classNamesString(
								"userPostsCalendar__panel_grid",
								{
									"userPostsCalendar__panel_grid_expanded": arePublishedPostsShown
								}
							)}
						>
							{publishedPosts.map(post =>
								<PostCard
									onChanged={() => refreshPosts()}
									key={`${post.ProjectId}_${post.ItemHash}`}
									post={post}
									userPages={userPages}
								/>
							)}
						</CardGrid>
					</>}

					{queuedPosts.length > 0 && <>
						<CardGridHeader
							isCollapsed={!areQueuedPostsShown}
							onCollapsedChanged={(isCollapsed) => setAreQueuedPostsShown(!isCollapsed)}>
							{/* Ожидающие публикации */}
							{locale.queuedPosts}

							<div className='userPostsCalendar__header_status'>
								<svg
									className='userPostsCalendar__header_status_icon userPostsCalendar__header_status_icon_clock'
									viewBox="0 0 512 512">
									<path d="m256 32c124 0 224 100 224 224c0 124-100 224-224 224c-124 0-224-100-224-224c0-124 100-224 224-224z m136 360c36-36 56-85 56-136c0-51-20-100-56-136c-36-36-85-56-136-56c-51 0-100 20-136 56c-36 36-56 85-56 136c0 51 20 100 56 136c36 36 85 56 136 56c51 0 100-20 136-56z m-168-120l16-176l24 0l8 160l80 96l-16 16z" />
								</svg>

								<span>
									{queuedPosts.length}
								</span>
							</div>
						</CardGridHeader>

						<CardGrid
							size={gridSize}
							className={classNamesString(
								"userPostsCalendar__panel_grid",
								{
									"userPostsCalendar__panel_grid_expanded": areQueuedPostsShown
								}
							)}
						>
							{queuedPosts.map(post =>
								<PostCard
									onChanged={() => refreshPosts()}
									key={`${post.ProjectId}_${post.ItemHash}`}
									post={post}
									userPages={userPages}
								/>
							)}
						</CardGrid>
					</>}

					<ComposeButton onAddPost={() => showComposeTimePicker()} />
				</Group>
			}
		</div>;
}

export default React.memo(UserPostsCalendar);