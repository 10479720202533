export interface MomentL {
    dateFormats12: DateFormats,
    dateFormats24: DateFormats
}

export abstract class MomentL {
    public static RU: MomentL = {
        dateFormats24: {
            time: 'HH:mm',
            shortDate: 'D MMM YY',
            shortDateNoYear: 'D MMM',
            shortDateWeekdayNoYear: 'dd, D MMM',
            fullDate: 'DD MMMM YYYY',
            fullDateNoYear: 'DD MMMM',
            mediumDateWeekdayNoYear: 'dddd, D MMM',
            weekday: 'dddd',
            fullDateWeekdayNoYear: 'dddd, D MMMM',
            fullDatetime: 'DD MMMM YYYY HH:mm',
            fullDatetimeNoYear: 'DD MMM в HH:mm',
            datetimeAt: 'D MMM в HH:mm',
            datetimeFullAt: 'D MMMM в HH:mm',
            datetimeFullAt_withYear: 'DD MMMM YYYY в HH:mm',
            shortMonth_withYear: 'MMM YYYY'
        },

        dateFormats12: {
            time: 'HH:mm',
            shortDate: 'D MMM YY',
            shortDateNoYear: 'D MMM',
            shortDateWeekdayNoYear: 'dd, D MMM',
            weekday: 'dddd',
            fullDate: 'DD MMMM YYYY',
            fullDateNoYear: 'DD MMMM',
            mediumDateWeekdayNoYear: 'dddd, D MMM',
            fullDateWeekdayNoYear: 'dddd, D MMMM',
            fullDatetime: 'DD MMMM YYYY HH:mm',
            fullDatetimeNoYear: 'DD MMM в HH:mm',
            datetimeAt: 'D MMM в HH:mm',
            datetimeFullAt: 'D MMMM в HH:mm',
            datetimeFullAt_withYear: 'DD MMMM YYYY в HH:mm',
            shortMonth_withYear: 'MMM YYYY'
        }
    }


    public static EN: MomentL = {
        dateFormats24: {
            time: 'HH:mm',
            shortDate: 'MMM D, YY',
            shortDateNoYear: 'MMM D',
            shortDateWeekdayNoYear: 'dd, MMM D',
            weekday: 'dddd',
            fullDate: 'MMMM D, YYYY',
            fullDateNoYear: 'MMMM D',
            mediumDateWeekdayNoYear: 'dddd, MMM D',
            fullDateWeekdayNoYear: 'dddd, MMMM D',
            fullDatetime: 'MMMM D, YYYY HH:mm',
            fullDatetimeNoYear: 'MMM D [at] HH:mm',
            datetimeAt: 'MMM D [at] HH:mm',
            datetimeFullAt: 'MMMM D [at] HH:mm',
            datetimeFullAt_withYear: 'MMMM DD, YYYY [at] HH:mm',
            shortMonth_withYear: 'MMM YYYY'
        },

        dateFormats12: {
            time: 'LT',
            shortDate: 'MMM D, YY',
            shortDateNoYear: 'MMM D',
            shortDateWeekdayNoYear: 'dd, MMM D',
            weekday: 'dddd',
            fullDate: 'MMMM D, YYYY',
            fullDateNoYear: 'MMMM D',
            mediumDateWeekdayNoYear: 'dddd, MMM D',
            fullDateWeekdayNoYear: 'dddd, MMMM D',
            fullDatetime: 'MMMM D, YYYY LT',
            fullDatetimeNoYear: 'MMM D [at] LT',
            datetimeAt: 'MMM D [at] LT',
            datetimeFullAt: 'MMMM D [at] LT',
            datetimeFullAt_withYear: 'MMMM DD, YYYY [at] LT',
            shortMonth_withYear: 'MMM YYYY'
        }
    }
}

export interface DateFormats {
    time: string,
    fullDate: string,
    fullDateNoYear: string,
    shortDate: string,
    shortDateNoYear: string,
    shortDateWeekdayNoYear: string,
    fullDatetime: string,
    mediumDateWeekdayNoYear: string,
    weekday: string,
    fullDateWeekdayNoYear: string,
    fullDatetimeNoYear: string,
    datetimeAt: string,
    datetimeFullAt: string,
    datetimeFullAt_withYear: string,
    shortMonth_withYear: string
}