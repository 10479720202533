export interface PostMenuL {
  cancel: string,
  edit: string,
  delete: string,
  publishTo: string,
  returnToQueued: string
}

export abstract class PostMenuL {
  public static RU: PostMenuL = {
    cancel: 'Отменить',
    edit: 'Редактировать',
    delete: 'Удалить',
    publishTo: 'Опубликовать в...',
    returnToQueued: 'Вернуть в неопубликованные'
  }


  public static EN: PostMenuL = {
    cancel: 'Cancel',
    edit: 'Edit',
    delete: 'Delete',
    publishTo: 'Publish to...',
    returnToQueued: 'Return to queued'
  }
}