export interface AskQuestionL {
  header: string,
  hint1: string,
  hint2: string
}

export abstract class AskQuestionL {
  public static RU: AskQuestionL = {
    header: 'Контакты',
    hint1: 'По любым вопросам пишите, пожалуйста, на',
    hint2: 'Будем рады ответить на все интересующие Вас вопросы.'
  }


  public static EN: AskQuestionL = {
    header: 'Contacts',
    hint1: 'If you have any questions, please email ',
    hint2: 'We will be pleased to answer any questions you may have.'
  }
}