export interface RegisterL {
  registerHeader: string,
  name: string,
  noNameError: string,
  namePlaceholder: string,
  noEmailError: string,
  emailPlaceholder: string,
  password: string,
  noPasswordError: string,
  passwordPlaceholder: string,
  promoCode: string,
  promoCodePlaceholder: string,
  iHavePromoCode: string,
  termsHint: string,
  terms: string,
  and: string,
  privacy: string,
  createAccount: string,
  back: string
}

export abstract class RegisterL {
  public static RU: RegisterL = {
    registerHeader: 'Регистрация',
    name: 'Полное имя',
    noNameError: 'Пожалуйста, введите Ваше имя',
    namePlaceholder: 'Ваше имя',
    noEmailError: 'Пожалуйста, введите электронную почту',
    emailPlaceholder: 'Ваш E-mail',
    password: 'Пароль',
    noPasswordError: 'Пожалуйста, введите пароль',
    passwordPlaceholder: 'Придумайте пароль',
    promoCode: 'Промокод',
    promoCodePlaceholder: 'Введите промокод (если есть)',
    iHavePromoCode: 'У меня есть промокод',
    termsHint: 'Нажав «Создать аккаунт», Вы принимаете условия',
    terms: 'Публичной оферты',
    and: 'и',
    privacy: 'Политики конфиденциальности',
    createAccount: 'Создать аккаунт',
    back: 'Назад'
  }


  public static EN: RegisterL = {
    registerHeader: 'Sign Up',
    name: 'Full name',
    noNameError: 'Please enter your name',
    namePlaceholder: 'Your name',
    noEmailError: 'Please enter your email',
    emailPlaceholder: 'Your Email',
    password: 'Password',
    noPasswordError: 'Please enter a password',
    passwordPlaceholder: 'Create a password',
    promoCode: 'Promo code',
    promoCodePlaceholder: 'Enter promo code',
    iHavePromoCode: 'I have the promo code',
    termsHint: 'By clicking «Create account», I agree to ',
    terms: 'NovaPress\'s Terms',
    and: 'and',
    privacy: 'Privacy Policy',
    createAccount: 'Create account',
    back: 'Back'
  }
}