export interface ReturnToQueuedL {
  back: string,
  header: string
}

export abstract class ReturnToQueuedL {
  public static RU: ReturnToQueuedL = {
    back: 'Назад',
    header: 'Возвращаем в неопубликованные...'
  }


  public static EN: ReturnToQueuedL = {
    back: 'Back',
    header: 'Returning to queued...'
  }
}