export interface SendRestorePasswordLetterL {
  noEmailError: string,
  restorePasswordHeader: string,
  restorePassword: string,
  back: string
}

export abstract class SendRestorePasswordLetterL {
  public static RU: SendRestorePasswordLetterL = {
    noEmailError: 'Пожалуйста, введите электронную почту',
    restorePasswordHeader: 'Восстановление пароля',
    restorePassword: 'Восстановить пароль',
    back: 'Назад'
  }


  public static EN: SendRestorePasswordLetterL = {
    noEmailError: 'Please enter your email',
    restorePasswordHeader: 'Restore password',
    restorePassword: 'Restore password',
    back: 'Back'
  }
}