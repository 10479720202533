import React, { useState } from 'react';
import { observer } from "mobx-react-lite"
import { useLocation, useRouter } from '@happysanta/router';

import './DeleteSubUser.css';

import { Api, StateManager, useLocale, useStore } from '../../../shared/_index';
import { DeleteSubUserL } from './DeleteSubUser.L';
import { Button, FormItem, FormLayout, Group, ModalPage, ModalPageHeader, PanelHeaderBack, PanelHeaderButton, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { Icon24Dismiss } from '@vkontakte/icons';

const DeleteSubUser = (props: { id: string, dynamicContentHeight: boolean }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let locale: DeleteSubUserL = useLocale(DeleteSubUserL);

	let router = useRouter();
	let location = useLocation();

	const [couldNotDelete, setCouldNotDelete] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { principalStore, subUserStore, routeStore } = useStore();

	const closeModal = () => {
		router.replaceModal(routeStore.Modals.SelectSubUser);
	}

	let subUser = StateManager.getStateValue(location, 'subUser');

	if (!subUser) {
		closeModal();
		return <></>;
	}

	const doDelete = () => {
		if (isSubmitting) {
			return;
		}

		setIsSubmitting(true);

		Api.executeMethod('subUsers.delete', {
			userId: subUser.UserId
		}).then(() => {
			let principal = principalStore.principal;

			principal.SubUsers = principal.SubUsers.filter(s => s.UserId !== subUser.UserId);

			if (subUserStore.subUser
				&& subUserStore.subUser.UserId === subUser.UserId) {
				subUserStore.subUser = principal.SubUsers[0];
			}

			setIsSubmitting(false);

			closeModal();
		}).catch(() => {
			setIsSubmitting(false);
			setCouldNotDelete(true);
		});
	}

	return <ModalPage
		id={props.id}
		header={subUser &&
			<ModalPageHeader
				before={
					<PanelHeaderBack label="" onClick={() => closeModal()} />
				}
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => closeModal()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{/* Подтверждение удаления */}
				{locale.header}
			</ModalPageHeader>
		}
	>
		{subUser && <Group>
			<FormLayout>
				<FormItem
					status={couldNotDelete ? "error" : "default"}
					bottom={
						couldNotDelete
							// Не удается удалить рабочую область. Попробуйте, пожалуйста, позже.
							? locale.error
							: null
					}
				>
					<div>
						{/* Вы уверены, что хотите удалить рабочую область [workspace]? */}
						{locale.confirmation.replace('[workspace]', subUser.WorkspaceName)}
					</div>
				</FormItem>

				<FormItem>
					<Button
						size="l"
						stretched
						onClick={() => doDelete()}
						loading={isSubmitting}>
						{/* Удалить */}
						{locale.cta}
					</Button>
				</FormItem>
			</FormLayout>
		</Group>}
	</ModalPage>;
}

export default React.memo(DeleteSubUser);