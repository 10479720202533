import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite"
import { useLocation, useRouter } from '@happysanta/router';

import './SwitchTo.css';

import { useStore } from '../../../shared/_index';
import { Panel, Spinner } from '@vkontakte/vkui';

const SwitchTo = (props: { id: string }) => {
	let router = useRouter();
	let location = useLocation();

	const { principalStore, subUserStore, routeStore } = useStore();

	useEffect(() => {
		principalStore.get().then(() => {
			let principal = principalStore.principal;

			let userId = +location.getParams().userId;

			if (!userId) {
				userId = subUserStore.subUser
					? subUserStore.subUser.UserId
					: principal.SubUsers[0].UserId;
			}

			if (principalStore.selectedUserTab === 'settings') {
				router.replacePage(routeStore.Pages.Settings.Route, { userId: userId.toString() });
			}
			else if (principalStore.selectedUserTab === 'analytics') {
				router.replacePage(routeStore.Pages.Analytics.Route, { userId: userId.toString() });
			}
			else {
				router.replacePage(routeStore.Pages.Posts.Route, { userId: userId.toString() });
			}
		});
	// eslint-disable-next-line
	}, [props.id]);

	return <Panel
		id={props.id}>
		<Spinner size="large" />
	</Panel>;
}

export default React.memo(SwitchTo);