import { autorun } from "mobx";
import { useState, useEffect } from 'react';
import { useStore } from '../_index';

export const useLocale = (localeObj: any): any => {
  const { languageStore } = useStore();
  const [languageCode, setLanguageCode] = useState(languageStore.language);

  useEffect(() => {
    autorun(() => {
      setLanguageCode(languageStore.language);
    });
    setLanguageCode(languageStore.language);
  });

  return localeObj[languageCode];
}