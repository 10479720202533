export interface UserPostDraftsL {
  noPosts: string,
  loadMore: string
}

export abstract class UserPostDraftsL {
  public static RU: UserPostDraftsL = {
    noPosts: 'Нет постов для отображения',
    loadMore: 'Загрузить ещё'
  }


  public static EN: UserPostDraftsL = {
    noPosts: 'No posts to display',
    loadMore: 'Load more'
  }
}