/** Социальная сеть. */
export enum SocialNetwork {
    /** Вконтакте. */
    Vkontakte = 0,

    /** Facebook. */
    Facebook = 1,

    /** Twitter. */
    Twitter = 2,

    /** Одноклассники. */
    Odnoklassniki = 3,

    /** Google+. */
    Google = 4,

    /** ЖЖ. */
    LiveJournal = 5,

    /** МойМир. */
    MailRu = 6,

    /** Instagram. */
    Instagram = 7,

    /** Telegram. */
    Telegram = 8,

    /** LinkedIn. */
    LinkedIn = 9,

    /** Pinterest. */
    Pinterest = 10,

    /** Viber. */
    Viber = 11
}

/** Социальная сеть. */
export abstract class SocialNetworks {
    /** Вконтакте. */
    public static Vkontakte: string = 'Vkontakte';

    /** Facebook. */
    public static Facebook: string = 'Facebook';

    /** Twitter. */
    public static Twitter: string = 'Twitter';

    /** Одноклассники. */
    public static Odnoklassniki: string = 'Odnoklassniki';

    /** Google+. */
    public static Google: string = 'Google';

    /** ЖЖ. */
    public static LiveJournal: string = 'LiveJournal';

    /** МойМир. */
    public static MailRu : string = 'MailRu';

    /** Instagram. */
    public static Instagram : string = 'Instagram';

    /** Telegram. */
    public static Telegram : string = 'Telegram';

    /** LinkedIn. */
    public static LinkedIn : string = 'LinkedIn';

    /** Pinterest. */
    public static Pinterest: string = 'Pinterest';

     /** Viber. */
     public static Viber: string = 'Viber';
}