/** Страница соц сети, подключенная к сервису. */
export interface SocialPage {
    /** Идентификатор страницы в соц сети. */
    Id: string,

    /** Имя страницы в соц сети. */
    Name: string,

    /** Адрес страницы в соц сети. */
    Url: string,

    /** Адрес фотографии страницы в соц сети. */
    PhotoUrl: string,

    /** Тип страницы в соц сети (группа, профиль, бизнес-страница). */
    PageType: "Unknown" | "Profile" | "Group" | "ManagedPage" | "Event" | "Application",

    /** Название типа страницы в соц сети (например, "Открытая группа"). */
    PageTypeName: string,

    /** К какой соц сети относится данная страница. */
    SocialNetwork: "Vkontakte" | "Facebook" | "Twitter" | "Odnoklassniki" | "Google" | "LiveJournal" | "MailRu" | "Instagram" | "Telegram" | "LinkedIn" | "Pinterest",

    /** Идентификатор профиля в соц сети, который администрирует эту страницу. */
    SocialAccountPageId: string,

    /** Название профиля в соц сети, который администрирует данную страницу. */
    SocialAccountPageName: string,

    /** Локализованное название типа страницы в соц сети (например, "Открытая группа"). */
    PageTypeNameLocalized?: string
}

export abstract class SocialPage {
    public static getPageLogoUrl(page: SocialPage): string | undefined {
        if (page.PhotoUrl) {
            if (page.SocialNetwork === "Odnoklassniki"
                && page.PhotoUrl.indexOf('.gif') !== -1) {
                return '/images/social-default-logos/ok-group.png';
            }
            else if (page.SocialNetwork === "Facebook"
                && page.PageType === "Group") {
                return '/images/social-default-logos/fb-group.png';
            }
            else {
                return page.PhotoUrl;
            }
        }
        else if (page.SocialNetwork === "Vkontakte") {
            if (page.PageType === "Profile") {
                return '/images/social-default-logos/vk-profile.png';
            }
            else {
                return '/images/social-default-logos/vk-group.gif';
            }
        }
        else if (page.SocialNetwork === "Facebook") {
            if (page.PageType === "Profile") {
                return '/images/social-default-logos/fb-profile.jpg';
            }
            else {
                return '/images/social-default-logos/fb-page.png';
            }
        }
        else if (page.SocialNetwork === "Google") {
            if (page.PageType === "Profile") {
                return '/images/social-default-logos/gplus-profile.jpg';
            }
            else if (page.PageType === "Group") {
                return '/images/social-default-logos/gplus-group.jpg';
            }
            else {
                return '/images/social-default-logos/gplus-page.jpg';
            }
        }
        else if (page.SocialNetwork === "Odnoklassniki") {
            return '/images/social-default-logos/ok-group.png';
        }
        else if (page.SocialNetwork === "Twitter") {
            return '/images/social-default-logos/tw-profile.png';
        }
        else if (page.SocialNetwork === "Instagram") {
            return '/images/social-default-logos/inst-2.jpg';
        }
        else if (page.SocialNetwork === "LiveJournal") {
            return '/images/social-default-logos/lj-any-2.png';
        }
        else if (page.SocialNetwork === "Telegram") {
            return null as any; // '/images/social-default-logos/telegram-channel.png';
        }
        else if (page.SocialNetwork === "LinkedIn") {
            if (page.PageType === "Profile") {
                return '/images/social-default-logos/linkedin-profile.png';
            }
            else {
                return '/images/social-default-logos/linkedin-company.png';
            }
        }
        else if (page.SocialNetwork === "Pinterest") {
            return '/images/social-default-logos/pinterest.png';
        }

        return null as any;
    }

}
