export interface PrivateToolbarL {
  posts: string,
  analytics: string,
  settings: string
}

export abstract class PrivateToolbarL {
  public static RU: PrivateToolbarL = {
    posts: 'Посты',
    analytics: 'Аналитика',
    settings: 'Настройки'
  }


  public static EN: PrivateToolbarL = {
    posts: 'Posts',
    analytics: 'Analytics',
    settings: 'Settings'
  }
}