import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite"

import './ReturnToQueued.css';

import { Api, useLocale, useStore } from '../../../shared/_index';
import { ReturnToQueuedL } from './ReturnToQueued.L';
import { ModalPage, ModalPageHeader, PanelHeaderButton, Spinner, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { Icon24Dismiss } from '@vkontakte/icons';
import { useLocation, useRouter } from '@happysanta/router';

const ReturnToQueued = (props: { id: string, dynamicContentHeight: boolean }) => {
	let locale: ReturnToQueuedL = useLocale(ReturnToQueuedL);

	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;

	let router = useRouter();
	let location = useLocation();

	let projectId = +location.getParams().itemProjectId;
	let itemHash = location.getParams().itemHash;

	const { principalStore, subUserStore } = useStore();

	useEffect(() => {
		principalStore.get().then(() => {
			let principal = principalStore.principal;

			Api.executeMethod('posts.returnToQueued', {
				userId: subUserStore.subUser.UserId,
				projectId: projectId,
				itemHash: itemHash
			}).then(() => {
				principalStore.postsChanged.next();
				principalStore.postReturnedToQueued.next({ projectId: projectId, itemHash: itemHash });

				router.popPage();
			});
		});
	}, [props.id]);

	return <ModalPage
		id={props.id}
		header={
			<ModalPageHeader
				after={
					isMobile &&
					// Назад
					<PanelHeaderButton aria-label={locale.back} onClick={() => router.popPage()}>
						<Icon24Dismiss />
					</PanelHeaderButton>
				}
			>
				{/* Возвращаем в неопубликованные... */}
				{locale.header}
			</ModalPageHeader>
		}
	>
		<Spinner
			size='medium'
			className='returnToQueued__spinner'
		/>
	</ModalPage >;
}

export default React.memo(ReturnToQueued);