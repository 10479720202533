export interface ComposeButtonL {
  connectPages: string,
  createPost: string
}

export abstract class ComposeButtonL {
  public static RU: ComposeButtonL = {
    connectPages: 'Подключить страницы',
    createPost: 'Создать пост'
  }


  public static EN: ComposeButtonL = {
    connectPages: 'Connect Pages',
    createPost: 'Create post'
  }
}