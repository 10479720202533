export interface PublishResultsListItemL {
  published: string,
  failed: string,
  notPublished: string
}

export abstract class PublishResultsListItemL {
  public static RU: PublishResultsListItemL = {
    published: 'Опубликовано',
    failed: 'Ошибка',
    notPublished: 'Не опубликовано'
  }


  public static EN: PublishResultsListItemL = {
    published: 'Published',
    failed: 'Error',
    notPublished: 'Not published'
  }
}