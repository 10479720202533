export interface LaunсherWorkspaceHeaderL {
  paidUntil: string
}

export abstract class LaunсherWorkspaceHeaderL {
  public static RU: LaunсherWorkspaceHeaderL = {
    paidUntil: 'Оплачен до'
  }


  public static EN: LaunсherWorkspaceHeaderL = {
    paidUntil: 'Paid until'
  }
}