import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"

import './PostCard.css';

import { PostDataShort, StateManager, UserPage, useStore } from '../../../shared/_index';
import { Card } from '@vkontakte/vkui';
import Moment from '../../../shared/components/Moment/Moment';
import PostCardPages from '../PostCardPages/PostCardPages';
import { useLocation, useRouter } from '@happysanta/router';

export interface PostCardProps {
	post: PostDataShort,
	userPages: UserPage[],

	onChanged: () => Promise<void>
}

const PostCard = (props: PostCardProps) => {
	const [post, setPost] = useState(props.post);

	let router = useRouter();
	let location = useLocation();

	const { principalStore, subUserStore, routeStore } = useStore();

	useEffect(() => {
		let $postSub = principalStore.postChanged.subscribe(postToSet => {
			if (postToSet.ProjectId === post.ProjectId && postToSet.ItemHash === post.ItemHash) {
				setPost(postToSet);
			}
		});

		return () => {
			$postSub.unsubscribe();
		};
		// eslint-disable-next-line
	}, []);

	const showMenu = (event: any, post: PostDataShort) => {
		router.pushPopup(routeStore.Popouts.PostMenu, {
			state: StateManager.setState({
				post: post,
				event: event
			})
		});
	}

	const showEditPost = () => {
		routeStore.Modals.PostEditor.show(subUserStore.subUser.UserId, props.post.ProjectId, props.post.ItemHash, location, router);
	};

	return <Card className='postCard'>
		{post.ImageUrl &&
			<div
				className='postCard__image'
				style={{ backgroundImage: 'url(' + post.ImageUrl + ')' }}
			/>
		}

		<div className={"postCard__content " + (!post.ImageUrl ? "postCard__content_noImage" : "")}>
			<div className='postCard__text'>
				{post.TextShort}
			</div>

			<div className='postCard__details'>
				<div className='postCard__time'>
					{(post.PublishState === "NotSent" || post.PublishState === "ExactTimePlanned") && post.PublishTime &&
						<svg className='postCard__icon_clock' viewBox="0 0 512 512">
							<path d="m256 32c124 0 224 100 224 224c0 124-100 224-224 224c-124 0-224-100-224-224c0-124 100-224 224-224z m136 360c36-36 56-85 56-136c0-51-20-100-56-136c-36-36-85-56-136-56c-51 0-100 20-136 56c-36 36-56 85-56 136c0 51 20 100 56 136c36 36 85 56 136 56c51 0 100-20 136-56z m-168-120l16-176l24 0l8 160l80 96l-16 16z" />
						</svg>
					}

					{(post.PublishState === "Sent") &&
						<svg className='postCard__icon_check' viewBox="0 0 512 512">
							<path d="m176 464l-176-176l80-83l112 107l248-248l72 72z" />
						</svg>
					}

					{(post.PublishState === "Failed") &&
						<div className='postCard__icon_fail'>
							<svg viewBox="0 0 512 512">
								<path d="m503 400l-219-383c-6-10-17-16-28-16-11 0-22 6-28 16l-219 383c-6 10-6 22 0 32 6 10 16 16 28 16l438 0c12 0 22-6 28-16 6-10 6-22 0-32z m-215-16l-64 0 0-64 64 0z m0-96l-64 0 0-128 64 0z" />
							</svg>
						</div>
					}

					<span className='postCard__icon_time_moment'>
						{post.PublishTime && <Moment value={post.PublishTime} format="time" />}
						{!post.PublishTime && <Moment value={post.CreatedTime} format="datetimeAt" />}
					</span>
				</div>
			</div>

			<PostCardPages
				post={post}
				userPages={props.userPages}
				className='postCard__pages'
			/>
		</div>

		<div className='postCard__menu' onClick={(e) => showMenu(e, post)}>
			<svg viewBox="0 0 512 512">
				<path d="m396 219c0 0-120 115-120 115c-6 6-12 9-20 9c-8 0-14-3-20-9c0 0-120-115-120-115c-6-5-8-12-8-21c0-8 2-15 8-21c13-13 26-13 40 0c0 0 100 96 100 96c0 0 100-96 100-96c14-13 27-13 40 0c6 6 8 13 8 21c0 9-2 16-8 21" />
			</svg>
		</div>

		<div className='postCard__edit' onClick={() => showEditPost()}>

		</div>
	</Card>;
}

export default React.memo(PostCard);